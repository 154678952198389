import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnEditorOptions, ColumnEditorType } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Rating } from 'primereact/rating';

const data = [
  {"id": "1000","code": "f230fh0g3","name": "Bamboo Watch","description": "Product Description","image": "bamboo-watch.jpg","price": 65,"category": "Accessories","quantity": 24,"inventoryStatus": "INSTOCK","rating": 5},
  {"id": "1001","code": "nvklal433","name": "Black Watch","description": "Product Description","image": "black-watch.jpg","price": 72,"category": "Accessories","quantity": 61,"inventoryStatus": "INSTOCK","rating": 4},
  {"id": "1002","code": "zz21cz3c1","name": "Blue Band","description": "Product Description","image": "blue-band.jpg","price": 79,"category": "Fitness","quantity": 2,"inventoryStatus": "LOWSTOCK","rating": 3},
  {"id": "1003","code": "244wgerg2","name": "Blue T-Shirt","description": "Product Description","image": "blue-t-shirt.jpg","price": 29,"category": "Clothing","quantity": 25,"inventoryStatus": "INSTOCK","rating": 5},
  {"id": "1004","code": "h456wer53","name": "Bracelet","description": "Product Description","image": "bracelet.jpg","price": 15,"category": "Accessories","quantity": 73,"inventoryStatus": "INSTOCK","rating": 4},
  {"id": "1005","code": "av2231fwg","name": "Brown Purse","description": "Product Description","image": "brown-purse.jpg","price": 120,"category": "Accessories","quantity": 0,"inventoryStatus": "OUTOFSTOCK","rating": 4},
  {"id": "1006","code": "bib36pfvm","name": "Chakra Bracelet","description": "Product Description","image": "chakra-bracelet.jpg","price": 32,"category": "Accessories","quantity": 5,"inventoryStatus": "LOWSTOCK","rating": 3},
  {"id": "1007","code": "mbvjkgip5","name": "Galaxy Earrings","description": "Product Description","image": "galaxy-earrings.jpg","price": 34,"category": "Accessories","quantity": 23,"inventoryStatus": "INSTOCK","rating": 5},
  {"id": "1008","code": "vbb124btr","name": "Game Controller","description": "Product Description","image": "game-controller.jpg","price": 99,"category": "Electronics","quantity": 2,"inventoryStatus": "LOWSTOCK","rating": 4},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
  {"id": "1009","code": "cm230f032","name": "Gaming Set","description": "Product Description","image": "gaming-set.jpg","price": 299,"category": "Electronics","quantity": 63,"inventoryStatus": "INSTOCK","rating": 3},
]

const statuses = [
  { label: 'In Stock', value: 'INSTOCK' },
  { label: 'Low Stock', value: 'LOWSTOCK' },
  { label: 'Out of Stock', value: 'OUTOFSTOCK' }
];

const getStatusLabel = (status : string) => {
  return statuses.find(x => x.value === status)?.label;
}

const columns = [
  { field: 'code', header: 'Code', minWidth: '400px' },
  { field: 'name', header: 'Name', minWidth: '400px' },
  { field: 'description', header: 'Product Description', minWidth: '400px' },
  { field: 'quantity', header: 'Quantity', minWidth: '400px' },
  { field: 'price', header: 'Price', minWidth: '400px' },
  { field: 'category', header: 'Category', minWidth: '400px' },
  { field: 'inventoryStatus', header: 'InventoryStatus', minWidth: '400px' },
  { field: 'rating', header: 'Rating', minWidth: '400px' },
];

// "Accessories","quantity": 24,"inventoryStatus": "INSTOCK","rating": 5},

function Experiments() {
  const cellEditor : ColumnEditorType = (options) => {
    if (options.field === 'price')
      return priceEditor(options);
    else if (options.field === 'inventoryStatus')
      return statusEditor(options);
    else if (options.field === 'rating')
      return ratingEditor(options);
    else
      return textEditor(options);
  }

  const ratingEditor = (options : ColumnEditorOptions) => {
    return <Rating
      value={options.value}
      onChange={(e) => options.editorCallback && options.editorCallback(e.target.value)}
      stars={5}
    />
  }
  const textEditor = (options : ColumnEditorOptions)  => {
    return <InputText
      type="text"
      value={options.value}
      onChange={(e) => {
        if (options.editorCallback) {
          options.editorCallback(e.target.value);
        }
      }}
    />;
  }

  const priceEditor = (options : ColumnEditorOptions) => {
    return <InputNumber
      value={options.value}
      mode="currency"
      currency="USD"
      locale="en-US"
      onValueChange={(e) => {
        if (options.editorCallback)
          options.editorCallback(e.target.value);
      }}
    />
  }

  const statusEditor = (options : ColumnEditorOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        optionLabel='label'
        optionValue='value'
        onChange={(e) => options.editorCallback && options.editorCallback(e.target.value)}
        placeholder='Select a Status'
        itemTemplate={(option) => {
          return <span className={`product-badge status-${option.value.toLowerCase()}`}>{option.label}</span>;
        }}
      />
    );
  }

  const priceBodyTemplate = (rowData : any) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.price);
  }

  const statusBodyTemplate = (rowData : any) => {
    return getStatusLabel(rowData.inventoryStatus);
  }

  const ratingBodyTemplate = (rowData: any) => {
    return <Rating value={rowData.rating} readOnly cancel={false}/>
  }

  const isPositiveInteger = (val : any) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  const onCellEditComplete = (e : any) => {
    console.debug('onCellEditComplete')
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case 'quantity':
      case 'price':
      case 'rating':
        if (isPositiveInteger(newValue))
          rowData[field] = newValue;
        else
          console.error(`invalid number ${newValue}`)
          event.preventDefault();
        break;

      default:
        if (newValue && newValue.trim().length > 0)
          rowData[field] = newValue;
        else
          event.preventDefault();
        break;
    }
  }

  return (
    <div className="card">
      <h5>Cell Editing with Sorting and Filter</h5>
      <DataTable
        value={data}
        editMode="cell"
        className="editable-cells-table"
        filterDisplay="row"
        responsiveLayout="scroll"
        scrollable
        scrollHeight="700px"
        scrollDirection="both"
        sortMode="multiple"
      >
        {
          columns.map(({ field, header }) => {
            return <Column
              key={field}
              field={field}
              header={header}
              filter
              sortable
              style={{ flexGrow: 1, flexBasis: '300px' }}
              body={
                    (field === 'price' && priceBodyTemplate) ||
                    (field === 'inventoryStatus' && statusBodyTemplate) ||
                    (field === 'rating' && ratingBodyTemplate)
              }
              editor={(options) => cellEditor(options)}
              onCellEditComplete={onCellEditComplete}
            />
          })
        }
      </DataTable>
    </div>
  );
}

export default Experiments;