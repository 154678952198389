import React, { memo, useMemo, useRef } from 'react';
import { Button } from 'primereact/button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Menu } from 'primereact/menu';
import { RowsWithId } from '../LogisticsDataGridBase';
import VisibilityToggler from '../../VisibilityToggler';

interface ActionMenuProps {
  actions?: ActionMenuItem[],
  selectedRows?: RowsWithId[],
  setSelectedRows:  React.Dispatch<React.SetStateAction<RowsWithId[] | undefined>>,
  hide?: boolean
}

export interface ActionMenuItem {
  label: string,
  icon?: string,
  action: (ids: number[], selectedRows: any[]) => void,
}

const ActionMenu = memo((props: ActionMenuProps) => {
  const { actions, selectedRows, setSelectedRows } = props;
  const menuRef = useRef<Menu>(null);

  const actionItems = useMemo(() => {
    return actions?.map(action => {
      return {
        label: action.label,
        icon: action.icon,
        command: () => {
          if (!selectedRows?.length) return;

          const ids = selectedRows.map(row => row.id!);
          action.action(ids, selectedRows);
          setSelectedRows(undefined);
        }
      }
    });
  },[selectedRows, actions, setSelectedRows]);

  if (!actionItems?.length) return <></>;

  return <div>
    <VisibilityToggler visible={!props.hide}>
      <Button
        className='p-button-outlined px-5 ml-2 text-base font-bold'
        disabled={!props.selectedRows?.length}
        onClick={(e) => {
          menuRef?.current?.toggle(e);
        }}
      >
        <p className='my-0'>Actions</p>
        <ExpandMoreIcon fontSize='medium' className='ml-1' />
      </Button>
      <Menu model={actionItems} popup ref={menuRef} key='popup_menu' />
    </VisibilityToggler>
  </div>;
});

export default ActionMenu;