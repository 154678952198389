/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ETrackingStatus = {
    NotSet: 'NotSet',
    Attention: 'Attention',
    Checking: 'Checking',
    Loaded: 'Loaded',
    Delivered: 'Delivered',
    Hidden: 'Hidden'
} as const;
export type ETrackingStatus = typeof ETrackingStatus[keyof typeof ETrackingStatus];


export function ETrackingStatusFromJSON(json: any): ETrackingStatus {
    return ETrackingStatusFromJSONTyped(json, false);
}

export function ETrackingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETrackingStatus {
    return json as ETrackingStatus;
}

export function ETrackingStatusToJSON(value?: ETrackingStatus | null): any {
    return value as any;
}

