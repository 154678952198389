import React, { CSSProperties } from 'react';
import { ApiListOfLoadsGetRequest, ListOfLoads } from '../../generated-api';
import dayjs from 'dayjs';
import LogisticsColumnProps from '../../components/data-grid/LogisticsColumnProps';
import serverFilterComponent from '../../components/filters/server/ServerFilter';
import ServerWeekCalendar from '../../components/filters/server/ServerWeekCalendar';
import RowActionButton, { RowActionItem } from '../../components/data-grid/actions/RowActionButton';


export default function listOfLoadsColumns(actionItems: (row: ListOfLoads) => RowActionItem<ListOfLoads>[] | undefined) : LogisticsColumnProps[] {
  return [
    {
      field: 'number',
      header: '#',
      style: rowStyle('6rem'),
      sortable: true,
      filter: true,
      ...serverFilterComponent(props => <ServerWeekCalendar {...props} key='week_filter' />),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiListOfLoadsGetRequest) : ApiListOfLoadsGetRequest => {
        return { ...serverFilterModel, number: filterMetaData.value };
      },
    },
    {
      field: 'syl',
      header: 'Syl#',
      style:  rowStyle('2rem'),
      sortable: true,
      filter: false,
    },
    {
      field: 'r',
      header: 'R#',
      style: rowStyle('2rem'),
      sortable: true,
      filter: false,
    },
    {
      field: 'createtime',
      header: 'Date',
      style: rowStyle('4rem'),
      sortable: true,
      filter: false,
      body: (rowData: ListOfLoads) => rowData.createtime
        ? dayjs.tz(rowData.createtime).format('MM/DD/YYYY')
        : '',
      },
    {
      field: 'vehicleId',
      header: 'Unit',
      style: rowStyle('2rem'),
      sortable: true,
      filter: false,
      body: (rowData: ListOfLoads) => {
        if (rowData.vehicleId) {
          return `V${rowData.vehicleId}`;
        }

        return '';
      }
    },
    {
      field: 'driver',
      header: 'Driver',
      style: rowStyle('4rem'),
      sortable: true,
      filter: false,
      body: (rowData: ListOfLoads) => {
        if (rowData.dFirstName && rowData.dLastName) {
          return `${rowData.dFirstName} ${rowData.dLastName}`;
        }
        return '';
      },
      serverSortFields: ['dFirstName', 'dLastName'],
    },
    {
      field: 'companyName',
      header: 'Company Name',
      style: rowStyle('8rem'),
      sortable: true,
      filter: false,
    },
    {
      field: 'brokerage.name',
      header: 'Brokerage',
      style: rowStyle('6rem'),
      sortable: true,
      filter: false,
      body: (rawData: ListOfLoads) => rawData?.brokerage?.name || '',
    },
    {
      field: 'pickUpAtCity',
      header: 'From',
      style: rowStyle('4rem'),
      sortable: true,
      filter: false,
    },
    {
      field: 'pickUpAtState',
      header: 'State',
      style: rowStyle('1rem'),
      sortable: true,
      filter: false,
    },
    {
      field: 'pickUpDate',
      header: 'Date',
      style: rowStyle('6rem'),
      sortable: true,
      filter: false,
      body: (rowData: ListOfLoads) => rowData.pickUpDate
      ? dayjs.tz(rowData.pickUpDate).format('MM/DD/YYYY hh:mm A')
        : '',
    },
    {
      field: 'deliverToCity',
      header: 'To',
      style: rowStyle('4rem'),
      sortable: true,
      filter: false,
    },
    {
      field: 'deliverToState',
      header: 'State',
      style: rowStyle('1rem'),
      sortable: true,
      filter: false,
    },
    {
      field: 'deliveryDate',
      header: 'Delivery Date',
      style: rowStyle('6rem'),
      sortable: true,
      filter: false,
      body: (rowData: ListOfLoads) => rowData.deliveryDate
        ? dayjs.tz(rowData.deliveryDate).format('MM/DD/YYYY hh:mm A')
        : '',
    },
    {
      field: 'user',
      header: 'Dispatcher',
      style: rowStyle('4rem'),
      sortable: true,
      filter: false,
      body: (rowData: ListOfLoads) => {
        if (rowData.user?.firstName && rowData.user?.lastName) {
          return `${rowData.user.firstName} ${rowData.user.lastName}`;
        }
        return '';
      },
      serverSortFields: ['user.firstName', 'user.lastName'],
    },
    {
      field: 'miles',
      header: 'Miles',
      style: rowStyle('2rem'),
      sortable: true,
      filter: false,
    },
    {
      field: 'gross',
      header: 'Gross',
      style: rowStyle('2rem'),
      sortable: true,
      filter: false,
    },
    {
      field: 'priceForDriver',
      header: 'Payment',
      style: rowStyle('6rem'),
      sortable: true,
      filter: false,
      body: (rowData: ListOfLoads) => {
        if (rowData.priceForDriver) {
          return <div
            className={rowData.payed ? 'text-green-500' : 'text-red-500'}
          >
            <span>
              {rowData.payed
              ? `${rowData.priceForDriver} (Payed)`
              : `${rowData.priceForDriver} (Not payed)`}
            </span>
          </div>
        }
        return '';
      },
      serverSortFields: ['priceForDriver', 'payed'],
    },
    {
      field: 'profit',
      header: 'Profit',
      style: rowStyle('2rem'),
      sortable: true,
      filter: false,
      body: (rowData: ListOfLoads) => `${rowData.profit || 0}`,
    },
    {
      field: 'profitPercent',
      header: '%',
      style: rowStyle('1rem'),
      sortable: true,
      filter: false,
      body: (rowData: ListOfLoads) => `${rowData.profitPercent || 0}%`,
    },
    {
      field: 'note',
      header: 'Comment',
      style: rowStyle('6rem'),
      sortable: true,
      filter: false,
    },
    {
      field: 'action',
      header: 'Action',
      style: rowStyle('1rem'),
      sortable: false,
      filter: false,
      body: (rowData: ListOfLoads) => <RowActionButton rowData={rowData} actionItems={actionItems(rowData)} /> ,
    },
  ];
}

const rowStyle = (flexWidth: number | string) => {
  return {
    flex: 1,
    flexBasis: flexWidth,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;
}
