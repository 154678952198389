import styled from '@emotion/styled';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css'

const StyledPhoneInput = styled(PhoneInput)`
display: flex;

& > .form-control {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  flex-grow: 1;
  text-transform: uppercase;
  font-size: 1rem;
  height: 28.75px;
}

& > .flag-dropdown {
  border: 0;
}

& > .p-inputtext:focus {
  box-shadow: none;
}
`

function LogisticsPhoneInput(props : PhoneInputProps) {
  return <StyledPhoneInput {...props} inputClass='p-inputtext' />
}

export default LogisticsPhoneInput;