/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EVehicleStatusFilter = {
    Active: 'Active',
    OutOfService: 'OutOfService',
    InService: 'InService',
    UnconfinedService: 'UnconfinedService',
    OnLoad: 'OnLoad',
    Retried: 'Retried',
    Error: 'Error'
} as const;
export type EVehicleStatusFilter = typeof EVehicleStatusFilter[keyof typeof EVehicleStatusFilter];


export function EVehicleStatusFilterFromJSON(json: any): EVehicleStatusFilter {
    return EVehicleStatusFilterFromJSONTyped(json, false);
}

export function EVehicleStatusFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): EVehicleStatusFilter {
    return json as EVehicleStatusFilter;
}

export function EVehicleStatusFilterToJSON(value?: EVehicleStatusFilter | null): any {
    return value as any;
}

