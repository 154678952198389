/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface DispSettings
 */
export interface DispSettings {
    /**
     * 
     * @type {string}
     * @memberof DispSettings
     */
    bidEmailTemplate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DispSettings
     */
    maxFlyRadius?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DispSettings
     */
    dispatcherId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof DispSettings
     */
    dispatcher?: User;
    /**
     * 
     * @type {number}
     * @memberof DispSettings
     */
    id?: number;
}

/**
 * Check if a given object implements the DispSettings interface.
 */
export function instanceOfDispSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DispSettingsFromJSON(json: any): DispSettings {
    return DispSettingsFromJSONTyped(json, false);
}

export function DispSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DispSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bidEmailTemplate': !exists(json, 'bidEmailTemplate') ? undefined : json['bidEmailTemplate'],
        'maxFlyRadius': !exists(json, 'maxFlyRadius') ? undefined : json['maxFlyRadius'],
        'dispatcherId': !exists(json, 'dispatcherId') ? undefined : json['dispatcherId'],
        'dispatcher': !exists(json, 'dispatcher') ? undefined : UserFromJSON(json['dispatcher']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function DispSettingsToJSON(value?: DispSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bidEmailTemplate': value.bidEmailTemplate,
        'maxFlyRadius': value.maxFlyRadius,
        'dispatcherId': value.dispatcherId,
        'dispatcher': UserToJSON(value.dispatcher),
        'id': value.id,
    };
}

