/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeletedOrder } from './DeletedOrder';
import {
    DeletedOrderFromJSON,
    DeletedOrderFromJSONTyped,
    DeletedOrderToJSON,
} from './DeletedOrder';
import type { LoadedOrder } from './LoadedOrder';
import {
    LoadedOrderFromJSON,
    LoadedOrderFromJSONTyped,
    LoadedOrderToJSON,
} from './LoadedOrder';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface VehicleType
 */
export interface VehicleType {
    /**
     * 
     * @type {string}
     * @memberof VehicleType
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleType
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof VehicleType
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleType
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof VehicleType
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {Array<LoadedOrder>}
     * @memberof VehicleType
     */
    loadedOrders?: Array<LoadedOrder> | null;
    /**
     * 
     * @type {Array<DeletedOrder>}
     * @memberof VehicleType
     */
    deletedOrders?: Array<DeletedOrder> | null;
    /**
     * 
     * @type {Array<Vehicle>}
     * @memberof VehicleType
     */
    vehicles?: Array<Vehicle> | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleType
     */
    id?: number;
}

/**
 * Check if a given object implements the VehicleType interface.
 */
export function instanceOfVehicleType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleTypeFromJSON(json: any): VehicleType {
    return VehicleTypeFromJSONTyped(json, false);
}

export function VehicleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'loadedOrders': !exists(json, 'loadedOrders') ? undefined : (json['loadedOrders'] === null ? null : (json['loadedOrders'] as Array<any>).map(LoadedOrderFromJSON)),
        'deletedOrders': !exists(json, 'deletedOrders') ? undefined : (json['deletedOrders'] === null ? null : (json['deletedOrders'] as Array<any>).map(DeletedOrderFromJSON)),
        'vehicles': !exists(json, 'vehicles') ? undefined : (json['vehicles'] === null ? null : (json['vehicles'] as Array<any>).map(VehicleFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function VehicleTypeToJSON(value?: VehicleType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'loadedOrders': value.loadedOrders === undefined ? undefined : (value.loadedOrders === null ? null : (value.loadedOrders as Array<any>).map(LoadedOrderToJSON)),
        'deletedOrders': value.deletedOrders === undefined ? undefined : (value.deletedOrders === null ? null : (value.deletedOrders as Array<any>).map(DeletedOrderToJSON)),
        'vehicles': value.vehicles === undefined ? undefined : (value.vehicles === null ? null : (value.vehicles as Array<any>).map(VehicleToJSON)),
        'id': value.id,
    };
}

