const ListOfLoadsEditCaptions = {
    'Syl': 'Syl#',
    'R': 'R#',
    'Createtime': 'Date',
    'VehicleId': 'Unit',
    'Driver': 'Driver',
    'CompanyName': 'Company Name',
    'Brokerage': 'Brokerage',
    'PickUpAtCity': 'From',
    'PickUpAtState': 'State',
    'PickUpDate': 'Date',
    'DeliverToCity': 'To',
    'DeliverToState': 'State',
    'DeliveryDate': 'Date',
    'Miles': 'Miles',
    'Gross': 'Gross',
    'PriceForDriver': 'Driver Price',
    'Week': 'Week',
    'TextWeek': 'Week',
    'Note': 'Comment',
    'User': 'Dispatcher',
    'Factoring': 'Factoring',
};

export default ListOfLoadsEditCaptions;