import auth from "./login/LoginSlice";
import loadedOrder from "./loaded-orders/LoadedOrdersSlice";
import bid from "./bids/BidsSlice";
import vehicle from "./vehicles/VehiclesSlice";
import dashboard from './dashboard/DashboardSlice';
import driver from './drivers/DriversSlice';
import owner from './owners/OwnersSlice';
import notification from './notifications/NotificationsSlice';
import listOfLoads from './list-of-loads/ListOfLoadsSlice';
import request from './hr/request-list/RequestSlice';
import settings from './settings/SettingsSlice'
import tracking from './tracking/TrackingSlice';
import users from './users/UsersSlice';
import configuration from './configuration/ConfigurationSlice';
import units from './hr/units-list/UnitSlice';

const entitiesReducers = {
  auth,
  loadedOrder,
  bid,
  vehicle,
  dashboard,
  driver,
  owner,
  notification,
  listOfLoads,
  request,
  settings,
  tracking,
  users,
  configuration,
  units
};


export default entitiesReducers;