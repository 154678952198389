import React, { useMemo } from 'react';
import { Button } from 'primereact/button';
import useLogisticsGoogleAuth from './LogisticsGoogleAuthHook';

function LogisticsGoogleAuth(props: { userLogin: string}) {
  const { login, authorizedEmail} = useLogisticsGoogleAuth(props.userLogin);

  const successfulAuthMessage = useMemo(() => {
    if (!authorizedEmail) return <></>;

    return `Google authorization successful: ${authorizedEmail}`;
  }, [authorizedEmail]);

  return <>

    <p className='text-xl'>
      {successfulAuthMessage}
    </p>
    <Button
      label='GOOGLE AUTH'
      className='p-button-success min-w-min w-16rem h-4rem mr-1'
      onClick={() => login()}>
    </Button>
  </>
}

export default LogisticsGoogleAuth;