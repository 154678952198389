import React, { memo, useCallback } from 'react';
import { Button } from 'primereact/button';
import customProtocolCheck from 'custom-protocol-check';
import { showErrorToast } from '../LogisticsToast';

type Callable = {
  phones?: string[] | null | undefined,
}

export type CallButtonProps = {
  label: string,
  className: string,
  callable?: Callable | null | undefined,
}

const CallButton = memo((props: CallButtonProps) => {
  const phone = props?.callable?.phones ? props.callable.phones[0] : null;
  const onClick = useCallback(() => {
    const formattedPhone = phone?.replaceAll(/\s+/g, '');
    const phoneAppUrl  = 'tel://' + formattedPhone;
    customProtocolCheck(
      phoneAppUrl,
      () => {
        const desktopAppUrl = 'rcapp://r/call?number=' + formattedPhone;
        customProtocolCheck(
          desktopAppUrl,
          () => showErrorToast('The device doesn\'t have the RingCentral app'),
          () => console.debug('Launching RingCentral app'),
          2000,
        );
      },
      () => console.debug('Launching RingCentral Phone app'),
      2000,
    );
  }, [phone]);

  if (!phone) return <></>;

  return <Button
    label={props.label}
    className={props.className}
    onClick={onClick}
  />
});

export default CallButton;