import React, { useEffect } from 'react';
import useRights from '../../hooks/RightsHook';
import useAuth from '../../features/login/AuthHook';
import { NavigateOptions } from 'react-router-dom';
import { history } from '../../shared';

function AutoRedirect() {
  const menuRights = useRights(security => security.menu);
  const { auth } = useAuth();

  // Auto-redirect to permission-specific default page after logged in
  useEffect(() => {
    const location = history.location;
    if (!auth.isAuthenticated || location.pathname !== '/') return;
    if (menuRights.dispatch) {
      navigate('/loaded-orders');
    }
    else if (menuRights.vehicles) {
      navigate('/vehicles');
    }
  }, [auth.isAuthenticated, menuRights]);
  return (
    <div></div>
  );
}

function navigate(path: string, options: NavigateOptions = {}) {
  if (history.location.pathname === path) return;

  history.navigate(path, options);
}

export default AutoRedirect;