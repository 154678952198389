import React, { useMemo } from 'react';
import { ENotificationType, Notification } from '../../../generated-api';
import VehicleExpiryFormatter from './components/VehicleExpiryFormatter';
import { Button } from 'primereact/button';
import { MdDone } from 'react-icons/md';
import { Splitter } from 'primereact/splitter';
import dayjs from 'dayjs';
import VehicleApprovedFormatter from './components/VehicleApprovedFormatter';
import OwnerDocumentsSignedFormatter from './components/OwnerDocumentsSignedFormatter';

export interface NotificationRowParameters extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  notification: Notification,
  onMarkAsRead: (notification: Notification) => any,
  onDelete: (notification: Notification) => any,
}

// noinspection JSUnusedLocalSymbols
const emptyFormatterFunction = (props: { jsonData: string }) => <></>;

function NotificationRow(props: NotificationRowParameters) {
  const NotificationMessage = useMemo(() => {
    if (!props.notification?.type) return emptyFormatterFunction;

    if (props.notification.type === ENotificationType.VehicleApproved)
      return VehicleApprovedFormatter;

    if (props.notification.type === ENotificationType.VehicleExpires)
      return VehicleExpiryFormatter;

    if (props.notification.type === ENotificationType.OwnerDocumentsSigned)
      return OwnerDocumentsSignedFormatter;

    console.warn(`No formatter found for ${props.notification.type} notification type`);
    return emptyFormatterFunction;
  }, [props.notification?.type]);

  const notificationDate = useMemo(() => {
    if (!props.notification?.date) return '';

    return dayjs(props.notification.date).tz().format('MM/DD/YYYY');
  }, [props.notification?.date]);

  return <div {...props}>
    <div className='flex flex-row'>
      <div className='flex justify-content-start align-items-center flex-grow-1'>
        <NotificationMessage jsonData={props.notification?.data ?? ''} />
      </div>
      <div className='flex justify-content-end align-items-center flex-grow-1'>
        <span className='font-bold'>
          {notificationDate}
        </span>
        <Button className='p-button-text' onClick={() => props.onMarkAsRead(props.notification)}>
          <MdDone className='text-green-400' />
        </Button>
      </div>
    </div>
    <Splitter className='my-2'/>
  </div>
}

export default NotificationRow;