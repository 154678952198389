import React, { memo, ReactNode } from 'react';

const VisibilityToggler = memo((props: {
  children: ReactNode,
  visible: boolean | null | undefined
}) => {
  if (!props.visible) return <></>;

  return <>{props.children}</>;
});

export default VisibilityToggler;