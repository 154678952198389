
import { defaultTimezoneOffset } from './date-format';
import dayjs from 'dayjs';

// Browser does not store timezone inside date object, only uses OS timezone for everything.
// That is why every date object is interpreted as date in browser timezone,
//   and in order to get date in other timezone only manual addition\subtraction can be used.

export function today() {
  return dayjs()
    .subtract(defaultTimezoneOffset(), 'minute')
    .startOf('day')
    .add(defaultTimezoneOffset(), 'minute');
}