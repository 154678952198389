import styled from '@emotion/styled';
import { TabView } from 'primereact/tabview';

const LogisticsTabs = styled(TabView)`
  & .p-tabview-title {
    padding: 0 3rem;
  }
  
  & .p-tabview-nav li a.p-tabview-nav-link {
    border: 0;
    border-radius: 0;
    background: none;
  }

  & .p-tabview-panels {
    padding: 0 !important;
  }
  
  &.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: unset;
  }
`

export default LogisticsTabs;