/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Notification } from './Notification';
import {
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
} from './Notification';

/**
 * 
 * @export
 * @interface NotificationPageResponse
 */
export interface NotificationPageResponse {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationPageResponse
     */
    records?: Array<Notification> | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationPageResponse
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationPageResponse
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationPageResponse
     */
    recordsPerPage?: number;
}

/**
 * Check if a given object implements the NotificationPageResponse interface.
 */
export function instanceOfNotificationPageResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationPageResponseFromJSON(json: any): NotificationPageResponse {
    return NotificationPageResponseFromJSONTyped(json, false);
}

export function NotificationPageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationPageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'records': !exists(json, 'records') ? undefined : (json['records'] === null ? null : (json['records'] as Array<any>).map(NotificationFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'recordsPerPage': !exists(json, 'recordsPerPage') ? undefined : json['recordsPerPage'],
    };
}

export function NotificationPageResponseToJSON(value?: NotificationPageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'records': value.records === undefined ? undefined : (value.records === null ? null : (value.records as Array<any>).map(NotificationToJSON)),
        'total': value.total,
        'pageNumber': value.pageNumber,
        'recordsPerPage': value.recordsPerPage,
    };
}

