import React, { useEffect, useRef, useState } from 'react';
import { StepProps } from '../step1/Step1';
import { Owner } from '../../../generated-api';
import Row from '../../../components/form/Row';
import Column from '../../../components/form/Column';
import { apiFactory } from '../../../shared';
import { RegistrationApi } from '../../../generated-api/apis/RegistrationApi';
import Header from '../../../components/form/Header';
import { Button } from 'antd';
import { showErrorToast } from '../../../components/LogisticsToast';
import Field from '../../../components/form/Field';
import LogisticsCanvas from '../../../components/canvas/LogisticsCanvas';
import { ReactSketchCanvasRef } from 'react-sketch-canvas';
import LogisticsPdf from '../../../components/form/files/pdf/LogisticsPdf';

function blobToUrl(blob: Blob) {
  return URL.createObjectURL(
    blob.slice(0, blob.size, 'application/pdf'));
}

function Step4(props: StepProps<Owner>) {
  const [w9Url, setW9Url] = useState('');
  const [contractUrl, setContractUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const canvasRef = useRef<ReactSketchCanvasRef>(null);
  useEffect(() => {
    Promise.allSettled([
      apiFactory(RegistrationApi).apiRegistrationW9Get( { ownerId: props.initialValue?.id }),
      apiFactory(RegistrationApi).apiRegistrationContractGet({ ownerId: props.initialValue?.id }),
    ]).then(async ([w9response, contractResponse]) => {
      if (w9response.status === 'fulfilled'){
        setW9Url(blobToUrl(w9response.value));
      }
      if (contractResponse.status === 'fulfilled') {
        setContractUrl(blobToUrl(contractResponse.value));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = async () => {
    try {
      setLoading(true);
      const sketchingTime = await canvasRef!.current!.getSketchingTime();
      if (sketchingTime === 0) {
        // Documents are not signed
        await apiFactory(RegistrationApi)
          .apiRegistrationStage4Post({ ownerId: props.initialValue!.id });
      } else {
        // Documents are signed
        const imageUrl = await canvasRef!.current!.exportImage('png');
        const imageResponse = await fetch(imageUrl);
        const imageBlob = await imageResponse.blob();
        await apiFactory(RegistrationApi)
          .apiRegistrationSignDocumentsPost({
            ownerId: props.initialValue!.id,
            signature: imageBlob,
            ownerDocumentIds: props.initialValue?.ownerDocuments?.map(x => x.id!),
          });
      }

      props.onStepComplete({});
    } catch (error: any) {
      showErrorToast('Error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  return <>
    <Row>
      <Column>
        <Header>Documents for signature</Header>
      </Column>
    </Row>
    <Row>
      <Column>
        <LogisticsPdf url={w9Url} />
      </Column>
      <Column>
        <LogisticsPdf url={contractUrl} placeholder='Missing contract template' />
      </Column>
    </Row>
    <Row>
      <Column>
        <div>* Your signature will only be used to sign the contract,</div>
        <div>and will not be stored</div>
      </Column>
    </Row>
    <Row>
      <Column className='col-12'>
        <Field label='Please sign below' required><></></Field>
        <LogisticsCanvas
          width='100%'
          height='300px'
          ref={canvasRef}
        />
      </Column>
    </Row>
    <Row>
      <Column className='col-12'>
        <Button
          type="primary"
          loading={loading}
          onClick={save}
          className='w-full'
        >
          Next
        </Button>
      </Column>
    </Row>
  </>
}

export default Step4;