import { DataTableMultiSortMetaType, DataTableSortMeta } from 'primereact/datatable';
import { useMemo } from 'react';
import LogisticsColumnProps from '../LogisticsColumnProps';
import useBrowserPageStorage from '../../../hooks/BrowserStorageHook';
import EmptyArrowFunction from '../../../shared/util/emptyArrowFunction';

function useSorting(
  componentId: string,
  initialMultisortMeta: DataTableSortMeta[] | undefined | null,
  columns:  LogisticsColumnProps[],
  alternativeSort?: { server?: string[], client?:  DataTableSortMeta[] | undefined},
) {
  const [storedSort, setStoredSort] = useBrowserPageStorage(componentId + '_sort', initialMultisortMeta);
  const clientSort = useMemo(
    () => cleanUp(storedSort, columns),
    [storedSort, columns]);

  const serverSortColumns = useMemo(
    () => columns.filter(x => x.serverSortFields?.length),
    [columns]);
  const serverSort = useMemo(
    () => extractServerSort(clientSort, serverSortColumns),
    [clientSort, serverSortColumns]);

  return alternativeSort
    ? { serverSort: alternativeSort.server, clientSort: alternativeSort.client, setSort: EmptyArrowFunction } as const
    : { serverSort, clientSort, setSort: setStoredSort } as const
}

function extractServerSort(
  multiSortMeta: DataTableMultiSortMetaType | undefined,
  complexColumns: LogisticsColumnProps[]
) {
  if (!multiSortMeta) {
    return undefined;
  }

  return multiSortMeta.map(singleSort => {
    const order = singleSort.order === -1 ? 'desc' : 'asc';
    const complexColumn = complexColumns.find(x => x.field === singleSort.field);
    if (complexColumn?.serverSortFields) {
      return complexColumn.serverSortFields.map(field => `${field},${order}`);
    }

    return [`${singleSort.field},${order}`];
  }).reduce((prev, cur) => [...cur, ...prev], []);
}

/**
 * Fix everything that can break the list.
 */
function cleanUp(
  currentMultisortMeta: DataTableSortMeta[] | undefined | null,
  columns:  LogisticsColumnProps[],
) {
  if (!columns?.length || !currentMultisortMeta?.length) return undefined;

  let fixedMultisort = currentMultisortMeta || []

  // Remove sort for non-existing columns
  fixedMultisort = fixedMultisort.filter(x =>
    columns.some(column => column.field === x.field));

  return fixedMultisort;
}

export default useSorting;