/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DispatcherLoadsAndProfitsByPeriodResponse
 */
export interface DispatcherLoadsAndProfitsByPeriodResponse {
    /**
     * 
     * @type {string}
     * @memberof DispatcherLoadsAndProfitsByPeriodResponse
     */
    period?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DispatcherLoadsAndProfitsByPeriodResponse
     */
    load?: number;
    /**
     * 
     * @type {number}
     * @memberof DispatcherLoadsAndProfitsByPeriodResponse
     */
    profit?: number;
}

/**
 * Check if a given object implements the DispatcherLoadsAndProfitsByPeriodResponse interface.
 */
export function instanceOfDispatcherLoadsAndProfitsByPeriodResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DispatcherLoadsAndProfitsByPeriodResponseFromJSON(json: any): DispatcherLoadsAndProfitsByPeriodResponse {
    return DispatcherLoadsAndProfitsByPeriodResponseFromJSONTyped(json, false);
}

export function DispatcherLoadsAndProfitsByPeriodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DispatcherLoadsAndProfitsByPeriodResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'period': !exists(json, 'period') ? undefined : json['period'],
        'load': !exists(json, 'load') ? undefined : json['load'],
        'profit': !exists(json, 'profit') ? undefined : json['profit'],
    };
}

export function DispatcherLoadsAndProfitsByPeriodResponseToJSON(value?: DispatcherLoadsAndProfitsByPeriodResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'period': value.period,
        'load': value.load,
        'profit': value.profit,
    };
}

