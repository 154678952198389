import React, { ReactNode, useMemo } from 'react';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import styled from '@emotion/styled';
import { focusedInputStyle } from './Styles';
import { Button } from 'primereact/button';
import LogisticsDropdownItem from './LogisticsDropdownItem';

export interface EditableDropdownProps<T> {
  options: T[],
  optionLabel: string;
  renderOption: (option: T) => ReactNode | string,
  renderSelected: (option: T | undefined) => ReactNode | string,
  renderEmptyOption?: (option: T | undefined) => ReactNode | string,
  renderEmptySelected?: (option: T | undefined) => ReactNode | string,
  value: T | undefined,
  onChange: (newValue: T) => any,
  openEditing?: (value: T) => any,
  openAdding?: () => any,
  others?: DropdownProps,
  disabled?: boolean | undefined,
  className?: string | undefined,
  filter?: boolean | undefined,
  hideEditAndCreate?: boolean | undefined,
}

interface EditableDropdownItem<T> {
  label: string,
  value?: T,
  className?: string,
  title?: string,
  disabled?: boolean
}

export const StyledDropdown = styled(Dropdown)`
  &.p-dropdown {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    flex-grow: 1;
    text-transform: uppercase;
    overflow: auto;
  }

  &.p-dropdown:not(.p-disabled).p-focus {
    ${focusedInputStyle}
  }
  
  &.p-dropdown .p-dropdown-label.p-placeholder {
    color: var(--text-color);
  }
`

function EditableDropdown<T>(props: EditableDropdownProps<T>) {
  const edit = props.openEditing && props.value && !props.hideEditAndCreate
    ? <Button
        icon='pi pi-pencil'
        className='p-button-lg p-button-rounded p-button-text p-button-plain'
        onClick={() => props.openEditing && props.openEditing(props.value!)}
    />
    : <></>;
  const add = props.openAdding && !props.hideEditAndCreate
    ? <Button
        disabled={props.disabled}
        icon='pi pi-plus'
        className='p-button-lg p-button-rounded p-button-text p-button-plain'
        onClick={() => props.openAdding && props.openAdding()}
    />
    : <></>;

  const orderOptions = (options: EditableDropdownItem<T>[]) => {
    return options.sort((a, b) => a.label > b.label ? 1 : -1);
  }

  const boxedSelected = useMemo(() => {
    return { value: props.value } as EditableDropdownItem<T>
  }, [props.value]);

  const renderSelected = boxedSelected.value
  ? props.renderSelected
  : props.renderEmptySelected || props.renderSelected;

  const propsRenderSelected = props.renderSelected;

  const emptyOption: EditableDropdownItem<T> = { value: undefined, label: '' };
  const options = useMemo(() => {
    let boxedOptions = props.options.map(x => {
      return { value: x, label: propsRenderSelected(x) } as EditableDropdownItem<T>;
    });

    if (props.renderEmptyOption) {
      boxedOptions = [emptyOption].concat(boxedOptions);
    }


    if (props.filter) {
      // Todo: make separate parameter for sorting
      boxedOptions = orderOptions(boxedOptions);
    }

    return boxedOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options, propsRenderSelected]);

  const renderOption = (option: EditableDropdownItem<T>) => {
    const renderedOption = !option.value && props.renderEmptyOption
      ? props.renderEmptyOption(option.value)
      : props.renderOption(option.value!);
    if (typeof renderedOption === 'string' ||
        typeof renderedOption === 'number' ||
        typeof renderedOption === 'boolean' ||
        !renderedOption) {
      return <LogisticsDropdownItem>{renderedOption}</LogisticsDropdownItem>;
    }

    return renderedOption;
  }

  return (
    <div className='flex flex-row align-items-center justify-content-evenly'>
      <StyledDropdown
        itemTemplate={renderOption}
        valueTemplate={() => renderSelected(boxedSelected.value)}
        options={options}
        value={boxedSelected}
        onChange={e => {
          const newValue = valueOrUndefined(e.value);
          props.onChange(newValue);
        }}
        optionLabel={props.optionLabel}
        placeholder='-'
        disabled={props.disabled}
        panelClassName='editable-dropdown'
        className={props.className}
        {...props.others}
        filter={props.filter}
        filterBy='label'
      />
      {edit}
      {add}
    </div>
  );
}

/**
 * There is some wired logic going on inside primereact-dropdown.
 * When `item.value` is not empty, the value returns. But
 * when `item.value` is empty, the item itself returns.
 * So detect those and return correct empty value;
 * @param value
 */
function valueOrUndefined(value: any){
  if (!value)
    return undefined;
  if (value.hasOwnProperty('value') && value.value === undefined)
    return undefined;

  return value;
}

export default EditableDropdown;