/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BidStatus,
} from '../models';
import {
    BidStatusFromJSON,
    BidStatusToJSON,
} from '../models';

export interface ApiBidStatusesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBidStatusesIdDeleteRequest {
    id: number;
}

export interface ApiBidStatusesIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBidStatusesIdPutRequest {
    id: number;
    bidStatus?: BidStatus;
}

export interface ApiBidStatusesPostRequest {
    bidStatus?: BidStatus;
}

/**
 * 
 */
export class BidStatusesApi extends runtime.BaseAPI {

    /**
     */
    async apiBidStatusesGetRaw(requestParameters: ApiBidStatusesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BidStatus>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bid-statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BidStatusFromJSON));
    }

    /**
     */
    async apiBidStatusesGet(requestParameters: ApiBidStatusesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BidStatus>> {
        const response = await this.apiBidStatusesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBidStatusesIdDeleteRaw(requestParameters: ApiBidStatusesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBidStatusesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bid-statuses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBidStatusesIdDelete(requestParameters: ApiBidStatusesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiBidStatusesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBidStatusesIdGetRaw(requestParameters: ApiBidStatusesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BidStatus>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBidStatusesIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bid-statuses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BidStatusFromJSON(jsonValue));
    }

    /**
     */
    async apiBidStatusesIdGet(requestParameters: ApiBidStatusesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BidStatus> {
        const response = await this.apiBidStatusesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBidStatusesIdPutRaw(requestParameters: ApiBidStatusesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBidStatusesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bid-statuses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BidStatusToJSON(requestParameters.bidStatus),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBidStatusesIdPut(requestParameters: ApiBidStatusesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiBidStatusesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBidStatusesPostRaw(requestParameters: ApiBidStatusesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BidStatus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bid-statuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BidStatusToJSON(requestParameters.bidStatus),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BidStatusFromJSON(jsonValue));
    }

    /**
     */
    async apiBidStatusesPost(requestParameters: ApiBidStatusesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BidStatus> {
        const response = await this.apiBidStatusesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
