import { Card } from 'primereact/card';
import { Bid, BidReply, GmailApi } from '../../generated-api';
import { List} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { apiFactory } from '../../shared';

export interface BidReplyViewerProps {
  bidReplies?: Array<BidReply> | null,
  bid?: Bid | null,
}

function BidReplyViewer(props: BidReplyViewerProps) {
  const items = props.bidReplies ?? undefined;
  const bid = props.bid;
  const [authorizedEmail, setAuthorizedEmail] = useState('');

  useEffect(() => {
    apiFactory(GmailApi).apiGmailEmailGet({ login: props.bid?.dispatcher?.login! })
      .then(email => {
        setAuthorizedEmail(email);
      }).catch(reason => {
        console.error('Failed to load authorized email', reason);
    });
  }, [props.bid?.dispatcher?.login]);

  const itemTemplate = (item : BidReply | undefined, _ : number) => {
    const getHeader = () => item?.incoming ? bid?.loadedOrder?.brokerage : bid?.dispatcher?.lastName + ' ' + bid?.dispatcher?.firstName;
    const getSubHeader = () => {
      const email = item?.incoming
        ? bid?.loadedOrder?.contactEmail
        : authorizedEmail;
      return email ? `<${email}>` : undefined;
    }

    return (
        <Card className='m-2'>
          <div className='grid'>
            <div className="col-8">
              <b>{getHeader()}</b>
            </div>
            <div className="col-4 text-right">
              {item?.createdDate ? dayjs(item.createdDate).tz().format('MM.DD.YYYY hh:mm a') : undefined}
            </div>
            <div className="col-12">
              <span className='text-500'>{getSubHeader()}</span>
            </div>
            <div className="col-12">
              {item?.text}
            </div>
          </div>
        </Card>
    );
  };

  return <div className="card flex flex-wrap h-full flex-grow-1 border-1 surface-border border-round" style={{maxWidth:'900px'}}>
    <span className='flex text-2xl font-bold m-2'>Last reply</span>
    <div className='overflow-scroll overflow-x-hidden overflow-y-auto' style={{height:'90%', width:'100%'}}>
      <List dataSource={items} renderItem={itemTemplate} />
    </div>
  </div>
}

export default BidReplyViewer;