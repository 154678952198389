import { EStatusHR } from '../../../generated-api';

const statusesHr = [
  {
    id: EStatusHR.New,
    caption: 'new',
    color: '#a4c2f4',
  },
  {
    id: EStatusHR.Approved,
    caption: 'approved',
    color: '#98fb98',
  },
  {
    id: EStatusHR.Rejected,
    caption: 'rejected',
    color: '#f36c6c',
  },
  {
    id: EStatusHR.Ready,
    caption: 'ready',
    color: '#ffb273',
  },
  {
    id: EStatusHR.InProgress,
    caption: 'in progress',
    color: '#bc9fe3',
  },
];

export default statusesHr;