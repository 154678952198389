/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EVehicleStatus = {
    Error: 'Error',
    Retried: 'Retried',
    OnLoad: 'OnLoad',
    UnconfinedService: 'UnconfinedService',
    InService: 'InService',
    OutOfService: 'OutOfService'
} as const;
export type EVehicleStatus = typeof EVehicleStatus[keyof typeof EVehicleStatus];


export function EVehicleStatusFromJSON(json: any): EVehicleStatus {
    return EVehicleStatusFromJSONTyped(json, false);
}

export function EVehicleStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EVehicleStatus {
    return json as EVehicleStatus;
}

export function EVehicleStatusToJSON(value?: EVehicleStatus | null): any {
    return value as any;
}

