/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyInformationResponse
 */
export interface CompanyInformationResponse {
    /**
     * 
     * @type {number}
     * @memberof CompanyInformationResponse
     */
    postedLoads?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInformationResponse
     */
    bids?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInformationResponse
     */
    loads?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInformationResponse
     */
    gross?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInformationResponse
     */
    revenue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInformationResponse
     */
    margin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInformationResponse
     */
    dispatchers?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInformationResponse
     */
    vehicles?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInformationResponse
     */
    vehiclesAvailable?: number | null;
}

/**
 * Check if a given object implements the CompanyInformationResponse interface.
 */
export function instanceOfCompanyInformationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompanyInformationResponseFromJSON(json: any): CompanyInformationResponse {
    return CompanyInformationResponseFromJSONTyped(json, false);
}

export function CompanyInformationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyInformationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postedLoads': !exists(json, 'postedLoads') ? undefined : json['postedLoads'],
        'bids': !exists(json, 'bids') ? undefined : json['bids'],
        'loads': !exists(json, 'loads') ? undefined : json['loads'],
        'gross': !exists(json, 'gross') ? undefined : json['gross'],
        'revenue': !exists(json, 'revenue') ? undefined : json['revenue'],
        'margin': !exists(json, 'margin') ? undefined : json['margin'],
        'dispatchers': !exists(json, 'dispatchers') ? undefined : json['dispatchers'],
        'vehicles': !exists(json, 'vehicles') ? undefined : json['vehicles'],
        'vehiclesAvailable': !exists(json, 'vehiclesAvailable') ? undefined : json['vehiclesAvailable'],
    };
}

export function CompanyInformationResponseToJSON(value?: CompanyInformationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postedLoads': value.postedLoads,
        'bids': value.bids,
        'loads': value.loads,
        'gross': value.gross,
        'revenue': value.revenue,
        'margin': value.margin,
        'dispatchers': value.dispatchers,
        'vehicles': value.vehicles,
        'vehiclesAvailable': value.vehiclesAvailable,
    };
}

