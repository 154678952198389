import { IdName } from './IdName';
import { ETrackingStatus } from '../generated-api';

const TrackingStatus: IdName<string>[] = [
  { id: ETrackingStatus.Attention, name: 'Attention' },
  { id: ETrackingStatus.Loaded, name: 'Loaded' },
  { id: ETrackingStatus.Hidden, name: 'Hidden' },
  { id: ETrackingStatus.Delivered, name: 'Delivered' },
  { id: ETrackingStatus.Checking, name: 'Checking' },
];

export default TrackingStatus;