import { ApiVehiclesGetRequest, Vehicle, VehiclesApi } from '../../generated-api';
import { createEntitySlice, EntityState } from '../../shared';
import { createAction, createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import paginationApiFactory from '../../shared/util/paginationApiFactory';

const defaultValue: Readonly<Vehicle> = { };

const initialState: EntityState<Vehicle> = {
  loading: false,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
}

export const getVehiclesPage = createAsyncThunk(
  "Vehicle/fetch_entity_page",
  async (queryParams : ApiVehiclesGetRequest) => {
    let totalCount = 0;
    const api = paginationApiFactory(VehiclesApi, total => totalCount = total);
    const entities = await api.apiVehiclesGet(queryParams);
    return { entities, totalCount };
  }
);

export const updateEntity = createAction(
  'Vehicle/update_entity',
   (entity: Vehicle) => {
    return { payload: entity };
  }
);

export const VehiclesSlice = createEntitySlice({
  name: 'Vehicles',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateEntity, (state, action) => {
      const entity = action.payload;
      const listEntity = state.entities.find(x => x.id === entity.id);
      if (!listEntity) return;

      return {
        ...state,
        entities: state.entities.map(x => x === listEntity ? entity : x),
      }
    }).addMatcher(isFulfilled(getVehiclesPage), (state, action) => {
      const data = action.payload;

      return {
        ...state,
        loading: false,
        entities: data.entities,
        totalItems: data.totalCount,
      }
    })
  }
});

export default VehiclesSlice.reducer;