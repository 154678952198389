import { ApiDriversGetRequest, Driver } from '../../generated-api';
import dayjs from 'dayjs';
import LogisticsColumnProps from '../../components/data-grid/LogisticsColumnProps';
import serverFilterComponent from '../../components/filters/server/ServerFilter';
import ServerInputNumber from '../../components/filters/server/ServerInputNumber';
import React from 'react';
import CallButton from '../../components/calls/CallButton';

export default function useColumns() : LogisticsColumnProps[] {
  return [
    {
      field: 'id',
      header: 'ID',
      style: { maxWidth: '5rem' },
      sortable: true,
      filter: true,
      ...serverFilterComponent(props => <ServerInputNumber {...props} />),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiDriversGetRequest) : ApiDriversGetRequest => {
        return { ...serverFilterModel, id: filterMetaData.value };
      },
    },
    {
      field: 'driverLogin',
      header: 'Login',
      style: { maxWidth: '15rem' },
      sortable: true,
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiDriversGetRequest) : ApiDriversGetRequest => {
        return { ...serverFilterModel, login: filterMetaData.value };
      },
    },
    {
      field: 'name ',
      header: 'Name ',
      style: { flex: 1 },
      sortable: true,
      body: (rowData: Driver) => `${rowData.firstName} ${rowData.lastName}`,
      serverSortFields: ['firstName', 'lastName'],
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiDriversGetRequest) : ApiDriversGetRequest => {
        return { ...serverFilterModel, name: filterMetaData.value };
      },
    },
    {
      field: 'address',
      header: 'Address',
      style: { flex: 1 },
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiDriversGetRequest) : ApiDriversGetRequest => {
        return { ...serverFilterModel, address: filterMetaData.value };
      },
    },
    {
      field: 'email',
      header: 'Email',
      style: { flex: 1 },
      sortable: true,
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiDriversGetRequest) : ApiDriversGetRequest => {
        return { ...serverFilterModel, email: filterMetaData.value };
      },
    },
    {
      field: 'phones',
      header: 'Phones',
      style: { maxWidth: '7rem' },
      body: (rowData: Driver) => <CallButton
        label='Call'
        className='px-5 bg-green-400'
        callable={rowData}
      />,
    },
    {
      field: 'licenceExpires',
      header: 'Licence expires',
      style: { maxWidth: '7rem' },
      sortable: true,
      body: (rowData: Driver) => rowData.licenceExpires
        ? dayjs.tz(rowData.licenceExpires).format('MMM DD YYYY')
        : '',
    },
    {
      field: 'owner',
      header: 'Owner',
      style: { flex: 1 },
      sortable: true,
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiDriversGetRequest) : ApiDriversGetRequest => {
        return { ...serverFilterModel, owner: filterMetaData.value };
      },
      body: (rowData: Driver) => rowData.vehicle?.owner
        ? `${rowData.vehicle.owner.firstName} ${rowData.vehicle.owner.lastName}`
        : '',
      serverSortFields: ['vehicle.owner.firstName', 'vehicle.owner.lastName'],
    }
  ];
}