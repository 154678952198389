import { ApiOwnersGetRequest, Owner } from '../../generated-api';
import LogisticsColumnProps from '../../components/data-grid/LogisticsColumnProps';
import serverFilterComponent from '../../components/filters/server/ServerFilter';
import ServerInputNumber from '../../components/filters/server/ServerInputNumber';
import React from 'react';
import CallButton from '../../components/calls/CallButton';

export default function useColumns() : LogisticsColumnProps[] {
  return [
    {
      field: 'id',
      header: 'ID',
      style: { maxWidth: '5rem' },
      sortable: true,
      filter: true,
      ...serverFilterComponent(props => <ServerInputNumber {...props} />),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiOwnersGetRequest) : ApiOwnersGetRequest => {
        return { ...serverFilterModel, id: filterMetaData.value };
      },
    },
    {
      field: 'companyName',
      header: 'Company name',
      style: { flex: 1 },
      sortable: true,
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiOwnersGetRequest) : ApiOwnersGetRequest => {
        return { ...serverFilterModel, companyName: filterMetaData.value };
      },
    },
    {
      field: 'name',
      header: 'Name',
      style: { flex: 1 },
      sortable: true,
      body: (rowData: Owner) => `${rowData.firstName} ${rowData.lastName}`,
      serverSortFields: ['firstName', 'lastName'],
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiOwnersGetRequest) : ApiOwnersGetRequest => {
        return { ...serverFilterModel, name: filterMetaData.value };
      },
    },
    {
      field: 'address',
      header: 'Address',
      style: { flex: 1 },
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiOwnersGetRequest) : ApiOwnersGetRequest => {
        return { ...serverFilterModel, address: filterMetaData.value };
      },
    },
    {
      field: 'email',
      header: 'Email',
      style: { flex: 1 },
      sortable: true,
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiOwnersGetRequest) : ApiOwnersGetRequest => {
        return { ...serverFilterModel, email: filterMetaData.value };
      },
    },
    {
      field: 'phones',
      header: 'Phones',
      style: { maxWidth: '7rem' },
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiOwnersGetRequest) : ApiOwnersGetRequest => {
        return { ...serverFilterModel, phones: filterMetaData.value };
      },
      body: (rowData: Owner) => <CallButton
        label='Call'
        className='px-5 bg-blue-400'
        callable={rowData}
      />,
    },
    {
      field: 'federalTaxpayerId',
      header: 'Taxpayer ID',
      style: { maxWidth: '8rem' },
      sortable: true,
      filter: true,
      ...serverFilterComponent(props => <ServerInputNumber {...props} />),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiOwnersGetRequest) : ApiOwnersGetRequest => {
        return { ...serverFilterModel, taxpayerId: filterMetaData.value };
      },
    },
  ];
}