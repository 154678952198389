/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Notification } from './Notification';
import {
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
} from './Notification';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface NotificationTarget
 */
export interface NotificationTarget {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationTarget
     */
    everyone?: boolean | null;
    /**
     * 
     * @type {Role}
     * @memberof NotificationTarget
     */
    role?: Role;
    /**
     * 
     * @type {string}
     * @memberof NotificationTarget
     */
    roleId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof NotificationTarget
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof NotificationTarget
     */
    userId?: string | null;
    /**
     * 
     * @type {Notification}
     * @memberof NotificationTarget
     */
    notification?: Notification;
    /**
     * 
     * @type {number}
     * @memberof NotificationTarget
     */
    notificationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationTarget
     */
    id?: number;
}

/**
 * Check if a given object implements the NotificationTarget interface.
 */
export function instanceOfNotificationTarget(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationTargetFromJSON(json: any): NotificationTarget {
    return NotificationTargetFromJSONTyped(json, false);
}

export function NotificationTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationTarget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'everyone': !exists(json, 'everyone') ? undefined : json['everyone'],
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'notification': !exists(json, 'notification') ? undefined : NotificationFromJSON(json['notification']),
        'notificationId': !exists(json, 'notificationId') ? undefined : json['notificationId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function NotificationTargetToJSON(value?: NotificationTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'everyone': value.everyone,
        'role': RoleToJSON(value.role),
        'roleId': value.roleId,
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'notification': NotificationToJSON(value.notification),
        'notificationId': value.notificationId,
        'id': value.id,
    };
}

