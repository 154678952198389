import { NavigateFunction, Location } from "react-router-dom";

interface IHistory {
    navigate: NavigateFunction,
    location: Location,
}

// custom history object to allow navigation outside react components
export const history: IHistory = {
    navigate: undefined as any,
    location: undefined as any,
};