import React, { useState } from 'react';
import { StepProps } from '../step1/Step1';
import { Step3Documents } from '../../../generated-api';
import { RegistrationApi } from '../../../generated-api/apis/RegistrationApi';
import { showErrorToast } from '../../../components/LogisticsToast';
import Row from '../../../components/form/Row';
import Column from '../../../components/form/Column';
import Header from '../../../components/form/Header';
import Field from '../../../components/form/Field';
import LogisticsFilesUpload from '../../../components/form/files/upload/LogisticsFilesUpload';
import { Button, Divider } from 'antd';
import { apiFactory } from '../../../shared';
import useFilePreview from '../../../components/form/files/preview/filePreviewWidget';
import LogisticsFilePreview from '../../../components/form/files/preview/LogisticsFilePreview';

function Step3(props : StepProps<Step3Documents>) {
  const [documents, setDocuments] = useState<Step3Documents>(props.initialValue!);
  const [loading, setLoading] = useState(false);
  const filePreviewWidget = useFilePreview();

  const save = async () => {
    try {
      setLoading(true);
      await apiFactory(RegistrationApi)
        .apiRegistrationStage3Post({ step3Documents: documents})
      props.onStepComplete(documents);
    } catch (error: any) {
      showErrorToast('Error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  return <>
    <Row>
      <Column>
        <div>* Optional (you can upload documents later)</div>
        <Header>Company</Header>
      </Column>
    </Row>
    <Row>
      <Column>
        <Field label='Void Check'>
          <LogisticsFilesUpload
            value={documents.voidCheck}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, voidCheck: value };
            })}
          />
        </Field>
      </Column>
      <Column>
        <Field label='MC and USDOT (optional)'>
          <LogisticsFilesUpload
            value={documents.mc}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, mc: value };
            })}
          />
        </Field>
      </Column>
    </Row>
    <Row>
      <Column>
        <Header>Driver</Header>
      </Column>
    </Row>
    <Row>
      <Column className='col-4'>
        <Field label='Driver License'>
          <LogisticsFilesUpload
            value={documents.licenseFile}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, licenseFile: value };
            })}
          />
        </Field>
      </Column>
      <Column className='col-4'>
        <Field label='Driver Record '>
          <LogisticsFilesUpload
            value={documents.drRecordFile}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, drRecordFile: value };
            })}
          />
        </Field>
      </Column>
      <Column className='col-4'>
        <Field label='Green card & Passport '>
          <LogisticsFilesUpload
            value={documents.passport}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, passport: value };
            })}
          />
        </Field>
      </Column>
    </Row>
    <Row>
      <Column>
        <Header>Vehicle</Header>
      </Column>
    </Row>
    <Row>
      <Column>
        <Field label='Incurance'>
          <LogisticsFilesUpload
            value={documents.insuranceFile}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, insuranceFile: value };
            })}
          />
        </Field>
      </Column>
      <Column>
        <Field label='Vehicle Registration'>
          <LogisticsFilesUpload
            value={documents.vehRegFile}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, vehRegFile: value };
            })}
          />
        </Field>
      </Column>
    </Row>
    <Row>
      <Column>
        <Header>Upload your vehicle photos</Header>
      </Column>
    </Row>
    {/*⦁	 – Vehicle.PhotoLS*/}
    {/*⦁	 – Vehicle.PhotoRS*/}
    {/*⦁	 – Vehicle.PhotoCargoSpace*/}

    <Row>
      <Column className='col-4'>
        <Field label='Photo Left Side'>
          <LogisticsFilesUpload
            value={documents.photoLS}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, photoLS: value };
            })}
          />
        </Field>
      </Column>
      <Column className='col-4'>
        <Field label='Photo Right Side'>
          <LogisticsFilesUpload
            value={documents.photoRS}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, photoRS: value };
            })}
          />
        </Field>
      </Column>
      <Column className='col-4'>
        <Field label='Cargo space photo'>
          <LogisticsFilesUpload
            value={documents.photoCargoSpace}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, photoCargoSpace: value };
            })}
          />
        </Field>
      </Column>
    </Row>
    <Divider />
    <Row>
      <Column>
        <Field label='Extra attachment file'>
          <LogisticsFilesUpload
            value={documents.extraAttachFile}
            filePreviewWidget={filePreviewWidget}
            onChange={value => setDocuments(old => {
              return { ...old, extraAttachFile: value };
            })}
          />
        </Field>
      </Column>
    </Row>
    <Row>
      <Column className='col-12'>
        <Button
          type="primary"
          loading={loading}
          onClick={save}
          className='w-full'
        >
          Next
        </Button>
      </Column>
    </Row>
    <LogisticsFilePreview filePreviewWidget={filePreviewWidget} />
  </>
}

export default Step3;