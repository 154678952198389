import LogisticsColumnProps from '../../../../components/data-grid/LogisticsColumnProps';
import { Driver } from '../../../../generated-api';
import React from 'react';
import statusesHr from '../../components/statusesHr';

const colStyle = {
  flex: 1,
}

const requestDriverListColumnDef : LogisticsColumnProps[] = [
  {
    field: 'id',
    header: 'ID',
    style: colStyle,
  },
  {
    field: 'fullName',
    header: 'Full Name',
    style: colStyle,
    body: (rowData: Driver) => {

      return `${rowData.firstName || ''} ${rowData.lastName || ''}`;
    },
  },
  {
    field: 'email',
    header: 'Email',
    style: colStyle,
  },
  {
    field: 'phone',
    header: 'Phone',
    style: colStyle,
    body: (rowData: Driver) => {
      if (!rowData.phones?.length) return <></>;

      return rowData.phones[0] || '';
    }
  },
  {
    field: 'statusHr',
    header: 'Status',
    style: colStyle,
    body: (rowData: Driver) => {
      if (!rowData.vehicle?.statusHR) return <></>;

      const status = statusesHr.find(x => x.id === rowData.vehicle?.statusHR);
      return status?.caption || '';
    }
  }
];

export default requestDriverListColumnDef;