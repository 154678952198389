/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface LoadInformationResponse
 */
export interface LoadInformationResponse {
    /**
     * 
     * @type {Array<Point>}
     * @memberof LoadInformationResponse
     */
    from?: Array<Point> | null;
    /**
     * 
     * @type {Array<Point>}
     * @memberof LoadInformationResponse
     */
    to?: Array<Point> | null;
}

/**
 * Check if a given object implements the LoadInformationResponse interface.
 */
export function instanceOfLoadInformationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LoadInformationResponseFromJSON(json: any): LoadInformationResponse {
    return LoadInformationResponseFromJSONTyped(json, false);
}

export function LoadInformationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadInformationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (json['from'] === null ? null : (json['from'] as Array<any>).map(PointFromJSON)),
        'to': !exists(json, 'to') ? undefined : (json['to'] === null ? null : (json['to'] as Array<any>).map(PointFromJSON)),
    };
}

export function LoadInformationResponseToJSON(value?: LoadInformationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from === null ? null : (value.from as Array<any>).map(PointToJSON)),
        'to': value.to === undefined ? undefined : (value.to === null ? null : (value.to as Array<any>).map(PointToJSON)),
    };
}

