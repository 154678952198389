import { useEffect } from 'react';
import { useAppSelector } from '../../hooks';
import useRights from '../../hooks/RightsHook';
import SystemSettingsEdit from './SystemSettingsEdit';
import VisibilityToggler from '../../components/VisibilityToggler';
import DispatcherSettingsEdit from './DispatcherSettingsEdit';
import UsersList from '../users/UsersList';
import { showToast } from '../../components/LogisticsToast';
import { GoogleOAuthProvider } from '@react-oauth/google';
import useConfiguration from '../../hooks/ConfigurationHook';

function SettingsPage() {
  const settingState = useAppSelector((state) => state.settings);
  const rights = useRights(security => security.settings);
  const {config} = useConfiguration();

  // Todo 2022-01-25 if loading errors are global
  //  then global error popup logic would be a good idea.
  useEffect(() => {
    if (settingState.errorMessage != null && settingState.errorMessage) {
      showToast({ severity: 'error', summary: 'Error', detail: settingState.errorMessage, sticky: true });
    }
  }, [settingState.errorMessage])

  return <div className='overflow-y-scroll' style={{height: '95%'}}>
    <GoogleOAuthProvider clientId={config.gmailOAuthClientId}>
      <VisibilityToggler visible={rights.system}>
        <SystemSettingsEdit />
      </VisibilityToggler>
      <VisibilityToggler visible={rights.dispatcher || rights.googleAuth}>
        <DispatcherSettingsEdit />
      </VisibilityToggler>
      <VisibilityToggler visible={rights.users}>
        <UsersList />
      </VisibilityToggler>
    </GoogleOAuthProvider>
  </div>;
}

export default SettingsPage;