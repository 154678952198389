import React from 'react';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import StyledInputText from '../../form/StyledInputText';

function DefaultServerFilterComponent(props: ColumnFilterElementTemplateOptions) {
  return (
    <StyledInputText
      value={props.value || ''}
      onChange={e => props.filterApplyCallback(e.target.value)}
      placeholder='Filter...'
    />
  );
}

export default DefaultServerFilterComponent;