import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { MdCrop, MdRotateLeft, MdSave, MdSettingsBrightness, MdRotate90DegreesCw, MdRotate90DegreesCcw, MdRestartAlt} from 'react-icons/md';
import useResizeObserver from 'use-resize-observer';
import VisibilityToggler from '../../../VisibilityToggler';
import { EditorSlider } from './EditorSlider';
import EditorButton from './EditorButton';
import useImageEditor from './useImageEditor';

export interface ImageEditorProps {
  imageUrl: string,
  onImageChange: (newImageUrl: string) => any,
  key2?: number,
}

const Wrapper = styled.div`
    & {
        position: relative;
        min-height: 320px;
        min-width: 480px;
        width: 100%;
        height: 100%;
    }
`

function ImageEditor(props: ImageEditorProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { ref: workAreaRef, width: workAreaWidth = 0, height: workAreaHeight = 0 } = useResizeObserver<HTMLDivElement>();
  const { ref: wrapperRef, width: wrapperWidth = 0, height: wrapperHeight = 0 } = useResizeObserver<HTMLDivElement>();

  const editor = useImageEditor({
    workAreaWidth,
    workAreaHeight,
    wrapperWidth,
    wrapperHeight,
    canvasRef,
    imageUrl: props.imageUrl});

  return <Wrapper ref={wrapperRef} key={props.key2}>
    <div className='flex h-full w-full absolute top-0 left-0'>
      <div className='flex flex-column z-2' style={{backdropFilter: 'blur(8px)'}}>
        <EditorButton
          onClick={() => editor.setMode('crop')}
          active={editor.state.mode === 'crop'}
        >
          <div className='flex justify-content-center align-items-center'>
            <MdCrop className='align-content-center' />
          </div>
          <span>crop</span>
        </EditorButton>
        <EditorButton
          onClick={() => editor.setMode('rotate')}
          active={editor.state.mode === 'rotate'}
        >
          <div className='flex justify-content-center align-items-center'>
            <MdRotateLeft className='align-content-center' />
          </div>
          <span>rotate</span>
        </EditorButton>
        <EditorButton
          onClick={() => editor.setMode('color')}
          active={editor.state.mode === 'color'}
        >
          <div className='flex justify-content-center align-items-center'>
            <MdSettingsBrightness className='align-content-center' />
          </div>
          <span>color</span>
        </EditorButton>
        <EditorButton
          onClick={async () => {
            const imageUrl = await editor.exportImage();
            if (imageUrl) {
              props.onImageChange(imageUrl);
            }
          }}
          active={false}
        >
          <div className='flex justify-content-center align-items-center'>
            <MdSave className='align-content-center' />
          </div>
          <span>save</span>
        </EditorButton>
      </div>
      <div className='flex flex-column flex-1' >
        <div className='flex flex-1 justify-content-center align-items-center pointer-events-none z-0' ref={workAreaRef}>
          main area
        </div>
        <div className='flex flex-column z-2' style={{backdropFilter: 'blur(8px)', minHeight: '20%', maxHeight: '20%'}}>
          <VisibilityToggler visible={editor.state.mode === 'rotate'}>
            rotate
            <EditorSlider
              min={-180}
              max={180}
              value={editor.editing.rotation || 0}
              onChange={e => {
                editor.editing._doTemp({ rotation: e.value as number });
              }}
              onSlideEnd={e => {
                editor.editing._do({ rotation: e.value as number});
              }}
            />
            <div>
              <EditorButton
                onClick={() => {
                  editor.editing._do( { rotation: (editor.editing.rotation || 0) + 90 });
                }}
                active={false}
              >
                <div className='flex justify-content-center align-items-center'>
                  <MdRotate90DegreesCw className='align-content-center' />
                </div>
                <span>90°</span>
              </EditorButton>
              <EditorButton
                onClick={() => {
                  editor.editing._do({ rotation: (editor.editing.rotation || 0) - 90 });
                }}
                active={false}
              >
                <div className='flex justify-content-center align-items-center'>
                  <MdRotate90DegreesCcw className='align-content-center' />
                </div>
                <span>-90°</span>
              </EditorButton>
              <EditorButton
                onClick={() => {
                  editor.editing._do({ rotation: 0 });
                }}
                active={false}
              >
                <div className='flex justify-content-center align-items-center'>
                  <MdRestartAlt className='align-content-center' />
                </div>
                <span>reset</span>
              </EditorButton>
            </div>
          </VisibilityToggler>
          <VisibilityToggler visible={editor.state.mode === 'color'}>
            brightness
            <EditorSlider
              value={editor.editing.brightness || 0}
              onChange={e => {
                editor.editing._doTemp({ brightness: e.value as number });
              }}
              onSlideEnd={e => {
                editor.editing._do({ brightness: e.value as number });
              }}
            />
            contrast
            <EditorSlider
              value={editor.editing.contrast || 0}
              onChange={e => {
                editor.editing._doTemp({ contrast: e.value as number });
              }}
              onSlideEnd={e => {
                editor.editing._do({ contrast: e.value as number });
              }}
            />
            exposure
            <EditorSlider
              value={editor.editing.exposure || 0}
              onChange={e => {
                editor.editing._doTemp({ exposure: e.value as number });
              }}
              onSlideEnd={e => {
                editor.editing._do({ exposure: e.value as number });
              }}
            />
            <div>
              <EditorButton
                onClick={() => {
                  editor.editing._do({ brightness: 0, contrast: 0, exposure: 0 });
                }}
                active={false}
              >
                <div className='flex justify-content-center align-items-center'>
                  <MdRestartAlt className='align-content-center' />
                </div>
                <span>reset</span>
              </EditorButton>
            </div>
          </VisibilityToggler>

        </div>
      </div>
    </div>
    <canvas
      className='absolute top-0 left-0 z-1'
      ref={canvasRef}
      width={wrapperWidth}
      height={wrapperHeight}
    >
      canvas
    </canvas>
  </Wrapper>
}


export default ImageEditor;