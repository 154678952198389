import React, { ClipboardEvent, useRef, useState, useMemo } from 'react';
import { InputMaskChangeParams } from 'primereact/inputmask';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import { focusedInputStyle } from '../Styles';
import UnstyledInputMask from '../UnstyledInputMask';
import { defaultTimezoneOffset } from '../../../shared';
import { today } from '../../../shared/util/dateUtils';

export interface CalendarProps {
  value: Date | undefined | null,
  onChange: (newValue: Date | undefined) => any,
  showTime?: boolean | undefined,
  showSetToNowButton?: boolean | undefined,
  isValidated?: boolean | undefined,
  disabled?: boolean | undefined
}

const dateFormat = 'MM/DD/YYYY';
const timeFormat = 'hh:mm A';

const dateMask = '99/99/9999';
const timeMask = '99:99 aa';

const dateToString = (date: Date | null | undefined, dateFormat: string) =>
  date ? (dayjs.tz(date).format(dateFormat)) : undefined;

const HiddenCalendar = styled(Calendar)`
  &, & input {
    all: unset;
    width: 0;
  }
`

const CustomInput = styled.div`
  & {
    display: flex;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0;
    border-bottom: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    appearance: none;
  }
  &.focused {
    ${focusedInputStyle}
  }
  &.validated {
    border-bottom: 1px solid red;
  }
`

function LogisticsCalendar(props: CalendarProps) {
  const { showTime, isValidated, disabled } = props;
  const datetimeFormat = props.showTime
    ? `${dateFormat} ${timeFormat}`
    : dateFormat;
  const datetimeMask = props.showTime
    ? `${dateMask} ${timeMask}`
    : dateMask;
  const dateValue = props.showTime ? convertTZ(props.value) : props.value;
  const textValueFromProps = useMemo(
    () => dateToString(props.value, datetimeFormat),
    [props.value, datetimeFormat]);
  const [textValue, setTextValue] = useState(textValueFromProps);
  if ((!textValue && textValueFromProps)
      || (textValue && !textValueFromProps)
      || (textValue && textValueFromProps && textValue !== textValueFromProps)) {
    // React to external value changes:
    // 1. Empty value to non-empty value
    // 2. Reset value if external prop becomes undefined
    // 3. Non-empty value to different non-empty value
    setTextValue(textValueFromProps);
  }

  const calendarRef = useRef<Calendar>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const underlineClass = useMemo(() => {
    if (isValidated) return 'validated';
    if (focused) return 'focused';
    return '';
  }, [isValidated, focused]);

  const handleOnCalendarChange = (e: CalendarChangeParams) => {
    const newDate = e.value instanceof Array
      ? e.value[0]
      : e.value;
    if (typeof(newDate) === 'string') {
      throw new Error('Not supported');
    }

    if (newDate === undefined || newDate === null) {
      setTextValue('');
      return;
    }

    const newTextDate = dateToString(unConvertTZ(newDate), datetimeFormat);
    setTextValue(newTextDate);
    props.onChange(unConvertTZ(newDate));
  }

  const handleOnInputMaskChange = (e:  InputMaskChangeParams) => {
    let copiedValue;
    if (e.originalEvent.type === 'paste') {
      copiedValue = (e.originalEvent as ClipboardEvent).clipboardData.getData('text');
    }

    const newTextDate = copiedValue || e.value || '';
    if (newTextDate === textValue) return;

    setTextValue(newTextDate);

    const newDate = dayjs(newTextDate, datetimeFormat, true);
    if (newDate.isValid()) {
      props.onChange(unConvertTZ(newDate.toDate()));
    } else {
      props.onChange(undefined);
    }
  }

  const handleTodayClick = () => {
    const date = today();
    setTextValue(date.format(datetimeFormat));
    props.onChange(date.toDate());
  }

  const setToNowButton = props.showSetToNowButton
    ? <Button
      icon='pi pi-clock'
      className='p-button-lg p-button-rounded p-button-text p-button-plain'
      onClick={() => {
        const now = new Date();
        props.onChange(now);
        setTextValue(dateToString(now, datetimeFormat));
      }}
      disabled={disabled}
    />
    : <></>;

  return (
    <CustomInput ref={wrapperRef} className={underlineClass}>
      <HiddenCalendar
        ref={calendarRef}
        value={dateValue || undefined}
        showTime={showTime}
        hourFormat='12'
        onChange={handleOnCalendarChange}
        showButtonBar
        onTodayButtonClick={handleTodayClick}
      />
      {setToNowButton}
      <UnstyledInputMask
        className='flex-1'
        mask={datetimeMask}
        value={textValue}
        onChange={handleOnInputMaskChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder={datetimeFormat}
        disabled={disabled}
      />
      <Button
        icon='pi pi-calendar'
        className='p-button-lg p-button-rounded p-button-text p-button-plain'
        onClick={() => {
          calendarRef?.current?.show();
        }}
        disabled={disabled}
      />
    </CustomInput>
  );
}

// Primereact calendar has no api for date libraries.
// We have to manually convert date to default timezone when passing to calendar
// and shift the date back when passing anywhere else.

/*
  Subtract timezone difference.
 */
function convertTZ(date: Date | null | undefined) {
  if (!date) return date;

  return dayjs(date).add(-1 * defaultTimezoneOffset(date), 'minute').toDate();
}

/*
  Add timezone difference.
 */
function unConvertTZ(date: Date | undefined) {
  if (!date) return date;

  return dayjs(date).add(defaultTimezoneOffset(date), 'minute').toDate();
}

export default LogisticsCalendar;