import { FilterMatchMode } from 'primereact/api';
import {
  ColumnFilterElementTemplateOptions,
  ColumnFilterMatchModeOptions,
  ColumnFilterMatchModeType,
} from 'primereact/column';
import SourceFilterInput from './SourceFilterInput';

export default function useSourceFilter() : {
  filterMatchMode:  ColumnFilterMatchModeType | undefined,
  filterMatchModeOptions : ColumnFilterMatchModeOptions[],
  filterElement: (options : ColumnFilterElementTemplateOptions) => JSX.Element,
}{

  const matchMode = FilterMatchMode.IN;
  return {
    filterMatchMode: matchMode,
    filterElement: (options) => <SourceFilterInput {...options} />,
    filterMatchModeOptions: [{
      value: matchMode, label: matchMode
    }]
  }
}