/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VehicleActions = {
    All: 'All',
    Favorites: 'Favorites',
    ReservedByYou: 'ReservedByYou',
    ReservedBySomeone: 'ReservedBySomeone',
    Free: 'Free',
    FromSylectus: 'FromSylectus',
    Virtual: 'Virtual',
    Temporary: 'Temporary'
} as const;
export type VehicleActions = typeof VehicleActions[keyof typeof VehicleActions];


export function VehicleActionsFromJSON(json: any): VehicleActions {
    return VehicleActionsFromJSONTyped(json, false);
}

export function VehicleActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleActions {
    return json as VehicleActions;
}

export function VehicleActionsToJSON(value?: VehicleActions | null): any {
    return value as any;
}

