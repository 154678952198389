import React, { memo, useMemo } from 'react';
import {
  Brokerage,
  EWidgetChart,
  EWidgetPeriodType,
  UserDto,
} from '../../../generated-api';
import { StyledDropdown } from '../../../components/form/EditableDropdown';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../hooks';
import { Button } from 'primereact/button';
import WidgetOptionsMultiselect from './WidgetOptionsMultiselect';
import { IdName } from '../../../data-moqs/IdName';
import DispatcherMultiselect from './DispatcherMultiselect';
import VisibilityToggler from '../../../components/VisibilityToggler';
import chartCaptions from './ChartCaptions';
import DashboardWeekPicker from './DashboardWeekPicker';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { RootState } from '../../../store/store';

export interface WidgetToolbarProps {
  value?: WidgetFilters,
  onChange: (value: WidgetFilters) => any,
  onFilter?: () => Promise<any>,
  visibility?: WidgetFiltersVisibility,
  charts?: EWidgetChart[],
  onExport?: (filters?: WidgetFilters) => any,
  dispatcherState?: (state: RootState) => UserDto[] | undefined,
}

//Todo generate it from backend
export interface WidgetFilters {
  periodType?: EWidgetPeriodType;
  from?: Date;
  to?: Date;
  dispatcherIds?: Array<string>;
  brokeragesIds?: Array<number>;
  charts?: Array<EWidgetChart>;
}

interface WidgetFiltersVisibility {
  period?: boolean,
  from?: boolean,
  to?: boolean,
  dispatchers?: boolean,
  brokerages?: boolean,
  filterButton?: boolean,
  options?: boolean,
}

const periodNames = new Map([
  [EWidgetPeriodType.Day, ['Day', 'day']],
  [EWidgetPeriodType.Week, ['Week', 'week']],
  [EWidgetPeriodType.Month, ['Month', 'month']],
]);

const WidgetToolbar = memo((props: WidgetToolbarProps) => {
  const defaultDispatchersState : (state: RootState) => UserDto[] | undefined = state => state.dashboard.dispatcherState?.all;
  const dispatchers = useAppSelector(props.dispatcherState ? props.dispatcherState : defaultDispatchersState);
  const brokerages = useAppSelector(state => state.dashboard.brokerages);
  const [placeholderFrom, placeholderTo, pickerType] = useMemo(() => {
    const periodType = props.value?.periodType;
    if (!periodNames.has(periodType as any)) return ['', '', ''];

    const [periodName, pickerType] = periodNames.get(periodType as any) || [];
    return [`From ${periodName}...`, `To ${periodName}...`, pickerType];
  }, [props.value?.periodType]);

  const dayjsFrom = useMemo(
    () => props.value?.from ? dayjs(props.value?.from) : undefined,
    [props.value?.from]);

  const dayjsTo = useMemo(
    () => props.value?.to ? dayjs(props.value?.to) : undefined,
    [props.value?.to]);

  const selectedDispatchers = useMemo(() => {
    if (!props.value?.dispatcherIds?.length) return [];

    return props.value.dispatcherIds
      .map(dispatcherId => dispatchers?.find(d => d.id === dispatcherId))
      .filter(x => x) as UserDto[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value?.dispatcherIds]);

  const selectedBrokerages = useMemo(() => {
    if (!props.value?.brokeragesIds?.length) return [];

    return props.value.brokeragesIds
      .map(brokeragesId => brokerages?.find(b => b.id === brokeragesId))
      .filter(x => x) as Brokerage[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value?.brokeragesIds]);

  const chartOptions = useMemo(() => {
    if (!props.charts) return [];

    return props.charts
      .map(x => chartCaptions.find(caption => caption.id === x))
      .filter(x => x);
  }, [props.charts]);

  const selectedCharts = useMemo(() => {
    if (!props.value?.charts) return [];

    return props.value.charts
      .map(x => chartCaptions.find(caption => caption.id === x))
      .filter(x => x);
  }, [props.value?.charts]);

  return (
    <>
      <VisibilityToggler visible={!props.visibility || props.visibility.period}>
        <div className='w-8rem'>
          <StyledDropdown
            options={[EWidgetPeriodType.Day, EWidgetPeriodType.Week, EWidgetPeriodType.Month]}
            valueTemplate={item => item}
            value={props.value?.periodType}
            onChange={e => props.onChange({
              ...props.value,
              periodType: e.value,
              from: new Date(),
              to: new Date(),
            })}
            className='w-full'
          />
        </div>
      </VisibilityToggler>

      <VisibilityToggler visible={!props.visibility || props.visibility.from} >
        <div className='w-8rem'>
          <DashboardWeekPicker
            picker={pickerType as any}
            placeholder={placeholderFrom}
            value={dayjsFrom}
            onChange={e => props.onChange({
              ...props.value,
              from: e?.toDate(),
              to: props.value?.to || e?.toDate(),
            })}
            className='w-full'
            suffixIcon={null}
            disabledDate={date => !!props.value?.to && date > dayjs(props.value.to)}
            showTime={false}
          />
        </div>
      </VisibilityToggler>
      <VisibilityToggler visible={!props.visibility || props.visibility.to} >
        <div className='w-8rem'>
          <DashboardWeekPicker
            picker={pickerType as any}
            placeholder={placeholderTo}
            value={dayjsTo}
            onChange={e => props.onChange({
              ...props.value,
              from: props.value?.from || e?.toDate(),
              to: e?.toDate(),
            })}
            className='w-full'
            suffixIcon={null}
            disabledDate={date => !!props.value?.from && date < dayjs(props.value.from)}
            showTime={false}
          />
        </div>
      </VisibilityToggler>
      <VisibilityToggler visible={!props.visibility || props.visibility.dispatchers} >
        <div className='w-10rem'>
          <DispatcherMultiselect
            value={selectedDispatchers}
            onChange={e => props.onChange({
              ...props.value,
              dispatcherIds: (e.value as UserDto[]).map(x => x.id || ''),
            })}
            options={dispatchers}
            optionLabel='name'
            itemTemplate={(dispatcher: UserDto) => `${dispatcher.firstName} ${dispatcher.lastName}`}
            selectedItemTemplate={() => <span>{'Dispatchers'}</span>}
            placeholder='Dispatchers'
            maxSelectedLabels={9999}
            className='w-full'
          />
        </div>
      </VisibilityToggler>
      <VisibilityToggler visible={!props.visibility || props.visibility.brokerages} >
        <div className='w-10rem'>
          <DispatcherMultiselect
            value={selectedBrokerages}
            onChange={e => props.onChange({
              ...props.value,
              brokeragesIds: (e.value as Brokerage[]).filter(x => x.id).map(x => x.id!),
            })}
            options={brokerages}
            optionLabel='name'
            itemTemplate={(brokerage: Brokerage) => brokerage.name}
            selectedItemTemplate={() => <span>{'Brokerages'}</span>}
            placeholder='Brokerages'
            maxSelectedLabels={9999}
            className='w-full'
            filter
          />
        </div>
      </VisibilityToggler>
      <VisibilityToggler visible={!props.visibility || props.visibility.filterButton} >
        <Button
          label='Filter'
          icon='pi pi-search'
          iconPos='right'
          className='p-button-text'
          onClick={props?.onFilter}
        />
      </VisibilityToggler>
      <VisibilityToggler visible={!!props?.onExport}>
        <Button 
          className='p-button-primary px-5 ml-2 text-base font-bold'
          onClick={() => {
            if (props?.onExport) {
              props.onExport(props.value);
            }
          }}>
            <p className='my-0'>Export</p>
            <UploadFileIcon fontSize='medium' className='ml-1'/>
        </Button>
      </VisibilityToggler>
      <VisibilityToggler visible={!props.visibility || props.visibility.options} >
        <WidgetOptionsMultiselect
          value={selectedCharts}
          onChange={e => {
            const value = (e.value || []) as IdName<string>[];
            const newValue = value.map(x => x.id as EWidgetChart);

            props.onChange({ ...props.value, charts: newValue });
          }}
          options={chartOptions}
          optionLabel='name'
        />
      </VisibilityToggler>
    </>
  );
});

export default WidgetToolbar;
