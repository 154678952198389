import { IdName } from './IdName';
import { EVehicleStatus } from '../generated-api';

const VehicleStatus: IdName<string>[] = [
  { id: EVehicleStatus.Retried, name: 'Retried' },
  { id: EVehicleStatus.OnLoad, name: 'On load' },
  { id: EVehicleStatus.UnconfinedService, name: 'Unconfined service' },
  { id: EVehicleStatus.InService, name: 'In service' },
  { id: EVehicleStatus.OutOfService, name: 'Out of service' },
];

export default VehicleStatus;