import { useEffect, useState } from 'react';
import Field from '../../components/form/Field';
import StyledInputText from '../../components/form/StyledInputText';
import StyledInputNumber from '../../components/form/StyledInputNumber';
import { Button } from 'primereact/button';
import CenteredSpinner from '../../components/CenteredSpinner';
import { SystemSettings } from '../../generated-api';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { createSystemSetting, getSystemSetting, updateSystemSetting } from './SettingsSlice';
import { showSuccessToast } from '../../components/LogisticsToast';
import HtmlEditor from '../../components/html/HtmlEditor';
import LogisticsPanel from '../../components/LogisticsPanel';
import LogisticsGoogleAuth from '../../components/google/LogisticsGoogleAuth';

function SystemSettingsEdit() {
  const dispatch = useAppDispatch();
  const storedSystemSettings = useAppSelector((state) => state.settings.system);
  // const account = useAppSelector((state : RootState) => state.auth).account;
  const [systemSetting, setSystemSetting] = useState<SystemSettings>(storedSystemSettings);

  useEffect(() => {
    dispatch(getSystemSetting({id: 1}))
      .then((response) => setSystemSetting({...(response.payload as SystemSettings)}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSaveButtonClick() {
    if (systemSetting.id == null) {
      dispatch(createSystemSetting({ systemSettings: systemSetting }))
        .then(() => showSuccessToast('System setting saved!'));
    } else {
      dispatch(updateSystemSetting({ id: systemSetting.id, systemSettings: systemSetting }))
        .then(() => showSuccessToast('System setting saved!'));
    }
  }

  return (
    <LogisticsPanel header='System Settings' toggleable>
      <div className='p-5'>
        <div className='grid'>
          <div className='col-6'>
            <div className='grid'>
              <div className='col'>
                <Field label='Company name'>
                  <StyledInputText
                    // @ts-ignore
                    value={systemSetting.companyName}
                    onChange={e => setSystemSetting(s => {
                      return { ...s, companyName: e.target.value, abbreviation: getAbbreviation(e.target.value)};
                    })}
                  />
                </Field>
              </div>
              <div className='col'>
                <Field label='Company short name'>
                  <StyledInputText
                    // @ts-ignore
                    value={systemSetting.cShortName}
                    onChange={e => setSystemSetting(s => {
                      return { ...s, cShortName: e.target.value };
                    })}
                  />
                </Field>
              </div>
            </div>
            <div className='grid'>
              <div className='col'>
                <Field label='Abbreviation'>
                  <StyledInputText
                    // @ts-ignore
                    value={systemSetting.abbreviation}
                    onChange={e => setSystemSetting(s => {
                      return { ...s, abbreviation: e.target.value };
                    })}
                  />
                </Field>
              </div>
              <div className='col'>
                <Field label='MC Number'>
                  <StyledInputNumber
                    value={systemSetting.mcNumber}
                    onChange={e => setSystemSetting(s => {
                      return { ...s, mcNumber: e.value || 0 };
                    })}
                  />
                </Field>
              </div>
            </div>
            <div className='grid'>
              <div className='col'>
                <LogisticsGoogleAuth userLogin='System' />
              </div>
            </div>
            <h2>Match settings</h2>
            <div className='grid'>
              <div className='col-6'>
                <Field label='Max vehicle fly radius (miles)'>
                  <StyledInputNumber
                    value={systemSetting.maxFlyRadius}
                    onChange={e => setSystemSetting(s => {
                      return { ...s, maxFlyRadius: e.value || 0 };
                    })}
                  />
                </Field>
              </div>
              <div className='col-6'>
                <Field label='Minimal profit'>
                  <StyledInputNumber
                    value={systemSetting.minProfit}
                    onChange={e => setSystemSetting(s => {
                      return { ...s, minProfit: e.value || 0 };
                    })}
                  />
                </Field>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <Field label='Bid email template'>
              <HtmlEditor
                value={systemSetting.bidEmailTemplateS || ''}
                onEditorChange={value => setSystemSetting(s => {
                  return {...s, bidEmailTemplateS: value}
                })} />
            </Field>
          </div>
          <div className='col-6'>
            <Button
              label='SAVE SETTINGS'
              className='p-button-success min-w-min w-16rem h-4rem mr-1'
              onClick={handleSaveButtonClick}>
            </Button>
          </div>
        </div>
        <CenteredSpinner visible={storedSystemSettings.loading} />
      </div>
    </LogisticsPanel>
  );
}

export default SystemSettingsEdit;

function getAbbreviation(name : string | null | undefined) : string {
  if (!name) {
    return '';
  }
  let result : string;
  const words = name.replace(/\s+/g, ' ').trim().split(' ');

  if (words.length > 1) {
    result = words[0][0] + words[1][0];
  }
  else {
    result = words[0].length > 1 ? words[0][0] + words[0][1] : words[0];
  }

  result = result.toUpperCase();
  return result;
}