import React, { memo, useRef } from 'react';
import { Button } from 'primereact/button';
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect';
import styled from '@emotion/styled';

const HiddenMultiSelect = styled(MultiSelect)`
  & {
    all: unset !important;
    width: 0 !important;
    display: block !important;
  }

  &.p-multiselect .p-multiselect-trigger {
    display: none !important;
  }
`

const WidgetOptionsMultiselect = memo((props: MultiSelectProps) => {
  const multiselectRef = useRef<MultiSelect>(null);
  return <>
    <Button
      icon='pi pi-cog'
      className='p-button-text p-button-secondary'
      onClick={() => multiselectRef?.current?.getElement().click()}
    />
    <HiddenMultiSelect {...props} ref={multiselectRef} />
  </>
});

export default WidgetOptionsMultiselect;