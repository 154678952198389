import styled from '@emotion/styled';
import DashboardPanel from '../components/DashboardPanel';

const StyledContainer = styled.div`
  & {
    width: 700px;
    height: 440px;
    overflow: hidden;
  }

  & iframe {
    position: relative;
    top: -355px;
    left: -2px;
    height: 800px;
    width: 100%;
  }
`;

const weatherRadarLink = 'https://www.accuweather.com/en/us/national/weather-radar';

function Weather() {
  return (
    <DashboardPanel className='min-w-max max-w-max'>
      <header className="flex">
        <span className="text-3xl font-medium white-space-nowrap flex justify-content-start align-items-center flex-grow-1 gap-2">
          <a target="_blank" rel="noopener noreferrer" href={weatherRadarLink} className='text-900 no-underline'>
            Weather
          </a>
        </span>
      </header>
      <StyledContainer>
        <iframe src={weatherRadarLink} title="Weather radar" scrolling="no"></iframe>
      </StyledContainer>
      <span>* Press Esc to switch off map full screen mode</span>
    </DashboardPanel>
  );
}

export default Weather;
