import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ConfigurationDto } from '../generated-api';
import { ConfigurationState, loadConfiguration, resetConfiguration, setConfiguration } from '../features/configuration/ConfigurationSlice';
import useBrowserPageStorage from './BrowserStorageHook';

type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> };
type ConfigurationManager = {
  config: NoUndefinedField<ConfigurationDto>,
  loadConfiguration: () => void,
  resetConfiguration: () => void
}
export const CONFIG_STATE_KEY = 'configState';

// Semaphore for preventing simultaneous loading
let loading = false;

export default function useConfiguration() : ConfigurationManager {
  const [storedConfiguration, setStoredConfiguration]
    = useBrowserPageStorage<ConfigurationState>(CONFIG_STATE_KEY, {} as any);
  const dispatch = useAppDispatch();
  const configurationState = useAppSelector(state => state.configuration);
  const load = async () => {
    try {
      await dispatch(loadConfiguration());
      setStoredConfiguration(configurationState);
      loading = false;
    } catch (e: any) {
      console.error('Failed to fetch configuration', e);
    }
  };
  useEffect(() => {
    if (loading) return;
    if (configurationState.loaded) {
      setStoredConfiguration(configurationState);
      return;
    }
    loading = true;
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationState.loaded]);

  useEffect(() => {
    if (!configurationState.loaded && storedConfiguration.loaded) {
      dispatch(setConfiguration(storedConfiguration));
    }
  });

  const _loadConfiguration = () => {
    load();
  };

  const _resetConfiguration = () => {
    dispatch(resetConfiguration());
  }

  return {
    // @ts-ignore
    config: configurationState || {},
    loadConfiguration: _loadConfiguration,
    resetConfiguration: _resetConfiguration
  };
}
