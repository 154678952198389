import React, { useMemo } from 'react';
import { MdClose, MdDone, MdCached } from 'react-icons/md';
import { IconType } from 'react-icons';

export type OcrStatus = 'none' | 'progress' | 'success' | 'fail';

const statusToText = [
  { status: 'fail', text: 'Recognition failed', color: '#B22222', icon: MdClose},
  { status: 'success', text: 'Recognition completed', color: '#2E8B57', icon: MdDone },
  { status: 'progress', text: 'Recognition is running, please wait', color: '#1E90FF', icon: MdCached },
] as { status: OcrStatus, text: string, color?: string, icon: IconType }[];

function OcrStatusBar(props: { status: OcrStatus }) {
  const { status } = props;
  const statusBlock = useMemo(() => {
    if (status === 'none')
      return <></>;

    const option = statusToText.find(x => x.status === status)!;
    const Icon = option.icon;
    return <span className='flex align-items-center' style={{color: option.color}}>
      <Icon />
      <span>{option?.text}</span>
    </span>
  }, [status]);

  return statusBlock;
}

export default OcrStatusBar;