import { useGoogleLogin } from '@react-oauth/google';
import { apiFactory } from '../../shared';
import { GmailApi } from '../../generated-api';
import { useEffect, useState } from 'react';

function useLogisticsGoogleAuth(userLogin: string) {
  const [authorizedEmail, setAuthorizedEmail] = useState('');
  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const email = await apiFactory(GmailApi).apiGmailAuthPost({ code, login: userLogin });
      setAuthorizedEmail(email);
    },
    flow: 'auth-code',
    scope: 'https://mail.google.com/',
  });

  // Load existing token there
  useEffect( () => {
    apiFactory(GmailApi).apiGmailEmailGet({ login: userLogin })
      .then(email => {
        setAuthorizedEmail(email);
      }).catch(reason => {
        console.error('Failed to load authorized email', reason);
    });
  }, [userLogin])


  return { login, authorizedEmail };
}

export default useLogisticsGoogleAuth;