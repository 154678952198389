import React, { useState, useMemo } from 'react';
import LogisticsDataGrid from '../../components/data-grid/LogisticsDataGrid';
import { ExternalFilterProperties } from '../../components/data-grid/LogisticsDataGridParameters';
import VisibilityToggler from '../../components/VisibilityToggler';
import AddButton from '../../components/data-grid/buttons/AddButton';
import useVehiclesColumns from './VehiclesColumnDef';
import { getVehiclesPage as getVehiclesEntities } from './VehiclesSlice';
import VehicleStatusFilter from '../../data-moqs/VehicleStatusFilter';
import { ApiVehiclesGetRequest, EStatusHR, EVehicleStatusFilter, Vehicle } from '../../generated-api';
import serverFilterComponent, { serverFilterFunctionName } from '../../components/filters/server/ServerFilter';
import { showErrorToast, showSuccessToast } from '../../components/LogisticsToast';
import { RootState } from '../../store/store';
import useRights from '../../hooks/RightsHook';
import { useModal } from '../../hooks';
import { Button } from 'primereact/button';
import VehicleEdit from './VehicleEdit';
import { apiFactory } from '../../shared';
import { VehiclesApi } from '../../generated-api';

const selectVehicle = (state: RootState) => state.vehicle;

function VehiclesList() {
  const [loading, setLoading] = useState(false);
  const vehicleModal = useModal<Vehicle>({});
  const vehicleRights = useRights(security => security.vehicle);

  const externalFilters = useMemo(() => {
    const visible = vehicleRights.executeVehicleStats;
    if (!visible) {
      return [
        {
          field: 'statusHR',
          ...serverFilterComponent(() => <></>),
          serverFilterRequest: (filterMetaData, serverFilterModel: ApiVehiclesGetRequest) => {
            return { ...serverFilterModel, statusHR: filterMetaData.value };
          }
        }
      ] as ExternalFilterProperties[];
    }

    return [
      {
        field: 'statusHR',
        ...serverFilterComponent(() => <></>),
        serverFilterRequest: (filterMetaData, serverFilterModel: ApiVehiclesGetRequest) => {
          return { ...serverFilterModel, statusHR: filterMetaData.value };
        }
      },
      {
        field: 'none1',
        filterMatchMode: 'custom',
        filterMatchModeOptions: [],
        filterElement: () => <Button
          label='Create VehicleStats'
          className='p-button-danger p-button-text'
          key='vehiclestats'
          onClick={handleCreateVehicleStatsClick}
        />,
      }] as ExternalFilterProperties[];
  }, [vehicleRights.executeVehicleStats]);

  const handleCreateVehicleStatsClick = async () => {
    try {
      setLoading(true);
      await apiFactory(VehiclesApi).apiVehiclesExecuteVehicleStatsGet();
      showSuccessToast('Completed');
    } catch {
      showErrorToast('Failed to create vehicle stats');
    } finally {
      setLoading(false);
    }
  }

  const vehicleEdit = vehicleModal.visible
    ? <VehicleEdit
      data={vehicleModal.data}
      visible={vehicleModal.visible}
      loading={false}
      hide={vehicleModal.hide}
    />
    : <></>;

  return (
    <>
      <LogisticsDataGrid
        id='vehiclesList'
        selectState={selectVehicle}
        externalFilters={externalFilters}
        leftHeaderElements={[
          <VisibilityToggler visible={vehicleRights.create}>
            <AddButton onClick={() => vehicleModal.show({})}/>
          </VisibilityToggler>
        ]}
        columns={useVehiclesColumns()}
        getEntitiesPage={getVehiclesEntities}
        onRowClick={a => vehicleModal.show(a.data)}
        storedDefaultFilterMeta={{
          'status': {
            value: VehicleStatusFilter.find(x => x.id === EVehicleStatusFilter.Active),
            // @ts-ignore
            matchMode: serverFilterFunctionName},
          'statusHR': {
            value: [EStatusHR.Approved, EStatusHR.Rejected],
            // @ts-ignore
            matchMode: serverFilterFunctionName
          }

        }}
        others={{
          multiSortMeta: [{field: 'externalId', order: -1}],
          selectionMode: 'single',
          loading: loading
        }}
      />
      {vehicleEdit}
    </>
  );
}

export default VehiclesList;
