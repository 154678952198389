/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EMailTemplateVariables,
  SystemSettings,
} from '../models';
import {
    EMailTemplateVariablesFromJSON,
    EMailTemplateVariablesToJSON,
    SystemSettingsFromJSON,
    SystemSettingsToJSON,
} from '../models';

export interface ApiSystemSettingsGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiSystemSettingsIdDeleteRequest {
    id: number;
}

export interface ApiSystemSettingsIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiSystemSettingsIdPutRequest {
    id: number;
    systemSettings?: SystemSettings;
}

export interface ApiSystemSettingsMailVariablesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiSystemSettingsPostRequest {
    systemSettings?: SystemSettings;
}

/**
 * 
 */
export class SystemSettingsApi extends runtime.BaseAPI {

    /**
     */
    async apiSystemSettingsGetRaw(requestParameters: ApiSystemSettingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SystemSettings>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/system-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SystemSettingsFromJSON));
    }

    /**
     */
    async apiSystemSettingsGet(requestParameters: ApiSystemSettingsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SystemSettings>> {
        const response = await this.apiSystemSettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSystemSettingsIdDeleteRaw(requestParameters: ApiSystemSettingsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSystemSettingsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/system-settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSystemSettingsIdDelete(requestParameters: ApiSystemSettingsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiSystemSettingsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSystemSettingsIdGetRaw(requestParameters: ApiSystemSettingsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SystemSettings>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSystemSettingsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/system-settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingsFromJSON(jsonValue));
    }

    /**
     */
    async apiSystemSettingsIdGet(requestParameters: ApiSystemSettingsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SystemSettings> {
        const response = await this.apiSystemSettingsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSystemSettingsIdPutRaw(requestParameters: ApiSystemSettingsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSystemSettingsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/system-settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SystemSettingsToJSON(requestParameters.systemSettings),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSystemSettingsIdPut(requestParameters: ApiSystemSettingsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiSystemSettingsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSystemSettingsMailVariablesGetRaw(requestParameters: ApiSystemSettingsMailVariablesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EMailTemplateVariables>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/system-settings/mailVariables`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EMailTemplateVariablesFromJSON(jsonValue));
    }

    /**
     */
    async apiSystemSettingsMailVariablesGet(requestParameters: ApiSystemSettingsMailVariablesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EMailTemplateVariables> {
        const response = await this.apiSystemSettingsMailVariablesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSystemSettingsPostRaw(requestParameters: ApiSystemSettingsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SystemSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/system-settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SystemSettingsToJSON(requestParameters.systemSettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingsFromJSON(jsonValue));
    }

    /**
     */
    async apiSystemSettingsPost(requestParameters: ApiSystemSettingsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SystemSettings> {
        const response = await this.apiSystemSettingsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
