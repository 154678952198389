import { useEffect } from 'react';
import { loadRights, SecurityState } from '../features/security/SecuritySlice';
import { useAppDispatch, useAppSelector } from '../hooks';

type AlwaysDefined<T> = Exclude<T, undefined | null>;

// Semaphore for preventing simultaneous loading
let loading = false;
const emptyRights = {};

export default function useRights<T>(
  selector?: (store: SecurityState) => T,
) : AlwaysDefined<T> {
  const dispatch = useAppDispatch();
  const allRights = useAppSelector(state => state.security);
  const requestedRights = useAppSelector(state => selector ? selector(state.security) : state.security);

  useEffect(() => {
    if (loading || allRights.loaded) return;
    loading = true;
    const load = async () => {
      try {
        await dispatch(loadRights());
        loading = false;
      } catch (e: any) {
        console.error('Failed to fetch rights', e);
      }
    }

    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRights.loaded]);

  // @ts-ignore
  return requestedRights || emptyRights;
}
