/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bid } from './Bid';
import {
    BidFromJSON,
    BidFromJSONTyped,
    BidToJSON,
} from './Bid';

/**
 * 
 * @export
 * @interface BidStatus
 */
export interface BidStatus {
    /**
     * 
     * @type {string}
     * @memberof BidStatus
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BidStatus
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BidStatus
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BidStatus
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BidStatus
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {Array<Bid>}
     * @memberof BidStatus
     */
    bids?: Array<Bid> | null;
    /**
     * 
     * @type {number}
     * @memberof BidStatus
     */
    id?: number;
}

/**
 * Check if a given object implements the BidStatus interface.
 */
export function instanceOfBidStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BidStatusFromJSON(json: any): BidStatus {
    return BidStatusFromJSONTyped(json, false);
}

export function BidStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'bids': !exists(json, 'bids') ? undefined : (json['bids'] === null ? null : (json['bids'] as Array<any>).map(BidFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function BidStatusToJSON(value?: BidStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'bids': value.bids === undefined ? undefined : (value.bids === null ? null : (value.bids as Array<any>).map(BidToJSON)),
        'id': value.id,
    };
}

