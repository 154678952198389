import { FormikErrors, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { useCallback, useEffect, useState } from 'react';
import CenteredSpinner from '../../components/CenteredSpinner';
import Column from '../../components/form/Column';
import EditableDropdown from '../../components/form/EditableDropdown';
import ErrorBox from '../../components/form/ErrorBox';
import Field from '../../components/form/Field';
import Row from '../../components/form/Row';
import StyledInputText from '../../components/form/StyledInputText';
import LogisticsDialog from '../../components/LogisticsDialog';
import UserStatus, { eUserStatusToUserStatus } from '../../data-moqs/UserStatus';
import { UserDto, UsersApi } from '../../generated-api';
import { useAppDispatch } from '../../hooks';
import { apiFactory } from '../../shared';
import { ModalParameters } from '../../shared';
import { updateUser } from './UsersSlice';
import { showSuccessToast, showToast } from '../../components/LogisticsToast';
import useRights from '../../hooks/RightsHook';

export interface UserEditParameters extends ModalParameters<UserDto> {
}

interface UserDtoWithPasswordFields extends UserDto {
  passwordCheck? : string | undefined,
}

function UserEdit (props: UserEditParameters) {
  const { visible } = props;
  const [data, setData] = useState(props.data);
  const [loading, setLoading] = useState(props.loading);
  const [authorities, setAuthorities] = useState<string[]>([]);
  const [passwordCheck, setPasswordCheck] = useState('');
  const dispatch = useAppDispatch();
  const rights = useRights(security => security.settings);
  const validation = useFormik<UserDtoWithPasswordFields>({
    initialValues: data as UserDtoWithPasswordFields,
    validateOnChange: false,

    validate: () => {
      const errors : FormikErrors<UserDtoWithPasswordFields> = {};
      const blankError = 'This value should not be blank.';

      const requiredFields = {
        firstName: undefined,
        lastName: undefined,
        login: undefined,
        status: undefined,
        roleName: undefined,
      };

      Object.keys(requiredFields).forEach(field => {
        if (!(data as any)[field])
          (errors as any)[field] = blankError;
      });

      validatePassword(errors);

      return errors;
    },
    onSubmit: () => {},
  });

  const hide = (user? : UserDto) => {
    if (user) {
      dispatch(updateUser(user));
    }
    props.hide(user);
    setLoading(false);
  };

  const validatePassword = (errors: FormikErrors<UserDtoWithPasswordFields>) => {
    let currentPasswordError : string | undefined;
    let currentPasswordCheckError: string | undefined;

    if (!data.id && !data.adminPassword) {
      currentPasswordError = 'Password is required for a new user.';
    }

    if (data.adminPassword && data.adminPassword.length < 4) {
      currentPasswordError = 'Password length must be more than 4 symbols.';
    }

    if (data.adminPassword && data.adminPassword.length > 100) {
      currentPasswordError = 'Password length must be less than 100 symbols.'
    }

    if (data.adminPassword && !passwordCheck) {
      currentPasswordCheckError = 'Field "Password again" is required.';
    }

    if (data.adminPassword && passwordCheck && data.adminPassword !== passwordCheck) {
      currentPasswordError = 'Passwords must be the same.'
      currentPasswordCheckError = 'Passwords must be the same.'
    }
    
    if (currentPasswordError || currentPasswordCheckError) {
      errors['adminPassword'] = currentPasswordError;
      errors['passwordCheck'] = currentPasswordCheckError;
    }
  };

  const save = useCallback(async () => {
    try {
      setLoading(true);

      validation.handleSubmit();
      const errors = await validation.validateForm(data as UserDtoWithPasswordFields);
      if (Object.keys(errors).length) {
        showToast({severity: 'error', summary: 'Error', detail: 'Fix errors and try again'});
        throw new Error('Validation\'s error');
      }

      let response = data.id == null 
        ? await apiFactory(UsersApi).apiAdminUsersPost({ userDto: data })
        : await apiFactory(UsersApi).apiAdminUsersPut({ userDto: data });

      showSuccessToast('User saved');
      validation.resetForm(data);
      hide(response || data);
    } catch(e: any) {
      showToast({
        severity: 'error',
        summary: 'Error',
        detail: 'Error on user\'s creation',
        life: 2000
      });
      throw e;
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, validation]);

  useEffect(() => {
    if (!visible) return;

    setLoading(true);
    const userPromise = props.data.login
      ? apiFactory(UsersApi).apiAdminUsersLoginGet({ login: props.data.login })
      : Promise.resolve(props.data);
    const authorityPromise = rights.allRoles 
      ? apiFactory(UsersApi).apiAdminUsersAuthoritiesGet() 
      : apiFactory(UsersApi).apiAdminUsersAuthoritiesLimitedGet()
    Promise.all([
      userPromise,
      authorityPromise
    ]).then(([loadedUser, authorities]) => {
      setData(loadedUser);
      setAuthorities(authorities);
    }).catch(() => showToast(
      { severity: 'error', summary: 'Error', detail: 'Something went wrong'}))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleOnHide = useCallback(() => {
    if (validation.dirty) {
      confirmDialog({
        message: 'Save changes?',
        closable: false,
        accept() {
          save();
        },
        reject() {
          hide();
        }
      });
    } else {
      hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide, data, validation]);

  const isNew = useCallback(() => {
    return !data.id;
  }, [data]);

  const setValue = useCallback((field : string, value : any) => {
    validation.setFieldValue(field, value);
    setData(d => { return {...d, [field]: value}});
    // To update validation messages when change is happened
    setTimeout(() => validation.setFieldTouched(field, true));
  }, [validation]);
  
  return <>;
    <LogisticsDialog
        visible={visible}
        onHide={handleOnHide}
        className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-8'
    >
      <header className='text-2xl w-full flex-wrap'>
        <span className='font-bold'>User {data.login}</span>
      </header>
      <main className='grid logistics-dialog-edit-form__content mt-6'>
        <Row>
          <Column>
            <Field label='Username' required>
              <StyledInputText
                // @ts-ignore
                value={data.login}
                onChange={e => setValue('login', e.target.value)}
              />
            </Field>
          </Column>
          <Column>
            <Field label='Password'>
              <StyledInputText
                // @ts-ignore
                value={data.adminPassword}
                placeholder={isNew() ? '' : '••••••••••'}
                onFocus={e => e.target.placeholder = ''}
                type='password'
                autoComplete='new-password'
                onChange={e => setValue('adminPassword', e.target.value)}
              />
            </Field>
            <ErrorBox>{validation.errors.adminPassword}</ErrorBox>
          </Column>
          <Column>
            <Field label='Password again'>
              <StyledInputText
                // @ts-ignore
                value={passwordCheck}
                type='password'
                onChange={e => { 
                  validation.setFieldValue('passwordCheck', e.target.value);
                  setPasswordCheck(e.target.value); 
                  setTimeout(() => validation.setFieldTouched('passwordCheck', true));}}
              />
            </Field>
            <ErrorBox>{validation.errors.passwordCheck}</ErrorBox>
          </Column>
          <Column>
            <Field label='First Name' required>
              <StyledInputText
                // @ts-ignore
                value={data.firstName}
                onChange={e => setValue('firstName', e.target.value)}
              />
            </Field>
            <ErrorBox>{validation.errors.firstName}</ErrorBox>
          </Column>
          <Column>
            <Field label='Last Name' required>
              <StyledInputText
                // @ts-ignore
                value={data.lastName}
                onChange={e => setValue('lastName', e.target.value)}
              />
            </Field>
            <ErrorBox>{validation.errors.lastName}</ErrorBox>
          </Column>
          <Column>
            <Field label='Status' required>
              <EditableDropdown
                options={UserStatus}
                optionLabel='name'
                renderOption={userStatus => userStatus.name}
                renderSelected={userStatus=> userStatus?.name}
                renderEmptySelected={() => '-'}
                value={eUserStatusToUserStatus(data.status)}
                onChange={newValue => setValue('status', newValue.id as any)}
              />
            </Field>
            <ErrorBox>{validation.errors.status}</ErrorBox>
          </Column>
          <Column>
            <Field label='Role' required>
              <EditableDropdown
                options={authorities}
                optionLabel='name'
                renderOption={a => a}
                renderSelected={a => a}
                renderEmptySelected={() => '-'}
                value={data.roleName}
                onChange={newValue => setValue('roleName', newValue)}
              />
            </Field>
            <ErrorBox>{validation.errors.roleName}</ErrorBox>
          </Column>
        </Row>
      </main>
      <footer className='flex justify-content-center w-full gap-3'>
        <Button
          label='SAVE'
          className='p-button-success'
          onClick={save}
        />
        <Button
          label='CLOSE'
          onClick={handleOnHide}
        />
      </footer>
      <CenteredSpinner visible={loading} />
    </LogisticsDialog>
  </>
}

export default UserEdit;