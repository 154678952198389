/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ERequestStatus,
  Owner,
  Request,
  User,
} from '../models';
import {
    ERequestStatusFromJSON,
    ERequestStatusToJSON,
    OwnerFromJSON,
    OwnerToJSON,
    RequestFromJSON,
    RequestToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface ApiRequestsExportGetRequest {
    dateFrom?: Date;
    dateTo?: Date;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRequestsGetRequest {
    globalFilter?: string;
    status?: ERequestStatus;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRequestsIdDeleteRequest {
    id: number;
}

export interface ApiRequestsIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRequestsIdPutRequest {
    id: number;
    request?: Request;
}

export interface ApiRequestsManagersGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRequestsOwnerEmployerIdGetRequest {
    employerId: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRequestsPostRequest {
    request?: Request;
}

/**
 * 
 */
export class RequestApi extends runtime.BaseAPI {

    /**
     */
    async apiRequestsExportGetRaw(requestParameters: ApiRequestsExportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/requests/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiRequestsExportGet(requestParameters: ApiRequestsExportGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiRequestsExportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRequestsGetRaw(requestParameters: ApiRequestsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Request>>> {
        const queryParameters: any = {};

        if (requestParameters.globalFilter !== undefined) {
            queryParameters['globalFilter'] = requestParameters.globalFilter;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestFromJSON));
    }

    /**
     */
    async apiRequestsGet(requestParameters: ApiRequestsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Request>> {
        const response = await this.apiRequestsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRequestsIdDeleteRaw(requestParameters: ApiRequestsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRequestsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRequestsIdDelete(requestParameters: ApiRequestsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiRequestsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiRequestsIdGetRaw(requestParameters: ApiRequestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Request>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRequestsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async apiRequestsIdGet(requestParameters: ApiRequestsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Request> {
        const response = await this.apiRequestsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRequestsIdPutRaw(requestParameters: ApiRequestsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Request>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRequestsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async apiRequestsIdPut(requestParameters: ApiRequestsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Request> {
        const response = await this.apiRequestsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRequestsManagersGetRaw(requestParameters: ApiRequestsManagersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/requests/managers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async apiRequestsManagersGet(requestParameters: ApiRequestsManagersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.apiRequestsManagersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRequestsOwnerEmployerIdGetRaw(requestParameters: ApiRequestsOwnerEmployerIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Owner>> {
        if (requestParameters.employerId === null || requestParameters.employerId === undefined) {
            throw new runtime.RequiredError('employerId','Required parameter requestParameters.employerId was null or undefined when calling apiRequestsOwnerEmployerIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/requests/owner/{employerId}`.replace(`{${"employerId"}}`, encodeURIComponent(String(requestParameters.employerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnerFromJSON(jsonValue));
    }

    /**
     */
    async apiRequestsOwnerEmployerIdGet(requestParameters: ApiRequestsOwnerEmployerIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Owner> {
        const response = await this.apiRequestsOwnerEmployerIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRequestsPostRaw(requestParameters: ApiRequestsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Request>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async apiRequestsPost(requestParameters: ApiRequestsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Request> {
        const response = await this.apiRequestsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
