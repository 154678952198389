import LogisticsColumnProps from '../../../../components/data-grid/LogisticsColumnProps';
import React from 'react';
import { EDriverOrder, Vehicle } from '../../../../generated-api';
import statusesHr from '../../components/statusesHr';

const colStyle = {
  flex: 1,
}

const requestUnitListColumnDef : LogisticsColumnProps[] = [
  {
    field: 'id',
    header: 'ID',
    style: colStyle,
  },
  {
    field: 'fullName',
    header: 'Full Name',
    style: colStyle,
    body: (rowData: Vehicle) => {
      if (!rowData.drivers?.length) return <></>;

      const driver = rowData.drivers.find(d => d.order === EDriverOrder.First)
        || rowData.drivers.find(d => d.order === EDriverOrder.Second);
      if (!driver) return <></>;

      return `${driver.firstName || ''} ${driver.lastName || ''}`;
    }
  },
  {
    field: 'vehicle',
    header: 'Vehicle',
    style: colStyle,
    body: (rowData: Vehicle) =>
      `${rowData.vehicleMake || ''} ${rowData.vehicleModel || ''}`
  },
  {
    field: 'phone',
    header: 'Phone',
    style: colStyle,
    body: (rowData: Vehicle) => {
      if (!rowData.drivers?.length) return <></>;

      const driver = rowData.drivers[0];
      if (!driver.phones?.length) return <></>;

      const phone = driver.phones[0];
      return phone || '';
    }
  },
  {
    field: 'statusHr',
    header: 'Status',
    style: colStyle,
    body: (rowData: Vehicle) => {
      const status = statusesHr.find(x => x.id === rowData.statusHR);
      return status?.caption || '';
    }
  },
  {
    field: 'note',
    header: 'Note',
    style: colStyle,
  }
];



export default requestUnitListColumnDef;