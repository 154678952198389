import styled from '@emotion/styled';
import { MultiSelect } from 'primereact/multiselect';

const StyledMultiselect = styled(MultiSelect)`
  &.p-multiselect:not(.p-disabled), &.p-multiselect:not(.p-disabled).p-focus {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    box-shadow: none;
    border-color: var(--surface-400);
  }

  .p-multiselect-label span ~ span::before {
    content: ', '
  }
`
export default StyledMultiselect;