import React, { CSSProperties, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { usePDFSlick, } from '@pdfslick/react';
import { AnnotationMode } from 'pdfjs-dist';
import "@pdfslick/react/dist/pdf_viewer.css";
import Toolbar from './Toolbar';
import { create } from 'zustand';
import { Property } from 'csstype';
import useKeyZoom from './useKeyZoom';

type LogisticsPdfProps = {
  url: string,
  placeholder?: string,
  width?:  Property.Width<string | number>,
  height?: Property.Width<string | number>,
  scale?: string,
}

export type CustomPdfStore = {
  fullscreen: boolean,
  toggleFullscreen: () => void,
}

const CompactContainer = styled.div`
  & {
    overflow-y: hidden;
  }
`

const FullscreenContainer = styled.div`
  & {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    // Todo: find better number
    z-index: 9999;
  }
`

const defaultCompactContainerSize = {
  width: '100%',
  height: '650px',
  maxHeight: '650px',
} as CSSProperties;

function LogisticsPdf(props: LogisticsPdfProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { viewerRef, usePDFSlickStore, PDFSlickViewer } = usePDFSlick(
    props.url,
    {
      singlePageViewer: false,
      scaleValue: props.scale || 'auto',
      annotationMode: AnnotationMode.ENABLE_FORMS,
      removePageBorders: false,
    }
  );
  const useCustomStore = useMemo(() => {
    return create<CustomPdfStore>(set => ({
      fullscreen: false,
      toggleFullscreen: () => set(state => ({...state, fullscreen: !state.fullscreen}))
    }));
  }, []);
  const fullscreen = useCustomStore(s => s.fullscreen);
  useKeyZoom(usePDFSlickStore);

  const Container = useMemo(() => {
    return fullscreen
      ? FullscreenContainer
      : CompactContainer;
  }, [fullscreen]);

  const containerSize : CSSProperties = useMemo(() => {
    if (fullscreen)
      return {};
    if (!props.width && !props.height)
      return defaultCompactContainerSize;

    return { width: props.width, height: props.height };
  },
    [fullscreen, props.width, props.height]);



  if (!props.url) return <>{props.placeholder || 'Not available'}</>;

  return <Container style={containerSize}>
    <div className='flex flex-column h-full'>
      <div className='flex-grow-0'>
        <Toolbar useCustomStore={useCustomStore} usePDFSlickStore={usePDFSlickStore}/>
      </div>
      <div className='relative h-full w-full bg-white flex-grow-1' ref={containerRef}>
        <PDFSlickViewer {...{ viewerRef, usePDFSlickStore }} />
      </div>
    </div>

  </Container>
}

export default LogisticsPdf;
