/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProfitStatisticsResponse
 */
export interface ProfitStatisticsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfitStatisticsResponse
     */
    period?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProfitStatisticsResponse
     */
    driversProfit?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProfitStatisticsResponse
     */
    profit?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProfitStatisticsResponse
     */
    margin?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProfitStatisticsResponse
     */
    loads?: Array<number> | null;
}

/**
 * Check if a given object implements the ProfitStatisticsResponse interface.
 */
export function instanceOfProfitStatisticsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProfitStatisticsResponseFromJSON(json: any): ProfitStatisticsResponse {
    return ProfitStatisticsResponseFromJSONTyped(json, false);
}

export function ProfitStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfitStatisticsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'period': !exists(json, 'period') ? undefined : json['period'],
        'driversProfit': !exists(json, 'driversProfit') ? undefined : json['driversProfit'],
        'profit': !exists(json, 'profit') ? undefined : json['profit'],
        'margin': !exists(json, 'margin') ? undefined : json['margin'],
        'loads': !exists(json, 'loads') ? undefined : json['loads'],
    };
}

export function ProfitStatisticsResponseToJSON(value?: ProfitStatisticsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'period': value.period,
        'driversProfit': value.driversProfit,
        'profit': value.profit,
        'margin': value.margin,
        'loads': value.loads,
    };
}

