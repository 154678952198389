/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ENotificationType = {
    None: 'None',
    VehicleApproved: 'VehicleApproved',
    VehicleExpires: 'VehicleExpires',
    OwnerDocumentsSigned: 'OwnerDocumentsSigned'
} as const;
export type ENotificationType = typeof ENotificationType[keyof typeof ENotificationType];


export function ENotificationTypeFromJSON(json: any): ENotificationType {
    return ENotificationTypeFromJSONTyped(json, false);
}

export function ENotificationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ENotificationType {
    return json as ENotificationType;
}

export function ENotificationTypeToJSON(value?: ENotificationType | null): any {
    return value as any;
}

