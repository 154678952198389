import React, { ChangeEvent, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { IAllProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { AlwaysDefined } from '../../shared';

// Bundle all TinyMCE editor locally: https://www.tiny.cloud/docs/tinymce/6/webpack-es6-npm/
// Anything used but not bundled will be downloaded via CDN,
//  which is not approved by client for now.

/* Import TinyMCE */
import 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default';

/* Required TinyMCE components */
import 'tinymce/themes/silver';
import 'tinymce/models/dom';

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.css';

/* Import plugins */
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/wordcount';

function HtmlEditor(props: IAllProps) {
  const editorRef = useRef<Editor>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Some types are exposed but not exported, so we cannot use them.
  const filePickerCallback = (callback: any, value: any, meta: any) => {
    if (!fileInputRef.current) return;

    const handleOnChange = (e: Event) => {
      const event = e as any as ChangeEvent<HTMLInputElement>;
      const file = event!.target!.files![0];
      const reader = new FileReader() as AlwaysDefined<FileReader>;

      reader.addEventListener('load', () => {
        if (!editorRef.current?.editor) return;
        if (!reader.result) return;

        const id = 'blobid' + (new Date()).getTime();
        const blobCache =  editorRef.current.editor.editorUpload.blobCache;
        const base64 = (reader.result as string).split(',')[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        callback(blobInfo.blobUri(), { title: file.name });
      });
      reader.readAsDataURL(file);
    };
    fileInputRef.current.addEventListener('change', handleOnChange);
    fileInputRef.current.click();
    fileInputRef.current.removeEventListener('change', handleOnChange);
  }
  return (
    <>
      <input
        type='file'
        accept='image/*'
        className='hidden'
        ref={fileInputRef}
      />
      <Editor
        {...props}
        ref={editorRef}
        init={{
          ...props.init,
          plugins: 'lists link image help wordcount table',
          selector: 'textarea' as any,
          file_picker_callback: filePickerCallback,
          images_upload_handler: (blobInfo, progress) => {
            const src = 'data:image/png;base64, ' + blobInfo.base64();
            return Promise.resolve(src);
          },
          automatic_uploads: false,
          table_sizing_mode: 'fixed',
        }}
      />
    </>

  );
}

export default HtmlEditor;