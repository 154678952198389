/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Offer,
} from '../models';
import {
    OfferFromJSON,
    OfferToJSON,
} from '../models';

export interface ApiOffersSuitableForOrderGetRequest {
    loadedOrderId?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiOffersSuitableForPointGetRequest {
    x?: number;
    y?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiOffersWithDriverBidsGetRequest {
    loadedOrderId?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class OffersApi extends runtime.BaseAPI {

    /**
     */
    async apiOffersSuitableForOrderGetRaw(requestParameters: ApiOffersSuitableForOrderGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Offer>>> {
        const queryParameters: any = {};

        if (requestParameters.loadedOrderId !== undefined) {
            queryParameters['loadedOrderId'] = requestParameters.loadedOrderId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/offers/suitable-for-order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferFromJSON));
    }

    /**
     */
    async apiOffersSuitableForOrderGet(requestParameters: ApiOffersSuitableForOrderGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Offer>> {
        const response = await this.apiOffersSuitableForOrderGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOffersSuitableForPointGetRaw(requestParameters: ApiOffersSuitableForPointGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Offer>>> {
        const queryParameters: any = {};

        if (requestParameters.x !== undefined) {
            queryParameters['x'] = requestParameters.x;
        }

        if (requestParameters.y !== undefined) {
            queryParameters['y'] = requestParameters.y;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/offers/suitable-for-point`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferFromJSON));
    }

    /**
     */
    async apiOffersSuitableForPointGet(requestParameters: ApiOffersSuitableForPointGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Offer>> {
        const response = await this.apiOffersSuitableForPointGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOffersWithDriverBidsGetRaw(requestParameters: ApiOffersWithDriverBidsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Offer>>> {
        const queryParameters: any = {};

        if (requestParameters.loadedOrderId !== undefined) {
            queryParameters['loadedOrderId'] = requestParameters.loadedOrderId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/offers/with-driver-bids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferFromJSON));
    }

    /**
     */
    async apiOffersWithDriverBidsGet(requestParameters: ApiOffersWithDriverBidsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Offer>> {
        const response = await this.apiOffersWithDriverBidsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
