/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingsAccess
 */
export interface SettingsAccess {
    /**
     * 
     * @type {boolean}
     * @memberof SettingsAccess
     */
    system?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsAccess
     */
    dispatcher?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsAccess
     */
    users?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsAccess
     */
    userDeletion?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsAccess
     */
    allRoles?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsAccess
     */
    googleAuth?: boolean | null;
}

/**
 * Check if a given object implements the SettingsAccess interface.
 */
export function instanceOfSettingsAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SettingsAccessFromJSON(json: any): SettingsAccess {
    return SettingsAccessFromJSONTyped(json, false);
}

export function SettingsAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'system': !exists(json, 'system') ? undefined : json['system'],
        'dispatcher': !exists(json, 'dispatcher') ? undefined : json['dispatcher'],
        'users': !exists(json, 'users') ? undefined : json['users'],
        'userDeletion': !exists(json, 'userDeletion') ? undefined : json['userDeletion'],
        'allRoles': !exists(json, 'allRoles') ? undefined : json['allRoles'],
        'googleAuth': !exists(json, 'googleAuth') ? undefined : json['googleAuth'],
    };
}

export function SettingsAccessToJSON(value?: SettingsAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'system': value.system,
        'dispatcher': value.dispatcher,
        'users': value.users,
        'userDeletion': value.userDeletion,
        'allRoles': value.allRoles,
        'googleAuth': value.googleAuth,
    };
}

