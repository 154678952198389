/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyUser } from './CompanyUser';
import {
    CompanyUserFromJSON,
    CompanyUserFromJSONTyped,
    CompanyUserToJSON,
} from './CompanyUser';
import type { ECompany } from './ECompany';
import {
    ECompanyFromJSON,
    ECompanyFromJSONTyped,
    ECompanyToJSON,
} from './ECompany';

/**
 * 
 * @export
 * @interface UserPerCompanyResponse
 */
export interface UserPerCompanyResponse {
    /**
     * 
     * @type {ECompany}
     * @memberof UserPerCompanyResponse
     */
    company?: ECompany;
    /**
     * 
     * @type {CompanyUser}
     * @memberof UserPerCompanyResponse
     */
    user?: CompanyUser;
}

/**
 * Check if a given object implements the UserPerCompanyResponse interface.
 */
export function instanceOfUserPerCompanyResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserPerCompanyResponseFromJSON(json: any): UserPerCompanyResponse {
    return UserPerCompanyResponseFromJSONTyped(json, false);
}

export function UserPerCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPerCompanyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company': !exists(json, 'company') ? undefined : ECompanyFromJSON(json['company']),
        'user': !exists(json, 'user') ? undefined : CompanyUserFromJSON(json['user']),
    };
}

export function UserPerCompanyResponseToJSON(value?: UserPerCompanyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company': ECompanyToJSON(value.company),
        'user': CompanyUserToJSON(value.user),
    };
}

