import React, { ReactNode, useMemo } from 'react';

export interface FieldProps {
  label: ReactNode,
  children: ReactNode,
  required?: boolean | undefined,
  className?: string,
  labelClassName?: string,
}

function Field(props: FieldProps) {
  const className = useMemo(() => {
    if (!props.className) return 'field';

    return 'field ' + props.className;
  }, [props.className]);


  const label =  useMemo(() => {
    const asterisk = props.required
      ? <span style={{ color: 'red'}}> *</span>
      : <></>;
    const opacityClass = props.label
      ? 'opacity-60'
      : 'opacity-0';
    const labelText = props.label || '1';

    return <div className={`text-color ${props.labelClassName} ${opacityClass}`}>{labelText}{asterisk}</div>;
  }, [props.label, props.required, props.labelClassName]);

  return (
    <div className={className}>
      {label}
      {props.children}
    </div>
  );
}

export default Field;