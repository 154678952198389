import React, { useState, useMemo } from 'react';
import { TabPanel } from 'primereact/tabview';
import useRights from '../../hooks/RightsHook';
import VehiclesList from './VehiclesList';
import OwnersList from '../owners/OwnersList';
import DriversList from '../drivers/DriversList';
import LogisticsTabs from '../../components/form/LogisticsTabs';

function VehiclesPage() {
  const [tabIndex, setTabIndex] = useState(0);
  const vehicleRights = useRights(security => security.vehicle);
  const driverRights = useRights(security => security.driver);
  const ownerRights = useRights(security => security.owner);

  const vehiclesTab = useMemo(() => {
    if (!vehicleRights.read) return <></>;

    return <TabPanel header="Vehicles" >
      <VehiclesList />
    </TabPanel>;
  } ,[vehicleRights.read]);

  const driversTab = useMemo(() => {
    if (!driverRights.read) return <></>;

    return <TabPanel header="Drivers">
      <DriversList />
    </TabPanel>;
  }, [driverRights.read]);

  const ownersTab = useMemo(() => {
    if (!ownerRights.read) return <></>;

    return <TabPanel header="Owners">
      <OwnersList />
    </TabPanel>;
  }, [ownerRights.read]);

  return <LogisticsTabs
    activeIndex={tabIndex}
    onTabChange={(e) => setTabIndex(e.index)}
  >
    {vehiclesTab}
    {driversTab}
    {ownersTab}
  </LogisticsTabs>
}

export default VehiclesPage;