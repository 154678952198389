/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Tracking,
} from '../models';
import {
    TrackingFromJSON,
    TrackingToJSON,
} from '../models';

export interface ApiTrackingsAdjustNumberGetRequest {
    id?: number;
    checkingNumber?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiTrackingsGetRequest {
    number?: string;
    global?: string;
    delivered?: boolean;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiTrackingsIdDeleteRequest {
    id: number;
}

export interface ApiTrackingsIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiTrackingsIdPutRequest {
    id: number;
    tracking?: Tracking;
}

export interface ApiTrackingsPostRequest {
    tracking?: Tracking;
}

/**
 * 
 */
export class TrackingsApi extends runtime.BaseAPI {

    /**
     */
    async apiTrackingsAdjustNumberGetRaw(requestParameters: ApiTrackingsAdjustNumberGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.checkingNumber !== undefined) {
            queryParameters['checkingNumber'] = requestParameters.checkingNumber;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trackings/adjust-number`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiTrackingsAdjustNumberGet(requestParameters: ApiTrackingsAdjustNumberGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiTrackingsAdjustNumberGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrackingsGetRaw(requestParameters: ApiTrackingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Tracking>>> {
        const queryParameters: any = {};

        if (requestParameters.number !== undefined) {
            queryParameters['number'] = requestParameters.number;
        }

        if (requestParameters.global !== undefined) {
            queryParameters['global'] = requestParameters.global;
        }

        if (requestParameters.delivered !== undefined) {
            queryParameters['delivered'] = requestParameters.delivered;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trackings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrackingFromJSON));
    }

    /**
     */
    async apiTrackingsGet(requestParameters: ApiTrackingsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Tracking>> {
        const response = await this.apiTrackingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrackingsIdDeleteRaw(requestParameters: ApiTrackingsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrackingsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trackings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrackingsIdDelete(requestParameters: ApiTrackingsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiTrackingsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTrackingsIdGetRaw(requestParameters: ApiTrackingsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tracking>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrackingsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trackings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrackingFromJSON(jsonValue));
    }

    /**
     */
    async apiTrackingsIdGet(requestParameters: ApiTrackingsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tracking> {
        const response = await this.apiTrackingsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrackingsIdPutRaw(requestParameters: ApiTrackingsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tracking>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrackingsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trackings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TrackingToJSON(requestParameters.tracking),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrackingFromJSON(jsonValue));
    }

    /**
     */
    async apiTrackingsIdPut(requestParameters: ApiTrackingsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tracking> {
        const response = await this.apiTrackingsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrackingsPostRaw(requestParameters: ApiTrackingsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tracking>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trackings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrackingToJSON(requestParameters.tracking),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrackingFromJSON(jsonValue));
    }

    /**
     */
    async apiTrackingsPost(requestParameters: ApiTrackingsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tracking> {
        const response = await this.apiTrackingsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
