/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EVehicleExpiryKind = {
    Insurance: 'Insurance',
    License: 'License'
} as const;
export type EVehicleExpiryKind = typeof EVehicleExpiryKind[keyof typeof EVehicleExpiryKind];


export function EVehicleExpiryKindFromJSON(json: any): EVehicleExpiryKind {
    return EVehicleExpiryKindFromJSONTyped(json, false);
}

export function EVehicleExpiryKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): EVehicleExpiryKind {
    return json as EVehicleExpiryKind;
}

export function EVehicleExpiryKindToJSON(value?: EVehicleExpiryKind | null): any {
    return value as any;
}

