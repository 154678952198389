import LogisticsColumnProps from '../../components/data-grid/LogisticsColumnProps';
import serverFilterComponent from '../../components/filters/server/ServerFilter';
import { UserDto } from '../../generated-api/models';
import { ApiAdminUsersGetRequest } from '../../generated-api';
import { DataTableFilterMetaData } from 'primereact/datatable';
import dayjs from 'dayjs';

export default function userColumns(actionButton: (row: UserDto) => JSX.Element) : LogisticsColumnProps[] {
  const generalProperties = (col: LogisticsColumnProps) : LogisticsColumnProps => {
    return {
      ...col,
      showClearButton: false,
      showFilterMenu: false,
    }
  };
  return [
    {
      field: 'fullName',
      header: 'Full Name',
      sortable: true,
      filter: true,
      style: { flex: 1 },
      body: (rowData: UserDto) => `${rowData.firstName || ''} ${rowData.lastName || ''}`,
      ...serverFilterComponent(),
      serverFilterRequest: (
        filterMetaData : DataTableFilterMetaData,
        serverFilterModel: ApiAdminUsersGetRequest) : ApiAdminUsersGetRequest => {
        return { ...serverFilterModel, fullName: filterMetaData.value };
      },
      serverSortFields: ['internalUser.firstName', 'internalUser.lastName'],
    },
    {
      field: 'internalUser.login',
      header: 'Username',
      sortable: true,
      filter: true,
      style: { flex: 1 },
      body: (rowData: UserDto) => rowData.login,
      serverFilterRequest: (
        filterMetaData : DataTableFilterMetaData,
        serverFilterModel: ApiAdminUsersGetRequest) : ApiAdminUsersGetRequest => {
        return { ...serverFilterModel, userName: filterMetaData.value };
      },
      ...serverFilterComponent(),
    },
    {
      field: 'role',
      header: 'Role',
      sortable: true,
      filter: true,
      style: { flex: 1 },
      body: (rowData: UserDto) => rowData.roleName,
      serverFilterRequest: (
        filterMetaData : DataTableFilterMetaData,
        serverFilterModel: ApiAdminUsersGetRequest) : ApiAdminUsersGetRequest => {
        return { ...serverFilterModel, role: filterMetaData.value };
      },
      ...serverFilterComponent(),
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      filter: true,
      style: { flex: 1 },
      body: (rowData: UserDto) => rowData.status,
      serverFilterRequest: (
        filterMetaData : DataTableFilterMetaData,
        serverFilterModel: ApiAdminUsersGetRequest) : ApiAdminUsersGetRequest => {
        return { ...serverFilterModel, status: filterMetaData.value };
      },
      ...serverFilterComponent(),
    },
    {
      field: 'internalUser.createdDate',
      header: 'Created Date',
      sortable: true,
      filter: true,
      style: { flex: 1 },
      body: (rowData: UserDto) => formatDate(rowData.createdDate),
      ...serverFilterComponent(),
      serverFilterRequest: (
        filterMetaData : DataTableFilterMetaData,
        serverFilterModel: ApiAdminUsersGetRequest) : ApiAdminUsersGetRequest => {
        return { ...serverFilterModel, createdDate: filterMetaData.value };
      },
    },
    {
      field: 'lastLoginDate',
      header: 'Last Login Date',
      sortable: true,
      filter: true,
      style: { flex: 1 },
      body: (rowData: UserDto) => formatDate(rowData.lastLoginDate),
      ...serverFilterComponent(),
      serverFilterRequest: (
        filterMetaData : DataTableFilterMetaData,
        serverFilterModel: ApiAdminUsersGetRequest) : ApiAdminUsersGetRequest => {
        return { ...serverFilterModel, lastLoginDate: filterMetaData.value };
      },
    },
    {
      field: 'action',
      header: 'Action',
      style: { flex: 1 },
      sortable: false,
      filter: false,
      body: actionButton,
    },
  ].map(col => generalProperties(col));
}

function formatDate(date: Date | null | undefined) {
  if (!date) return '';

  return dayjs(date).tz().format('MMM DD YYYY hh:mm A');
}