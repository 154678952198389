import { ApiRequestsGetRequest, Request, RequestApi } from '../../../generated-api';
import { createEntitySlice, EntityState, PageResponse } from '../../../shared';
import { createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import paginationApiFactory from '../../../shared/util/paginationApiFactory';

const defaultValue: Readonly<Request> = {

}

const initialState: EntityState<Request> = {
  loading: false,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
}

export const getRequestsPage = createAsyncThunk(
  "request/fetch_page",
  async (param: ApiRequestsGetRequest) => {
    let totalCount = 0;
    const api = paginationApiFactory(RequestApi, total => totalCount = total);
    const entities = await api.apiRequestsGet(param);
    return { entities, totalCount } as PageResponse<Request>;
  }
);

export const RequestSlcce = createEntitySlice({
    name: 'request',
    initialState,
    extraReducers(builder) {
      builder
        .addMatcher(isFulfilled(getRequestsPage), (state, action) => {
          const data = action.payload;

          return {
            ...state,
            loading: false,
            entities: data.entities,
            totalItems: data.totalCount,
          }
        });
    }
  },
);

export default RequestSlcce.reducer;