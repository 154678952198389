/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListOfLoadsActionsAccess
 */
export interface ListOfLoadsActionsAccess {
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoadsActionsAccess
     */
    driverPayed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoadsActionsAccess
     */
    remove?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoadsActionsAccess
     */
    actionsButton?: boolean | null;
}

/**
 * Check if a given object implements the ListOfLoadsActionsAccess interface.
 */
export function instanceOfListOfLoadsActionsAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListOfLoadsActionsAccessFromJSON(json: any): ListOfLoadsActionsAccess {
    return ListOfLoadsActionsAccessFromJSONTyped(json, false);
}

export function ListOfLoadsActionsAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOfLoadsActionsAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driverPayed': !exists(json, 'driverPayed') ? undefined : json['driverPayed'],
        'remove': !exists(json, 'remove') ? undefined : json['remove'],
        'actionsButton': !exists(json, 'actionsButton') ? undefined : json['actionsButton'],
    };
}

export function ListOfLoadsActionsAccessToJSON(value?: ListOfLoadsActionsAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driverPayed': value.driverPayed,
        'remove': value.remove,
        'actionsButton': value.actionsButton,
    };
}

