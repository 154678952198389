import { memo } from 'react';
import { LogisticsFile } from '../../../../generated-api';
import useFileUpload from './hook';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/es/upload/interface';
import * as React from 'react';
import styled from '@emotion/styled';
import FileContainer from './FileContainer';
import { Button } from 'primereact/button';
import useFilePreview, { FilePreviewWidget } from '../preview/filePreviewWidget';
import VisibilityToggler from '../../../VisibilityToggler';
import LogisticsFilePreview from '../preview/LogisticsFilePreview';
import type { UploadRequestOption } from 'rc-upload/lib/interface';

export interface LogisticsFilesUploadProperties {
  value?: LogisticsFile[] | null | undefined,
  onChange: (value: LogisticsFile[]) => any,
  disabled?: boolean,
  maxCount?: number,
  filePreviewWidget?: FilePreviewWidget,
  accept?: string,
}

const StyledUpload = styled(Upload)`
  & .ant-upload-list-item-container {
    width: 100% !important;
  }
  
  & .ant-upload {
    width: 100% !important;
  }
`

const emptyArray = [] as LogisticsFile[];

const LogisticsFilesUpload = memo((props: LogisticsFilesUploadProperties) => {
  // Use internal widget if no external
  const internalFilePreviewWidget = useFilePreview();
  const filePreviewWidget = props.filePreviewWidget || internalFilePreviewWidget;
  const {
    files,
    downloadFile,
    uploadFileRequest,
    onRemove,
    replaceFile,
  } = useFileUpload({
    value: props.value || emptyArray,
    filePreviewWidget: filePreviewWidget,
    onChange: props.onChange,
  });

  const preview = filePreviewWidget(state => state.openPreview);
  const previewAction = (file : UploadFile<LogisticsFile>) => {
    if (!props.filePreviewWidget) {
      console.warn('No external widget for file preview');
    }

    preview(file);
  };


  const beforeUpload = (file : UploadFile<LogisticsFile>) => {
    if (!props.accept) {
      return true;
    }

    const validTypes = props.accept?.replace(/\s+/g, '').split(",");
    const isAcceptable = validTypes.some(type => file.type === type);
    return isAcceptable;
  };

  return <>
    <StyledUpload
      accept={props.accept}
      itemRender={(originNode: React.ReactElement, file: UploadFile, fileList: Array<UploadFile<LogisticsFile>>, actions: {
        download: () => void;
        preview: () => void;
        remove: () => void;
      }) => {
        const editAction = (oldFile: UploadFile<LogisticsFile>, newUrl: string) => {
          replaceFile(oldFile, { file: newUrl } as UploadRequestOption<any>);
        }

        const extendedActions = {...actions, edit: editAction };
        return FileContainer(originNode, file, fileList, extendedActions);
      }}
      fileList={files}
      customRequest={uploadFileRequest}
      onRemove={onRemove}
      onPreview={previewAction}
      onDownload={downloadFile}
      beforeUpload={beforeUpload}
      maxCount={props.maxCount}
      multiple
      disabled={props.disabled}
    >
      <Button className='p-button-secondary p-button-text w-full justify-content-center gap-1' disabled={props.disabled}>
        <UploadOutlined />
        <span>Choose your file</span>
      </Button>
    </StyledUpload>
    <VisibilityToggler visible={!props.filePreviewWidget}>
      <LogisticsFilePreview filePreviewWidget={internalFilePreviewWidget} />
    </VisibilityToggler>
  </>
});

export default LogisticsFilesUpload;