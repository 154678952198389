/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RevenueByDispatcherResponse
 */
export interface RevenueByDispatcherResponse {
    /**
     * 
     * @type {string}
     * @memberof RevenueByDispatcherResponse
     */
    dispatcher?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RevenueByDispatcherResponse
     */
    bids?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RevenueByDispatcherResponse
     */
    loads?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RevenueByDispatcherResponse
     */
    profit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RevenueByDispatcherResponse
     */
    margin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RevenueByDispatcherResponse
     */
    conversion?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RevenueByDispatcherResponse
     */
    gross?: number | null;
}

/**
 * Check if a given object implements the RevenueByDispatcherResponse interface.
 */
export function instanceOfRevenueByDispatcherResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RevenueByDispatcherResponseFromJSON(json: any): RevenueByDispatcherResponse {
    return RevenueByDispatcherResponseFromJSONTyped(json, false);
}

export function RevenueByDispatcherResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevenueByDispatcherResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dispatcher': !exists(json, 'dispatcher') ? undefined : json['dispatcher'],
        'bids': !exists(json, 'bids') ? undefined : json['bids'],
        'loads': !exists(json, 'loads') ? undefined : json['loads'],
        'profit': !exists(json, 'profit') ? undefined : json['profit'],
        'margin': !exists(json, 'margin') ? undefined : json['margin'],
        'conversion': !exists(json, 'conversion') ? undefined : json['conversion'],
        'gross': !exists(json, 'gross') ? undefined : json['gross'],
    };
}

export function RevenueByDispatcherResponseToJSON(value?: RevenueByDispatcherResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dispatcher': value.dispatcher,
        'bids': value.bids,
        'loads': value.loads,
        'profit': value.profit,
        'margin': value.margin,
        'conversion': value.conversion,
        'gross': value.gross,
    };
}

