import React, { useMemo } from 'react';
import { TUsePDFSlickStore } from '@pdfslick/react/dist/esm/usePDFSlick';
import { MdAdd, MdRemove } from 'react-icons/md';
import { Button } from 'primereact/button';
import EditableDropdown from '../../../EditableDropdown';
import { nameof } from 'ts-simple-nameof';

type ZoomingProps = {
  usePDFSlickStore: TUsePDFSlickStore,
}

type ScaleOption = {
  title: string,
  id: string,
}

const scalePresets = [
  { title: 'Automatic zoom', id: 'auto', },
  { title: 'Actual size', id: 'page-actual' },
  { title: 'Page fit', id: 'page-fit', },
  { title: 'Page width', id: 'page-width', },
  { title: '50%', id: '0.5', },
  { title: '75%', id: '0.75', },
  { title: '100%', id: '1', },
  { title: '125%', id: '1.25', },
  { title: '150%', id: '1.5', },
  { title: '200%', id: '2', },
] as ScaleOption[];

function Zooming(props: ZoomingProps) {
  const scale = props.usePDFSlickStore((s) => s.scale);
  const scaleValue = props.usePDFSlickStore(s => s.scaleValue);
  const pdfSlick =  props.usePDFSlickStore((s) => s.pdfSlick);

  const visibleScale : ScaleOption = useMemo(() => {
    if (!scaleValue && !scale)
      return { title: 'Automatic zoom', id: 'auto' };
    const preset = scalePresets.find(x => x.id === scaleValue);
    if (preset)
      return preset;

    return { title: `${(scale * 100).toFixed(0)}%`, id: scale.toString() };
  }, [scaleValue, scale])

  return <>
    <Button
      className='p-button-text p-button-secondary px-1'
      onClick={() => pdfSlick?.viewer.decreaseScale()}
      disabled={scale <= 0.25}
    >
      <MdRemove/>
    </Button>
    <span className='w-13rem'>
      <EditableDropdown
        options={scalePresets}
        optionLabel={nameof<ScaleOption>(x => x.title)}
        renderOption={x => x.title}
        renderSelected={x => x?.title}
        value={visibleScale}
        onChange={newValue => {
          try {
            const scaleValue = parseFloat(newValue.id);
            pdfSlick!.viewer.currentScale = scaleValue;
          } catch {
            pdfSlick!.viewer.currentScaleValue = newValue.id;
          }
        }}
      />
    </span>
    <Button
      className='p-button-text p-button-secondary px-1'
      onClick={() => pdfSlick?.viewer.increaseScale()}
      disabled={scale >= 5}
    >
      <MdAdd/>
    </Button>
  </>;
}

export default Zooming;