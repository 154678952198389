/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LogisticsFile } from './LogisticsFile';
import {
    LogisticsFileFromJSON,
    LogisticsFileFromJSONTyped,
    LogisticsFileToJSON,
} from './LogisticsFile';

/**
 * 
 * @export
 * @interface Step3Documents
 */
export interface Step3Documents {
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    voidCheck?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    mc?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    licenseFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    drRecordFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    passport?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    insuranceFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    vehRegFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    photoLS?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    photoRS?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    photoCargoSpace?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Step3Documents
     */
    extraAttachFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {number}
     * @memberof Step3Documents
     */
    vehicleId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Step3Documents
     */
    requestId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Step3Documents
     */
    ownerId?: number | null;
}

/**
 * Check if a given object implements the Step3Documents interface.
 */
export function instanceOfStep3Documents(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Step3DocumentsFromJSON(json: any): Step3Documents {
    return Step3DocumentsFromJSONTyped(json, false);
}

export function Step3DocumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Step3Documents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'voidCheck': !exists(json, 'voidCheck') ? undefined : (json['voidCheck'] === null ? null : (json['voidCheck'] as Array<any>).map(LogisticsFileFromJSON)),
        'mc': !exists(json, 'mc') ? undefined : (json['mc'] === null ? null : (json['mc'] as Array<any>).map(LogisticsFileFromJSON)),
        'licenseFile': !exists(json, 'licenseFile') ? undefined : (json['licenseFile'] === null ? null : (json['licenseFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'drRecordFile': !exists(json, 'drRecordFile') ? undefined : (json['drRecordFile'] === null ? null : (json['drRecordFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'passport': !exists(json, 'passport') ? undefined : (json['passport'] === null ? null : (json['passport'] as Array<any>).map(LogisticsFileFromJSON)),
        'insuranceFile': !exists(json, 'insuranceFile') ? undefined : (json['insuranceFile'] === null ? null : (json['insuranceFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'vehRegFile': !exists(json, 'vehRegFile') ? undefined : (json['vehRegFile'] === null ? null : (json['vehRegFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'photoLS': !exists(json, 'photoLS') ? undefined : (json['photoLS'] === null ? null : (json['photoLS'] as Array<any>).map(LogisticsFileFromJSON)),
        'photoRS': !exists(json, 'photoRS') ? undefined : (json['photoRS'] === null ? null : (json['photoRS'] as Array<any>).map(LogisticsFileFromJSON)),
        'photoCargoSpace': !exists(json, 'photoCargoSpace') ? undefined : (json['photoCargoSpace'] === null ? null : (json['photoCargoSpace'] as Array<any>).map(LogisticsFileFromJSON)),
        'extraAttachFile': !exists(json, 'extraAttachFile') ? undefined : (json['extraAttachFile'] === null ? null : (json['extraAttachFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
    };
}

export function Step3DocumentsToJSON(value?: Step3Documents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'voidCheck': value.voidCheck === undefined ? undefined : (value.voidCheck === null ? null : (value.voidCheck as Array<any>).map(LogisticsFileToJSON)),
        'mc': value.mc === undefined ? undefined : (value.mc === null ? null : (value.mc as Array<any>).map(LogisticsFileToJSON)),
        'licenseFile': value.licenseFile === undefined ? undefined : (value.licenseFile === null ? null : (value.licenseFile as Array<any>).map(LogisticsFileToJSON)),
        'drRecordFile': value.drRecordFile === undefined ? undefined : (value.drRecordFile === null ? null : (value.drRecordFile as Array<any>).map(LogisticsFileToJSON)),
        'passport': value.passport === undefined ? undefined : (value.passport === null ? null : (value.passport as Array<any>).map(LogisticsFileToJSON)),
        'insuranceFile': value.insuranceFile === undefined ? undefined : (value.insuranceFile === null ? null : (value.insuranceFile as Array<any>).map(LogisticsFileToJSON)),
        'vehRegFile': value.vehRegFile === undefined ? undefined : (value.vehRegFile === null ? null : (value.vehRegFile as Array<any>).map(LogisticsFileToJSON)),
        'photoLS': value.photoLS === undefined ? undefined : (value.photoLS === null ? null : (value.photoLS as Array<any>).map(LogisticsFileToJSON)),
        'photoRS': value.photoRS === undefined ? undefined : (value.photoRS === null ? null : (value.photoRS as Array<any>).map(LogisticsFileToJSON)),
        'photoCargoSpace': value.photoCargoSpace === undefined ? undefined : (value.photoCargoSpace === null ? null : (value.photoCargoSpace as Array<any>).map(LogisticsFileToJSON)),
        'extraAttachFile': value.extraAttachFile === undefined ? undefined : (value.extraAttachFile === null ? null : (value.extraAttachFile as Array<any>).map(LogisticsFileToJSON)),
        'vehicleId': value.vehicleId,
        'requestId': value.requestId,
        'ownerId': value.ownerId,
    };
}

