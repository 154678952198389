/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bid } from './Bid';
import {
    BidFromJSON,
    BidFromJSONTyped,
    BidToJSON,
} from './Bid';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ViewedBid
 */
export interface ViewedBid {
    /**
     * 
     * @type {number}
     * @memberof ViewedBid
     */
    bidId?: number | null;
    /**
     * 
     * @type {Bid}
     * @memberof ViewedBid
     */
    bid?: Bid;
    /**
     * 
     * @type {string}
     * @memberof ViewedBid
     */
    userId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof ViewedBid
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof ViewedBid
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ViewedBid
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ViewedBid
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ViewedBid
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ViewedBid
     */
    id?: number;
}

/**
 * Check if a given object implements the ViewedBid interface.
 */
export function instanceOfViewedBid(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ViewedBidFromJSON(json: any): ViewedBid {
    return ViewedBidFromJSONTyped(json, false);
}

export function ViewedBidFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewedBid {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bidId': !exists(json, 'bidId') ? undefined : json['bidId'],
        'bid': !exists(json, 'bid') ? undefined : BidFromJSON(json['bid']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ViewedBidToJSON(value?: ViewedBid | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bidId': value.bidId,
        'bid': BidToJSON(value.bid),
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'id': value.id,
    };
}

