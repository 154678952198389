/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListOfLoads } from './ListOfLoads';
import {
    ListOfLoadsFromJSON,
    ListOfLoadsFromJSONTyped,
    ListOfLoadsToJSON,
} from './ListOfLoads';
import type { LoadedOrder } from './LoadedOrder';
import {
    LoadedOrderFromJSON,
    LoadedOrderFromJSONTyped,
    LoadedOrderToJSON,
} from './LoadedOrder';
import type { Tracking } from './Tracking';
import {
    TrackingFromJSON,
    TrackingFromJSONTyped,
    TrackingToJSON,
} from './Tracking';

/**
 * 
 * @export
 * @interface Brokerage
 */
export interface Brokerage {
    /**
     * 
     * @type {string}
     * @memberof Brokerage
     */
    contactAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Brokerage
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Brokerage
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Brokerage
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Brokerage
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Brokerage
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {Array<LoadedOrder>}
     * @memberof Brokerage
     */
    loadedOrders?: Array<LoadedOrder> | null;
    /**
     * 
     * @type {Array<ListOfLoads>}
     * @memberof Brokerage
     */
    listOfLoads?: Array<ListOfLoads> | null;
    /**
     * 
     * @type {Array<Tracking>}
     * @memberof Brokerage
     */
    trackings?: Array<Tracking> | null;
    /**
     * 
     * @type {number}
     * @memberof Brokerage
     */
    id?: number;
}

/**
 * Check if a given object implements the Brokerage interface.
 */
export function instanceOfBrokerage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BrokerageFromJSON(json: any): Brokerage {
    return BrokerageFromJSONTyped(json, false);
}

export function BrokerageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Brokerage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactAddress': !exists(json, 'contactAddress') ? undefined : json['contactAddress'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'loadedOrders': !exists(json, 'loadedOrders') ? undefined : (json['loadedOrders'] === null ? null : (json['loadedOrders'] as Array<any>).map(LoadedOrderFromJSON)),
        'listOfLoads': !exists(json, 'listOfLoads') ? undefined : (json['listOfLoads'] === null ? null : (json['listOfLoads'] as Array<any>).map(ListOfLoadsFromJSON)),
        'trackings': !exists(json, 'trackings') ? undefined : (json['trackings'] === null ? null : (json['trackings'] as Array<any>).map(TrackingFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function BrokerageToJSON(value?: Brokerage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactAddress': value.contactAddress,
        'name': value.name,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'loadedOrders': value.loadedOrders === undefined ? undefined : (value.loadedOrders === null ? null : (value.loadedOrders as Array<any>).map(LoadedOrderToJSON)),
        'listOfLoads': value.listOfLoads === undefined ? undefined : (value.listOfLoads === null ? null : (value.listOfLoads as Array<any>).map(ListOfLoadsToJSON)),
        'trackings': value.trackings === undefined ? undefined : (value.trackings === null ? null : (value.trackings as Array<any>).map(TrackingToJSON)),
        'id': value.id,
    };
}

