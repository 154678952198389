/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ECompany } from './ECompany';
import {
    ECompanyFromJSON,
    ECompanyFromJSONTyped,
    ECompanyToJSON,
} from './ECompany';

/**
 * 
 * @export
 * @interface SystemSettings
 */
export interface SystemSettings {
    /**
     * 
     * @type {string}
     * @memberof SystemSettings
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemSettings
     */
    cShortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemSettings
     */
    abbreviation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SystemSettings
     */
    mcNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SystemSettings
     */
    bidEmailTemplateS?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SystemSettings
     */
    maxFlyRadius?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemSettings
     */
    minProfit?: number | null;
    /**
     * 
     * @type {ECompany}
     * @memberof SystemSettings
     */
    company?: ECompany;
    /**
     * 
     * @type {number}
     * @memberof SystemSettings
     */
    id?: number;
}

/**
 * Check if a given object implements the SystemSettings interface.
 */
export function instanceOfSystemSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SystemSettingsFromJSON(json: any): SystemSettings {
    return SystemSettingsFromJSONTyped(json, false);
}

export function SystemSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'cShortName': !exists(json, 'cShortName') ? undefined : json['cShortName'],
        'abbreviation': !exists(json, 'abbreviation') ? undefined : json['abbreviation'],
        'mcNumber': !exists(json, 'mcNumber') ? undefined : json['mcNumber'],
        'bidEmailTemplateS': !exists(json, 'bidEmailTemplateS') ? undefined : json['bidEmailTemplateS'],
        'maxFlyRadius': !exists(json, 'maxFlyRadius') ? undefined : json['maxFlyRadius'],
        'minProfit': !exists(json, 'minProfit') ? undefined : json['minProfit'],
        'company': !exists(json, 'company') ? undefined : ECompanyFromJSON(json['company']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function SystemSettingsToJSON(value?: SystemSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyName': value.companyName,
        'cShortName': value.cShortName,
        'abbreviation': value.abbreviation,
        'mcNumber': value.mcNumber,
        'bidEmailTemplateS': value.bidEmailTemplateS,
        'maxFlyRadius': value.maxFlyRadius,
        'minProfit': value.minProfit,
        'company': ECompanyToJSON(value.company),
        'id': value.id,
    };
}

