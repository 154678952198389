import { useAppDispatch, useAppSelector } from '../../hooks';
import { resetRights } from '../security/SecuritySlice';
import { AccountApi, UserJwtApi } from '../../generated-api';
import { useEffect } from 'react';
import { AuthenticationState, login, setSecondSession } from './LoginSlice';
import { apiFactory, clearApiCache } from '../../shared';
import useBrowserPageStorage from '../../hooks/BrowserStorageHook';
import useConfiguration from '../../hooks/ConfigurationHook';
export const AUTH_STATE_KEY = 'authState';

export default function useAuth() {
  // The only source of truth.
  const [storedAuthState, setStoredAuthState]
    = useBrowserPageStorage<AuthenticationState>(AUTH_STATE_KEY, {} as any);
  // Use redux to only track changes on current tab;
  const reduxAuthState = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const _setSecondSession = (value: boolean) => {
    dispatch(setSecondSession(value));
  }
  const { loadConfiguration, resetConfiguration } = useConfiguration();

  useEffect(() => {
    if (storedAuthState.isSecondSession === reduxAuthState.isSecondSession) return;

    _setSecondSession(reduxAuthState.isSecondSession);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxAuthState.isSecondSession]);
  useEffect(() => {
    setStoredAuthState(reduxAuthState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxAuthState]);
  useEffect(() => {
    if (!reduxAuthState.loaded && storedAuthState.loaded) {
      dispatch(login(storedAuthState));
    }
  });

  const _login = async (username: string, password: string, rememberMe?: boolean) => {
    dispatch(resetRights());
    setStoredAuthState({...storedAuthState, loading: true});
    try {
      const tokenResult = await apiFactory(UserJwtApi).apiAuthenticatePost({
        loginDto: {
          username,
          password,
          rememberMe: rememberMe || false
        }});
      if (!tokenResult?.idToken) {
        throw new Error('Unable to obtain token');
      }

      const token = tokenResult.idToken.replaceAll('"', '');
      setStoredAuthState({
        ...storedAuthState,
        token,
      });
      clearApiCache();

      const account = await apiFactory(AccountApi, true).apiAccountGet();
      const isAuthenticated = account?.activated || false;
      const newAuthState = {
        isAuthenticated,
        account,
        token,
        loaded: true,
      } as AuthenticationState;
      setStoredAuthState({
        ...storedAuthState,
        ...newAuthState,
      });
      dispatch(login(newAuthState));
      loadConfiguration();
    } catch(error: any) {
      console.error(error);
    } finally {
      setStoredAuthState({...storedAuthState, loading: false});
    }
  };
  const _logout = () => {
    apiFactory(AccountApi).apiAccountLogoutGet()
      .catch(reason => console.error('Failed to log out', reason))
      // Hard reset for everything cached
      .finally(() => {
        setStoredAuthState({} as any);
        clearApiCache();
        dispatch(resetRights());
        resetConfiguration();
        window.location.reload();
      });
  }

  return {
    auth: storedAuthState,
    login: _login,
    logout: _logout,
    setSecondSession: _setSecondSession
  };
}