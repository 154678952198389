import React, { useEffect, useState } from 'react';
import StyledInputText from '../../../StyledInputText';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Button } from 'primereact/button';
import { TUsePDFSlickStore } from '@pdfslick/react/dist/esm/usePDFSlick';

type PagingProps = {
  usePDFSlickStore: TUsePDFSlickStore,
}

function Paging(props: PagingProps) {
  const pageNumber = props.usePDFSlickStore(s => s.pageNumber);
  const numPages = props.usePDFSlickStore(s => s.numPages);
  const slick = props.usePDFSlickStore(s => s.pdfSlick);


  const [visualPageNumber, setVisualPageNumber] = useState<string>('1');
  useEffect(() => {
    setVisualPageNumber(pageNumber?.toString() || '');
  }, [pageNumber]);

  return <>
    <Button
      className='p-button-text p-button-secondary px-1'
      onClick={() => slick?.viewer.previousPage()}
      disabled={pageNumber <= 1}
    >
      <MdKeyboardArrowDown/>
    </Button>
    <Button
      className='p-button-text p-button-secondary px-1'
      onClick={() => slick?.viewer.nextPage()}
      disabled={pageNumber >= numPages}
    >
      <MdKeyboardArrowUp />
    </Button>

    <span className='w-3rem'>
      <StyledInputText value={visualPageNumber} onChange={e => {
        const newValue = e.target.value;
        const newPageNumber = parseInt(newValue);
        if (
          Number.isInteger(newPageNumber) &&
          newPageNumber > 0 &&
          newPageNumber <= numPages
        ) {
          slick?.linkService.goToPage(newPageNumber);
        } else {
          setVisualPageNumber(pageNumber.toString());
        }
      }}/>
    </span>
    <span className='px-1'>{`of ${numPages}`}</span>
  </>
}

export default Paging;