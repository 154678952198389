/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Bid,
  VehicleForBid,
} from '../models';
import {
    BidFromJSON,
    BidToJSON,
    VehicleForBidFromJSON,
    VehicleForBidToJSON,
} from '../models';

export interface ApiBidsArchiveGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBidsGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBidsGetUnreadBidsGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBidsIdDeleteRequest {
    id: number;
}

export interface ApiBidsIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBidsIdPutRequest {
    id: number;
    bid?: Bid;
}

export interface ApiBidsMarkAllAsReadGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBidsPostRequest {
    bid?: Bid;
}

export interface ApiBidsSelectedVehicleGetRequest {
    bidId?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class BidsApi extends runtime.BaseAPI {

    /**
     */
    async apiBidsArchiveGetRaw(requestParameters: ApiBidsArchiveGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bids/archive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBidsArchiveGet(requestParameters: ApiBidsArchiveGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiBidsArchiveGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBidsGetRaw(requestParameters: ApiBidsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Bid>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BidFromJSON));
    }

    /**
     */
    async apiBidsGet(requestParameters: ApiBidsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Bid>> {
        const response = await this.apiBidsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBidsGetUnreadBidsGetRaw(requestParameters: ApiBidsGetUnreadBidsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bids/GetUnreadBids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiBidsGetUnreadBidsGet(requestParameters: ApiBidsGetUnreadBidsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiBidsGetUnreadBidsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBidsIdDeleteRaw(requestParameters: ApiBidsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBidsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bids/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBidsIdDelete(requestParameters: ApiBidsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiBidsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBidsIdGetRaw(requestParameters: ApiBidsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Bid>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBidsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bids/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BidFromJSON(jsonValue));
    }

    /**
     */
    async apiBidsIdGet(requestParameters: ApiBidsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Bid> {
        const response = await this.apiBidsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBidsIdPutRaw(requestParameters: ApiBidsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBidsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bids/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BidToJSON(requestParameters.bid),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBidsIdPut(requestParameters: ApiBidsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiBidsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBidsMarkAllAsReadGetRaw(requestParameters: ApiBidsMarkAllAsReadGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bids/MarkAllAsRead`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBidsMarkAllAsReadGet(requestParameters: ApiBidsMarkAllAsReadGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiBidsMarkAllAsReadGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBidsPostRaw(requestParameters: ApiBidsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Bid>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bids`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BidToJSON(requestParameters.bid),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BidFromJSON(jsonValue));
    }

    /**
     */
    async apiBidsPost(requestParameters: ApiBidsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Bid> {
        const response = await this.apiBidsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBidsSelectedVehicleGetRaw(requestParameters: ApiBidsSelectedVehicleGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleForBid>> {
        const queryParameters: any = {};

        if (requestParameters.bidId !== undefined) {
            queryParameters['bidId'] = requestParameters.bidId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bids/selectedVehicle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleForBidFromJSON(jsonValue));
    }

    /**
     */
    async apiBidsSelectedVehicleGet(requestParameters: ApiBidsSelectedVehicleGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleForBid> {
        const response = await this.apiBidsSelectedVehicleGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
