/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EChangeType = {
    None: 'None',
    Added: 'Added',
    Modified: 'Modified',
    Deleted: 'Deleted'
} as const;
export type EChangeType = typeof EChangeType[keyof typeof EChangeType];


export function EChangeTypeFromJSON(json: any): EChangeType {
    return EChangeTypeFromJSONTyped(json, false);
}

export function EChangeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EChangeType {
    return json as EChangeType;
}

export function EChangeTypeToJSON(value?: EChangeType | null): any {
    return value as any;
}

