import { IdName } from './IdName';
import { EDockSize } from '../generated-api';

const DockSize: IdName<string>[] = [
  { id: EDockSize.True, name: 'True' },
  { id: EDockSize.Ramps, name: 'Ramps' },
  { id: EDockSize.LandingGear, name: 'Landing gear' },
  { id: EDockSize.No, name: 'No' },
];

export default DockSize;