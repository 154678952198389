import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store/store';
import React, { useCallback, useState } from 'react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type ModalWidget<TData> = {
    visible: boolean,
    data: TData,
    show: (dataToShow: TData) => void,
    hide: () => void,
    setData: React.Dispatch<React.SetStateAction<TData>>,
};

export function useModal<TData>(initialData: TData) {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState<TData>(initialData);

    const show = useCallback((dataToShow: TData) => {
        setVisible(true);
        setData(dataToShow);
    }, []);

    const hide = useCallback(() => {
        setVisible(false);
    }, []);

    return {
        visible,
        data,
        show,
        hide,
        setData,
    };
}