import React, { useMemo, useState } from 'react';
import DashboardPanel from '../dashboard/components/DashboardPanel';
import Row from '../../components/form/Row';
import Column from '../../components/form/Column';
import LogisticsToolbar from '../../components/data-grid/LogisticsToolbar';
import { Steps } from 'antd';
import { StepProps } from 'antd/es/steps';

import Step1 from './step1/Step1';
import Step2 from './step2/Step2';
import { Request, Vehicle } from '../../generated-api';
import Step3 from './step3/Step3';
import Step4 from './step4/Step4';
import FinalStage from './finalStage/FinalStage';

type Step = { number: number } & StepProps;

const steps: Step[]  = [
  { number: 1 },
  { number: 2 },
  { number: 3 },
  { number: 4 },
];

function Registration() {
  const [step, setStep] = useState(steps[0]);
  const [request, setRequest] = useState<Request>({});
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [completed, setCompleted] = useState(false);
  const nextStep = () => {
    if (step.number - 1 === steps.length)
      return;

    setStep(old => steps[old.number]);
  }

  const stepComponent = useMemo(() => {
    if (!step?.number)
      return null;

    if (step.number === 1) {
      return <Step1
        onStepComplete={newRequest => {
          setRequest(newRequest);
          nextStep();
        }}
      />;
    }

    if (step.number === 2) {
      return <Step2
        initialValue={{requestId: request.id, ownerId: request.ownerId}}
        onStepComplete={newVehicle => {
          setVehicle(newVehicle);
          nextStep();
        }}
      />
    }

    if (step.number === 3) {
      return <Step3
        initialValue={{
          requestId: request.id,
          ownerId: request.ownerId || request.owner?.id,
          vehicleId: vehicle?.id,
        }}
        onStepComplete={uploadedDocuments => {
          nextStep();
        }}
      />;
    }

    if (step.number === 4) {
      return <Step4
        initialValue={request?.owner}
        onStepComplete={() => {
          setCompleted(true);
        }}
      />
    }

    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const mainComponent = useMemo(() => {
    if (completed) {
      return <FinalStage />;
    }

    return <>
      <LogisticsToolbar
        rightSideElements={[<Steps
          current={step.number - 1}
          items={steps}
          style={{width: '50%'}}
          key='steps'
        />]}
        leftSideElements={[<header
          className='text-4xl w-full flex-wrap mb-5'
          key='header'
        >
          <span className='font-bold'>Registration</span>
        </header>]}
      />
      {stepComponent}
    </>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed, stepComponent]);
  return <div style={{height: '95%', overflowY: 'auto', overflowX: 'hidden'}}>
    <Row className='mt-8'>
      <Column className='col-2'></Column>
      <Column className='col-8'>
        <DashboardPanel>
          {mainComponent}
        </DashboardPanel>
      </Column>
      <Column className='col-2'></Column>
    </Row>;
  </div>
}

export default Registration;