import { css } from '@emotion/react';

export const inputStyle = css`
  display: block;
  flex-basis: unset;
  flex-grow: unset;
  flex-shrink: unset;
  border-width: 0;
  border-bottom-width: 1px;
  width: 100%;
  border-radius: 0;
`

export const focusedInputStyle = css`
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset;
  border-color: #6366F1;
`

