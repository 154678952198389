import LogisticsColumnProps from '../LogisticsColumnProps';
import { DataTableFilterMeta } from 'primereact/datatable';
import { useCallback, useMemo, useState } from 'react';
import useBrowserPageStorage from '../../../hooks/BrowserStorageHook';
import { ExternalFilterProperties } from '../LogisticsDataGridParameters';
import { isDataTableFilterMetaData } from '../../../shared';

function useFilters(
  componentId: string,
  columns: LogisticsColumnProps[],
  storedDefaultFilterMeta: DataTableFilterMeta | undefined,
  alwaysDefaultFilterMeta: DataTableFilterMeta | undefined,
  externalFilters: ExternalFilterProperties[] | undefined,
): [
  DataTableFilterMeta | undefined,
  DataTableFilterMeta | undefined,
  any,
  (value: (DataTableFilterMeta | undefined)) => any,
] {

  const filterKey = componentId + '_filters';
  const defaultFilters = useMemo(() => {
    return extractDefaultFilters(columns, storedDefaultFilterMeta, alwaysDefaultFilterMeta);
  }, [columns, storedDefaultFilterMeta, alwaysDefaultFilterMeta]);
  const [storedFilters, setStoredFilters]
    = useBrowserPageStorage<DataTableFilterMeta | undefined>(filterKey, defaultFilters);
  // Reset on every initialization.
  const [alwaysDefaultFilters, setAlwaysDefaultFilters] = useState(alwaysDefaultFilterMeta);
  const filters = useMemo(
  () => {
    return cleanUp({ ...storedFilters, ...alwaysDefaultFilters }, defaultFilters);
  }, [storedFilters, alwaysDefaultFilters, defaultFilters]);

  const serverFilterColumns = useMemo(
    () => columns.filter(x => x.serverFilterRequest),
    [columns],
  );
  const serverFilters = useMemo(() => {
    return extractServerFilter(filters, serverFilterColumns, externalFilters);
  }, [filters, serverFilterColumns, externalFilters]);

  const setFilters = useCallback((value: DataTableFilterMeta | undefined) => {
    setStoredFilters(value);
    setAlwaysDefaultFilters(extractAlwaysDefaultFilters(value, alwaysDefaultFilterMeta));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[alwaysDefaultFilterMeta]);

  return [defaultFilters, filters, serverFilters, setFilters];
}

/**
 * Fix everything that can break the list.
 */
function cleanUp(
  filters: DataTableFilterMeta | undefined,
  defaultFilters: DataTableFilterMeta | undefined,
) {
  if (!defaultFilters) return undefined;

  let fixedFilters = filters
    ? { ...filters }
    : {};

  // Remove filters for non-existing columns.
  Object.keys(fixedFilters).forEach(field => {
    if (!defaultFilters[field]) {
      delete fixedFilters[field];
    }
  });

  // Add missing filters.
  Object.keys(defaultFilters).forEach(field => {
    if (!fixedFilters[field]) {
      fixedFilters[field] = defaultFilters[field];
    }
  });

  return fixedFilters
}

function extractAlwaysDefaultFilters(
  storedFilters: DataTableFilterMeta | undefined,
  alwaysDefaultFilterMeta: DataTableFilterMeta | undefined
) {
  if (!alwaysDefaultFilterMeta || !storedFilters || !Object.keys(alwaysDefaultFilterMeta).length) {
    return undefined;
  }

  let res = {} as DataTableFilterMeta;
  for (const [field] of Object.entries(alwaysDefaultFilterMeta)) {
    res[field] = storedFilters[field];
  }

  return res;
}

function extractDefaultFilters(
  columns: LogisticsColumnProps[],
  storedDefaultFilterMeta: DataTableFilterMeta | undefined,
  alwaysDefaultFilterMeta: DataTableFilterMeta | undefined,
) {
  const filteringColumns = columns.filter(column => column.filter && column.filter);
  if (!filteringColumns.length) {
    return undefined;
  }

  let filters = {} as DataTableFilterMeta;
  filteringColumns.forEach(column => {
    filters[column.field!] = { value: null, matchMode: column.filterMatchMode || 'contains' };
  });

  return {
    ...filters,
    ...(storedDefaultFilterMeta || {}),
    ...(alwaysDefaultFilterMeta || {}),
  };
}

function extractServerFilter(
  filterMeta: DataTableFilterMeta | undefined,
  serverFilterColumns: LogisticsColumnProps[],
  externalFilters: ExternalFilterProperties[] | undefined,
) {
  if (!filterMeta || !serverFilterColumns?.length) {
    return undefined;
  }

  const serverFilters = serverFilterColumns.map(serverFilterColumn => {
    const filterMetaData = filterMeta[serverFilterColumn.field || ''];
    if (!filterMetaData) return undefined;
    if (!isDataTableFilterMetaData(filterMetaData)) return undefined;
    if (filterMetaData.value === null
      || filterMetaData.value === undefined
      || filterMetaData.value === '') return undefined;

    return serverFilterColumn.serverFilterRequest!(filterMetaData, {});
  }).filter(x => x);

  const externalServerFilters = externalFilters?.map(externalFilter => {
    const filterMetaData = filterMeta[externalFilter.field || ''];
    if (!filterMetaData) return undefined;
    if (!isDataTableFilterMetaData(filterMetaData)) return undefined;
    if (filterMetaData.value === null
      || filterMetaData.value === undefined
      || filterMetaData.value === '') return undefined;

    return externalFilter.serverFilterRequest!(filterMetaData, {});
  }).filter(x => x);

  return serverFilters.concat(externalServerFilters).reduce((prev, cur) => {
    return {...prev, ...cur };
  }, {})
}

export default useFilters;