import { EBidIssuerType, EDriverOrder, LoadedOrder, Offer, VehicleReservation } from '../generated-api';
import { Column } from 'primereact/column';
import { ReactNode, useCallback } from 'react';
import { formatTime } from '../shared';
import FlightIcon from '@mui/icons-material/Flight';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import styled from '@emotion/styled';
import CallButton from './calls/CallButton';
import { CompactDataTable } from './data-grid/LogisticsDataGridBase';
import { isMobile } from 'react-device-detect';
import VisibilityToggler from './VisibilityToggler';
import { MdOutlineFlag, MdStarBorder } from 'react-icons/md';
import dayjs from 'dayjs';
import LogisticsTooltip from './LogisticsTooltip';
import useAuth from '../features/login/AuthHook';
import { AuthenticationState } from '../features/login/LoginSlice';

export interface LoadedOrderVehiclesBlockParams {
  data? : Offer[] | null;
  loadedOrder? : LoadedOrder | null;
  selected?: Offer | null;
  loading?: Offer | null;
  onSelectionChange: (selection: Offer | undefined) => void;
  extraHeaderContent?: ReactNode | undefined;
}

const StyledFlightIcon = styled(FlightIcon)`
  & {
    vertical-align: top;
  }
`

const StyledLocalShippingIcon = styled(LocalShippingIcon)`
  & {
    vertical-align: top;
  }
`

const LoadedOrderVehiclesBlock = (props: LoadedOrderVehiclesBlockParams) => {
  const { auth } = useAuth();
  const {data, loadedOrder, selected, onSelectionChange} = props;
  const extraHeaderContent = props.extraHeaderContent || <></>;

  const availableTemplate = useCallback((data: Offer) => {
    if (data.vehicle) {
      let availableCityElement : JSX.Element = <span className='text-purple-600'>{data.vehicle.availableCity}</span>;
      if (data.vehicle.availableCity && loadedOrder?.pickUpAt) {
        const destinationParameter = loadedOrder?.deliverTo 
          ? `&destination=${encodeURI(loadedOrder.deliverTo)}&waypoints=${encodeURI(loadedOrder?.pickUpAt)}`
          : `&destination=${encodeURI(loadedOrder?.pickUpAt)}`
        const link = `https://www.google.com/maps/dir/?api=1&origin=${encodeURI(data.vehicle.availableCity)}${destinationParameter}`;
        const className = `loaded-order-map-link ${isMobile ? 'text-sm' : 'text-lg'} pl-4 text-purple-600`
        availableCityElement = <a
          className={className}
          target="_blank"
          rel="noreferrer"
          href={link}>
            {data.vehicle.availableCity}
        </a>
      }
      return <>
        {availableCityElement}
        <br/>
        <span className='ml-1'>{formatTime(data.vehicle.availableDate)}</span>
      </>
    }
    return null;
  }, [loadedOrder]);

  return <>
    <div className='text-2xl font-bold'>
      Units found: {data?.length}
      {extraHeaderContent}
    </div>
    <div className='my-3'>
      <CompactDataTable
        scrollable={isMobile}
        size={(isMobile ? 'small' :  'normal')}
        value={data!}
        selection={selected}
        onSelectionChange={e => onSelectionChange(e.value)}
        sortMode="multiple"
        className={isMobile ? "text-sm" : ""}
      >
        <Column selectionMode="single" style={{flex: "0 0 7%"}}></Column>
        <Column style={{flex: "0 0 10em", flexWrap: "nowrap", overflow: "visible"}} field='flyMiles' align='center' alignHeader='center' header='Unit' body={unitNameTemplate(props, auth)}></Column>
        <Column style={{flex: "0 0 15em", overflow: "hidden"}} align='center' alignHeader='center' header='Driver' body={driverTemplate}></Column>
        <Column style={{flex: "0 0 10em", overflow: "hidden"}} align='center' alignHeader='center' header='Vehicle' body={vehicleTemplate}></Column>
        <Column style={{flex: "0 0 15em", overflow: "hidden"}} align='center' alignHeader='center' header='Available' body={availableTemplate}></Column>
        <Column style={{flex: "0 0 10em", overflow: "hidden"}} align='center' alignHeader='center' header='Dimensions' body={dimensionsTemplate}></Column>
        <Column style={{flex: "0 0 10em", overflow: "hidden"}} align='center' alignHeader='center' header='Dispatcher' body={dispatcherTemplate}></Column>
        <Column style={{flex: "0 0 10em", overflow: "hidden"}} align='center' alignHeader='center' header='Notes' body={notesTemplate}></Column>
        <Column style={{flex: "0 0 15em", overflow: "hidden"}} align='center' alignHeader='center' header='Call' body={callTemplate}></Column>
        <Column style={{flex: "0 0 10em", overflow: "hidden"}} align='center' alignHeader='center' header='Bid' body={driverBidTemplate}></Column>
      </CompactDataTable>
    </div>
  </>;
};

const ReservationTooltip = (props: VehicleReservation) => {
  const dispatcherLogin = props?.dispatcherLogin || '';
  const reservationDate = props?.bidCreationDate
    ? dayjs(props?.bidCreationDate).tz().format('MMM D, hh:mm A')
    : '';

  return <div className='flex flex-column px-1 pb-1'>
    <span>{dispatcherLogin}</span>
    <span>{reservationDate}</span>
  </div>;
}

const unitNameTemplate = (props: LoadedOrderVehiclesBlockParams, auth: AuthenticationState) => (data: Offer) => {
  const loading =
    data.loadedOrderId === props.loading?.loadedOrderId
    && data.vehicleId === props.loading?.vehicleId;

  return <div className='flex align-items-center'>
    <div className='flex flex-row'>
      <VisibilityToggler visible={!!data.vehicle?.reservation}>
        <LogisticsTooltip label={<ReservationTooltip {...data.vehicle?.reservation} />}>
          <MdOutlineFlag style={{color: '#EA3323', width: '2rem', height: '2rem'}}/>
        </LogisticsTooltip>
      </VisibilityToggler>
      <VisibilityToggler visible={auth.account.id === data.vehicle?.dispatcherId || auth.account.id === data.vehicle?.dispatcher?.id}>
        <MdStarBorder style={{color: '#FFCD36', width: '2rem', height: '2rem'}}/>
      </VisibilityToggler>
    </div>
    <div>
      <span>{`V#${data.vehicleId}`}</span>
      <VisibilityToggler visible={loading}>
        <div>loading</div>
      </VisibilityToggler>
      <VisibilityToggler visible={!loading}>
        <div>
          <VisibilityToggler visible={!!data.vehicleGroundMiles}>
            <StyledLocalShippingIcon fontSize='small' />
            {data.vehicleGroundMiles}&nbsp;mi
          </VisibilityToggler>
          <VisibilityToggler visible={!data.vehicleGroundMiles}>
            <StyledFlightIcon fontSize='small' />
            {data.vehicleFlyMiles}&nbsp;mi
          </VisibilityToggler>
        </div>
      </VisibilityToggler>
    </div>
  </div>;
};

const driverTemplate = (data: Offer) => {
  let result = null;
  if (data.vehicle?.drivers?.length) {
    const driver = data.vehicle?.drivers.find(d => d.order === EDriverOrder.First);
    if (driver) {
      result = <>
        <span>{(driver.firstName ?? '') + (' ' + driver.lastName ?? '')}</span>
        <br />
        <span className='text-gray-600'>{driver.driverLogin}</span>
      </>;
    }
  }

  return result;
};

const vehicleTemplate = (data: Offer) => {
  if (data.vehicle) {
    return <>
      <span>{data.vehicle.vehicleType?.name}</span>
      <br />
      <span>{data.vehicle.payload}</span>
    </>
  }
  return null;
};

const dimensionsTemplate = (data: Offer) => {
  if (data.vehicle && data.vehicle.boxDimensions?.length === 3) {
    const [w, h, d] = data.vehicle.boxDimensions!;
    return <span>{`${w}x${h}x${d}in.`}</span>
  }
  return null;
};

const dispatcherTemplate = (data: Offer) => {
  if (data.vehicle && data.vehicle.dispatcher) {
    return <>
      <span>{data.vehicle.dispatcher.firstName}</span>
      <br/>
      <span>{data.vehicle.dispatcher.lastName}</span>
    </>
  }
}

const notesTemplate = (data: Offer) => {
  if (data.vehicle) {
    return <span className='text-red-500'>{data.vehicle.note}</span>
  }
  return null;
};

const callTemplate = (data: Offer) => {
  if (!data.vehicle?.drivers?.length)
    return null;

  const driver = data.vehicle.drivers[0];
  const owner = data.vehicle.owner;

  return <>
    <CallButton label='DRIVER' className='p-button-success' callable={driver} />
    <CallButton label='OWNER' className='p-button-info ml-1' callable={owner} />
  </>;
}

function driverBidTemplate(data: Offer) {
  if (!data.vehicle?.driverBids?.length)
    return null;

  const driverBid = data.vehicle.driverBids[0];
  let bidType = ''
  if (driverBid.type === EBidIssuerType.Driver) {
    bidType = "(D)";
  } else if (driverBid.type === EBidIssuerType.Owner) {
    bidType = "(O)";
  }

  return <span style={{backgroundColor: '#98fb98'}}>
    {`$ ${driverBid.driverPrice || 0} ${bidType}`}
  </span>;
}

export default LoadedOrderVehiclesBlock;