/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DispatcherLoadsAndProfitsByPeriodResponse } from './DispatcherLoadsAndProfitsByPeriodResponse';
import {
    DispatcherLoadsAndProfitsByPeriodResponseFromJSON,
    DispatcherLoadsAndProfitsByPeriodResponseFromJSONTyped,
    DispatcherLoadsAndProfitsByPeriodResponseToJSON,
} from './DispatcherLoadsAndProfitsByPeriodResponse';

/**
 * 
 * @export
 * @interface DispatcherProfitResponse
 */
export interface DispatcherProfitResponse {
    /**
     * 
     * @type {string}
     * @memberof DispatcherProfitResponse
     */
    dispatcherId?: string | null;
    /**
     * 
     * @type {Array<DispatcherLoadsAndProfitsByPeriodResponse>}
     * @memberof DispatcherProfitResponse
     */
    loadAndProfitsByPeriod?: Array<DispatcherLoadsAndProfitsByPeriodResponse> | null;
}

/**
 * Check if a given object implements the DispatcherProfitResponse interface.
 */
export function instanceOfDispatcherProfitResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DispatcherProfitResponseFromJSON(json: any): DispatcherProfitResponse {
    return DispatcherProfitResponseFromJSONTyped(json, false);
}

export function DispatcherProfitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DispatcherProfitResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dispatcherId': !exists(json, 'dispatcherId') ? undefined : json['dispatcherId'],
        'loadAndProfitsByPeriod': !exists(json, 'loadAndProfitsByPeriod') ? undefined : (json['loadAndProfitsByPeriod'] === null ? null : (json['loadAndProfitsByPeriod'] as Array<any>).map(DispatcherLoadsAndProfitsByPeriodResponseFromJSON)),
    };
}

export function DispatcherProfitResponseToJSON(value?: DispatcherProfitResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dispatcherId': value.dispatcherId,
        'loadAndProfitsByPeriod': value.loadAndProfitsByPeriod === undefined ? undefined : (value.loadAndProfitsByPeriod === null ? null : (value.loadAndProfitsByPeriod as Array<any>).map(DispatcherLoadsAndProfitsByPeriodResponseToJSON)),
    };
}

