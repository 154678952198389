import { UploadFile } from 'antd/es/upload/interface';
import { LogisticsFile } from '../../../../generated-api';
import { useMemo } from 'react';
import { create, StoreApi, UseBoundStore } from 'zustand';

type FilePreviewStore = {
  files: UploadFile<LogisticsFile>[][],
  activeFile?: UploadFile<LogisticsFile>
  isPreviewing: boolean,
  joinPreview: (files: UploadFile<LogisticsFile>[]) => any,
  leavePreview: (files: UploadFile<LogisticsFile>[]) => any,
  openPreview: (activeFile: UploadFile<LogisticsFile>) => any,
  closePreview: () => any,
}

export type  FilePreviewWidget = UseBoundStore<StoreApi<FilePreviewStore>>;

function useFilePreview() : FilePreviewWidget {

  const useCustomStore = useMemo(() => {
    return create<FilePreviewStore>((set) => ({
      ...{} as FilePreviewStore,
      files: [],
      activeFile: undefined,
      joinPreview: (files) => set(state => {
        const alreadyInPreview = state.files.find(x => x === files);
        if (!alreadyInPreview) {
          return { files: [...state.files, files] };
        } else {
          console.warn('Preview already exists');
          return {};
        }
      }),
      leavePreview: (files) => set(state => {
        const filesInPreview = state.files.find(x => x === files);
        if (filesInPreview) {
          const updatedFiles = state.files.filter(x => x !== files);
          return { files: updatedFiles };
        } else {
          console.warn('No such files in preview');
          return {};
        }
      }),
      openPreview: (activeFile) => set(state => {
        const fileGroups = state.files;
        if (!fileGroups?.length) {
          console.warn('No components are registered for file preview. Consider register LogisticsFilesUpload via "joinPreview"');
          return {};
        }

        return { activeFile: activeFile, isPreviewing: true };
      }),
      closePreview: () => set(state => {
        return { isPreviewing: false };
      })
    }));
  }, []);

  return useCustomStore;
}

export default useFilePreview;