import React, { useRef } from 'react';
import { OwnerDocumentsSignedNotification } from '../../../../generated-api';
import styled from '@emotion/styled';
import { NotificationArrowButton } from './NotificationArrowButton';
import { useNavigate } from 'react-router-dom';

const Container = styled.div `
  .p-toast-message-content:has(&) {
    background-color: #b1f8e6;
  }
`

function DocumentsSignedNotification(props: OwnerDocumentsSignedNotification) {
  const ref = useRef<HTMLDivElement>({} as any);
  const navigate = useNavigate();
  return <Container
    className='flex flex-row flex-grow-1 justify-content-evenly align-items-center text-2xl'
    ref={ref}
  >
    <span className='flex-grow-1'>{`Request #${props.requestId || ''} contract signed`}</span>
    <NotificationArrowButton
      onClick={() => {
        navigate('/request/' + props.requestId?.toString() || '');
        const closeButton = ref.current.parentElement!.querySelector('button.p-toast-icon-close') as HTMLButtonElement;
        closeButton.click();
      }}
      className='flex-grow-0 pl-4'
    >
      <i className='pi pi-angle-right text-2xl' />
    </NotificationArrowButton>
  </Container>
}

export default DocumentsSignedNotification;