import React, { Dispatch, SetStateAction, useMemo } from 'react';
import LogisticsDataGridBase from '../../../components/data-grid/LogisticsDataGridBase';
import LogisticsColumnProps from '../../../components/data-grid/LogisticsColumnProps';
import { EChangeType, Owner, OwnerDocument } from '../../../generated-api';
import { MdEditDocument } from 'react-icons/md';
import styled from '@emotion/styled';
import LogisticsFileUpload from '../../../components/form/files/upload/LogisticsFileUpload';
import { FilePreviewWidget } from '../../../components/form/files/preview/filePreviewWidget';
import LogisticsCalendar from '../../../components/form/datetime/LogisticsCalendar';
import { Button } from 'primereact/button';

interface OwnerDocumentsTableProps {
  ownerDocuments?: OwnerDocument[] | null | undefined,
  onChange: Dispatch<SetStateAction<Owner>>,
  filePreviewWidget?: FilePreviewWidget,
  disabled?: boolean,
}

function OwnerDocumentsTable(props: OwnerDocumentsTableProps) {
  const columns = useMemo(() => {
    const filePreviewWidget = props.filePreviewWidget;
    const w9fileColumn : LogisticsColumnProps = {
      field: 'file',
      header: 'W9 form',
      style : {flex: "1", flexWrap: "nowrap", overflow: "hidden"},
      body: (row: OwnerDocument) => {
        return <span className="w-full">
          <LogisticsFileUpload
            value={row.w9File}
            filePreviewWidget={filePreviewWidget}
            disabled={props.disabled}
            onChange={newValue => {
              props.onChange(owner => {
                const updatedDocuments = owner.ownerDocuments?.map(ownerDocument => {
                  if (ownerDocument.id !== row.id) return ownerDocument;

                  return { ...ownerDocument, w9File: newValue, w9Signature: !!newValue };
                }) || [];

                owner.ownerDocuments = updatedDocuments;
                return owner;
              });
            }}
          />
        </span>
        ;
      },
    };

    const dateColumn: LogisticsColumnProps = {
      field: 'date',
      header: 'Date',
      style : {flex: "1", flexWrap: "nowrap", overflow: "hidden"},
      body: (row: OwnerDocument) => {
        return <div className='w-full'>
          <LogisticsCalendar
            value={row.w9Date}
            onChange={newValue => {
              props.onChange(owner => {
                const updatedDocuments = owner.ownerDocuments?.map(ownerDocument => {
                  if (ownerDocument.id !== row.id) return ownerDocument;

                  return { ...ownerDocument, w9Date: newValue };
                }) || [];

                owner.ownerDocuments = updatedDocuments;
                return owner;
              });
            }}
            disabled={props.disabled}
          />
        </div>
      },
    }

    const actionsColumn: LogisticsColumnProps = {
      field: 'action',
      header: 'Action',
      style: { flex: 0.5 },
      sortable: false,
      filter: false,
      body: rowData => <Button
        icon='pi pi-trash'
        disabled={props.disabled}
        className='p-button-lg p-button-rounded p-button-text p-button-plain hover:text-red-400'
        onClick={(e) => {
          if (!rowData?.id) return;

          props.onChange(owner => {
            const updatedDocuments = owner.ownerDocuments?.map(ownerDocument => {
              if (ownerDocument.id !== rowData.id) return ownerDocument;

              return { ...ownerDocument, changeType: EChangeType.Deleted };
            }) || [];

            return { ...owner, ownerDocuments: updatedDocuments }
          });
        }}
      />
    }

    return [dateColumn, w9fileColumn, signatureColumn, actionsColumn];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disabled]);

  const buttons = useMemo(() => {
    if (props.disabled) return [];

    return [
      <Button
        onClick={() => {
          props.onChange(owner => {
            const updatedDocuments
              = (owner.ownerDocuments || []).concat({w9Signature: false});
            return { ...owner, ownerDocuments: updatedDocuments };
          });
        }}
      >
        Add
      </Button>
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disabled]);

  const filteredDocuments = useMemo(
    () => props.ownerDocuments?.filter(x => x.changeType !== EChangeType.Deleted),
    [props.ownerDocuments]);

  return <LogisticsDataGridBase
    columns={columns}
    entities={filteredDocuments}
    fullSize={false}
    rightHeaderElements={buttons}
  />
}


const signatureColumn: LogisticsColumnProps = {
  field: 'signature',
  header: 'Signature',
  style : {flex: "0.5", flexWrap: "nowrap", overflow: "hidden"},
  body: (row: OwnerDocument) => {
    const icon = row.w9Signature
      ? <DocumentSigned />
      : <DocumentNotSigned />;
    return <span className='w-full flex justify-content-center align-items-center'>
      {icon}
    </span>
  },
}


const DocumentSigned = styled(MdEditDocument)`
  & {
    width: 2rem;
    height: 2rem;
    color: limegreen;
`

const DocumentNotSigned = styled(MdEditDocument)`
  & {
    width: 2rem;
    height: 2rem;
    color: #a21824;
`

export default OwnerDocumentsTable;