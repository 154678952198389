/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterPreset,
} from '../models';
import {
    FilterPresetFromJSON,
    FilterPresetToJSON,
} from '../models';

export interface ApiFilterPresetsGetRequest {
    componentId?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiFilterPresetsIdDeleteRequest {
    id: number;
}

export interface ApiFilterPresetsIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiFilterPresetsIdPutRequest {
    id: number;
    filterPreset?: FilterPreset;
}

export interface ApiFilterPresetsPostRequest {
    filterPreset?: FilterPreset;
}

/**
 * 
 */
export class FilterPresetsApi extends runtime.BaseAPI {

    /**
     */
    async apiFilterPresetsGetRaw(requestParameters: ApiFilterPresetsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterPreset>>> {
        const queryParameters: any = {};

        if (requestParameters.componentId !== undefined) {
            queryParameters['componentId'] = requestParameters.componentId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/filter-presets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterPresetFromJSON));
    }

    /**
     */
    async apiFilterPresetsGet(requestParameters: ApiFilterPresetsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterPreset>> {
        const response = await this.apiFilterPresetsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFilterPresetsIdDeleteRaw(requestParameters: ApiFilterPresetsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFilterPresetsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/filter-presets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFilterPresetsIdDelete(requestParameters: ApiFilterPresetsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiFilterPresetsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFilterPresetsIdGetRaw(requestParameters: ApiFilterPresetsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilterPreset>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFilterPresetsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/filter-presets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilterPresetFromJSON(jsonValue));
    }

    /**
     */
    async apiFilterPresetsIdGet(requestParameters: ApiFilterPresetsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilterPreset> {
        const response = await this.apiFilterPresetsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFilterPresetsIdPutRaw(requestParameters: ApiFilterPresetsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFilterPresetsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/filter-presets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FilterPresetToJSON(requestParameters.filterPreset),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFilterPresetsIdPut(requestParameters: ApiFilterPresetsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiFilterPresetsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFilterPresetsPostRaw(requestParameters: ApiFilterPresetsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilterPreset>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/filter-presets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterPresetToJSON(requestParameters.filterPreset),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilterPresetFromJSON(jsonValue));
    }

    /**
     */
    async apiFilterPresetsPost(requestParameters: ApiFilterPresetsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilterPreset> {
        const response = await this.apiFilterPresetsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
