import {
  ApiTrackingsGetRequest,
  Tracking, TrackingsApi,
} from '../../generated-api';
import { createEntitySlice, EntityState, PageResponse } from '../../shared';
import { createAction, createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import paginationApiFactory from '../../shared/util/paginationApiFactory';

const emptyValue: Readonly<Tracking> = {} as any;

const initialState: EntityState<Tracking> = {
  loading: false,
  entities: [],
  entity: emptyValue,
  updating: false,
  updateSuccess: false,
}

export const getTrackingsPage = createAsyncThunk(
  "Tracking/fetch_entity_page",
  async (queryParams : ApiTrackingsGetRequest) => {
    let totalCount = 0;
    const api = paginationApiFactory(TrackingsApi, total => totalCount = total);
    const entities = await api.apiTrackingsGet(queryParams);
    return { entities, totalCount } as PageResponse<Tracking>;
  }
);

export const updateTracking = createAction(
  'Tracking/update',
  (tracking: Tracking) => {
    return { payload: tracking };
  }
);

export const TrackingSlice = createEntitySlice({
  name: 'Tracking',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateTracking, (state, action) => {
      const entity = action.payload;
      const listEntity = state.entities.find(x => x.id === entity.id);
      if (!listEntity) return;

      return {
        ...state,
        entities: state.entities.map(x => x === listEntity ? entity : x),
      }
    }).addMatcher(isFulfilled(getTrackingsPage), (state, action) => {
      const data = action.payload;

      return {
        ...state,
        loading: false,
        entities: data.entities,
        totalItems: data.totalCount,
      }
    });

  }
});

export default TrackingSlice.reducer;