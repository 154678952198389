import React, { useEffect, useState } from 'react';
import LogisticsDialog from '../../../components/LogisticsDialog';
import { apiFactory, ModalParameters } from '../../../shared';
import { Notification, NotificationsApi } from '../../../generated-api';
import Header from '../../../components/form/Header';
import { Button } from 'primereact/button';
import { showErrorToast } from '../../../components/LogisticsToast';
import NotificationRow from './NotificationRow';
import { useAppDispatch } from '../../../hooks';
import { updateStoredNotificationsCount } from '../NotificationsSlice';
import CenteredSpinner from '../../../components/CenteredSpinner';
import LogisticsPagination from '../../../components/data-grid/pagination/LogisticsPagination';

export interface NotificationsModalParameters extends ModalParameters<undefined> {
}

const notificationsPerPage = 20;

function NotificationsModal(props: NotificationsModalParameters) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!props.visible)
      return;

    setLoading(true);
    apiFactory(NotificationsApi).apiNotificationsGet({ page: page, size: notificationsPerPage })
      .then(notificationPage => {
        setNotifications(notificationPage.records || []);
        setTotalNotificationsCount(notificationPage.total ?? 0);
      })
      .catch(reason => {
        showErrorToast('Something went wrong');
        console.error(reason);
      })
      .finally(() => setLoading(false));
  }, [props.visible, page]);

  const onMarkAsRead = async (notification: Notification) => {
    try {
      await apiFactory(NotificationsApi)
        .apiNotificationsMarkAsReadPut({ id: notification.id });
      const updatedNotifications = notifications.filter(x => x.id !== notification.id);
      setNotifications(updatedNotifications);
      dispatch(updateStoredNotificationsCount(x => x - 1));
    } catch (error: any) {
      console.error(error);
      showErrorToast('Something went wrong');
    }
  }

  const onDelete = async (notification: Notification) => {
    try {
      await apiFactory(NotificationsApi)
        .apiNotificationsDelete({ id: notification.id });
      const updatedNotifications = notifications.filter(x => x.id !== notification.id);
      setNotifications(updatedNotifications);
      if (!notification.read) {
        dispatch(updateStoredNotificationsCount(x => x - 1));
      }
    } catch (error: any) {
      console.error(error);
      showErrorToast('Something went wrong');
    }
  }

  const onMarkAllAsRead = async () => {
    try {
      setLoading(true);
      await apiFactory(NotificationsApi).apiNotificationsMarkAllAsReadPut();
      setNotifications([]);
      dispatch(updateStoredNotificationsCount(() => 0));
    } catch (error: any) {
      console.error(error);
      showErrorToast('Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  return <LogisticsDialog
    visible={props.visible}
    onHide={() => props.hide(undefined)}
    className='full-height-dialog w-6'
  >
    <div className='flex flex-column h-full'>
      <header className='flex flex-row flex-grow-0'>
        <div className='flex justify-content-start flex-grow-1'>
          <Header >
            Notifications
          </Header>
        </div>
        <div className='flex justify-content-end flex-grow-1'>
          <Button className='white-space-nowrap p-button-secondary' onClick={onMarkAllAsRead}>
            MARK ALL AS READ
          </Button>
        </div>
      </header>
      <main className='flex flex-column h-full flex-grow-1 overflow-y-scroll'>
        {notifications.map(notification =>
          <NotificationRow
            notification={notification}
            key={notification.id}
            onMarkAsRead={onMarkAsRead}
            onDelete={onDelete}
          />
        )}
      </main>
      <footer className='flex-grow-0'>
        <LogisticsPagination
          onPageChange={newPage => setPage(newPage)}
          totalRecords={totalNotificationsCount}
          recordsPerPage={notificationsPerPage} />
      </footer>
    </div>

    <CenteredSpinner visible={loading} />
  </LogisticsDialog>
}

export default NotificationsModal;