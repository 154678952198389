import { memo, useEffect } from 'react';
import { useHubConnection } from '../../hooks/SignalR';
import { useAppDispatch } from '../../hooks';
import {
  getCurrentDispSetting,
  getSystemSetting,
} from './SettingsSlice';

const SettingsInvalidator = memo(() => {
  const hub = useHubConnection();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Might create load spikes
    hub.on('InvalidateDispatcherSettings', () => {
      console.debug('received InvalidateDispatcherSettings message');
      dispatch(getCurrentDispSetting({}));
    });
    hub.on('InvalidateSystemSettings', () => {
      console.debug('received InvalidateSystemSettings message');
      dispatch(getSystemSetting({ id: 1}));
    })

    return () => {
      hub.off('InvalidateDispatcherSettings');
      hub.off('InvalidateSystemSettings');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <></>;
});
export default SettingsInvalidator;