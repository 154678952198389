import { LoadedOrder } from '../generated-api';
import { Fragment } from 'react';
import dayjs from 'dayjs';
import { BrowserView, MobileView } from 'react-device-detect';

const LoadedOrderDataBlock = (data : LoadedOrder) => {
  const vehicleTypes = data?.vehicleTypes?.map(vt => vt.name).join(', ');

  const pickUpDateString = data.source === 'Ivia' ? 'Pick-up date (local): ' : 'Pick-up date (ET): ';
  const pickUpDate = data.source === 'Ivia' ? dayjs(data?.pickUpDate).utc().format('MM/DD/YYYY HH:mm') : dayjs(data?.pickUpDate).tz().format('MM/DD/YYYY HH:mm');
  const deliveryDateString = data.source === 'Ivia' ? 'Delivery date (local): ' : 'Delivery date (ET): ';
  const deliveryDate = data.source === 'Ivia' ? dayjs(data?.deliveryDate).utc().format('MM/DD/YYYY HH:mm') : dayjs(data?.deliveryDate).tz().format('MM/DD/YYYY HH:mm');
  const orderNumber = data.source === 'Ivia' ? `Ref.# ${data?.orderNumber}` : `#${data?.orderNumber}`;

  function getIviaLink(orderId: string) {
    const link = `https://web.ivia.com/load-board/find-loads/unquoted?id=${orderId}`;
    return (<a
      target="_blank"
      rel="noreferrer"
      href={link}>
        Submit quote
    </a>);
  };

  return <Fragment>
    <div className='my-3 font-bold'>
      Pick-up at: {data?.pickUpAt}
      <br/>{data?.asap ? 'ASAP' : pickUpDateString + pickUpDate}
    </div>
    <div className='my-3 font-bold'>
      <div>Deliver to: {data?.deliverTo}</div>
      {data?.deliverDirect ?
        'Deliver Direct' :
        data?.asapDeliver ? 'ASAP' :
        <div>{deliveryDateString + deliveryDate}</div>
      }
    </div>
    <div className='my-3'>
      {GeneralInfo(data)}
      <div className='font-bold'>
        Suggested Truck Size: {vehicleTypes}
      </div>
    </div>
    <div className='my-3 font-bold'>
      {data?.notes && 'Notes: '}{data?.notes}
      <br/>
      {data.source === 'Ivia' && getIviaLink(data.orderNumber || '')}
    </div>
    <div className='my-3 font-bold'>
      {data?.postingExpiryDate && 'This posting expires (EST): '}{dayjs(data?.postingExpiryDate).tz().format('MM/DD/YYYY HH:mm')}
    </div>
    <div className='my-3'>
      If you are interested in this load, please contact:
      <br/>{data?.brokerage}
      {/*<br/>{data?.brokerage?.contactAddress}*/}
      <br/>{data?.contactPhone}
    </div>
    <div className='my-3'>
      <div>Load posted by: {data?.postedBy}</div>
      <div>Phone: {data?.postedByPhone}</div>
    </div>
    <div className='my-3'>
      Please reference our ORDER NUMBER: {orderNumber}
    </div>
  </Fragment>
};

function GeneralInfo(data : LoadedOrder) {
  return <>
    <BrowserView>
      { data?.pays && <div>Pays: U${data?.pays}</div>}
      <div>Miles: {data?.miles}</div>
      <div>{data?.pieces && 'Pieces: '}{data?.pieces}</div>
      <div>Weight: {data?.weight}</div>
      <div>Dims: {data?.dimensions}</div>
      <div>{data?.stackable && 'Stackable: '}{data?.stackable}</div>
      <div>{data?.hazardous && 'Hazardous: '}{data?.hazardous}</div>
      <div>{data?.fastLoad && 'FAST Load: '}{data?.fastLoad}</div>
      <div>Dock Level: {data?.dockLevel}</div>
      <div>{data?.liftgate && 'Lift gate: '}{data?.liftgate}</div>
      <div>{data?.waitAndReturn && 'Wait and return: '}{data?.waitAndReturn}</div>
      <div>{data?.team && 'Team: '}{data?.team}</div>
      <div>{data?.csa && 'CSA: '}{data?.csa}</div>
      <div>{data?.sysRef && 'Sys ref: '}{data?.sysRef}</div>
      <div>{data?.shipmentId && 'Shipment id: '}{data?.shipmentId}</div>
      <div>{data?.jobDescription && 'Job description: '}{data?.jobDescription}</div>
      <div>{data?.packaging && 'Packaging: '}{data?.packaging}</div>
      <div>{data?.items && 'Items: '}{data?.items}</div>
      <div>{data?.brokerMC && 'Broker MC: '}{data?.brokerMC}</div>
    </BrowserView>
    <MobileView>
      <div className="grid">
        { data?.pays && <div className="col-3">Pays: U${data?.pays}</div>}
        { data?.miles && <div className="col-3">Miles: {data?.miles}</div>}
        { data?.pieces &&  <div className="col-3">Pieces: {data?.pieces}</div>}
        { data?.weight && <div className="col-3">Weight: {data?.weight}</div>}
        { data?.dimensions && <div className="col-3">Dims: {data?.dimensions}</div>}
        { data?.stackable && <div className="col-3">Stackable: {data?.stackable}</div>}
        { data?.hazardous && <div className="col-3">Hazardous: {data?.hazardous}</div>}
        { data?.fastLoad && <div className="col-3">FAST Load: {data?.fastLoad}</div>}
        { data?.dockLevel && <div className="col-3">Dock Level: {data?.dockLevel}</div>}
        { data?.liftgate && <div className="col-3">Lift gate: {data?.liftgate}</div>}
        { data?.waitAndReturn && <div className="col-3">Wait and return: {data?.waitAndReturn}</div>}
        { data?.team && <div className="col-3">Team: {data?.team}</div>}
        { data?.csa && <div className="col-3">CSA: {data?.csa}</div>}
        { data?.sysRef && <div className="col-3">Sys ref: {data?.sysRef}</div>}
        { data?.shipmentId && <div className="col-3">Shipment id: {data?.shipmentId}</div>}
        { data?.jobDescription && <div className="col-3">Job description: {data?.jobDescription}</div>}
        { data?.packaging && <div className="col-3">Packaging: {data?.packaging}</div>}
        { data?.items && <div className="col-3">Items: {data?.items}</div>}
        { data?.brokerMC && <div className="col-3">Broker MC: {data?.brokerMC}</div>}
      </div>
    </MobileView>
  </>
}

export default LoadedOrderDataBlock;
