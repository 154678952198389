import React, { memo } from 'react';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const GlobalFilter = memo((props: ColumnFilterElementTemplateOptions) => {
  return <>
    <p className='text-base font-normal'>Filter:</p>
    <Input
      className='w-4 text-base py-2'
      placeholder="Type to filter..."
      onChange={e => {
        props.filterApplyCallback(e.target.value);
      }}
      value={props.value}
      suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 15 }} />}
    />
  </>
});

export default GlobalFilter;