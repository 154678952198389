/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EWidgetChart } from './EWidgetChart';
import {
    EWidgetChartFromJSON,
    EWidgetChartFromJSONTyped,
    EWidgetChartToJSON,
} from './EWidgetChart';
import type { EWidgetPeriodType } from './EWidgetPeriodType';
import {
    EWidgetPeriodTypeFromJSON,
    EWidgetPeriodTypeFromJSONTyped,
    EWidgetPeriodTypeToJSON,
} from './EWidgetPeriodType';

/**
 * 
 * @export
 * @interface WidgetParameters
 */
export interface WidgetParameters {
    /**
     * 
     * @type {EWidgetPeriodType}
     * @memberof WidgetParameters
     */
    periodType?: EWidgetPeriodType;
    /**
     * 
     * @type {Date}
     * @memberof WidgetParameters
     */
    from?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof WidgetParameters
     */
    to?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetParameters
     */
    dispatcherIds?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WidgetParameters
     */
    brokeragesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<EWidgetChart>}
     * @memberof WidgetParameters
     */
    charts?: Array<EWidgetChart> | null;
}

/**
 * Check if a given object implements the WidgetParameters interface.
 */
export function instanceOfWidgetParameters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WidgetParametersFromJSON(json: any): WidgetParameters {
    return WidgetParametersFromJSONTyped(json, false);
}

export function WidgetParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'periodType': !exists(json, 'periodType') ? undefined : EWidgetPeriodTypeFromJSON(json['periodType']),
        'from': !exists(json, 'from') ? undefined : (json['from'] === null ? null : new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (json['to'] === null ? null : new Date(json['to'])),
        'dispatcherIds': !exists(json, 'dispatcherIds') ? undefined : json['dispatcherIds'],
        'brokeragesIds': !exists(json, 'brokeragesIds') ? undefined : json['brokeragesIds'],
        'charts': !exists(json, 'charts') ? undefined : (json['charts'] === null ? null : (json['charts'] as Array<any>).map(EWidgetChartFromJSON)),
    };
}

export function WidgetParametersToJSON(value?: WidgetParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'periodType': EWidgetPeriodTypeToJSON(value.periodType),
        'from': value.from === undefined ? undefined : (value.from === null ? null : value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to === null ? null : value.to.toISOString()),
        'dispatcherIds': value.dispatcherIds,
        'brokeragesIds': value.brokeragesIds,
        'charts': value.charts === undefined ? undefined : (value.charts === null ? null : (value.charts as Array<any>).map(EWidgetChartToJSON)),
    };
}

