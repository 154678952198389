import { IdName } from './IdName';
import { EEquipment } from '../generated-api';

const Equipment: IdName<string>[] = [
  { id: EEquipment.Bulkhead, name: 'Bulkhead' },
  { id: EEquipment.Dolly, name: 'Dolly' },
  { id: EEquipment.Straps, name: 'Straps' },
  { id: EEquipment.Ppe, name: 'PPE' },
  { id: EEquipment.TempControl, name: 'Temp Control' },
  { id: EEquipment.AirRide, name: 'Air ride' },
  { id: EEquipment.LiftGate, name: 'Lift gate' },
  { id: EEquipment.PalletJack, name: 'Pallet jack' },
  { id: EEquipment.Tent, name: 'Tent' },
  { id: EEquipment.SideLoading, name: 'Side loading' },
  { id: EEquipment.LoadBars, name: 'Load bars' },
  { id: EEquipment.ETracks, name: 'ETracks' },
];

export default Equipment;