/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Point,
  TwoPoints,
} from '../models';
import {
    PointFromJSON,
    PointToJSON,
    TwoPointsFromJSON,
    TwoPointsToJSON,
} from '../models';

export interface ApiGeoGetRequest {
    address?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiGeoRouteDistancePostRequest {
    twoPoints?: TwoPoints;
}

/**
 * 
 */
export class GeoApi extends runtime.BaseAPI {

    /**
     */
    async apiGeoGetRaw(requestParameters: ApiGeoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Point>> {
        const queryParameters: any = {};

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/geo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointFromJSON(jsonValue));
    }

    /**
     */
    async apiGeoGet(requestParameters: ApiGeoGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Point> {
        const response = await this.apiGeoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGeoRouteDistancePostRaw(requestParameters: ApiGeoRouteDistancePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/geo/route-distance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TwoPointsToJSON(requestParameters.twoPoints),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiGeoRouteDistancePost(requestParameters: ApiGeoRouteDistancePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiGeoRouteDistancePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
