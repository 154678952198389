import React, { useMemo, useRef, useState } from 'react';
import { Avatar } from 'primereact/avatar';
import styled from '@emotion/styled';
import { TieredMenu } from 'primereact/tieredmenu';
import { MenuItem } from 'primereact/menuitem';
import useAuth from '../../features/login/AuthHook';
import UserReferralLinkPage from './UserReferralLinkPage';
import StoredNotifications from '../../features/notifications/stored/StoredNotifications';

const StyledSpan = styled.span`
  & {
    font-family: Roboto,Helvetica,Arial,sans-serif;
  }
`

const UserPanel = () => {
  const { auth, logout } = useAuth();
  const [userReferralLinkPageVisible, setUserReferralLinkPageVisible] = useState(false);
  const menuRef = useRef<TieredMenu>(null);

  const userReferralLinkPage = userReferralLinkPageVisible
  ? <UserReferralLinkPage
    data={auth.account}
    loading={false}
    visible={userReferralLinkPageVisible}
    hide={() => setUserReferralLinkPageVisible(false)}
  />
  : <></>;

  const userMenuOptions : MenuItem[] = useMemo(() => [
    {
      label: 'Referral Link',
      command: () => setUserReferralLinkPageVisible(true),
    },
    {
      label: 'Logout',
      command: logout,

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  return (
    <>
      {userReferralLinkPage}
      <div className='flex flex-grow-1 justify-content-end align-items-center mr-5'>
        <StoredNotifications />
        <span>
          <StyledSpan className="mr-2 font-light text-xl">
            {auth.account.firstName}
          </StyledSpan>
          <TieredMenu model={userMenuOptions} popup ref={menuRef} id='userMenu' />
          <button
            style={{backgroundColor: 'rgba(0, 0, 0, 0)', borderWidth: 0}}
            className='p-0 m-0 border-0 shadow-none bg-op cursor-pointer'
            onClick={(event) => menuRef?.current?.toggle(event)}
          >
            <Avatar
              label={auth.account.firstName?.charAt(0)}
              shape='circle'
              size='large'
              className='border-1 border-gray-700 border-solid border-circle'/>
          </button>
        </span>
      </div>
    </>
  );
};

export default UserPanel;