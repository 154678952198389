/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {User}
     * @memberof UserRole
     */
    user?: User;
    /**
     * 
     * @type {Role}
     * @memberof UserRole
     */
    role?: Role;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    roleId?: string | null;
}

/**
 * Check if a given object implements the UserRole interface.
 */
export function instanceOfUserRole(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserRoleFromJSON(json: any): UserRole {
    return UserRoleFromJSONTyped(json, false);
}

export function UserRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
    };
}

export function UserRoleToJSON(value?: UserRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'role': RoleToJSON(value.role),
        'userId': value.userId,
        'roleId': value.roleId,
    };
}

