const Env = {
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
    defaultTimeout: 15000,
  },
  app: {
    name: 'Ursa Logistics',
    timezone: process.env.REACT_APP_TIMEZONE,
  }
};

export default Env;
