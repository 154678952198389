import React, { useEffect } from 'react';
import LogisticsDialog from '../../../components/LogisticsDialog';
import { apiFactory, ModalParameters } from '../../../shared';
import { Button } from 'antd';
import Header from '../../../components/form/Header';
import { RegistrationApi } from '../../../generated-api';

let companyName = '';

function RegistrationConditions(props: ModalParameters<never>) {
  useEffect(() => {
    if (companyName) return;

    apiFactory(RegistrationApi).apiRegistrationCompanyNameGet()
      .then(response => {
        companyName = response;
      });
  }, []);

  return <LogisticsDialog
    onHide={() => props.hide(undefined)}
    visible={props.visible}
  >
    <Header>
      To finish the contract request make sure you have following documents.
    </Header>
    <ul className='text-xl'>
      <li className='mb-3'>Driving license</li>
      <li className='mb-3'>Vehicle registration</li>
      <li className='mb-3'>Voided check</li>
      <li className='mb-3'>Driving record</li>
      <li className='mb-3'>D3 pictures of your truck</li>
      <li className='mb-3'>Insurance certificate</li>
    </ul>
    <Header>Insurance requirements</Header>
    <ul className='text-xl'>
      <li className='mb-3'>$1,000,000 Primary Liability Commercial Auto CSL</li>
      <li className='mb-3'>$100,000 Broad Form Cargo with $1,000 deductible max.</li>
      <li className='mb-3'>Unlimited Radius of Operations</li>
      <li className='mb-3'>Certificates must also show listed Vehicles (Year, Make, VIN#) as well as all listed drivers.</li>
      <li className='mb-3'>{companyName} shall be listed as additional insured and certificate holder</li>
    </ul>
    <Button
      type='primary'
      danger
      className='w-full'
      onClick={() => props.hide(undefined)}
    >
      <i className='pi pi-arrow-left mr-1'/>
      Back
    </Button>
  </LogisticsDialog>
}

export default RegistrationConditions;