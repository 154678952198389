/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoadedOrderAccess
 */
export interface LoadedOrderAccess {
    /**
     * 
     * @type {boolean}
     * @memberof LoadedOrderAccess
     */
    archive?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoadedOrderAccess
     */
    create?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoadedOrderAccess
     */
    read?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoadedOrderAccess
     */
    update?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoadedOrderAccess
     */
    _delete?: boolean | null;
}

/**
 * Check if a given object implements the LoadedOrderAccess interface.
 */
export function instanceOfLoadedOrderAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LoadedOrderAccessFromJSON(json: any): LoadedOrderAccess {
    return LoadedOrderAccessFromJSONTyped(json, false);
}

export function LoadedOrderAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadedOrderAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'archive': !exists(json, 'archive') ? undefined : json['archive'],
        'create': !exists(json, 'create') ? undefined : json['create'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'update': !exists(json, 'update') ? undefined : json['update'],
        '_delete': !exists(json, 'delete') ? undefined : json['delete'],
    };
}

export function LoadedOrderAccessToJSON(value?: LoadedOrderAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'archive': value.archive,
        'create': value.create,
        'read': value.read,
        'update': value.update,
        'delete': value._delete,
    };
}

