
import styled from '@emotion/styled';
import { ResponsiveContainer } from 'recharts';
const padding = 60;

const getCssHeight = (value : number | string | undefined, padding : number) => {
  if (!value) {
    return 'auto';
  }
  const isNumber = !isNaN(parseInt(value.toString()));
  const result = isNumber ? `${(value as number) + padding}px !important` : value;
  return result;
}

const ResponsiveContainerWithPadding = styled(ResponsiveContainer)`
 & .recharts-surface {
  overflow: visible;
 }

 & .recharts-wrapper {
  padding-top: ${padding}px;
  height: ${props => `${getCssHeight(props.height, padding)}`};
 }

 height: ${props => `${getCssHeight(props.height, padding)}`};
`;

export default ResponsiveContainerWithPadding;