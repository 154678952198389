import React, { useMemo } from 'react';
import { Vehicle, VehicleApprovedNotification } from '../../../../generated-api';
import { useModal } from '../../../../hooks';
import VehicleEdit from '../../../vehicles/VehicleEdit';
import { RedCircle } from './RedCircle';
import { LinkLikeSpan } from './LinkLikeSpan';

function VehicleApprovedFormatter(props: { jsonData: string }) {
  const data = useMemo(
    () => JSON.parse(props.jsonData) as VehicleApprovedNotification,
    [props.jsonData]);
  const modal = useModal({ id: data.vehicleId } as Vehicle);

  // Have to recreate VehicleEdit because it does not load data otherwise
  const vehicleEdit = useMemo(() => {
    return modal.visible
      ? <VehicleEdit data={modal.data} loading={false} visible={modal.visible} hide={modal.hide} />
      : <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.visible]);

  return <>
    <RedCircle />
    <span>
      New unit&nbsp;
      <LinkLikeSpan onClick={() => modal.show({ id: data.vehicleId! })}>
        #{data.vehicleId}&nbsp;
      </LinkLikeSpan>
      has been added
    </span>
    {vehicleEdit}
  </>;
}

export default VehicleApprovedFormatter;