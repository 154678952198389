/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeyAndPasswordDto
 */
export interface KeyAndPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof KeyAndPasswordDto
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeyAndPasswordDto
     */
    newPassword?: string | null;
}

/**
 * Check if a given object implements the KeyAndPasswordDto interface.
 */
export function instanceOfKeyAndPasswordDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KeyAndPasswordDtoFromJSON(json: any): KeyAndPasswordDto {
    return KeyAndPasswordDtoFromJSONTyped(json, false);
}

export function KeyAndPasswordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyAndPasswordDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
    };
}

export function KeyAndPasswordDtoToJSON(value?: KeyAndPasswordDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'newPassword': value.newPassword,
    };
}

