import React, { useCallback, useState } from 'react';
import LogisticsDialog from '../../components/LogisticsDialog';
import { GeoApi, Point } from '../../generated-api';
import { apiFactory, ModalParameters } from '../../shared';
import Header from '../../components/form/Header';
import Row from '../../components/form/Row';
import Column from '../../components/form/Column';
import Field from '../../components/form/Field';
import StyledInputText from '../../components/form/StyledInputText';
import ErrorBox from '../../components/form/ErrorBox';
import { FormikErrors, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { showErrorToast, showToast } from '../../components/LogisticsToast';

export interface AddressPickerModalParameters extends ModalParameters<AddressPickerProps> {
}

export interface AddressPickerProps {
  address?: string,
  point?: Point,
}

export const AddressPickerModal = (props: AddressPickerModalParameters) => {
  const [pickUpAt, setPickUpAt] = useState('');
  const validation = useFormik<{ pickUpAt: string}>({
    initialValues: { pickUpAt },
    validate: data => {

      const errors : FormikErrors<{ pickUpAt: string}> = {};
      const blankError = 'This value should not be blank.';
      if (!data.pickUpAt) {
        errors.pickUpAt = blankError;
      }

      return errors;
    },
    onSubmit: () => {}
  });

  const setValue = useCallback((value: string) => {
    validation.setValues({ pickUpAt: value});
    setPickUpAt(value);
  }, [validation]);

  const validate = useCallback(async () => {
    validation.handleSubmit();
    const errors = await validation.validateForm({ pickUpAt });
    if (Object.keys(errors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [pickUpAt, validation]);

  const create = useCallback(async () => {
    try {
      await validate();
    } catch (error: any) {
      showToast({
        severity: 'error',
        summary: 'Fix errors and try again.',
        detail: error.toString()});
      return;
    }

    try {
      const location = await apiFactory(GeoApi).apiGeoGet({address: pickUpAt});
      if (!location) {
        showErrorToast('Could not determine exact location');
        return;
      }

      props.hide({ address: pickUpAt, point: location });
    } catch (e: any) {
      showToast({severity: 'error', summary: 'Error', detail: e.toString()});
      throw e;
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpAt, validate, props.hide]);

  const handleOnHide = useCallback(() => {
    props.hide(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide]);

  return <LogisticsDialog
    visible={props.visible}
    onHide={() => handleOnHide}
    className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-3'
  >
    <div className="ml-4">
      <Header>Create custom load order</Header>  
    </div>
    <main className='grid logistics-dialog-edit-form__content'>
      <Row>
        <Column className='col-12'>
          <Field label='Pick-up at' required>
            <StyledInputText
              value={pickUpAt}
              onChange={e => setValue(e.target.value)}
              autoFocus
            />
            <ErrorBox>{validation.errors.pickUpAt}</ErrorBox>
          </Field>
        </Column>
      </Row>
    </main>
    <footer className='flex justify-content-center w-full gap-3'>
      <Button
        label='CREATE'
        className='p-button-success'
        onClick={create}
      />
      <Button
        label='CLOSE'
        onClick={handleOnHide}
      />
    </footer>
  </LogisticsDialog>
};

export default AddressPickerModal;