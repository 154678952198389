import React, { Dispatch, SetStateAction, useMemo, useRef } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

export interface RowActionButtonProps<TEntity> {
  rowData? : TEntity | null | undefined,
  actionItems? :  RowActionItem<TEntity>[] | undefined,
  setRowData?: Dispatch<SetStateAction<TEntity>>,
}

export interface RowActionItem<TEntity> {
  label: string,
  icon: string,
  command: (
    rowData?: TEntity | null | undefined,
    setRowData?: Dispatch<SetStateAction<TEntity>>
  ) => any,
}

function RowActionButton<TEntity>(props: RowActionButtonProps<TEntity>) {
  const { rowData , actionItems } = props;
  const menuRef = useRef<Menu>(null);

  const actions = useMemo(() => {
    return actionItems?.map(actionItem => {
      return {
        label: actionItem.label,
        icon: actionItem.icon,
        command: () => actionItem.command(rowData),
      } as MenuItem;
    })
  }, [rowData, actionItems]);

  return <div>
    <Button
      icon='pi pi-bars'
      className='p-button-lg p-button-rounded p-button-text p-button-plain'
      onClick={(e) => {
        menuRef?.current?.toggle(e);
      }}
    />
    <Menu model={actions} popup ref={menuRef} key='popup_menu'/>
  </div>
}

export default RowActionButton;