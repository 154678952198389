import React, { useMemo } from 'react';
import { TUsePDFSlickStore } from '@pdfslick/react/dist/esm/usePDFSlick';
import { Toolbar } from 'primereact/toolbar';
import Paging from './Paging';
import Zooming from './Zooming';
import { Divider } from 'antd';
import Fullscreen from './Fullscreen';
import { StoreApi, UseBoundStore } from 'zustand';
import { CustomPdfStore } from '../LogisticsPdf';
import Download from './Download';

type ToolbarProps = {
  usePDFSlickStore: TUsePDFSlickStore,
  useCustomStore:  UseBoundStore<StoreApi<CustomPdfStore>>,
}

function _Toolbar(props: ToolbarProps) {
  const leftMenuItems = useMemo(() => {
    return <>
      <Paging usePDFSlickStore={props.usePDFSlickStore} />
      <Divider type={'vertical'}/>
      <Zooming usePDFSlickStore={props.usePDFSlickStore}  />
      <Divider type={'vertical'}/>
    </>
  }, [props.usePDFSlickStore]);

  const rightMenuItems = useMemo(() => {
    return <>
      <Fullscreen useCustomStore={props.useCustomStore} />
      <Divider type={'vertical'}/>
      <Download usePDFSlickStore={props.usePDFSlickStore} />
    </>
  }, [props.useCustomStore, props.usePDFSlickStore]);

  return (
    <Toolbar left={leftMenuItems} right={rightMenuItems}/>
  );
}

export default _Toolbar;