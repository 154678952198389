import { ColumnProps } from 'primereact/column';
import { Bid } from '../../generated-api/models';
import dayjs from 'dayjs';

function formatDate(rawDate : Date | undefined | null){
  if (!rawDate)
    return '';

  return dayjs(rawDate).tz().format('MMM D, H:mm A');
}

export default function useColumns() : ColumnProps[] {
  const generalProperties = (col: ColumnProps) : ColumnProps => {
    return {
      ...col,
      sortable: false,
      filter: false,
    }
  };
  return [
    {
      field: 'createdDate',
      header: 'Created',
      style: { flex: '1 0 10em' },
      body: (rowData: Bid) => formatDate(rowData.createdDate),
    },
    {
      field: 'loadedOrder.brokerage.name',
      header: 'Brokerage',
      style: { flex: '1 0 10em' },
      body: (rowData: Bid) => rowData.loadedOrder?.brokerage,
    },
    {
      field: 'loadedOrder.pickUpAt',
      header: 'Pick-up at',
      style: { flex: '1 0 10em'},
      body: (rowData: Bid) => rowData.loadedOrder?.pickUpAt,
    },
    {
      field: 'loadedOrder.deliverTo',
      header: 'Deliver to',
      style: { flex: '1 0 10em' },
      body: (rowData: Bid) => rowData.loadedOrder?.deliverTo,
    },
    {
      field: 'vehicle.id',
      header: 'Unit #',
      style: { flex: '1 0 4em' },
      body: (rowData: Bid) => {
        return rowData.vehicleForBid?.vehicleId ?? '';
      }
    },
    {
      field: 'loadedOrder.miles',
      header: 'Miles',
      style: { flex: '1 0 6em' },
      body: (rowData: Bid) => rowData.loadedOrder?.miles,
    },
    {
      field: 'prices',
      header: 'Prices',
      style: { flex: '1 0 8em' },
      body: (rowData: Bid) => `$${rowData.priceForBroker || 0}/${rowData.priceForDrive || 0}`,
    },
    {
      field: 'percent',
      header: 'Percent',
      style: { flex: '1 0 6em' },
      body: (rowData: Bid) => `${rowData.profit}%`,
    },
    {
      field: 'dispatcher',
      header: 'Dispatcher',
      style: { flex: '1 0 10em' },
      body: (rowData: Bid) => rowData.dispatcher ? `${rowData.dispatcher?.firstName} ${rowData.dispatcher?.lastName}` : '',
    }
  ].map(col => generalProperties(col));
}