import { useLoadScript, GoogleMap, MarkerF } from "@react-google-maps/api";
import DashboardPanel from "../components/DashboardPanel";
import { DashboardApi } from '../../../generated-api';
import { useMemo } from "react";
import styled from '@emotion/styled';
import { apiFactory } from "../../../shared";
import WidgetToolbar from "../components/WidgetToolbar";
import useWidget from "../hooks/WidgetHook";
import { today } from '../../../shared/util/dateUtils';
import useConfiguration from "../../../hooks/ConfigurationHook";

const MapDashboardPanel = styled(DashboardPanel)`
  & .map-container {
    height: 455px;
    width: 100%;
  }
`;

const iconBase = 'http://maps.google.com/mapfiles/kml/paddle/';

const red = iconBase + 'red-circle.png';
const blue = iconBase + 'blu-circle.png';

function LoadInformation() {
  const { config } = useConfiguration();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.javaScriptMapsApiKey
  });

  const { data, filters, loadData, setFilters } = useWidget({
    initialFilters: {
      periodType: 'Day',
      from: today().toDate(),
      to: today().toDate(),
    },
    initialWidgetData: {},
    loadWidgetData: filters => apiFactory(DashboardApi).apiDashboardLoadInformationGet(filters),
  });

  const center = useMemo(() => ({ lat: 38, lng: -100 }), []); // USA center

  const Map = () => {
    if (!isLoaded) return <div>Loading...</div>;

    return (
      <GoogleMap zoom={4} center={center} mapContainerClassName="map-container">
        {data.from?.map((point, index) => <MarkerF key={'from' + index} icon={red} position={{lat: point.y, lng: point.x}} />)}
        {data.to?.map((point, index) => <MarkerF key={'to' + index} icon={blue} position={{lat: point.y, lng: point.x}} />)}
      </GoogleMap>
    );
  };

  return (
    <MapDashboardPanel>
    <header className="flex flex-wrap">
      <span className='text-3xl font-medium white-space-nowrap flex justify-content-start flex-grow-1 gap-2'>
        Load information
      </span>
      <div className='flex max-w-full justify-content-end align-items-center flex-grow-1 gap-2'>
        <WidgetToolbar
          value={filters}
          onChange={setFilters}
          onFilter={loadData}
          visibility={{
            period: true,
            from: true,
            to: true,
            filterButton: true,
          }}
        />
      </div>
    </header>
    <Map/>
    </MapDashboardPanel>
  )
}

export default LoadInformation;
