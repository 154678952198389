/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrokerageInformationResponse
 */
export interface BrokerageInformationResponse {
    /**
     * 
     * @type {string}
     * @memberof BrokerageInformationResponse
     */
    brokerage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrokerageInformationResponse
     */
    profit?: number;
    /**
     * 
     * @type {number}
     * @memberof BrokerageInformationResponse
     */
    loads?: number;
}

/**
 * Check if a given object implements the BrokerageInformationResponse interface.
 */
export function instanceOfBrokerageInformationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BrokerageInformationResponseFromJSON(json: any): BrokerageInformationResponse {
    return BrokerageInformationResponseFromJSONTyped(json, false);
}

export function BrokerageInformationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrokerageInformationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brokerage': !exists(json, 'brokerage') ? undefined : json['brokerage'],
        'profit': !exists(json, 'profit') ? undefined : json['profit'],
        'loads': !exists(json, 'loads') ? undefined : json['loads'],
    };
}

export function BrokerageInformationResponseToJSON(value?: BrokerageInformationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brokerage': value.brokerage,
        'profit': value.profit,
        'loads': value.loads,
    };
}

