import StateFilterInput from './StateFilterInput';
import StateFilterModel from './StateFilterModel';
import { FilterService } from 'primereact/api';
import FilterProperties from '../FilterProperties';

const filterFunctionName = 'stateFilter'
export default function useStateFilter() : FilterProperties {
  // Todo видимо сейчас какой-то переходный период в определении фильтров.
  // CUSTOM не работает, и актуальные примеры еще не завезли.
  // https://github.com/primefaces/primereact/issues/3325
  FilterService.register(
    filterFunctionName,
    (cellValue: string, filterValue: StateFilterModel[]) => {
      if (!filterValue || filterValue.length === 0) {
        return true;
      }

      return !!filterValue.find(state => cellValue.match(`, ${state.code}`));
    });
  FilterService.register(
    'custom',
    () => {
      console.error('inappropriate filter function call')
      return true;
    }
  )

  return {
    filterMatchModeOptions: [{ value: filterFunctionName, label: 'State filter'}],
    filterElement: (options) => {
      return <StateFilterInput {...options}></StateFilterInput>;
    },
    // @ts-ignore https://github.com/primefaces/primereact/issues/3325
    filterMatchMode: filterFunctionName,
  }
}
