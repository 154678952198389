import { RootState } from "../../store/store";
import useColumns from "./BidsColumnDef";
import LogisticsDataGrid from '../../components/data-grid/LogisticsDataGrid';
import { getBidsPage } from './BidsSlice';
import { useModal } from '../../hooks';
import React, { useMemo, useState } from 'react';
import { Bid, BidsApi } from '../../generated-api';
import BidEdit from './BidEdit/BidEdit';
import { Button } from 'primereact/button';
import { apiFactory } from '../../shared';
import { showErrorToast, showSuccessToast } from '../../components/LogisticsToast';
import useRights from '../../hooks/RightsHook';
import { ExternalFilterProperties } from '../../components/data-grid/LogisticsDataGridParameters';

const selectState = (state: RootState) => state.bid;

function BidList() {
  const columns = useColumns();
  const bidModal = useModal<Bid>({});
  const [loading, setLoading] = useState(false);
  const rights = useRights(security => security.bid);

  const handleArchiveClick = async () => {
    try {
      setLoading(true);
      await apiFactory(BidsApi).apiBidsArchiveGet();
      showSuccessToast('Completed');
    } catch {
      showErrorToast('Failed to archive bids');
    } finally {
      setLoading(false);
    }
  }

  const adminOperations = useMemo(() => {
    let operations = [] as  ExternalFilterProperties[];

    if (rights.archive) {
      operations.push({
        field: 'buttonArchive',
        filterMatchMode: 'custom',
        filterMatchModeOptions: [],
        filterElement: () => <Button
          label='Archive'
          className='p-button-danger p-button-text'
          key='archive'
          onClick={handleArchiveClick}
        />,
      });
    }

    if (rights.readAll) {
      operations.push({
        field: 'buttonReadAll',
        filterMatchMode: 'custom',
        filterMatchModeOptions: [],
        filterElement: () => <Button
          className='p-button-danger p-button-text'
          label='Read all'
          key='readAll'
          onClick={() => {
            setLoading(true);
            apiFactory(BidsApi).apiBidsMarkAllAsReadGet()
              .finally(() => setLoading(false))
          }}
        />,
      });
    }

    return operations;
  }, [rights.archive, rights.readAll]);

  return <>
    <LogisticsDataGrid
      id='bidsList'
      selectState={selectState}
      getEntitiesPage={getBidsPage}
      columns={columns}
      leftHeaderElements={[]}
      onRowClick={e => bidModal.show(e.data)}
      externalFilters={adminOperations}
      ignoreFiltersReload
      others={{
        selectionMode: 'single',
        multiSortMeta: [{ field: 'createdDate', order: -1 }],
        loading: loading,
        rowClassName(data: Bid) {
          return { 'font-bold': !data.read, 'bg-green-list-row': data.hasNewReplies };
        },
      }}
    />
    <BidEdit
      key={bidModal.data.id}
      hide={bidModal.hide}
      data={bidModal.data}
      loading={false}
      visible={bidModal.visible}
    />
  </>;
}

export default BidList;
