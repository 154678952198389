import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { AppMenuItem } from './Menu';

const StyledLink = styled(Link)`
  &:hover,
  &.active {
    background-color: var(--surface-d);
  }
`
const MenuItem = (props: AppMenuItem & { badge?: ReactNode}) => {
  const resolvedPath = useResolvedPath(props.url);
  const isActive = useMatch({ path: resolvedPath.pathname, end: false });

  return (
    <StyledLink
      to={props.url}
      className={
        (isActive ? 'active ' : '') +
        'flex align-items-end text-xl px-3 py-3 no-underline text-color p-menuitem-active'
      }
    >
      {props.icon}
      <span className='ml-1 p-overlay-badge white-space-nowrap'>
        {props.label?.toUpperCase()}
        {props.badge}
      </span>
    </StyledLink>
  );
};

export default MenuItem;