import {
  ApiVehiclesGetRequest, Driver,
  EDriverOrder,
  EVehicleStatusFilter,
  Vehicle,
  VehicleActions,
} from '../../generated-api';
import React from 'react';
import dayjs from 'dayjs';
import LogisticsColumnProps from '../../components/data-grid/LogisticsColumnProps';
import serverFilterComponent from '../../components/filters/server/ServerFilter';
import ServerInputNumber from '../../components/filters/server/ServerInputNumber';
import EditableDropdown from '../../components/form/EditableDropdown';
import { IdName } from '../../data-moqs/IdName';
import VehicleActions2 from '../../data-moqs/VehicleActions';
import VehicleStatusFilter from '../../data-moqs/VehicleStatusFilter';

const statusSystemToPrintable = {
  Error: {
    text: 'Error',
    class: 'bg-red-400'
  },
  Retried: {
    text: 'Retried',
    class: ''
  },
  OnLoad: {
    text: 'On load',
    class: '',
  },
  UnconfinedService: {
    text: 'Unconfined service',
    class: 'bg-yellow-400',
  },
  InService: {
    text: 'In service',
    class: 'bg-green-300',
  },
  OutOfService: {
    text: 'Out of service',
    class: 'bg-red-400'},
};

export default function useColumns() : LogisticsColumnProps[] {
  return [
    {
      field: 'id',
      header: 'Id',
      style: { maxWidth: '5rem' },
      sortable: true,
      filter: true,
      ...serverFilterComponent(props => <ServerInputNumber {...props} />),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesGetRequest) : ApiVehiclesGetRequest => {
        return { ...serverFilterModel, id: filterMetaData.value };
      },
    },
    {
      field: 'externalId',
      header: 'Sylectus ID',
      sortable: true,
      filter: false,
      hidden: true,
      ...serverFilterComponent(props => <ServerInputNumber {...props} />),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesGetRequest) : ApiVehiclesGetRequest => {
        return { ...serverFilterModel, sylectusId: filterMetaData.value };
      },
    },
    {
      field: 'owner',
      header: 'Owner',
      filter: true,
      sortable: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesGetRequest) : ApiVehiclesGetRequest => {
        return { ...serverFilterModel, owner: filterMetaData.value };
      },
      serverSortFields: [
        'owner.companyName',
      ],
      body: (rowData: Vehicle) => {
        if (!rowData.owner?.companyName) return '';

        return rowData.owner.companyName;
      },
    },
    {
      field: 'drivers',
      header: 'Drivers',
      style: { flexDirection: 'column', alignItems: 'flex-start'},
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesGetRequest) : ApiVehiclesGetRequest => {
        return { ...serverFilterModel, drivers: filterMetaData.value };
      },
      body: (rowData: Vehicle) => {
        if (!rowData.drivers?.length) return <></>;

        const drivers = [
          rowData.drivers.find(d => d.order === EDriverOrder.First),
          rowData.drivers.find(d => d.order === EDriverOrder.Second),
        ].filter(x => x) as Driver[];
        return drivers.map(driver => {
          var driverValue = `${driver.firstName} ${driver.lastName} #${driver.id} ${driver.driverLogin || ''}`;
          return <div key={driver.id} className='w-full'>
            {driverValue}
          </div>
        });
      },
    },
    {
      field: 'vehicleType',
      header: 'Type',
      style: { maxWidth: '10rem' },
      body: (rowData: Vehicle) => rowData.vehicleType?.name,
      sortable: true,
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverSortFields: [
        'vehicleType.name',
      ],
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesGetRequest) : ApiVehiclesGetRequest => {
        return { ...serverFilterModel, type: filterMetaData.value };
      },
    },
    {
      field: 'boxDimensions',
      header: 'Size',
      style: { maxWidth: '7rem'},
      filter: true,
      ...serverFilterComponent(props => <ServerInputNumber {...props} />),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesGetRequest) : ApiVehiclesGetRequest => {
        return { ...serverFilterModel, vehicleSize: filterMetaData.value };
      },
      body: (rowData: Vehicle) => {
        const dimensions = rowData.boxDimensions;
        if (!dimensions) return '';

        return dimensions.join('x');
      }
    },
    {
      field: 'status',
      header: 'Status',
      style: { maxWidth: '12rem' },
      filter: true,
      ...serverFilterComponent(props => <EditableDropdown
        options={VehicleStatusFilter}
        optionLabel='name'
        renderOption={option => option.name}
        renderSelected={selected => selected?.name}
        value={props.value as IdName<string>}
        onChange={newValue => props.filterApplyCallback(newValue)}
        renderEmptyOption={() => 'All'}
        renderEmptySelected={() => 'All'}
      />),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesGetRequest) : ApiVehiclesGetRequest => {
        return { ...serverFilterModel, status: (filterMetaData.value as IdName<EVehicleStatusFilter>).id };
      },
      body: (rowData: Vehicle) => {
        // @ts-ignore
        const printable = statusSystemToPrintable[rowData.status];
        return <div
          style={{display: 'flex', alignItems: 'center', width: '100%', height: '100%'}}
          className={printable?.class}
        >
          <span style={{ padding: '0.5rem' }}>
            {(printable?.text as string)?.toUpperCase()}
          </span>
        </div>
        ;
      },
      sortable: true,
      className: 'colored',
    },
    {
      field: 'available',
      header: 'Available',
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesGetRequest) : ApiVehiclesGetRequest => {
        return { ...serverFilterModel, available: filterMetaData.value };
      },
      body: (rowData: Vehicle) => {
        const availableDate = rowData.availableDate
          ? dayjs.tz(rowData.availableDate).format('MMM DD YYYY hh:mm A')
          : '';
        return <>
          {rowData.availableCity}
          <br/>
          {availableDate}
        </>
      },
      sortable: true,
      serverSortFields: ['availableCity', 'availableDate'],
    },
    {
      field: 'dispatcher',
      header: 'Dispatcher',
      filter: true,
      ...serverFilterComponent(),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesGetRequest) : ApiVehiclesGetRequest => {
        return { ...serverFilterModel, dispatcher: filterMetaData.value };
      },
      body: (rowData: Vehicle) => {
        if (rowData.dispatcher?.firstName && rowData.dispatcher?.lastName) {
          return `${rowData.dispatcher.firstName} ${rowData.dispatcher.lastName}`;
        }
        return '';
      },
      sortable: true,
      serverSortFields: ['dispatcher.firstName', 'dispatcher.lastName'],
    },
    {
      field: 'actions',
      header: 'Actions',
      style: { maxWidth: '10rem' },
      filter: true,
      ...serverFilterComponent(props => <EditableDropdown
        options={VehicleActions2}
        optionLabel='name'
        renderOption={option => option.name}
        renderSelected={selected => selected?.name}
        value={props.value as IdName<string>}
        onChange={newValue => props.filterApplyCallback(newValue)}
        renderEmptyOption={() => 'All'}
        renderEmptySelected={() => 'All'}
      />),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesGetRequest) : ApiVehiclesGetRequest => {
        return { ...serverFilterModel, actions: (filterMetaData.value as IdName<VehicleActions>).id };
      },
    },
  ];
}
