const colorPalette = [
  "#02b3a8",
  "#00e5d7",
  "#007de5",
  "#00aee5",
  "#feb259",
  "#fee459",
  "#ff4c41",
];

export default colorPalette;