import React from 'react';

function Dots() {
  return (
    <span
      className='text-color-secondary opacity-60 font-bold p-2'
      style={{ userSelect: "none"}}
    >
      ...
    </span>
  );
}

export default Dots;