/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EUserStatus } from './EUserStatus';
import {
    EUserStatusFromJSON,
    EUserStatusFromJSONTyped,
    EUserStatusToJSON,
} from './EUserStatus';

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    activated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    langKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {Set<string>}
     * @memberof UserDto
     */
    authorities?: Set<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    lastLoginDate?: Date | null;
    /**
     * 
     * @type {EUserStatus}
     * @memberof UserDto
     */
    status?: EUserStatus;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    adminPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    roleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    refLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    mobileSettings?: string | null;
}

/**
 * Check if a given object implements the UserDto interface.
 */
export function instanceOfUserDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "login" in value;

    return isInstance;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'login': json['login'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'activated': !exists(json, 'activated') ? undefined : json['activated'],
        'langKey': !exists(json, 'langKey') ? undefined : json['langKey'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'authorities': !exists(json, 'authorities') ? undefined : json['authorities'],
        'lastLoginDate': !exists(json, 'lastLoginDate') ? undefined : (json['lastLoginDate'] === null ? null : new Date(json['lastLoginDate'])),
        'status': !exists(json, 'status') ? undefined : EUserStatusFromJSON(json['status']),
        'adminPassword': !exists(json, 'adminPassword') ? undefined : json['adminPassword'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'refLink': !exists(json, 'refLink') ? undefined : json['refLink'],
        'mobileSettings': !exists(json, 'mobileSettings') ? undefined : json['mobileSettings'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'login': value.login,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'imageUrl': value.imageUrl,
        'activated': value.activated,
        'langKey': value.langKey,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'authorities': value.authorities,
        'lastLoginDate': value.lastLoginDate === undefined ? undefined : (value.lastLoginDate === null ? null : value.lastLoginDate.toISOString()),
        'status': EUserStatusToJSON(value.status),
        'adminPassword': value.adminPassword,
        'roleName': value.roleName,
        'refLink': value.refLink,
        'mobileSettings': value.mobileSettings,
    };
}

