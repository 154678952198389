import React, { SetStateAction, useCallback, useMemo } from 'react';
import { EStatusHR, EVehicleStatus, Vehicle, Request, VehiclesApi, RequestApi } from '../../../../generated-api';
import LogisticsDataGridBase from '../../../../components/data-grid/LogisticsDataGridBase';
import requestUnitListColumnDef from './RequestUnitListColumnDef';
import RowActionButton, { RowActionItem } from '../../../../components/data-grid/actions/RowActionButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from 'primereact/button';
import { useModal } from '../../../../hooks';
import UnitEdit from './UnitEdit';
import { apiFactory } from '../../../../shared';
import { logisticsConfirmDialog } from '../../../../components/LogisticsConfifmDialog';
import useRights from '../../../../hooks/RightsHook';
import { showErrorToast } from '../../../../components/LogisticsToast';

type RequestUnitListProps = {
  vehicles?: Vehicle[] | null | undefined,
  setVehicle: (vehicle?: Vehicle, forDeletion?: boolean) => any,
  request: Request,
  setRequest?: (action: SetStateAction<Request>, validate?: boolean) => void
}

function RequestUnitList(props: RequestUnitListProps) {
  const unitModal = useModal<Vehicle>({});
  const rights = useRights(security => security.request);

  const updateUnitStatus = useCallback(
    (setRowData: (vehicle?: Vehicle, forDeletion?: boolean) => any, unit: Vehicle, statusHr?: EStatusHR, status?: EVehicleStatus) => {
      logisticsConfirmDialog({
        message: 'Do you want to change the status of a unit',
        closable: false,
        accept() {
          apiFactory(VehiclesApi).apiVehiclesUnitStatusPut({ id: unit.id, statusHr: statusHr, status: status})
            .then((updatedVehicle) => {
              setRowData({ ...unit, statusHR: updatedVehicle.statusHR, status: updatedVehicle.status});
              if (props.setRequest && props.request.id) {
                apiFactory(RequestApi).apiRequestsIdGet({id: props.request.id}).then(updatedRequest => {
                  props.setRequest!(old => {
                    return {
                      ...old,
                      ringDate: updatedRequest.ringDate,
                      nextRingDate: updatedRequest.nextRingDate
                    }
                  });
                })
              }
            });
        },
        reject() {
        }
      });
    },
    [props.setRequest, props.request],
  );

  const actionItems = useMemo(() => {
    let actions : RowActionItem<Vehicle>[] = [
      {
        label: 'Ready for approval',
        icon: '',
        command: vehicle => {
          if (!vehicle) return;
          updateUnitStatus(props.setVehicle, vehicle, EStatusHR.Ready);
        },
      },
      {
        label: 'Reject',
        icon: '',
        command: vehicle => {
          if (!vehicle) return;
          updateUnitStatus(props.setVehicle, vehicle, EStatusHR.Rejected, EVehicleStatus.Retried);
        },
      },
    ];
  
    const approveAction : RowActionItem<Vehicle> = {
      label: 'Approve',
      icon: '',
      command: vehicle => {
        if (!vehicle) return;
        updateUnitStatus(props.setVehicle, vehicle, EStatusHR.Approved, EVehicleStatus.UnconfinedService);
      },
    };

    const deleteAction : RowActionItem<Vehicle> = {
      label: 'Delete',
      icon: '',
      command: vehicle => {
        if (!vehicle) return;
        deleteUnit(props.setVehicle, vehicle);
      },
    };

    if (rights.actions?.approve) {
      actions.push(approveAction);
    }

    if (rights.actions?.deleteUnit) {
      actions.push(deleteAction);
    }
    return actions;
  }, [rights, props.setVehicle, updateUnitStatus]);

  const columns = useMemo(() => {
    return requestUnitListColumnDef.concat({
      field: 'actions',
      header: 'Actions',
      style: { flex: 1 },
      sortable: false,
      body: (rowData: Vehicle) => <RowActionButton
        rowData={rowData}
        actionItems={actionItems}
      />,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionItems]);

  const addUnitButton = <Button
    disabled={!props.request?.id || !rights.update}
    className='p-button-success px-5 ml-2 text-base font-bold'
    onClick={() => unitModal.show({
      request: props.request,
      owner: props.request.owner,
    })}
  >
    <p className='my-0'>Add unit</p>
    <AddCircleOutlineIcon fontSize='medium' className='ml-1'/>
  </Button>;

  const unitEdit = unitModal.visible
    ? <UnitEdit
      data={unitModal.data}
      loading={false}
      visible={unitModal.visible}
      hide={vehicle => {
        if (!vehicle) return unitModal.hide();

        props.setVehicle(vehicle);
        unitModal.hide();
      }}
    />
    : <></>;

  return <>
    <LogisticsDataGridBase
      columns={columns}
      entities={props.vehicles}
      fullSize={false}
      rightHeaderElements={[
        addUnitButton,
      ]}
      onRowClick={e => unitModal.show(e.data)}
    />
    {unitEdit}
  </>
}

function deleteUnit(setRowData: (vehicle?: Vehicle, forDeletion?: boolean) => any, unit: Vehicle) {
  logisticsConfirmDialog({
    message: 'Are you sure?',
    closable: false,
    rejectLabel: 'Cancel',
    acceptLabel: 'Delete',
    accept() {
      apiFactory(VehiclesApi).apiVehiclesUnitIdDelete({id: unit.id!})
      .then(test => {
        setRowData(unit, true);
      })
      .catch(reason => {
        showErrorToast('Error: It is impossible to delete the unit because there are other records linked to it.');
        console.error('Error on deleting unit:', reason);
      })
    },
    reject() {
    }
  });
}


export default RequestUnitList;