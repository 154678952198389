import React from 'react';
import { TUsePDFSlickStore } from '@pdfslick/react/dist/esm/usePDFSlick';
import { MdDownload } from 'react-icons/md';
import { Button } from 'primereact/button';

type DownloadProps = {
  usePDFSlickStore: TUsePDFSlickStore,
}


function Download(props: DownloadProps) {
  const slick = props.usePDFSlickStore(s => s.pdfSlick);

  return <Button
    className='p-button-text p-button-secondary px-1'
    onClick={() => slick?.download()}
  >
    <MdDownload/>
  </Button>
}

export default Download;