/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Driver } from './Driver';
import {
    DriverFromJSON,
    DriverFromJSONTyped,
    DriverToJSON,
} from './Driver';
import type { DriverBid } from './DriverBid';
import {
    DriverBidFromJSON,
    DriverBidFromJSONTyped,
    DriverBidToJSON,
} from './DriverBid';
import type { EDockSize } from './EDockSize';
import {
    EDockSizeFromJSON,
    EDockSizeFromJSONTyped,
    EDockSizeToJSON,
} from './EDockSize';
import type { EEquipment } from './EEquipment';
import {
    EEquipmentFromJSON,
    EEquipmentFromJSONTyped,
    EEquipmentToJSON,
} from './EEquipment';
import type { EStatusHR } from './EStatusHR';
import {
    EStatusHRFromJSON,
    EStatusHRFromJSONTyped,
    EStatusHRToJSON,
} from './EStatusHR';
import type { EVehicleStatus } from './EVehicleStatus';
import {
    EVehicleStatusFromJSON,
    EVehicleStatusFromJSONTyped,
    EVehicleStatusToJSON,
} from './EVehicleStatus';
import type { LogisticsFile } from './LogisticsFile';
import {
    LogisticsFileFromJSON,
    LogisticsFileFromJSONTyped,
    LogisticsFileToJSON,
} from './LogisticsFile';
import type { Owner } from './Owner';
import {
    OwnerFromJSON,
    OwnerFromJSONTyped,
    OwnerToJSON,
} from './Owner';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';
import type { Request } from './Request';
import {
    RequestFromJSON,
    RequestFromJSONTyped,
    RequestToJSON,
} from './Request';
import type { State } from './State';
import {
    StateFromJSON,
    StateFromJSONTyped,
    StateToJSON,
} from './State';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { VehicleForBid } from './VehicleForBid';
import {
    VehicleForBidFromJSON,
    VehicleForBidFromJSONTyped,
    VehicleForBidToJSON,
} from './VehicleForBid';
import type { VehicleReservation } from './VehicleReservation';
import {
    VehicleReservationFromJSON,
    VehicleReservationFromJSONTyped,
    VehicleReservationToJSON,
} from './VehicleReservation';
import type { VehicleType } from './VehicleType';
import {
    VehicleTypeFromJSON,
    VehicleTypeFromJSONTyped,
    VehicleTypeToJSON,
} from './VehicleType';

/**
 * 
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    externalId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    payload?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Vehicle
     */
    boxDimensions?: Array<number> | null;
    /**
     * 
     * @type {EVehicleStatus}
     * @memberof Vehicle
     */
    status?: EVehicleStatus;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    availableCity?: string | null;
    /**
     * 
     * @type {Point}
     * @memberof Vehicle
     */
    availableGeo?: Point;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    availableDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    vehicleMake?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    vehicleModel?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    vehicleYear?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    vin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    licensePlate?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    licenseExpiryDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    insuranceExpires?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    approveDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    note?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Vehicle
     */
    updatable?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Vehicle
     */
    temporary?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Vehicle
     */
    favorite?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Vehicle
     */
    active?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    widthDoor?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    heightDoor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    gvw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    mc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    insuranceCompany?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    policyNo?: string | null;
    /**
     * 
     * @type {Array<EEquipment>}
     * @memberof Vehicle
     */
    equipment?: Array<EEquipment> | null;
    /**
     * 
     * @type {EDockSize}
     * @memberof Vehicle
     */
    dockSize?: EDockSize;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Vehicle
     */
    insuranceFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Vehicle
     */
    vehRegFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Vehicle
     */
    mcFail?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Vehicle
     */
    photoLS?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Vehicle
     */
    photoRS?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Vehicle
     */
    photoCargoSpace?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Vehicle
     */
    extraAttachFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {EStatusHR}
     * @memberof Vehicle
     */
    statusHR?: EStatusHR;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    previousCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Vehicle
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    vehicleTypeId?: number | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof Vehicle
     */
    vehicleType?: VehicleType;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    licenceStateId?: number | null;
    /**
     * 
     * @type {State}
     * @memberof Vehicle
     */
    licenceState?: State;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    ownerId?: number | null;
    /**
     * 
     * @type {Owner}
     * @memberof Vehicle
     */
    owner?: Owner;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    dispatcherId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof Vehicle
     */
    dispatcher?: User;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    requestId?: number | null;
    /**
     * 
     * @type {Request}
     * @memberof Vehicle
     */
    request?: Request;
    /**
     * 
     * @type {Array<Driver>}
     * @memberof Vehicle
     */
    drivers?: Array<Driver> | null;
    /**
     * 
     * @type {Array<DriverBid>}
     * @memberof Vehicle
     */
    driverBids?: Array<DriverBid> | null;
    /**
     * 
     * @type {Array<VehicleForBid>}
     * @memberof Vehicle
     */
    vehiclesForBid?: Array<VehicleForBid> | null;
    /**
     * 
     * @type {VehicleReservation}
     * @memberof Vehicle
     */
    reservation?: VehicleReservation;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    id?: number;
}

/**
 * Check if a given object implements the Vehicle interface.
 */
export function instanceOfVehicle(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleFromJSON(json: any): Vehicle {
    return VehicleFromJSONTyped(json, false);
}

export function VehicleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vehicle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'payload': !exists(json, 'payload') ? undefined : json['payload'],
        'boxDimensions': !exists(json, 'boxDimensions') ? undefined : json['boxDimensions'],
        'status': !exists(json, 'status') ? undefined : EVehicleStatusFromJSON(json['status']),
        'availableCity': !exists(json, 'availableCity') ? undefined : json['availableCity'],
        'availableGeo': !exists(json, 'availableGeo') ? undefined : PointFromJSON(json['availableGeo']),
        'availableDate': !exists(json, 'availableDate') ? undefined : (json['availableDate'] === null ? null : new Date(json['availableDate'])),
        'vehicleMake': !exists(json, 'vehicleMake') ? undefined : json['vehicleMake'],
        'vehicleModel': !exists(json, 'vehicleModel') ? undefined : json['vehicleModel'],
        'vehicleYear': !exists(json, 'vehicleYear') ? undefined : json['vehicleYear'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'licensePlate': !exists(json, 'licensePlate') ? undefined : json['licensePlate'],
        'licenseExpiryDate': !exists(json, 'licenseExpiryDate') ? undefined : (json['licenseExpiryDate'] === null ? null : new Date(json['licenseExpiryDate'])),
        'insuranceExpires': !exists(json, 'insuranceExpires') ? undefined : (json['insuranceExpires'] === null ? null : new Date(json['insuranceExpires'])),
        'approveDate': !exists(json, 'approveDate') ? undefined : (json['approveDate'] === null ? null : new Date(json['approveDate'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'updatable': !exists(json, 'updatable') ? undefined : json['updatable'],
        'temporary': !exists(json, 'temporary') ? undefined : json['temporary'],
        'favorite': !exists(json, 'favorite') ? undefined : json['favorite'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'widthDoor': !exists(json, 'widthDoor') ? undefined : json['widthDoor'],
        'heightDoor': !exists(json, 'heightDoor') ? undefined : json['heightDoor'],
        'gvw': !exists(json, 'gvw') ? undefined : json['gvw'],
        'mc': !exists(json, 'mc') ? undefined : json['mc'],
        'insuranceCompany': !exists(json, 'insuranceCompany') ? undefined : json['insuranceCompany'],
        'policyNo': !exists(json, 'policyNo') ? undefined : json['policyNo'],
        'equipment': !exists(json, 'equipment') ? undefined : (json['equipment'] === null ? null : (json['equipment'] as Array<any>).map(EEquipmentFromJSON)),
        'dockSize': !exists(json, 'dockSize') ? undefined : EDockSizeFromJSON(json['dockSize']),
        'insuranceFile': !exists(json, 'insuranceFile') ? undefined : (json['insuranceFile'] === null ? null : (json['insuranceFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'vehRegFile': !exists(json, 'vehRegFile') ? undefined : (json['vehRegFile'] === null ? null : (json['vehRegFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'mcFail': !exists(json, 'mcFail') ? undefined : (json['mcFail'] === null ? null : (json['mcFail'] as Array<any>).map(LogisticsFileFromJSON)),
        'photoLS': !exists(json, 'photoLS') ? undefined : (json['photoLS'] === null ? null : (json['photoLS'] as Array<any>).map(LogisticsFileFromJSON)),
        'photoRS': !exists(json, 'photoRS') ? undefined : (json['photoRS'] === null ? null : (json['photoRS'] as Array<any>).map(LogisticsFileFromJSON)),
        'photoCargoSpace': !exists(json, 'photoCargoSpace') ? undefined : (json['photoCargoSpace'] === null ? null : (json['photoCargoSpace'] as Array<any>).map(LogisticsFileFromJSON)),
        'extraAttachFile': !exists(json, 'extraAttachFile') ? undefined : (json['extraAttachFile'] === null ? null : (json['extraAttachFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'statusHR': !exists(json, 'statusHR') ? undefined : EStatusHRFromJSON(json['statusHR']),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'previousCity': !exists(json, 'previousCity') ? undefined : json['previousCity'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'vehicleTypeId': !exists(json, 'vehicleTypeId') ? undefined : json['vehicleTypeId'],
        'vehicleType': !exists(json, 'vehicleType') ? undefined : VehicleTypeFromJSON(json['vehicleType']),
        'licenceStateId': !exists(json, 'licenceStateId') ? undefined : json['licenceStateId'],
        'licenceState': !exists(json, 'licenceState') ? undefined : StateFromJSON(json['licenceState']),
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'owner': !exists(json, 'owner') ? undefined : OwnerFromJSON(json['owner']),
        'dispatcherId': !exists(json, 'dispatcherId') ? undefined : json['dispatcherId'],
        'dispatcher': !exists(json, 'dispatcher') ? undefined : UserFromJSON(json['dispatcher']),
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'request': !exists(json, 'request') ? undefined : RequestFromJSON(json['request']),
        'drivers': !exists(json, 'drivers') ? undefined : (json['drivers'] === null ? null : (json['drivers'] as Array<any>).map(DriverFromJSON)),
        'driverBids': !exists(json, 'driverBids') ? undefined : (json['driverBids'] === null ? null : (json['driverBids'] as Array<any>).map(DriverBidFromJSON)),
        'vehiclesForBid': !exists(json, 'vehiclesForBid') ? undefined : (json['vehiclesForBid'] === null ? null : (json['vehiclesForBid'] as Array<any>).map(VehicleForBidFromJSON)),
        'reservation': !exists(json, 'reservation') ? undefined : VehicleReservationFromJSON(json['reservation']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function VehicleToJSON(value?: Vehicle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
        'payload': value.payload,
        'boxDimensions': value.boxDimensions,
        'status': EVehicleStatusToJSON(value.status),
        'availableCity': value.availableCity,
        'availableGeo': PointToJSON(value.availableGeo),
        'availableDate': value.availableDate === undefined ? undefined : (value.availableDate === null ? null : value.availableDate.toISOString()),
        'vehicleMake': value.vehicleMake,
        'vehicleModel': value.vehicleModel,
        'vehicleYear': value.vehicleYear,
        'vin': value.vin,
        'licensePlate': value.licensePlate,
        'licenseExpiryDate': value.licenseExpiryDate === undefined ? undefined : (value.licenseExpiryDate === null ? null : value.licenseExpiryDate.toISOString().substr(0,10)),
        'insuranceExpires': value.insuranceExpires === undefined ? undefined : (value.insuranceExpires === null ? null : value.insuranceExpires.toISOString().substr(0,10)),
        'approveDate': value.approveDate === undefined ? undefined : (value.approveDate === null ? null : value.approveDate.toISOString().substr(0,10)),
        'note': value.note,
        'updatable': value.updatable,
        'temporary': value.temporary,
        'favorite': value.favorite,
        'active': value.active,
        'widthDoor': value.widthDoor,
        'heightDoor': value.heightDoor,
        'gvw': value.gvw,
        'mc': value.mc,
        'insuranceCompany': value.insuranceCompany,
        'policyNo': value.policyNo,
        'equipment': value.equipment === undefined ? undefined : (value.equipment === null ? null : (value.equipment as Array<any>).map(EEquipmentToJSON)),
        'dockSize': EDockSizeToJSON(value.dockSize),
        'insuranceFile': value.insuranceFile === undefined ? undefined : (value.insuranceFile === null ? null : (value.insuranceFile as Array<any>).map(LogisticsFileToJSON)),
        'vehRegFile': value.vehRegFile === undefined ? undefined : (value.vehRegFile === null ? null : (value.vehRegFile as Array<any>).map(LogisticsFileToJSON)),
        'mcFail': value.mcFail === undefined ? undefined : (value.mcFail === null ? null : (value.mcFail as Array<any>).map(LogisticsFileToJSON)),
        'photoLS': value.photoLS === undefined ? undefined : (value.photoLS === null ? null : (value.photoLS as Array<any>).map(LogisticsFileToJSON)),
        'photoRS': value.photoRS === undefined ? undefined : (value.photoRS === null ? null : (value.photoRS as Array<any>).map(LogisticsFileToJSON)),
        'photoCargoSpace': value.photoCargoSpace === undefined ? undefined : (value.photoCargoSpace === null ? null : (value.photoCargoSpace as Array<any>).map(LogisticsFileToJSON)),
        'extraAttachFile': value.extraAttachFile === undefined ? undefined : (value.extraAttachFile === null ? null : (value.extraAttachFile as Array<any>).map(LogisticsFileToJSON)),
        'statusHR': EStatusHRToJSON(value.statusHR),
        'comment': value.comment,
        'previousCity': value.previousCity,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'vehicleTypeId': value.vehicleTypeId,
        'vehicleType': VehicleTypeToJSON(value.vehicleType),
        'licenceStateId': value.licenceStateId,
        'licenceState': StateToJSON(value.licenceState),
        'ownerId': value.ownerId,
        'owner': OwnerToJSON(value.owner),
        'dispatcherId': value.dispatcherId,
        'dispatcher': UserToJSON(value.dispatcher),
        'requestId': value.requestId,
        'request': RequestToJSON(value.request),
        'drivers': value.drivers === undefined ? undefined : (value.drivers === null ? null : (value.drivers as Array<any>).map(DriverToJSON)),
        'driverBids': value.driverBids === undefined ? undefined : (value.driverBids === null ? null : (value.driverBids as Array<any>).map(DriverBidToJSON)),
        'vehiclesForBid': value.vehiclesForBid === undefined ? undefined : (value.vehiclesForBid === null ? null : (value.vehiclesForBid as Array<any>).map(VehicleForBidToJSON)),
        'reservation': VehicleReservationToJSON(value.reservation),
        'id': value.id,
    };
}

