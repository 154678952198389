import { useEffect } from 'react';
import { TUsePDFSlickStore } from '@pdfslick/react/dist/esm/usePDFSlick';

export default function useKeyZoom(usePDFSlickStore:  TUsePDFSlickStore) {
  const pdfSlick = usePDFSlickStore(s => s.pdfSlick);

  useEffect(() => {
    const increaseScale = (e: Event) => {
      e.preventDefault();
      pdfSlick?.viewer.increaseScale();
    }
    const decreaseScale = (e: Event) => {
      e.preventDefault();
      pdfSlick?.viewer.decreaseScale();
    }
    const keyListener = (e: KeyboardEvent) => {
      if (!e.ctrlKey) return;

      // numpad
      if (e.location === 3) {
        if (e.key === "+") {
          increaseScale(e);
        } else if (e.key === "-") {
          decreaseScale(e);
        }
        // normal keyboard
      } else if (e.location === 0) {
        if(["+", "="].find(x => x === e.key)) {
          increaseScale(e);
        } else if (["-", "_"].find(x => x === e.key)) {
          decreaseScale(e);
        }
      }
    }

    const wheelListener = (e: WheelEvent) => {
      if (!e.ctrlKey) return;

      // scroll down
      if (e.deltaY > 0) {
        decreaseScale(e);
        // scroll up
      } else if (e.deltaY < 0) {
        increaseScale(e);
      }
    }

    document.addEventListener('keydown', keyListener);
    // Not working without "passive: false"
    document.addEventListener('wheel', wheelListener, { passive: false });

    return () => {
      document.removeEventListener('keydown', keyListener);
      document.removeEventListener('wheel', wheelListener);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfSlick?.viewer?.increaseScale, pdfSlick?.viewer?.decreaseScale]);
}