import { Audit, FieldChange } from '../generated-api';
import { Fragment } from 'react';
import dayjs from 'dayjs';
import { IdName } from '../data-moqs/IdName';

export interface ChangelogBlockProps {
    changelog?: Audit[] | null;
    formCaptions: { [name: string]: string }
    enums?: { name: string, enum: IdName<any>[] }[]
}

const getChangelogEntryText = (changelogEntry: Audit) => {
    return `${dayjs(changelogEntry?.operationTime).tz().format('MM/DD/YYYY HH:mm:ss')} changed by ${changelogEntry?.userName}`
};

const getFieldChangeText = (fieldChange: FieldChange, props: ChangelogBlockProps) => {
    var name = props.formCaptions[fieldChange.name || ''];
    var from = fieldChange?.from;
    var to = fieldChange?.to;

    if (fieldChange._enum) {
        const _enum = props.enums?.find((x) => x.name === fieldChange._enum);
        from = _enum?.enum.find(x => x.id.toString() === from)?.name;
        to = _enum?.enum.find(x => x.id.toString() === to)?.name;
    }

    return `${name || fieldChange.name} changed from ${from || fieldChange.from || 'null'} to ${to || fieldChange.to || 'null'}`
};

const ChangelogBlock = (props: ChangelogBlockProps) => {
    if (!props.changelog || !Array.isArray(props.changelog) || props.changelog.length === 0) {
        return <div>No changes</div>
    }

    return <Fragment>
        <div className='flex flex-column max-h-30rem overflow-auto'>
            {props.changelog?.map((changelogEntry, changelogEntryIndex) =>
                <div key={changelogEntryIndex}>
                    <div className='font-bold'>{getChangelogEntryText(changelogEntry)}</div>
                    {changelogEntry?.serializedFields?.map((fieldChange, fieldChangeIndex) => {
                        return <div key={fieldChangeIndex}>{getFieldChangeText(fieldChange, props)}</div>
                    })}
                    <br />
                </div>)}
        </div>
    </Fragment>
};

export default ChangelogBlock;