/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LoadedOrder } from './LoadedOrder';
import {
    LoadedOrderFromJSON,
    LoadedOrderFromJSONTyped,
    LoadedOrderToJSON,
} from './LoadedOrder';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {Vehicle}
     * @memberof Offer
     */
    vehicle?: Vehicle;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    vehicleId?: number | null;
    /**
     * 
     * @type {LoadedOrder}
     * @memberof Offer
     */
    loadedOrder?: LoadedOrder;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    loadedOrderId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    vehicleFlyMiles?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    orderFlyMiles?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    vehicleGroundMiles?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    orderGroundMiles?: number | null;
}

/**
 * Check if a given object implements the Offer interface.
 */
export function instanceOfOffer(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OfferFromJSON(json: any): Offer {
    return OfferFromJSONTyped(json, false);
}

export function OfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): Offer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleFromJSON(json['vehicle']),
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'loadedOrder': !exists(json, 'loadedOrder') ? undefined : LoadedOrderFromJSON(json['loadedOrder']),
        'loadedOrderId': !exists(json, 'loadedOrderId') ? undefined : json['loadedOrderId'],
        'vehicleFlyMiles': !exists(json, 'vehicleFlyMiles') ? undefined : json['vehicleFlyMiles'],
        'orderFlyMiles': !exists(json, 'orderFlyMiles') ? undefined : json['orderFlyMiles'],
        'vehicleGroundMiles': !exists(json, 'vehicleGroundMiles') ? undefined : json['vehicleGroundMiles'],
        'orderGroundMiles': !exists(json, 'orderGroundMiles') ? undefined : json['orderGroundMiles'],
    };
}

export function OfferToJSON(value?: Offer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicle': VehicleToJSON(value.vehicle),
        'vehicleId': value.vehicleId,
        'loadedOrder': LoadedOrderToJSON(value.loadedOrder),
        'loadedOrderId': value.loadedOrderId,
        'vehicleFlyMiles': value.vehicleFlyMiles,
        'orderFlyMiles': value.orderFlyMiles,
        'vehicleGroundMiles': value.vehicleGroundMiles,
        'orderGroundMiles': value.orderGroundMiles,
    };
}

