import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { AnyFunction } from '../shared/interfaces/AnyFunction';

const useDebounce = <TArg, TResult>(callback: AnyFunction<TArg, TResult>, wait: number = 500) => {
  const ref = useRef<AnyFunction<TArg, TResult>>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo((args?: any) => {
    const func = () => {
      ref.current?.(args);
    };

    return debounce(func, wait);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return debouncedCallback;
};

export default useDebounce;