import React, { useEffect } from 'react';
import { MdNotifications } from 'react-icons/md';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import styled from '@emotion/styled';
import NotificationsModal from './NotificationsModal';
import { useAppDispatch, useAppSelector, useModal } from '../../../hooks';
import { updateStoredNotificationsCount } from '../NotificationsSlice';
import { apiFactory } from '../../../shared';
import { NotificationsApi } from '../../../generated-api';

const StyledMdNotification = styled(MdNotifications)`
& {
  width: 2rem !important;
  height: 2rem !important;
}
`

function StoredNotifications() {
  const notificationsCount = useAppSelector(state => state.notification.storedNotificationsCount);
  const badgeClass = notificationsCount ? 'opacity-80' : 'opacity-0';
  const notificationsModal = useModal(undefined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    apiFactory(NotificationsApi)
      .apiNotificationsCountGet()
      // Todo: find out why generated api converts integer response to string.
      .then(count => dispatch(updateStoredNotificationsCount(() => parseInt(count as any))))
      .catch(error => console.error('Error on loading stored notifications count', error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <Button
      className='p-button-text text-color px-3'
      onClick={() => notificationsModal.show(undefined)}>
      <span className='p-overlay-badge'>
        <StyledMdNotification />
        <Badge
          value={notificationsCount}
          severity='danger'
          className={badgeClass}
        />
      </span>
    </Button>
    <NotificationsModal
      data={notificationsModal.data}
      visible={notificationsModal.visible}
      hide={notificationsModal.hide}
      loading={false}
    />
  </>
}

export default StoredNotifications;