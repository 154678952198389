import {
  Access,
  RightsApi,
} from '../../generated-api';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiFactory } from '../../shared';

export type SecurityState = Access & {
  loaded: boolean;
};

const initialState: SecurityState  = {
  loaded: false,
};

export const setRights = createAction(
  'security/setRights',
  (data: SecurityState) => ({payload: data}),
);

export const resetRights = createAction(
  'security/resetRights',
)

export const loadRights = createAsyncThunk(
  'security/loadRights',
  async () => {
    const rights = await apiFactory(RightsApi).apiRightsGet();
    return { payload: rights };
});

export const SecuritySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setRights, (state, action) => {
        return { ...state, ...action.payload };
      })
      .addCase(resetRights, () => {
        return initialState;
      })
      .addCase(loadRights.fulfilled, (state, action) => {
        return { ...state, ...action.payload.payload, loaded: true };
      })
      // Processing of pending case moved to useRights hook.
  }
});

export default SecuritySlice.reducer;