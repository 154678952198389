import { createAction, createSlice } from '@reduxjs/toolkit';
import { HubConnection } from '@microsoft/signalr';

type NotificationStorage = {
  unreadBidsCount: number,
  bidId: number,
  hubs: HubConnection[],
  storedNotificationsCount: number,
};

const initialState: NotificationStorage = {
  bidId: 0,
  unreadBidsCount: 0,
  hubs: [],
  storedNotificationsCount: 0,
};

export const addUnreadBid = createAction(
  'notification/add_new',
  (bidId: number) => ({ payload: bidId }),
);

export const removeUnreadBid = createAction(
  'notification/minus_one',
);

export const setHub = createAction(
  'notification/set_hub',
  (data: HubConnection) => ({ payload: data }),
);

export const setUnreadBidsCount = createAction(
  'notification/set_unread_bids_count',
  (data: number) => ({ payload: data }),
);

export const updateStoredNotificationsCount = createAction(
  'notification/updateStoredCount',
  (data: (old: number) => number) => ({ payload: data }),
);

export const NotificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(addUnreadBid, (state, action) => {
        const bidId = action.payload;
        return { ...state, bidId, unreadBidsCount: state.unreadBidsCount + 1 };
      })
      .addCase(removeUnreadBid, state => {
        return { ...state, unreadBidsCount: state.unreadBidsCount - 1 };
      })
      .addCase(setHub, (state, action) => {
        const hub = action.payload;
        const hubs = state.hubs || [];
        if (hubs.find(x => x.baseUrl === hub.baseUrl)) {
          return;
        }

        return { ...state, hubs: [...hubs, hub] };
      })
      .addCase(setUnreadBidsCount, (state, action) => {
        return { ...state, unreadBidsCount: parseInt(action.payload as any) };
      })
      .addCase(updateStoredNotificationsCount, (state, action) => {
        const updateAction = action.payload;
        if (!updateAction) return;
        state.storedNotificationsCount = updateAction(state.storedNotificationsCount);
      });
    }
  },
);

export default NotificationsSlice.reducer;