import {
  createAsyncThunk,
  isFulfilled,
  createAction,
} from "@reduxjs/toolkit";

import {
  apiFactory,
  createEntitySlice,
  EntityState,
} from '../../shared';

import { ApiLoadedOrdersGetRequest, LoadedOrder, LoadedOrdersApi } from '../../generated-api';
import paginationApiFactory from '../../shared/util/paginationApiFactory';
import { calcTimeLeft } from './NewDriverBidsHook';

const defaultValue: Readonly<LoadedOrder> = {
  read: false,
};

const initialState: EntityState<LoadedOrder> = {
  loading: false,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

// Actions


export const getLoadedOrdersPage = createAsyncThunk(
  "loadedOrder/fetch_entity_page",
  async (param: ApiLoadedOrdersGetRequest) => {
    let totalCount = 0;
    const api = paginationApiFactory(LoadedOrdersApi, total => totalCount = total);
    const entitiesPromise = api.apiLoadedOrdersGet(param);
    const recentOrdersPromise = apiFactory(LoadedOrdersApi).apiLoadedOrdersRecentGet(param);

    const [orders, recentOrders] = await Promise.all([entitiesPromise, recentOrdersPromise]);
    const ordersWithoutRecentOrders = orders.filter(
      loadedOrder => recentOrders.every(recentOrder => recentOrder.id !== loadedOrder.id));
    const entities = recentOrders.concat(ordersWithoutRecentOrders);
    return { entities, totalCount };
  }
);

export const markLoadedOrderAsRead = createAction(
  'loadedOrder/mark_as_read',
  (loadedOrderId: number) => ({payload: loadedOrderId}),
);

export const updateEntity = createAction(
  'loadedOrder/update_entity',
  (entity: LoadedOrder) => {
    return { payload: entity };
  }
);

/**
 * Расширяет список сущностей переданным списком сущностей,
 * добавляя новые сущности в начало списка.
 */
export const extendEntities = createAction(
  "loadedOrder/extend_entity_list",
  (data: LoadedOrder[]) => ({ payload: data })
);

export const newDriverBid = createAction(
  'loadedOrder/newDriverBid',
  (data: LoadedOrder) => ({ payload: data}),
);

export const expireDriverBid = createAction(
  'loadedOrder/expireDriverBid',
)

export const LoadedOrderSlice = createEntitySlice({
  name: "loadedOrder",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(
        extendEntities, (state, action) => {
          const newEntities = action.payload;
          newEntities.forEach(newLoadedOrder => {
            const oldIndex = state.entities.findIndex(loadedOrder => loadedOrder.id === newLoadedOrder.id);
            if (oldIndex >= 0) {
              state.entities.splice(oldIndex, 1);
            }
          });
          state.entities.push(...newEntities);
          sort(state.entities);
        })
      .addCase(updateEntity, (state, action) => {
        const entity = action.payload;
        const listEntity = state.entities.find(x => x.id === entity.id);
        if (!listEntity) return;
        entity.read = listEntity.read;
  
        return {
          ...state,
          entities: state.entities.map(x => x === listEntity ? entity : x),
        }
      })
      .addCase(
        markLoadedOrderAsRead, (state, action) => {
          const markedId = action.payload;
          const markedLoadedOrder = state.entities.find(lo => lo.id === markedId);
          if (!markedLoadedOrder) return;

          markedLoadedOrder.read = true;
        }
      )
      .addCase(
        expireDriverBid, (state, action) => {
          sort(state.entities);
        }
      )
      .addMatcher(isFulfilled(getLoadedOrdersPage), (state, action) => {
        const data = action.payload;

        sort(data.entities);
        return {
          ...state,
          loading: false,
          entities: data.entities,
          totalItems: data.totalCount,
        };
      });
  },
});

function sort(loadedOrders: LoadedOrder[]){
  const timeLeftCache = new Map<number, number>();
  function getTimeLeft(loadedOrder: LoadedOrder) {
    let timeLeft: number;
    if (timeLeftCache.has(loadedOrder.id!)) {
      timeLeft = timeLeftCache.get(loadedOrder.id!)!;
    } else {
      timeLeft = calcTimeLeft(loadedOrder);
      timeLeftCache.set(loadedOrder.id!, timeLeft);
    }

    return timeLeft;
  }

  loadedOrders.sort((first, second) => {
    const firstTimeLeft = getTimeLeft(first);
    const secondTimeLeft = getTimeLeft(second);

    // Both have recent DriverBids
    if (firstTimeLeft > 0 && secondTimeLeft > 0) {
      const receivedDateSort = (second.receivedDate as any) - (first.receivedDate as any);
      return receivedDateSort || second.id! - first.id!;
    }

    // First has no recent DriverBids and second does have
    if (firstTimeLeft <= 0 && secondTimeLeft > 0) {
      return 1;
    }

    // First does have recent DriverBids and second does not
    if (firstTimeLeft > 0 && secondTimeLeft <= 0) {
      return -1;
    }

    // Both have not recent DriverBids
    const receivedDateSort = (second.receivedDate as any) - (first.receivedDate as any);
    return receivedDateSort || second.id! - first.id!;
  });
}

export const { reset } = LoadedOrderSlice.actions;

// Reducer
export default LoadedOrderSlice.reducer;
