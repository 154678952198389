import * as fabric from 'fabric';
import React, { useEffect, useState } from 'react';
import useImageState from './useImageState';
import { useShallow } from 'zustand/react/shallow';

export type EditorMode = 'none' | 'crop' | 'rotate' | 'color';

export interface EditorState {
  mode: EditorMode,
  rawImage?: HTMLImageElement,
  originalImage?: fabric.FabricImage,
  modifiedImage?: fabric.FabricImage,
  canvasBackground?: fabric.Rect,
  cropRectangle?: fabric.Rect,
  overlayRectangle?: fabric.Rect,
  canvasContext?: fabric.Canvas,
}

interface Props  {
  workAreaWidth: number,
  workAreaHeight: number,
  wrapperWidth:number,
  wrapperHeight: number,
  imageUrl: string,
  canvasRef:  React.RefObject<HTMLCanvasElement>,
}


type toDataUrlOptions = Parameters<typeof fabric.FabricImage.prototype.toDataURL>[0];

export default function useImageEditor(props: Props) {
  const {workAreaWidth, workAreaHeight,wrapperWidth,wrapperHeight,canvasRef} = props;
  const [editorState, setEditorState] = useState<EditorState>({ mode: 'none' });
  const useStorage = useImageState();
  const imageState = useStorage(useShallow(state => ({
    brightness: state.currentState.brightness,
    contrast: state.currentState.contrast,
    exposure: state.currentState.exposure,
    rotation: state.currentState.rotation,
    crop: state.currentState.crop,
    _do: state._do,
    _undo: state._undo,
    _doTemp: state._doTemp,
  })));

  useEffect(() => {
    const newImage = new Image();
    newImage.onload = function () {
      setEditorState(prev => ({ ...prev, rawImage: newImage }));
    }
    newImage.src = props.imageUrl;
  }, [props.imageUrl]);

  useEffect(() => {
    if (!wrapperHeight || !wrapperWidth) return;

    if (!editorState.rawImage) {
      return;
    }

    const canvas = new fabric.Canvas(canvasRef.current!, {
      width: wrapperWidth,
      height: wrapperHeight,
    });
    setEditorState(prev => ({ ...prev, canvasContext: canvas }));

    canvas.clear();
    const workAreaTop = 0;
    const workAreaLeft = wrapperWidth - workAreaWidth;
    const offset = 10;
    const maxWidth = workAreaWidth - offset * 2;
    const maxHeight = workAreaHeight - offset * 2;

    const canvasImage = new fabric.Image(editorState.rawImage, {
      left: workAreaLeft + offset,
      top: workAreaTop + offset,
      evented: false,
      selectable: false,
    });

    // Bigger ratio = wide image, smaller ratio = tall image
    const workAreaRatio = maxWidth / maxHeight;
    const imageRatio = canvasImage.getScaledWidth() / canvasImage.getScaledHeight();

    if (imageRatio > workAreaRatio) {
      canvasImage.scaleToWidth(maxWidth);
      canvasImage.set({
        top: canvasImage.top + (maxHeight - canvasImage.getScaledHeight()) / 2,
      });
    } else {
      canvasImage.scaleToHeight(maxHeight);
      canvasImage.set({
        left: canvasImage.left + (maxWidth - canvasImage.getScaledWidth()) / 2,
      });
    }

    const background = new fabric.Rect({
      left: 0,
      top: 0,
      width: wrapperWidth,
      height: wrapperHeight,
      fill: '#FFFFFF',
      evented: false,
      selectable: false,
    });

    canvasImage.clone().then(clonedImage => {
      clonedImage.set({
        selectable: false,
        evenatable: false,
      });
      canvas.add(background, clonedImage);
      setEditorState(prev => ({
        ...prev,
        originalImage: canvasImage,
        modifiedImage: clonedImage,
        canvasBackground: background
      }));
    })

    return () => {
      setEditorState(prev => ({ ...prev, canvasContext: undefined }));
      canvas.dispose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editorState.rawImage,
    wrapperWidth,
    wrapperHeight,
    workAreaWidth,
    workAreaHeight]);

  const rotate = (image?: fabric.Image) => {
    if (!image) {
      return;
    }
    if (!editorState.canvasContext) {
      return;
    }

    image.set({ centeredRotation: true });
    image.rotate(imageState.rotation || 0);
    setEditorState(prev => ({ ...prev, modifiedImage: image }));
    editorState.canvasContext.requestRenderAll();
  }

  useEffect(() => {
    rotate(editorState.modifiedImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageState.rotation, editorState.modifiedImage]);

  const setBrightness = (image?: fabric.Image) => {
    if (!image) {
      return;
    }
    if (!editorState.canvasContext) {
      return;
    }

    // (-100, 100) to (-1, 1)
    const brightness = (imageState.brightness || 0) / 100;
    let filter = image.filters?.find(x => x.type === fabric.filters.Brightness.type) as fabric.filters.Brightness;
    if (filter) {
      filter.brightness = brightness;
    } else {
      filter = new fabric.filters.Brightness({brightness: brightness});
      image.filters.push(filter);
    }

    image.applyFilters();
    setEditorState(prev => ({ ...prev, modifiedImage: image}));
    editorState.canvasContext.requestRenderAll();
  }

  useEffect(() => {
    setBrightness(editorState.modifiedImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageState.brightness, editorState.modifiedImage]);

  const setContrast = (image?: fabric.Image) => {
    if (!image) {
      return;
    }
    if (!editorState.canvasContext) {
      return;
    }

    // (-100, 100) to (-1, 1)
    const contrast = (imageState.contrast || 0) / 100;
    let filter = image.filters?.find(x => x.type === fabric.filters.Contrast.type) as fabric.filters.Contrast;
    if (filter) {
      filter.contrast = contrast;
    } else {
      filter = new fabric.filters.Contrast({contrast: contrast});
      image.filters.push(filter);
    }

    image.applyFilters();
    setEditorState(prev => ({ ...prev, modifiedImage: image}));
    editorState.canvasContext.requestRenderAll();
  }

  useEffect(() => {
    setContrast(editorState.modifiedImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageState.contrast, editorState.modifiedImage]);

  const setExposure = (image?: fabric.Image) => {
    if (!image) {
      return;
    }
    if (!editorState.canvasContext) {
      return;
    }

    // (-100, 100) to (0, 2)
    const exposure = ((imageState.exposure || 0) + 100) / 100;
    let filter = image.filters?.find(x => x.type === fabric.filters.Gamma.type) as fabric.filters.Gamma;
    if (filter) {
      filter.gamma = [exposure, exposure, exposure];
    } else {
      filter = new fabric.filters.Gamma({gamma: [exposure, exposure, exposure]});
      image.filters.push(filter);
    }

    image.applyFilters();
    setEditorState(prev => ({ ...prev, modifiedImage: image}));
    editorState.canvasContext.requestRenderAll();
  }

  useEffect(() => {
    setExposure(editorState.modifiedImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageState.exposure, editorState.modifiedImage]);

  const beginCrop = () => {
    if (!editorState.modifiedImage || !editorState.canvasContext) return;

    const image = editorState.modifiedImage;
    const canvas = editorState.canvasContext;

    let crop = imageState.crop;
    if (!crop || [crop.x1, crop.y1, crop.x2, crop.y2].every(x => !x)) {
      crop = {
        x1: image.left,
        y1: image.top,
        x2: image.width * image.scaleX,
        y2: image.height * image.scaleY,
      };
    }

    canvas.clipPath = undefined;
    // image.clipPath = undefined;
    const cropRectangle = new fabric.Rect({
      left: crop.x1,
      top: crop.y1,
      width: crop.x2,
      height: crop.y2,
      stroke: "rgb(42, 67, 101)",
      strokeWidth: 2,
      strokeDashArray: [5, 5],
      fill: "rgba(255, 255, 255, 1)",
      globalCompositeOperation: "overlay",
      lockRotation: true,
    });

    const overlayRectangle = new fabric.Rect({
      left: wrapperWidth - workAreaWidth,
      top: 0,
      width: workAreaWidth,
      height: workAreaHeight,
      selectable: false,
      selection: false,
      fill: "rgba(0, 0, 0, 0.5)",
      lockRotation: true,
    });

    setEditorState(prev => ({ ...prev, cropRectangle: cropRectangle, overlayRectangle: overlayRectangle }));
    canvas.add(overlayRectangle);
    canvas.add(cropRectangle);
    canvas.discardActiveObject();
    canvas.setActiveObject(cropRectangle);
    canvas.requestRenderAll();

    cropRectangle.on('moving', () => {
      if (cropRectangle.top < image.top || cropRectangle.left < image.left) return;

      cropRectangle.left = cropRectangle.left < image.left ? image.left : cropRectangle.left;
      cropRectangle.top = cropRectangle.top < image.top ? image.top : cropRectangle.top;

      if (cropRectangle.top + cropRectangle.getScaledHeight() > image.top + image.getScaledHeight()
        || cropRectangle.left + cropRectangle.getScaledWidth() > image.left + image.getScaledWidth()) {
        return;
      }

      cropRectangle.top =
        cropRectangle.top + cropRectangle.getScaledHeight() > image.top + image.getScaledHeight()
          ? image.top + image.getScaledHeight() - cropRectangle.getScaledHeight()
          : cropRectangle.top;
      cropRectangle.left =
        cropRectangle.left + cropRectangle.getScaledWidth() > image.left + image.getScaledWidth()
          ? image.left + image.getScaledWidth() - cropRectangle.getScaledWidth()
          : cropRectangle.left;
    });
  }

  const endCrop = () => {
    const {
      cropRectangle,
      modifiedImage,
      canvasContext,
      overlayRectangle,
      originalImage,
    } = editorState;
    if (!cropRectangle || !modifiedImage || !canvasContext || !originalImage) return;

    canvasContext.clipPath = cropRectangle;
    imageState._do({ crop: {
        x1: cropRectangle.left,
        y1: cropRectangle.top,
        x2: cropRectangle.width * cropRectangle.scaleX,
        y2: cropRectangle.height * cropRectangle.scaleY}});
    canvasContext.remove(cropRectangle);
    if (overlayRectangle) {
      canvasContext.remove(overlayRectangle);
    }
    canvasContext.requestRenderAll();
  }

  const setMode = (mode: EditorMode) => {
    const activeMode = editorState.mode;

    if (mode === 'crop' && activeMode !== 'crop') {
      beginCrop();
    }

    if (activeMode === 'crop') {
      endCrop();
    }

    const newMode: EditorMode = activeMode === mode ? 'none' : mode;
    setEditorState(prev => ({ ...prev, mode: newMode}));
  }

  const exportImage = () => {
    const { modifiedImage, canvasContext, cropRectangle } = editorState;
    if (!modifiedImage || !canvasContext) return;

    if (editorState.mode === 'crop') {
      endCrop();
    }

    const boundaries = modifiedImage.getBoundingRect();
    let imagePoints: fabric.Point[] = [
      new fabric.Point(boundaries.left, boundaries.top),
      new fabric.Point(boundaries.left, boundaries.top + boundaries.height),
      new fabric.Point(boundaries.left + boundaries.width, boundaries.top),
      new fabric.Point(boundaries.left + boundaries.width, boundaries.top + boundaries.height),
      // Both Point.rotate() and Point.transform() dont work for some reasons.
    ].map(point => fabric.util.rotatePoint(
      point,
      new fabric.Point({x: boundaries.left + boundaries.width / 2, y: boundaries.top + boundaries.height / 2 }),
      fabric.util.degreesToRadians(imageState.rotation || 0)
    ));

    const transformedBoundaries = fabric.util.makeBoundingBoxFromPoints(imagePoints);

    let options: toDataUrlOptions = {
      format: 'png',
    };
    if (cropRectangle) {
      options = {
        ...options,
        left: cropRectangle.left - transformedBoundaries.left,
        top: cropRectangle.top - transformedBoundaries.top,
        width: cropRectangle.getScaledWidth(),
        height: cropRectangle.getScaledHeight(),
      }
    } else {
      options = {
        ...options,
        left: 0,
        top: 0,
        width: transformedBoundaries.width,
        height: transformedBoundaries.height,
      }
    }

    const imageUrl = modifiedImage.toDataURL(options);

    return imageUrl;
  }

  return {
    setMode,
    exportImage,
    state: editorState,
    editing: imageState,
  } as const;
}