import React, { CSSProperties, useMemo } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { CloseOutlined, DownloadOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { LogisticsFile } from '../../../../generated-api';
import useResizeObserver from 'use-resize-observer';
import ImageEditorModal from '../editing/ImageEditorModal';
import { useModal } from '../../../../hooks';
import FileSaver from 'file-saver';
import { isImage } from '../util';

const Container = styled.div`
  & {
    position: relative;
    width: 100%;
    background-color: black;
    margin: 0.3rem;
    border-radius: 1rem;
  }
`

const ToolbarForPreview = styled.header`
  & {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    //mix-blend-mode: hard-light;
    background: linear-gradient(black 0%, transparent 100%);
    padding-bottom: 1rem;
    border-radius: 1rem;
  }
`

const ToolbarWithoutPreview = styled.header`
  & {
    width: 100%;
    background: var(--gray-800);
    padding-bottom: 1rem;
    border-radius: 1rem;
  }
`

const ToolbarButton = styled.button`
  & {
    all: unset;
    color: white !important;
    transition: color 200ms;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  &:hover {
    cursor: pointer;
    color: lightgray !important;
  }
`

// Height is width * 0.7
const heightToWidthRatio = 0.7;

function FileContainer(originNode: React.ReactElement, file: UploadFile, fileList: Array<UploadFile<LogisticsFile>>, actions: {
  download: () => void;
  preview: () => void;
  remove: () => void;
  edit: (oldFile: UploadFile, newFileUrl: string) => void;
}) {
  const { ref, width}
    = useResizeObserver<HTMLDivElement>();
  const Toolbar = useMemo(() => {
    if (!file.url && !file.thumbUrl)
      return ToolbarWithoutPreview;

    return isImage(file.fileName)
      ? ToolbarForPreview
      : ToolbarWithoutPreview;

  }, [file.url, file.thumbUrl, file.fileName]);
  const modal = useModal('');

  const thumbnail = useMemo(() => {
    if ((file.url || file.thumbUrl) && isImage(file.fileName)) {
      const heightStyle : CSSProperties = width
        ? { maxHeight: width * heightToWidthRatio + 'px' }
        : {};

      return <img
        src={file.thumbUrl || file.url}
        alt={file.fileName}
        style={{ ...heightStyle, maxWidth: '100%', borderRadius: '1rem' }}
      />
    }

    return <></>;
  }, [file.url, file.fileName, file.thumbUrl, width]);

  return <Container ref={ref} >
    <div className='w-full h-full bg-black' />
    <div className='flex align-items-center justify-content-center'>
      {thumbnail}
    </div>
    <Toolbar>
      <div className='flex'>
        <div className='flex justify-content-start flex-grow-1'>
          <ToolbarButton onClick={actions.preview}><EyeOutlined /></ToolbarButton>
          <ToolbarButton onClick={() => {
            if (file.url) {
              FileSaver.saveAs(file.url, file.fileName);
            } else {
              actions.download();
            }
          }}>
            <DownloadOutlined />
          </ToolbarButton>
          <ToolbarButton onClick={() => {
            modal.show(file.url || '');
          }}>
            <EditOutlined />
          </ToolbarButton>
        </div>
        <div className='flex justify-content-end flex-grow-1'>
          <ToolbarButton onClick={actions.remove}><CloseOutlined /></ToolbarButton>
        </div>
      </div>
      <div className='w-full'>
        <div className='text-white-alpha-90 text-sm mx-1 white-space-nowrap text-overflow-ellipsis overflow-hidden'>{file.fileName}</div>
      </div>
    </Toolbar>
    <ImageEditorModal
      data={modal.data}
      loading={false}
      visible={modal.visible}
      hide={url => {
        modal.hide();
        if (url) {
          actions.edit(file, url);
        }
      }}
    />
  </Container>;
}

export default FileContainer;