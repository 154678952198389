import { ApiOwnersGetRequest, Owner, OwnersApi } from '../../generated-api';
import { createEntitySlice, EntityState, PageResponse } from '../../shared';
import { createAction, createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import paginationApiFactory from '../../shared/util/paginationApiFactory';

const defaultValue: Readonly<Owner> = { };

const initialState: EntityState<Owner> = {
  loading: false,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
}

export const getOwnersPage = createAsyncThunk(
  "Owner/fetch_entity_page",
  async (params : ApiOwnersGetRequest) => {
    let totalCount = 0;
    const api = paginationApiFactory(OwnersApi, total => totalCount = total);
    const entities = await api.apiOwnersGet(params);
    return { entities, totalCount } as PageResponse<Owner>;
  }
);

export const updateEntity = createAction(
  'Owner/update_entity',
  (entity: Owner) => {
    return { payload: entity };
  }
);

export const VehiclesSlice = createEntitySlice({
  name: 'Owners',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateEntity, (state, action) => {
      const entity = action.payload;
      const listEntity = state.entities.find(x => x.id === entity.id);
      if (!listEntity) return;

      return {
        ...state,
        entities: state.entities.map(x => x === listEntity ? entity : x),
      }
    }).addMatcher(isFulfilled(getOwnersPage), (state, action) => {
      const data = action.payload;

      return {
        ...state,
        loading: false,
        entities: data.entities,
        totalItems: data.totalCount,
      }
    })
  }
});

export default VehiclesSlice.reducer;