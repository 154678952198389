import React from 'react';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import DashboardPage from '../features/dashboard/DashboardPage';
import LoadedOrderList from '../features/loaded-orders/LoadedOrderList';
import BidList from '../features/bids/BidList';
import Vehicles from '../features/vehicles/VehiclesPage';
import Settings from '../features/settings/SettingsPage';
import Login from '../features/login/Login';
import { history } from '../shared';
import Experiments from '../features/experimental/Experiments';
import ListOfLoadsList from '../features/list-of-loads/ListOfLoadsList';
import TrackingPage from '../features/tracking/TrackingPage';
import RequestList from '../features/hr/request-list/RequestList';
import RequestEdit from '../features/hr/request-edit/RequestEdit';
import HRPage from '../features/hr/HRPage';
import Registration from '../features/registration/Registration';
import RequestEditPublic from '../features/hr/request-edit-public/RequestEditPublic';
import AppConfig from '../app/AppConfig';
import PrivateApp from '../app/private/PrivateApp';

function AppRoutes() {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <Routes>
      <Route path="/" element={<AppConfig />}>
        {/* Public routes */}
        <Route path='referral' element={<Registration />} />
        <Route path='referral/:referralId' element={<Registration />} />
        <Route path='request/pub/:id' element={<RequestEditPublic />} />
        <Route path='login' element={<Login />} />
        {/* Private routes */}
        <Route path='/' element={<PrivateApp />}>
          <Route path='dashboard' element={<DashboardPage />} />
          <Route path='loaded-orders' element={<LoadedOrderList />} />
          <Route path='bids' element={<BidList />} />
          <Route path='tracking' element={<TrackingPage />} />
          <Route path='vehicles' element={<Vehicles />} />
          <Route path='list-of-loads' element={<ListOfLoadsList />} />
          <Route path='request' element={<RequestList /> } />
          <Route path='request/new' element={<RequestEdit />} />
          <Route path='request/:id' element={<RequestEdit />} />
          <Route path='hr' element={<HRPage /> } />
          <Route path='settings' element={<Settings />} />
          <Route path='experimental' element={<Experiments />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoutes;
