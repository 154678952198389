import React, { useEffect, useMemo } from 'react';
import DashboardPanel from '../components/DashboardPanel';
import WidgetToolbar, { WidgetFilters } from '../components/WidgetToolbar';
import { ColumnType } from 'antd/lib/table';
import { Table } from 'antd';
import styled from '@emotion/styled';
import { nameof } from 'ts-simple-nameof';
import {
  DashboardApi,
  OwnerInformationResponse,
} from '../../../generated-api';
import { apiFactory } from '../../../shared';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import useWidget from '../hooks/WidgetHook';
import { today } from '../../../shared/util/dateUtils';
import { getDashboardDispatchersOwnerInformation } from '../DashboardSlice';
import { showSuccessToast } from '../../../components/LogisticsToast';
import FileSaver from 'file-saver';
import { getPeriodFromWidgetFilters } from './utils/widgets-utils';

function OwnerInformation() {
  const appDispatch = useAppDispatch();
  const dispatchers = useAppSelector(state => state.dashboard.dispatcherState?.ownerInformation);
  const initialFilters : WidgetFilters = {
    periodType: 'Day',
    from: today().toDate(),
    to: today().toDate(),
    dispatcherIds: dispatchers?.map(x => x.id || '') || [],
  };

  const { data, filters, loadData, setFilters } = useWidget({
    initialFilters: initialFilters,
    loadWidgetData: filters => apiFactory(DashboardApi).apiDashboardOwnerInformationGet(filters as any),
    initialWidgetData: [],
    usesDispatchers: true,
    dispatcherState: state => state.dashboard.dispatcherState?.ownerInformation,
    getDispatcherSliceAction: getDashboardDispatchersOwnerInformation,
  });

  useEffect(() => {
    appDispatch(getDashboardDispatchersOwnerInformation(initialFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const joinedTableData = useMemo(() => {
    let newTableData = data.map((x, i) => {
      const dispatcher = dispatchers?.find(d => d.id === x.dispatcherOwner);
      const dispatcherName = dispatcher ? `${dispatcher.firstName} ${dispatcher.lastName}` : x.dispatcherOwner;

      return { ...x, dispatcherOwner: dispatcherName, key: i } as OwnerInformationResponse;
    });

    return newTableData.sort((a:OwnerInformationResponse, b:OwnerInformationResponse) => {
      const aValue = a.dispatcherOwner?.toLowerCase();
      const bValue = b.dispatcherOwner?.toLowerCase();
      if (aValue && bValue) {
        if (aValue === 'all') {
          return 1;
        }

        if (aValue < bValue) {
          return -1;
        }

        if (aValue > bValue) {
          return 1;
        }
      }

      return 0;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onExport = async (exportFilters?: WidgetFilters) => {
    const response = await apiFactory(DashboardApi).apiDashboardOwnerInformationExportPost({widgetParameters: exportFilters});
    const period = getPeriodFromWidgetFilters(exportFilters);
    FileSaver.saveAs(response!, `Owner Information ${period}.xlsx`);
    showSuccessToast('Data successfully exported');
  };

  return <StyledDashboardPanel>
    <header className='flex flex-wrap'>
      <span className='text-3xl font-medium white-space-nowrap flex justify-content-start flex-grow-1 gap-2'>
        Owner Information
      </span>
      <div className='flex max-w-full justify-content-end align-items-center flex-grow-1 gap-2'>
        <WidgetToolbar
          value={filters}
          dispatcherState={(state) => state.dashboard.dispatcherState?.ownerInformation}
          onChange={setFilters}
          onFilter={loadData}
          onExport={onExport}
          visibility={{
            period: true,
            from: true,
            to: true,
            dispatchers: true,
            filterButton: true,
          }}
        />
      </div>
    </header>
    <div className='flex flex-column pt-3'>
      <StyledTable
        dataSource={joinedTableData}
        columns={columns}
        size='small'
        pagination={false}
        bordered
      />
    </div>
  </StyledDashboardPanel>
}

const StyledTable = styled(Table)`
  & {
    height: 400px;
    overflow: auto;
  }

  & .ant-table-content tr:last-child {
    font-weight: bold;
  }
`

const StyledDashboardPanel = styled(DashboardPanel)`
& {
  align-self: end;
}
`

const columns: ColumnType<OwnerInformationResponse>[] = [
  {
    title: 'Dispatcher \\ Owner',
    dataIndex: nameof<OwnerInformationResponse>(x => x.dispatcherOwner),
    key: nameof<OwnerInformationResponse>(x => x.dispatcherOwner),
    width: 500,
  },
  {
    title: 'Bids',
    dataIndex: nameof<OwnerInformationResponse>(x => x.bids),
    key: nameof<OwnerInformationResponse>(x => x.bids),
    align: 'center',
    width: 30,
  },
  {
    title: 'Loads',
    dataIndex: nameof<OwnerInformationResponse>(x => x.loads),
    key: nameof<OwnerInformationResponse>(x => x.loads),
    align: 'center',
    width: 30,
  },
  {
    title: 'Profit',
    dataIndex: nameof<OwnerInformationResponse>(x => x.profit),
    key: nameof<OwnerInformationResponse>(x => x.profit),
    align: 'center',
    width: 30,
    render: (data: number) => {
      return '$' + Math.trunc(data || 0);
    }
  }
];

export default OwnerInformation;
