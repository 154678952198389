import React from 'react';
import { InputNumberProps } from 'primereact/inputnumber';
import StyledInputNumber from './StyledInputNumber';

function InputNumberWithButtons(props: InputNumberProps) {
  return <StyledInputNumber
    showButtons
    mode='decimal'
    step={1}
    minFractionDigits={2}
    min={0}
    decrementButtonClassName='p-button-text'
    incrementButtonClassName='p-button-text'
    {...props}
  />;
}

export default InputNumberWithButtons;