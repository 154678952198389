import React, { useEffect, useMemo, useState } from 'react';
import { Owner, OwnersApi } from '../../generated-api';
import { ModalParameters } from '../../shared';
import { apiFactory } from '../../shared';
import { Button } from 'primereact/button';
import LogisticsDialog from '../../components/LogisticsDialog';
import { showToast } from '../../components/LogisticsToast';
import StyledInputText from '../../components/form/StyledInputText';
import CenteredSpinner from '../../components/CenteredSpinner';

export interface OwnerSelectParameters extends ModalParameters<Owner | undefined> {

}

function OwnerSelect(props : OwnerSelectParameters) {
  const [owners, setOwners] = useState<Owner[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const visibleOwners = useMemo(() => {
    if (!search) return owners;

    return owners.filter(owner => (owner.firstName + ' ' + owner.lastName).toLowerCase().includes(search.toLowerCase()));
  }, [owners, search]);

  useEffect(() => {
    setLoading(true);
    apiFactory(OwnersApi).apiOwnersAllGet()
      .then(loadedOwners => {
        setOwners(loadedOwners);
      }).catch(() => showToast(
        { severity: 'error', summary: 'Error', detail: 'Something went wrong'}
      )).finally(() => {
        setLoading(false);
    });
  }, []);

  return (
    <>
      <LogisticsDialog
        visible={props.visible}
        onHide={() => props.hide(undefined)}
        className='full-height-dialog no-padding-dialog w-3'
      >
        <div className='flex flex-column h-full '>
          <header className='p-3 text-2xl font-bold'>
            Select owner
            <StyledInputText
              value={search}
              onChange={e => setSearch(e.target.value)}
              autoFocus
              placeholder={'Search...'}
            />
          </header>
          <main className='overflow-y-auto flex-1 h-full px-3 border-bottom-1 border-top-1 border-gray-500 py-2'>
            {visibleOwners.map(owner => <Button
              label={owner.firstName + ' ' + owner.lastName}
              key={owner.id}
              className='p-button-text p-button-plain block w-full text-left'
              onClick={() => props.hide(owner)}
            />)}
            <CenteredSpinner visible={loading} />
          </main>
          <footer className='flex justify-content-end p-3'>
            <Button
              label='CLOSE'
              className='p-button-text'
              onClick={() => {
                props.hide(undefined);
              }}
            />
          </footer>

        </div>
      </LogisticsDialog>
    </>
  );
}

export default OwnerSelect;