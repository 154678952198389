import { IdName } from './IdName';
import { EVehicleStatusFilter } from '../generated-api';

const VehicleStatusFilter: IdName<string>[] = [
  { id: EVehicleStatusFilter.Active, name: 'Active' },
  { id: EVehicleStatusFilter.OutOfService, name: 'Out of service' },
  { id: EVehicleStatusFilter.InService, name: 'In service' },
  { id: EVehicleStatusFilter.UnconfinedService, name: 'Unconfined service' },
  { id: EVehicleStatusFilter.OnLoad, name: 'On load' },
  { id: EVehicleStatusFilter.Retried, name: 'Retried' },
  { id: EVehicleStatusFilter.Error, name: 'Error' },
];

export default VehicleStatusFilter;