import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { showErrorToast } from '../../../components/LogisticsToast';
import { WidgetFilters } from '../components/WidgetToolbar';
import { RootState } from '../../../store/store';
import { UserDto } from '../../../generated-api';

function useWidget<TResponse>(props: WidgetParameters<TResponse>) : Widget<TResponse> {
  const appDispatch = useAppDispatch();
  const defaultDispatchersState : (state: RootState) => UserDto[] | undefined = state => state.dashboard.dispatcherState?.all;
  const dispatchers = useAppSelector(props.dispatcherState ? props.dispatcherState : defaultDispatchersState);
  const brokerages = useAppSelector(state => state.dashboard.brokerages);
  const initialRunWithDispatchers = useRef(true); //initialize "true" to load dispatchers first
  const initialRunWithBrokerages = useRef(false);
  const [filters, setFilters] = useState<WidgetFilters>(props.initialFilters || {});
  const [loadedWidgetData, setLoadedWidgetData] = useState<TResponse>(props.initialWidgetData || null as any);
  const _loadWidgetData = (_filters: WidgetFilters) => {
    if (props.usesDispatchers && !_filters.dispatcherIds?.length) {
      showErrorToast("Failed to load widget data: no dispatcher is selected");
      return;
    }
    if (props.usesBrokerages && !_filters.brokeragesIds?.length) {
      showErrorToast("Failed to load widget data: no brokerage is selected");
      return;
    }
    if (!props.loadWidgetData) return;

    props.loadWidgetData(_filters)
      .then(response => setLoadedWidgetData(response))
      .catch(() => showErrorToast('Failed to load widget data'));
  }

  const loadWidgetData = useCallback(() => {
    _loadWidgetData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (!props.usesDispatchers && !props.usesBrokerages) {
      loadWidgetData();
    }

    if (props.usesDispatchers && props.getDispatcherSliceAction) {
      appDispatch(props.getDispatcherSliceAction({...filters})).then(() => {
        initialRunWithDispatchers.current = false;
      });
    } else if (props.usesDispatchers) {
      initialRunWithDispatchers.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.getDispatcherSliceAction) {
      appDispatch(props.getDispatcherSliceAction({...filters}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.from, filters.to]);

  useEffect(() => {
    if (!props.usesDispatchers) return;

    const dispatcherIds = dispatchers?.map(x => x.id || '') || [];
    setFilters(prev => {
      return { ...prev, dispatcherIds };
    });

    if (!initialRunWithDispatchers.current && dispatcherIds.length > 0) {
      _loadWidgetData({ ...filters, dispatcherIds });
      initialRunWithDispatchers.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchers, initialRunWithDispatchers.current]);

  useEffect(() => {
    if (!props.usesBrokerages) return;

    const brokeragesIds = brokerages?.filter(x => x.id).map(x => x.id!) || [];
    setFilters(prev => {
      return { ...prev, brokeragesIds };
    });

    if (!initialRunWithBrokerages.current && brokeragesIds.length > 0) {
      _loadWidgetData({ ...filters, brokeragesIds });
      initialRunWithBrokerages.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerages, initialRunWithBrokerages.current]);

  return { filters, setFilters, loadData: loadWidgetData, data: loadedWidgetData} as Widget<TResponse>;
}

interface WidgetParameters<TResponse> {
  initialFilters?: WidgetFilters,
  initialWidgetData?: TResponse,
  loadWidgetData?: (filters: WidgetFilters) => Promise<TResponse>,
  usesDispatchers?: boolean,
  usesBrokerages?: boolean,
  dispatcherState?: (state: RootState) => UserDto[] | undefined,
  getDispatcherSliceAction?: (param: any) => any
}

interface Widget<TResponse> {
  filters: WidgetFilters,
  setFilters: Dispatch<SetStateAction<WidgetFilters>>,
  data: TResponse,
  loadData: (param?: any) => any,
}

export default useWidget;