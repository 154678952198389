/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EMailTemplateVariables = {
    PriceForBroker: 'PriceForBroker',
    Dimensions: 'Dimensions',
    PayLoad: 'PayLoad',
    McNumber: 'MCNumber',
    Miles: 'Miles',
    Eta: 'ETA',
    DockHigh: 'DockHigh',
    TtDrivers: 'TTDrivers',
    LiftGate: 'LiftGate',
    PalletJack: 'PalletJack',
    DriverAssist: 'DriverAssist',
    FullPpe: 'FullPPE'
} as const;
export type EMailTemplateVariables = typeof EMailTemplateVariables[keyof typeof EMailTemplateVariables];


export function EMailTemplateVariablesFromJSON(json: any): EMailTemplateVariables {
    return EMailTemplateVariablesFromJSONTyped(json, false);
}

export function EMailTemplateVariablesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EMailTemplateVariables {
    return json as EMailTemplateVariables;
}

export function EMailTemplateVariablesToJSON(value?: EMailTemplateVariables | null): any {
    return value as any;
}

