import React from 'react';
import useAuth from './AuthHook';
import { useIdleTimer } from 'react-idle-timer';

const idleInterval = 15 * 60 * 1000; // 15 min
function IdleLogout() {
  const { logout } = useAuth();
  const idleTimer  = useIdleTimer({
    timeout: idleInterval,
    crossTab: true,
    leaderElection: true,
    startOnMount: true,
    syncTimers: 1000,
    onIdle: () => {
      console.debug('Logging out due to inactivity')
      logout();
    }
  });

  console.debug('Idle tracking, tab id:', idleTimer.getTabId());

  return <></>;
}

export default IdleLogout;