/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ETrackingOption = {
    NotSet: 'NotSet',
    Solo: 'Solo',
    Team: 'Team',
    LikeATeam: 'LikeATeam'
} as const;
export type ETrackingOption = typeof ETrackingOption[keyof typeof ETrackingOption];


export function ETrackingOptionFromJSON(json: any): ETrackingOption {
    return ETrackingOptionFromJSONTyped(json, false);
}

export function ETrackingOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETrackingOption {
    return json as ETrackingOption;
}

export function ETrackingOptionToJSON(value?: ETrackingOption | null): any {
    return value as any;
}

