/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EBusinessType } from './EBusinessType';
import {
    EBusinessTypeFromJSON,
    EBusinessTypeFromJSONTyped,
    EBusinessTypeToJSON,
} from './EBusinessType';
import type { LogisticsFile } from './LogisticsFile';
import {
    LogisticsFileFromJSON,
    LogisticsFileFromJSONTyped,
    LogisticsFileToJSON,
} from './LogisticsFile';
import type { OwnerDocument } from './OwnerDocument';
import {
    OwnerDocumentFromJSON,
    OwnerDocumentFromJSONTyped,
    OwnerDocumentToJSON,
} from './OwnerDocument';
import type { State } from './State';
import {
    StateFromJSON,
    StateFromJSONTyped,
    StateToJSON,
} from './State';
import type { Tracking } from './Tracking';
import {
    TrackingFromJSON,
    TrackingFromJSONTyped,
    TrackingToJSON,
} from './Tracking';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface Owner
 */
export interface Owner {
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    externalId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    email?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Owner
     */
    phones?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    federalTaxpayerId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Owner
     */
    active?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    businessAs?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    employerId?: string | null;
    /**
     * 
     * @type {EBusinessType}
     * @memberof Owner
     */
    businessType?: EBusinessType;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    otherType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Owner
     */
    taxExempt?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    exemptPayeeCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    fatca?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Owner
     */
    birth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    depositCompanyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    depositCityStateZip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    depositAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    blankName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    accountingNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    routingNumber?: string | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Owner
     */
    w9Form?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Owner
     */
    voidCheck?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof Owner
     */
    contract?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    emergencyPhone?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Owner
     */
    signature?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Owner
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Owner
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    stateId?: number | null;
    /**
     * 
     * @type {State}
     * @memberof Owner
     */
    state?: State;
    /**
     * 
     * @type {Array<Vehicle>}
     * @memberof Owner
     */
    vehicles?: Array<Vehicle> | null;
    /**
     * 
     * @type {Array<Tracking>}
     * @memberof Owner
     */
    trackings?: Array<Tracking> | null;
    /**
     * 
     * @type {User}
     * @memberof Owner
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    userId?: string | null;
    /**
     * 
     * @type {Array<OwnerDocument>}
     * @memberof Owner
     */
    ownerDocuments?: Array<OwnerDocument> | null;
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    id?: number;
}

/**
 * Check if a given object implements the Owner interface.
 */
export function instanceOfOwner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OwnerFromJSON(json: any): Owner {
    return OwnerFromJSONTyped(json, false);
}

export function OwnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Owner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phones': !exists(json, 'phones') ? undefined : json['phones'],
        'federalTaxpayerId': !exists(json, 'federalTaxpayerId') ? undefined : json['federalTaxpayerId'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'businessAs': !exists(json, 'businessAs') ? undefined : json['businessAs'],
        'employerId': !exists(json, 'employerId') ? undefined : json['employerId'],
        'businessType': !exists(json, 'businessType') ? undefined : EBusinessTypeFromJSON(json['businessType']),
        'otherType': !exists(json, 'otherType') ? undefined : json['otherType'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'exemptPayeeCode': !exists(json, 'exemptPayeeCode') ? undefined : json['exemptPayeeCode'],
        'fatca': !exists(json, 'fatca') ? undefined : json['fatca'],
        'birth': !exists(json, 'birth') ? undefined : (json['birth'] === null ? null : new Date(json['birth'])),
        'depositCompanyName': !exists(json, 'depositCompanyName') ? undefined : json['depositCompanyName'],
        'depositCityStateZip': !exists(json, 'depositCityStateZip') ? undefined : json['depositCityStateZip'],
        'depositAddress': !exists(json, 'depositAddress') ? undefined : json['depositAddress'],
        'blankName': !exists(json, 'blankName') ? undefined : json['blankName'],
        'accountingNumber': !exists(json, 'accountingNumber') ? undefined : json['accountingNumber'],
        'routingNumber': !exists(json, 'routingNumber') ? undefined : json['routingNumber'],
        'w9Form': !exists(json, 'w9Form') ? undefined : (json['w9Form'] === null ? null : (json['w9Form'] as Array<any>).map(LogisticsFileFromJSON)),
        'voidCheck': !exists(json, 'voidCheck') ? undefined : (json['voidCheck'] === null ? null : (json['voidCheck'] as Array<any>).map(LogisticsFileFromJSON)),
        'contract': !exists(json, 'contract') ? undefined : (json['contract'] === null ? null : (json['contract'] as Array<any>).map(LogisticsFileFromJSON)),
        'emergencyPhone': !exists(json, 'emergencyPhone') ? undefined : json['emergencyPhone'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'stateId': !exists(json, 'stateId') ? undefined : json['stateId'],
        'state': !exists(json, 'state') ? undefined : StateFromJSON(json['state']),
        'vehicles': !exists(json, 'vehicles') ? undefined : (json['vehicles'] === null ? null : (json['vehicles'] as Array<any>).map(VehicleFromJSON)),
        'trackings': !exists(json, 'trackings') ? undefined : (json['trackings'] === null ? null : (json['trackings'] as Array<any>).map(TrackingFromJSON)),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'ownerDocuments': !exists(json, 'ownerDocuments') ? undefined : (json['ownerDocuments'] === null ? null : (json['ownerDocuments'] as Array<any>).map(OwnerDocumentFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function OwnerToJSON(value?: Owner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
        'companyName': value.companyName,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'address': value.address,
        'city': value.city,
        'zip': value.zip,
        'email': value.email,
        'phones': value.phones,
        'federalTaxpayerId': value.federalTaxpayerId,
        'active': value.active,
        'businessAs': value.businessAs,
        'employerId': value.employerId,
        'businessType': EBusinessTypeToJSON(value.businessType),
        'otherType': value.otherType,
        'taxExempt': value.taxExempt,
        'exemptPayeeCode': value.exemptPayeeCode,
        'fatca': value.fatca,
        'birth': value.birth === undefined ? undefined : (value.birth === null ? null : value.birth.toISOString().substr(0,10)),
        'depositCompanyName': value.depositCompanyName,
        'depositCityStateZip': value.depositCityStateZip,
        'depositAddress': value.depositAddress,
        'blankName': value.blankName,
        'accountingNumber': value.accountingNumber,
        'routingNumber': value.routingNumber,
        'w9Form': value.w9Form === undefined ? undefined : (value.w9Form === null ? null : (value.w9Form as Array<any>).map(LogisticsFileToJSON)),
        'voidCheck': value.voidCheck === undefined ? undefined : (value.voidCheck === null ? null : (value.voidCheck as Array<any>).map(LogisticsFileToJSON)),
        'contract': value.contract === undefined ? undefined : (value.contract === null ? null : (value.contract as Array<any>).map(LogisticsFileToJSON)),
        'emergencyPhone': value.emergencyPhone,
        'signature': value.signature,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'stateId': value.stateId,
        'state': StateToJSON(value.state),
        'vehicles': value.vehicles === undefined ? undefined : (value.vehicles === null ? null : (value.vehicles as Array<any>).map(VehicleToJSON)),
        'trackings': value.trackings === undefined ? undefined : (value.trackings === null ? null : (value.trackings as Array<any>).map(TrackingToJSON)),
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'ownerDocuments': value.ownerDocuments === undefined ? undefined : (value.ownerDocuments === null ? null : (value.ownerDocuments as Array<any>).map(OwnerDocumentToJSON)),
        'id': value.id,
    };
}

