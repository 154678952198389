const bidsByHourColorPalette = {
  orders: "#007de5",
  ordersLastYear: "#00e5d7",
  bidsColors: [
    "#02b3a8",
    "#00e5d7",
    "#007de5",
    "#00aee5",
    "#feb259",
    "#fee459",
    "#ff4c41"
  ]
};

export default bidsByHourColorPalette;