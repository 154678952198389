import React, { useCallback, useMemo, useState } from 'react';
import { RootState } from '../../../store/store';
import requestsColumnDef from './RequestColumnDef';
import LogisticsDataGrid from '../../../components/data-grid/LogisticsDataGrid';
import { getRequestsPage } from './RequestSlice';
import serverFilterComponent, { serverFilterFunctionName } from '../../../components/filters/server/ServerFilter';
import { ApiRequestsGetRequest, Request, RequestApi } from '../../../generated-api';
import GlobalFilter from '../../../components/filters/server/GlobalFilter';
import { Button } from 'primereact/button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import { useModal } from '../../../hooks';
import RequestLink from './RequestLink';
import useRights from '../../../hooks/RightsHook';
import VisibilityToggler from '../../../components/VisibilityToggler';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import RequestExport from './RequestExport';
import { RowActionItem } from '../../../components/data-grid/actions/RowActionButton';
import { logisticsConfirmDialog } from '../../../components/LogisticsConfifmDialog';
import { apiFactory } from '../../../shared';
import { showErrorToast } from '../../../components/LogisticsToast';

const selectState = (state: RootState) => state.request;

function RequestList() {
  const navigate = useNavigate();
  const rights = useRights(security => security.request);
  const linkModal = useModal(0);
  const requestExportModal = useModal(undefined);
  const [loading, setLoading] = useState(false);
  const columns = useMemo(() => {
    const actions : RowActionItem<Request>[] = [{
      label: 'Link for update',
      icon: '',
      command: request => linkModal.show(request?.id || 0),
    }];
    if (rights._delete) {
      actions.push({
        label: 'Delete',
        icon: '',
        command: request => deleteRequest(request!),
      });
    }

    return requestsColumnDef(actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rights._delete]);
  const externalButtons = useMemo(() => {
    return [
      <>
        <VisibilityToggler visible={rights.create}>
          <Button
            className='p-button-success px-5 ml-2 text-base font-bold'
            onClick={() => navigate('/request/new')}
          >
            <p className='my-0'>Add request</p>
            <AddCircleOutlineIcon fontSize='medium' className='ml-1'/>
          </Button>
        </VisibilityToggler>
        <Button className='p-button-primary px-5 ml-2 text-base font-bold' onClick={() => requestExportModal.show(undefined)}>
          <p className='my-0'>Export</p>
          <UploadFileIcon fontSize='medium' className='ml-1'/>
        </Button>
      </>
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rights]);

  const handleRowClick = useCallback((e: DataTableRowClickEventParams) => {
    const rowData = e.data as Request;
    navigate('/request/' + rowData?.id?.toString() || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteRequest = (request: Request) => {
    logisticsConfirmDialog({
      message: 'Are you sure?',
      closable: false,
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept() {
        // Fixme: setting this should not reload list
        setLoading(true);
        apiFactory(RequestApi)
          .apiRequestsIdDelete({ id: request.id! })
          .catch((e) => {
            console.error(e);
            showErrorToast('Error: It is impossible to delete the request because there are other records linked to it.');
          }).finally(() => {
            setLoading(false);
        });
      },
    });
  }

  return <>
    <RequestLink
      visible={linkModal.visible}
      data={linkModal.data}
      loading={false}
      hide={linkModal.hide}
    />
    <RequestExport
      hide={requestExportModal.hide}
      data={undefined}
      loading={false}
      visible={requestExportModal.visible}
    />
    <LogisticsDataGrid
      id='requestList'
      selectState={selectState}
      getEntitiesPage={getRequestsPage}
      columns={columns}
      onRowClick={handleRowClick}
      leftHeaderElements={externalButtons}
      externalFilters={[
        {
          field: 'global',
          ...serverFilterComponent(props => <GlobalFilter {...props} />),
          serverFilterRequest: (filterMetaData, serverFilterModel: ApiRequestsGetRequest) => {
            return { ...serverFilterModel, globalFilter: filterMetaData.value} as ApiRequestsGetRequest;
          },
        },
      ]}
      storedDefaultFilterMeta={{
        global: { value: ``, matchMode: serverFilterFunctionName as any },
      }}
      others={{
        columnResizeMode: 'expand',
        resizableColumns: true,
        multiSortMeta: [{ field: 'status', order: -1 }],
        loading: loading,
      }}
    />
  </>
}

export default RequestList;