
import UnstyledInputMask from '../UnstyledInputMask';
import { focusedInputStyle } from '../Styles';
import { useMemo, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import styled from '@emotion/styled';
import { InputMaskChangeParams } from 'primereact/inputmask';
import dayjs from 'dayjs';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import { today } from '../../../shared/util/dateUtils';

const HiddenCalendar = styled(Calendar)`
  &, & input {
    all: unset;
    width: 0;
  }
`

const CustomInput = styled.div`
  & {
    display: flex;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0;
    border-bottom: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    appearance: none;
  }
  &.focused {
    ${focusedInputStyle}
  }
  &.validated {
    border-bottom: 1px solid red;
  }
`

export interface TimeOnlyProps {
  value: string | null | undefined,
  onChange: (newValue: string | undefined) => any,
  showSeconds?: boolean | undefined,
  isValidated?: boolean | undefined,
  disabled?: boolean | undefined
}

const fullTimeFormat = 'HH:mm:ss';
const fullTimeMask = '99:99:99';

function LogisticsTimeOnly(props: TimeOnlyProps) {
  const { isValidated, disabled, showSeconds } = props;
  const timeFormat = showSeconds ? fullTimeFormat : 'HH:mm';
  const timeMask = showSeconds ? fullTimeMask : '99:99';

  const calendarRef = useRef<Calendar>(null);
  const dateValue = stringToTime(props.value);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const [textValue, setTextValue] = useState(timeToString(dateValue, timeFormat));
  const underlineClass = useMemo(() => {
    if (isValidated) return 'validated';
    if (focused) return 'focused';
    return '';
  }, [isValidated, focused]);

  const handleOnCalendarChange = (e: CalendarChangeParams) => {
    const newDate = e.value instanceof Array
      ? e.value[0]
      : e.value;
    if (typeof(newDate) === 'string') {
      throw new Error('Not supported');
    }

    if (newDate === undefined || newDate === null) {
      setTextValue('');
      return;
    }

    const textToDisplay = timeToString(newDate, timeFormat);
    const textToSave = timeToString(newDate, fullTimeFormat);
    setTextValue(textToDisplay);
    props.onChange(textToSave);
  }

  const handleOnInputMaskChange = (e:  InputMaskChangeParams) => {
    const newTextTime =  e.value || '';
    if (newTextTime === textValue) return;

    setTextValue(newTextTime);

    const newDate = dayjs(newTextTime, timeFormat, true);
    if (newDate.isValid()) {
      const newTimeToString = timeToString(newDate.toDate(), fullTimeFormat);
      props.onChange(newTimeToString);
    } else {
      props.onChange(undefined);
    }
  }

  const handleTodayClick = () => {
    const date = today().format(timeMask);
    setTextValue(date);
    props.onChange(date);
  }

  return <>
    <CustomInput ref={wrapperRef} className={underlineClass}>
      <HiddenCalendar
        ref={calendarRef}
        value={dateValue || undefined}
        showTime
        timeOnly
        showSeconds={showSeconds}
        hourFormat='24'
        onChange={handleOnCalendarChange}
        showButtonBar
        onTodayButtonClick={handleTodayClick}
      />      
      <UnstyledInputMask
        className='flex-1'
        mask={timeMask}
        value={textValue}
        onChange={handleOnInputMaskChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder={timeFormat}
        disabled={disabled}
      />
      <Button
        icon='pi pi-calendar'
        className='p-button-lg p-button-rounded p-button-text p-button-plain'
        onClick={() => {
          calendarRef?.current?.show();
        }}
        disabled={disabled}
      />
    </CustomInput>
  </>
}

function stringToTime(time : string | null | undefined) {
  if (!time) return undefined;
  const date = dayjs(time, fullTimeFormat);
  return date.isValid() ? date.toDate() : undefined;
}

function timeToString(date: Date | undefined, timeFormat: string) {
  return date ? dayjs(date).format(timeFormat) : undefined;
}


export default LogisticsTimeOnly;
