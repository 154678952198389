import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getLoadedOrdersPage, extendEntities, markLoadedOrderAsRead } from './LoadedOrdersSlice';
import LogisticsDataGrid from '../../components/data-grid/LogisticsDataGrid';
import type { RootState } from '../../store/store';
import { LoadedOrderFromJSON, LoadedOrder, LoadedOrdersApi, ApiLoadedOrdersGetRequest } from '../../generated-api';
import { useAppDispatch, useModal } from '../../hooks';
import { Button } from 'primereact/button';
import LoadedOrderEdit from './LoadedOrderEdit/LoadedOrderEdit';
import { useHubConnection } from '../../hooks/SignalR';
import { apiFactory } from '../../shared';
import { showErrorToast, showSuccessToast } from '../../components/LogisticsToast';
import loadedOrdersColumnDef from './LoadedOrdersColumnDef';
import { ExternalFilterProperties } from '../../components/data-grid/LogisticsDataGridParameters';
import useRights from '../../hooks/RightsHook';
import RealtimeLoadedOrderDto from '../notifications/RealtimeLoadedOrderDto';
import sourceFilter from '../../components/filters/source/SourceFilterOperator';
import { serverFilterFunctionName } from '../../components/filters/server/ServerFilter';
import { ESource } from '../../generated-api';
import './LoadedOrderList.css';
import { AddressPickerModal, AddressPickerProps } from './AddressPickerModal';
import LoadedOrderCustomEdit from './LoadedOrderCustomEdit';
import { now } from 'lodash';
import dayjs from 'dayjs';
import LoadedOrderTimer from './LoadedOrderTimer';

const selectState = (state: RootState) => state.loadedOrder;
const columns = loadedOrdersColumnDef();

export const LoadedOrderList = () => {
  const hubConnection = useHubConnection();
  const liveUpdatesRef = useRef<boolean>(true);
  const [liveUpdates, setLiveUpdates] = useState(true);
  const [loading, setLoading] = useState(false);
  const orderDispatchModal = useModal<LoadedOrder>({});
  const addressPickerModal = useModal<AddressPickerProps>({});
  const orderEditModal = useModal<LoadedOrder>({});
  const dispatch = useAppDispatch();
  const rights = useRights(security => security.loadedOrder);

  useEffect(() => {
    hubConnection.on(
      'SendLoadedOrder',
      (json) => {
        console.debug('received SendLoadedOrder message');
        if (liveUpdatesRef.current) {
          dispatch(extendEntities([LoadedOrderFromJSON(json)]))
        }
      }
    );

    hubConnection.on(
      'ReceiveReadLoadedOrder',
      (json) => {
        const notifications = json as RealtimeLoadedOrderDto;
        dispatch(markLoadedOrderAsRead(notifications.loadedOrdersId));
      }
    );

    const onReceiveNewDriverBid = (json: any) => {
      const loadedOrder = json as LoadedOrder;
      console.debug('received ReceiveLoadedOrderWithNewDriverBid message');
      if (loadedOrder?.id) {
        dispatch(extendEntities([loadedOrder]));
      }
    }
    hubConnection.on('ReceiveLoadedOrderWithNewDriverBid', onReceiveNewDriverBid);

    return () => {
      hubConnection.off('SendLoadedOrder');
      hubConnection.off('ReceiveReadLoadedOrder');
      hubConnection.off('ReceiveLoadedOrderWithNewDriverBid', onReceiveNewDriverBid);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const liveUpdatesButton = useMemo(() => {
    const iconClass = liveUpdates ?
      "pi-pause" :
      "pi-play";

    const button = <Button
      icon={'pi ' + iconClass}
      className="p-button-info p-button-text"
      aria-label="User"
      tooltip="real time"
      onClick={() => {
        setLiveUpdates(!liveUpdates);
        liveUpdatesRef.current = !liveUpdatesRef.current;
      }}
      key="live updates"
    />;

    const addNew = <Button
      iconPos="right"
      className="p-button-success pl-5 pr-5 align-items-baseline"
      icon='pi pi-plus-circle'
      label='Check'
      onClick={() => addressPickerModal.show({})}
    />;

    const wrappingDiv = <div>{[addNew, button]}</div>;
    return [wrappingDiv];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveUpdates]);

  const adminOperations = useMemo(() => {
    let operations = [] as ExternalFilterProperties[];

    const sourceFilterOperation : ExternalFilterProperties = {
      field: 'source',
      ...sourceFilter(),
      serverFilterRequest: (filterMetaData, serverFilterModel: ApiLoadedOrdersGetRequest) => {
        return  (filterMetaData.value && (filterMetaData.value as Array<ESource>).length > 0 ? { ...serverFilterModel, source: filterMetaData.value} : serverFilterModel) as ApiLoadedOrdersGetRequest;
      },
    };

    if (rights.archive){
      operations.push({
        field: 'none',
        filterMatchMode: 'custom',
        filterMatchModeOptions: [],
        filterElement: () => <Button
          label='Archive'
          className='p-button-danger p-button-text'
          key='archive'
          onClick={handleArchiveClick}
        />,
      });
    }

    operations.push(sourceFilterOperation);

    return operations;
  }, [rights.archive]);

  const handleArchiveClick = async () => {
    try {
      setLoading(true);
      await apiFactory(LoadedOrdersApi).apiLoadedOrdersArchiveGet();
      showSuccessToast('Completed');
    } catch {
      showErrorToast('Failed to archive loaded orders');
    } finally {
      setLoading(false);
    }
  }

  // Stop all chaotic renderings.
  // Seems like any parent rendering causes to complete list re-render, which is not how it should work.
  // Todo: either refactor LogisticsDataGrid or wrap all usages with useMemo.
  const list = useMemo(() => {
    return  <LogisticsDataGrid
      id='loadedOrdersList'
      selectState={selectState}
      getEntitiesPage={getLoadedOrdersPage}
      columns={columns}
      leftHeaderElements={liveUpdatesButton}
      onRowClick={e => orderDispatchModal.show(e.data)}
      filterPresetsEnabled
      externalFilters={adminOperations}
      storedDefaultFilterMeta={{
        // @ts-ignore
        source: { value: [], matchMode: serverFilterFunctionName},
      }}
      ignoreFiltersReload
      alternativeSort={{
        server: ['receivedDate,desc'],
        // Should be sorted inside slice
        client: [],
      }}
      others={{
        selectionMode: 'single',
        loading: loading,
        rowClassName(data: LoadedOrder) {
          const hasBid = (data.bids?.length || 0) > 0;
          const hasRecentDriverBid = data.lastDriverBid
            && dayjs(data.lastDriverBid).diff(new Date(), 'minute') < 10;

          return {
            'font-bold': !data.read,
            'loaded-order-bid-placed': hasBid,
            'loaded-order-recent-driver-bid': hasRecentDriverBid,
          };
        },
      }}
    />
    // Do not add uncontrolled objects like orderDispatchModal - they are likely to be new and break memoization.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveUpdatesButton, adminOperations, loading]);
  return <>
    {list}
    <LoadedOrderEdit
      key={orderDispatchModal.data.id}
      hide={orderDispatchModal.hide}
      data={orderDispatchModal.data}
      loading={false}
      visible={orderDispatchModal.visible}
    />
    <AddressPickerModal
      hide={(addressPoint) => {
        if (addressPoint?.point) {
          orderEditModal.show({
            pickUpAt: addressPoint.address,
            pickUpAtPoint: addressPoint.point,
          } as LoadedOrder);
        }
        addressPickerModal.hide();
      }}
      data={addressPickerModal.data}
      loading={false}
      visible={addressPickerModal.visible}
      key={now().toString()}
    />
    <LoadedOrderCustomEdit
      hide={orderEditModal.hide}
      data={orderEditModal.data}
      loading={false}
      visible={orderEditModal.visible}
    />
    <LoadedOrderTimer />
  </>
};

export default LoadedOrderList;
