import { useLocalStorage, useSessionStorage } from 'primereact/hooks';
import { useCallback } from 'react';

/**
 * Stores value in session with local storage fallback
 * @param key
 * @param initialValue
 */
function useBrowserPageStorage<T>(key: string, initialValue: T) : [T, (value: T) => any] {
  const [localStorageValue, setLocalStorageValue]
    = useLocalStorage<T>(initialValue, key);
  const [sessionStorageValue, setSessionStorageValue]
    = useSessionStorage<T>(localStorageValue, key);

  const setValue = useCallback((value: T) => {
    setSessionStorageValue(value);
    setLocalStorageValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [sessionStorageValue, setValue];
}

export default useBrowserPageStorage;