import React, { SetStateAction, useCallback, useState } from 'react';
import { EEquipment, Vehicle } from '../../../generated-api';
import { StepProps } from '../step1/Step1';
import Row from '../../../components/form/Row';
import Column from '../../../components/form/Column';
import Header from '../../../components/form/Header';
import Field from '../../../components/form/Field';
import { FormikErrors, useFormik } from 'formik';
import StyledInputText from '../../../components/form/StyledInputText';
import ErrorBox from '../../../components/form/ErrorBox';
import { IdName } from '../../../data-moqs/IdName';
import Equipment from '../../../data-moqs/Equipment';
import DockSize from '../../../data-moqs/DockSize';
import EditableDropdown from '../../../components/form/EditableDropdown';
import InputNumberWithButtons from '../../../components/form/InputNumberWithButtons';
import StyledMultiselect from '../../../components/form/StyledMultiselect';
import { Button } from 'antd';
import { RegistrationApi } from '../../../generated-api/apis/RegistrationApi';
import { showErrorToast } from '../../../components/LogisticsToast';
import { apiFactory } from '../../../shared';

type VehicleWithSeparateDimensions = Vehicle & {
  cargoLength?: number,
  cargoWidth?: number,
  cargoHeight?: number,
}

function Step2(props: StepProps<Vehicle>) {
  const [vehicle, _setVehicle] = useState<Vehicle>(props.initialValue || {});
  const [loading, setLoading] = useState(false);
  const setVehicle = useCallback((action: SetStateAction<Vehicle>) => {
    _setVehicle(action);
    validation.setValues(action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validation = useFormik<VehicleWithSeparateDimensions>({
    initialValues: vehicle,
    validate: data => {
      const errors: FormikErrors<VehicleWithSeparateDimensions> = {};
      const blankError = 'This field is required';

      const requiredFields: VehicleWithSeparateDimensions = {
        vehicleMake: undefined as any,
        vehicleModel: undefined as any,
        widthDoor: undefined as any,
        heightDoor: undefined as any,
        equipment: undefined as any,
        dockSize: undefined as any,
        boxDimensions: undefined as any,
        payload: undefined as any,
        gvw: undefined as any,
      }

      Object.keys(requiredFields).forEach(field => {
        if (!(data as any)[field])
          (errors as any)[field] = blankError;
      });

      if (!data.boxDimensions?.length) {
        errors.cargoHeight = blankError;
        errors.cargoWidth = blankError;
        errors.cargoLength = blankError;
      } else {
        if (!data.boxDimensions[0]) {
          errors.cargoLength = blankError;
        }

        if (!data.boxDimensions[1]) {
          errors.cargoWidth = blankError;
        }

        if (!data.boxDimensions[2]){
          errors.cargoHeight = blankError;
        }
      }

      return errors;
    },
    onSubmit: () => {},
  });
  const save = async () =>  {
    validation.handleSubmit();
    const errors = await validation.validateForm(vehicle);
    if (Object.keys(errors).length)
      return;

    try {
      setLoading(true);
      const response = await apiFactory(RegistrationApi)
        .apiRegistrationStage2Post({vehicle});
      props.onStepComplete(response);
    } catch (error: any) {
      showErrorToast('Error');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return <>
    <Row>
      <Column>
        <Header>Vehicle info</Header>
      </Column>
    </Row>
    <Row>
      <Column className='col-6'>
        <Field label='Make and Model' required>
          <StyledInputText
            // @ts-ignore
            value={vehicle.vehicleMake}
            placeholder='Make'
            onChange={e => {
              setVehicle(vehicle => {
                return { ...vehicle, vehicleMake: e.target.value };
              })
            }}
          />
        </Field>
        <ErrorBox>{validation.errors.vehicleMake}</ErrorBox>
      </Column>
      <Column className='col-6'>
        <Field label=''>
          <StyledInputText
            // @ts-ignore
            value={vehicle.vehicleModel}
            placeholder='Model'
            onChange={e => {
              setVehicle(vehicle => {
                return { ...vehicle, vehicleModel: e.target.value };
              })
            }}
          />
        </Field>
        <ErrorBox>{validation.errors.vehicleModel}</ErrorBox>
      </Column>
    </Row>
    <Row>
      <Column className='col-6'>
        <Field label='Door dimensions' required>
          <InputNumberWithButtons
            value={vehicle.widthDoor}
            placeholder='Width'
            onChange={e => {
              setVehicle(vehicle => {
                return { ...vehicle, widthDoor: e.value };
              })
            }}
          />
        </Field>
        <ErrorBox>{validation.errors.widthDoor}</ErrorBox>
      </Column>
      <Column className='col-6'>
        <Field label=''>
          <InputNumberWithButtons
            value={vehicle.heightDoor}
            placeholder='Height'
            onChange={e => {
              setVehicle(vehicle => {
                return { ...vehicle, heightDoor: e.value };
              })
            }}
          />
        </Field>
        <ErrorBox>{validation.errors.heightDoor}</ErrorBox>
      </Column>
    </Row>
    <Row>
      <Column>
        <Field label='Equipment & dock height' required>
          <StyledMultiselect
            value={Equipment.filter(x => vehicle.equipment?.some(y => y === x.id))}
            onChange={e => setVehicle(old => {
              return { ...old, equipment: ((e.value as IdName<string>[]).map(x => x.id)) as EEquipment[] };
            })}
            options={Equipment}
            optionLabel='id'
            itemTemplate={option => option.name}
            placeholder='Equipment'
            maxSelectedLabels={20}
            className='w-full'
          />
          <ErrorBox>{validation.errors.equipment}</ErrorBox>
        </Field>
      </Column>
      <Column>
        <Field label=''>
          <EditableDropdown
            options={DockSize}
            optionLabel='id'
            renderOption={option => option.name}
            renderSelected={size => size?.name}
            renderEmptySelected={() => 'Dock height'}
            value={DockSize.find(x => vehicle.dockSize === x.id)}
            onChange={newStatus => setVehicle(old => {
              return { ...old, dockSize: newStatus.id as any };
            })}
            filter
          />
          <ErrorBox>{validation.errors.dockSize}</ErrorBox>
        </Field>
      </Column>
    </Row>
    <Row>
      <Column className='col-4'>
        <Field label='Usefull cargo dimentions (inches)' required>
          <InputNumberWithButtons
            value={vehicle.boxDimensions ? vehicle.boxDimensions[0] : null}
            placeholder='Length'
            onChange={e => setVehicle(old => {
              let newDimensions = [...old.boxDimensions || [0, 0, 0]];
              newDimensions.splice(0, 1, e.value!);
              return { ...old, boxDimensions: newDimensions };
            })}
          />
          <ErrorBox>{validation.errors.cargoLength}</ErrorBox>
        </Field>
      </Column>
      <Column className='col-4'>
        <Field label=''>
          <InputNumberWithButtons
            value={vehicle.boxDimensions ? vehicle.boxDimensions[1] : null}
            placeholder='Width'
            onChange={e => setVehicle(old => {
              let newDimensions = [...old.boxDimensions || [0, 0, 0]];
              newDimensions.splice(1, 1, e.value!);
              return { ...old, boxDimensions: newDimensions };
            })}
          />
          <ErrorBox>{validation.errors.cargoWidth}</ErrorBox>
        </Field>
      </Column>
      <Column className='col-4'>
        <Field label=''>
          <InputNumberWithButtons
            value={vehicle.boxDimensions ? vehicle.boxDimensions[2] : null}
            placeholder='Height'
            onChange={e => setVehicle(old => {
              let newDimensions = [...old.boxDimensions || [0, 0, 0]];
              newDimensions.splice(2, 1, e.value!);
              return { ...old, boxDimensions: newDimensions };
            })}
          />
          <ErrorBox>{validation.errors.cargoHeight}</ErrorBox>
        </Field>
      </Column>
    </Row>
    <Row>
      <Column>
        <Field label='Payload and GVW' required>
          <InputNumberWithButtons
            value={vehicle.payload}
            placeholder='Payload (lbs)'
            onChange={e => {
              setVehicle(old => {
                return { ...old, payload: e.value };
              })
            }}
          />
        </Field>
        <ErrorBox>{validation.errors.payload}</ErrorBox>
      </Column>
      <Column>
        <Field label=''>
          <InputNumberWithButtons
            value={vehicle.gvw ? parseFloat(vehicle.gvw) : null}
            placeholder='GVW (lbs)'
            onChange={e => {
              setVehicle(old => {
                return { ...old, gvw: e.value?.toString() };
              });
            }}
          />
        </Field>
        <ErrorBox>{validation.errors.gvw}</ErrorBox>
      </Column>
    </Row>
    <Row>
      <Column className='col-12'>
        <Button
          type="primary"
          loading={loading}
          onClick={save}
          className='w-full'
        >
          Next
        </Button>
      </Column>
    </Row>
  </>
}


export default Step2;