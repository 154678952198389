import { RootState } from '../../store/store';
import LogisticsDataGrid from '../../components/data-grid/LogisticsDataGrid';
import { getTrackingsPage } from './TrackingSlice';
import { Button } from 'primereact/button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import serverFilterComponent, { serverFilterFunctionName } from '../../components/filters/server/ServerFilter';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ApiTrackingsGetRequest, ETrackingStatus, Tracking } from '../../generated-api';
import useColumns from './TrackingColumnDef';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import TrackingEdit from './TrackingEdit';
import { useModal } from '../../hooks';
import './TrackingList.css';
import useBrowserPageStorage from '../../hooks/BrowserStorageHook';
import useRights from '../../hooks/RightsHook';
import VisibilityToggler from '../../components/VisibilityToggler';

const selectState = (state: RootState) => state.tracking;

export interface TrackingListParameters {
  delivered: boolean,
}

function TrackingList(props: TrackingListParameters) {
  const columns = useColumns();
  const rights = useRights(security => security.tracking);
  // eslint-disable-next-line
  const [weekFromStorage, setWeekFromStorage] = useBrowserPageStorage<Dayjs | null>('tracking_week', dayjs());
  const trackingModal = useModal<Tracking>({});

  const handleOnHide = () => {
    // setting id to re-mount edit page component via key parameter
    trackingModal.setData({id: -1})
    trackingModal.hide();
  }

  return <>
    <LogisticsDataGrid
      id={`trackingList_${props.delivered ? 'delivered' : 'tracking'}`}
      selectState={selectState}
      getEntitiesPage={getTrackingsPage}
      columns={columns}
      onRowClick={e => trackingModal.show(e.data)}
      leftHeaderElements={[
        <VisibilityToggler visible={rights.create}>
          <div className='mx-2'>
            <Button
              className='p-button-success px-5 ml-2 text-base font-bold'
              onClick={() => trackingModal.show({})}
            >
              <p className='my-0'>Create</p>
              <AddCircleOutlineIcon fontSize='medium' className='ml-1'/>
            </Button>
          </div>
        </VisibilityToggler>
      ]}
      externalFilters={[
        {
          field: 'global',
          ...serverFilterComponent(props => <>
            <p className='text-base font-normal'>Filter:</p>
            <Input className='w-4 text-base py-2'
                   placeholder="Type to filter..."
                   onChange={e => {
                     props.filterApplyCallback(e.target.value);
                   }}
                   value={props.value}
                   suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)', fontSize: 15 }} />}
            />
          </>),
          serverFilterRequest: (filterMetaData, serverFilterModel: ApiTrackingsGetRequest) => {
            return { ...serverFilterModel, global: filterMetaData.value} as ApiTrackingsGetRequest;
          },
        },
        {
          field: 'delivered',
          ...serverFilterComponent(() => <></>),
          serverFilterRequest: (filterMetaData, serverFilterModel: ApiTrackingsGetRequest) => {
            return { ...serverFilterModel, delivered: filterMetaData.value };
          }
        }
      ]}
      storedDefaultFilterMeta={{
        global: { value: ``, matchMode: serverFilterFunctionName as any },
        'delivered': { value: props.delivered, matchMode: serverFilterFunctionName as any },
      }}
      alwaysDefaultFilterMeta={{
        'number': { value: `${dayjs(weekFromStorage).tz().format('YY-w')}-`, matchMode: serverFilterFunctionName as any },
      }}
      others={{
        columnResizeMode: "expand",
        resizableColumns: true,
        selectionMode: 'single',
        rowClassName(rowData: Tracking) {
          return {
            '': !rowData.status || rowData.status === ETrackingStatus.NotSet,
            'tracking-attention': rowData.status === ETrackingStatus.Attention,
            'tracking-checking': rowData.status === ETrackingStatus.Checking,
            'tracking-loaded': rowData.status === ETrackingStatus.Loaded,
            'tracking-delivered': rowData.status === ETrackingStatus.Delivered,
            'tracking-hidden': rowData.status === ETrackingStatus.Hidden,
          }
        }
      }}
    />
    <TrackingEdit
      key={trackingModal.data.id}
      hide={handleOnHide}
      data={trackingModal.data}
      loading={false}
      visible={trackingModal.visible}
    />
  </>
}

export default TrackingList;