import React, { useMemo } from 'react';
import { EVehicleExpiryKind, Vehicle, VehicleExpiryNotification } from '../../../../generated-api';
import VehicleEdit from '../../../vehicles/VehicleEdit';
import { useModal } from '../../../../hooks';
import dayjs from 'dayjs';
import { LinkLikeSpan } from './LinkLikeSpan';
import { RedCircle } from './RedCircle';

function VehicleExpiryFormatter(props: { jsonData: string }) {
  const data = useMemo(
    () => JSON.parse(props.jsonData) as VehicleExpiryNotification,
    [props.jsonData]);
  const modal = useModal({ id: data.vehicleId } as Vehicle);

  // Have to recreate VehicleEdit because it does not load data otherwise
  const vehicleEdit = useMemo(() => {
    return modal.visible
      ? <VehicleEdit data={modal.data} loading={false} visible={modal.visible} hide={modal.hide} />
      : <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.visible]);

  const expiryType = useMemo(() => {
    if (data.expiryKind === EVehicleExpiryKind.Insurance)
      return 'vehicle insurance';

    if (data.expiryKind === EVehicleExpiryKind.License)
      return 'license';

    return 'vehicle';
  }, [data.expiryKind]);

  const expiryDate = useMemo(() => {
    if (!data.expiryDate) return '';

    // Do not convert timezones because there is no time part
    return dayjs(data.expiryDate).format('MM/DD/YYYY');
  }, [data.expiryDate]);

  return <>
    <RedCircle />
    <span>
      The unit&nbsp;
      <LinkLikeSpan onClick={() => modal.show({id: data.vehicleId!})}>
        #{data.vehicleId}&nbsp;
      </LinkLikeSpan>
      {expiryType} expires at {expiryDate}
    </span>
    {vehicleEdit}
  </>
}

export default VehicleExpiryFormatter;