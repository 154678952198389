import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

interface CenteredSpinnerProps {
  visible: boolean;
}

const CenteredSpinner = (props: CenteredSpinnerProps) => {
  return <>
    {props.visible && <div className='logistics-centered-spinner'>
      <ProgressSpinner className='edit-form-spinner' />
    </div>}
  </>;
};

export default CenteredSpinner;