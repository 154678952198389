import React from 'react';
import Env from '../../config/Env';
import styled from '@emotion/styled';

const StyledAnchor = styled.a`
  & {
    font-family: monospace;
  }
`
function Logo() {
  return (
    // TODO спрячем для показа
    <StyledAnchor href="/" className='flex flex-nowrap ml-5 mr-5 ql-font-monospace font-bold text-color no-underline text-xl opacity-0'>
      {Env.app.name}
    </StyledAnchor>
  );
}

export default Logo;