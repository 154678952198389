import styled from '@emotion/styled';
import { Panel } from 'primereact/panel';

const DashboardPanel = styled(Panel)`
  & {
    margin: 1rem;
  }

  &.p-panel .p-panel-content {
    border: 1px solid #dee2e6;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-radius: 15px;
  }
`

export default DashboardPanel;
