import styled from '@emotion/styled';
import { InputText } from 'primereact/inputtext';
import { focusedInputStyle, inputStyle } from './Styles';

const StyledInputText = styled(InputText)`
  & {
    ${inputStyle}
  }

  &:enabled:focus {
    ${focusedInputStyle}
  }

  &.p-invalid:focus {
    border-color: red;
  }
`
export default StyledInputText;