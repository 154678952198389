import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import LogisticsDataGridParameters from './LogisticsDataGridParameters';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  DataTableFilterMeta,
  DataTableFilterMetaData,
} from 'primereact/datatable';
import { Button } from 'primereact/button';
import LogisticsPagination from './pagination/LogisticsPagination';
import useFilterPresets, { TFilter, TFilterPreset, TFilters } from './filters/FilterPresetsHook';
import EditableDropdown from '../form/EditableDropdown';
import FilterSaver from './filters/FilterSaver';
import { isDataTableFilterMetaData } from '../../shared';
import useFilters from './filters/FiltersHook';
import LogisticsDropdownItem from '../form/LogisticsDropdownItem';
import { logisticsConfirmDialog } from '../LogisticsConfifmDialog';
import useDebounce from '../../hooks/DebounceHook';
import LogisticsDataGridBase from './LogisticsDataGridBase';
import { isEqual } from 'lodash';
import useSorting from './sorting/SortingHook';
import { isMobile } from 'react-device-detect';

const pageSize: number = 100;



/**
 * Universal data table
 * @param props
 * @constructor
 */
function LogisticsDataGrid<TEntity>(props: LogisticsDataGridParameters<TEntity>) {
  const {
    getEntitiesPage,
    columns,
    leftHeaderElements,
    selectState,
    externalFilters,
    storedDefaultFilterMeta,
    alwaysDefaultFilterMeta,
    globalFilterValue,
    globalFilterColumns,
    others,
    ignoreFiltersReload
  } = props;
  const entities = useAppSelector(selectState).entities;
  const entitiesCount = useAppSelector(selectState).totalItems;

  const [page, setPage] = useState<number>(0);
  const { serverSort, clientSort, setSort }
    = useSorting(props.id, others?.multiSortMeta, columns, props.alternativeSort);
  const dispatch = useAppDispatch();
  const [
    defaultFilters,
    filters,
    serverFilters,
    setFilters,
  ] = useFilters(
    props.id,
    columns,
    storedDefaultFilterMeta,
    alwaysDefaultFilterMeta,
    externalFilters);
  const [
    allPresets,
    currentPreset,
    setCurrentPreset,
    savePreset,
    deletePreset,
  ] = useFilterPresets(props.id);
  const [filterSaverVisible, setFilterSaverVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const serverFiltersSaved = useRef(serverFilters);
  const filtersForSave = useMemo(() => {
    if (!filterSaverVisible) return undefined;
    if (!filters) return undefined;

    return Object.keys(filters).reduce((result, field) => {
      const column = columns.find(column => column.field === field);
      const filter = filters[field];
      if (!isDataTableFilterMetaData(filter)) return result;

      const newFilter = {
        ...filter,
        caption: column?.header,
      } as TFilter;
      newFilter.formattedValue = column?.filterValueToString
        ? column.filterValueToString(filter)
        : filter.value;

      return { ...result, [field]: newFilter}
    }, {} as TFilters);
  }, [filterSaverVisible, filters, columns]);

  const reloadList = () => {
    setLoading(true);
    dispatch(getEntitiesPage({
      size: pageSize,
      page: page - 1,
      sort: serverSort,
      ...serverFilters,
    })).finally(() => setLoading(false));
  };

  const reloadListWithEqualityCheck = useCallback(() => {
    if (ignoreFiltersReload && isEqual(serverFilters, serverFiltersSaved.current)) {
      return; 
    }

    serverFiltersSaved.current = serverFilters;
    reloadList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverFilters, ignoreFiltersReload]);

  const reloadListDebounced = useDebounce(reloadListWithEqualityCheck , 500);

  const reloadListMemorized = useCallback(
    reloadList,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, serverSort, serverFilters]);

  const confirmAndApplyPreset = useCallback((preset: TFilterPreset) => {
    logisticsConfirmDialog({
      message: 'The filter is installed. Set a preset?',
      rejectClassName: 'hidden',
      accept() {
        setCurrentPreset(preset);
        setFilters(preset?.filters);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmAndDeletePreset = useCallback((preset: TFilterPreset) => {
    logisticsConfirmDialog({
      message: 'Delete preset?',
      closable: false,
      accept() {
        const currentPresetId = currentPreset?.id;
        deletePreset(preset).then(() => {
          if (currentPresetId === preset!.id) {
            setFilters(defaultFilters);
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePreset, ]);

  useEffect(() => {
    reloadListMemorized();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, serverSort]);

  useEffect(() => {
    reloadListDebounced();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverFilters]);

  const filtersAreDefault = useCallback(() => {
    if (!defaultFilters) return !defaultFilters && !filters;
    if (!filters) return false;

    return Object.keys(defaultFilters).every(property => {
      const defaultFilter = defaultFilters[property] as DataTableFilterMetaData;
      const filter = filters[property] as DataTableFilterMetaData;

      return defaultFilter.value === filter.value;
    })
  }, [defaultFilters, filters]);

  const saveFilterButton = useMemo(() => {
    if (!props.filterPresetsEnabled) return <></>;

    return <Button
      className='p-button-text'
      label='Save filter'
      onClick={() => setFilterSaverVisible(true)}
      key='saveFilter'
      disabled={filtersAreDefault()}
    />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterPresetsEnabled, filtersAreDefault]);
  const filterDropdown = useMemo(() => {
    if (!props.filterPresetsEnabled) return <></>;

    return <EditableDropdown
      options={allPresets}
      optionLabel='filter'
      renderOption={option => <div className='relative'>
        <LogisticsDropdownItem>{option?.name}</LogisticsDropdownItem>
        <Button
          icon='pi pi-times'
          className='p-button-plain p-button-text mx-1 border-none text-red-400 absolute right-0 top-0'
          onClick={e => {
            confirmAndDeletePreset(option);
            e.stopPropagation();
          }}
        />
      </div>}
      renderSelected={selected => selected?.name}
      value={currentPreset}
      renderEmptySelected={() => <span className='opacity-50'>
        {'Filters...'}
      </span>}
      onChange={newValue => {
        if (!newValue) return;
        if (!currentPreset) {
          confirmAndApplyPreset(newValue);
        } else {
          setCurrentPreset(newValue);
          setFilters(newValue.filters);
        }
      }}
      others={{
        style: { minWidth: '20rem' }
      }}
      key='selectFilter'
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterPresetsEnabled, currentPreset, allPresets]);

  const reloadButton = useMemo(() => <Button
    icon="pi pi-refresh"
    className="p-button-rounded p-button-info p-button-text"
    onClick={reloadListMemorized}
    key="reload"
  />, [reloadListMemorized]);

  const resetFiltersButton = useMemo(
    () => <Button
      icon="pi pi-times"
      className="p-button-rounded p-button-info p-button-text"
      onClick={() => handleOnFilter(defaultFilters)}
      key="reset"
    />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultFilters]);

  const filterSaver = useMemo(() => {
    if (!filtersForSave) return <></>;
    const filterPreset = { filters: filtersForSave} as TFilterPreset;

    return filterSaverVisible
      ? <FilterSaver
        data={filterPreset}
        loading={false}
        visible={filterSaverVisible}
        hide={preset => {
          setFilterSaverVisible(false);
          savePreset(preset);
        }}
      />
      : <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForSave]);

  const handleOnFilter = (f: DataTableFilterMeta | undefined)  => {
    setFilters(f);
    setCurrentPreset(undefined);
  }

  const leftHeaderElementsWrapped = useMemo(() => {
    const test = props.externalFilters?.map(externalFilter => {
      return externalFilter.filterElement({
        value: (filters && filters[externalFilter.field] as any)?.value,
        index: 0,
        field: externalFilter.field,
        // @ts-ignore
        filterModel: filters,
        filterApplyCallback: (value?: any) => {
          const newFilters = {
            ...filters,
            [externalFilter.field]: { value: value, matchMode: externalFilter.filterMatchMode || 'contains' },
          };
          handleOnFilter(newFilters);
        }
      });
    }) || [];
    const wrappingDiv = <div className='flex' style={{flex: '1 0'}}>{test}</div>;
    return [wrappingDiv, ...((isMobile ? leftHeaderElements : [])|| [])];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.externalFilters, filters, isMobile, leftHeaderElements]);

  return (
    <>
      {filterSaver}
      <LogisticsDataGridBase
        {...props}
        entities={entities as TEntity[]}
        columns={columns}
        rightHeaderElements={[
          ...((isMobile ? [] : leftHeaderElements)|| []),
          saveFilterButton,
          filterDropdown,
          resetFiltersButton,
          reloadButton,
        ]}
        leftHeaderElements={leftHeaderElementsWrapped}
        others={{
          ...others,
          filters: filters,
          multiSortMeta: clientSort,
          onFilter: e => handleOnFilter(e.filters),
          onSort: e => setSort(e.multiSortMeta),
          globalFilter: globalFilterValue,
          globalFilterFields: globalFilterColumns,
          loading: loading || props.others?.loading,
          footer: <LogisticsPagination
            onPageChange={(newPage: number) => setPage(newPage)}
            totalRecords={entitiesCount || 0}
            recordsPerPage={100}
          />
        }}
      />
    </>
  );
}

export default LogisticsDataGrid;