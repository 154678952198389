import styled from '@emotion/styled';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button/button';

const StyledButton = styled(Button)`
    & {
        border-width: 0;
        border-image-width: 0;
        padding: 0.5rem;
        height: auto;
        background-color: transparent;
    }

    & svg {
        width: 3rem;
        height: 3rem;
    }
`;

const Container = styled.div`
    & {
        display: flex;
        flex-direction: column;
        width: 5rem;
        height: 5rem;
    }
`

function EditorButton(props : ButtonProps & { active: boolean }) {
  return <StyledButton {...props}>
    <Container style={{
      ...props.active ? { backgroundColor: 'lightgray', borderRadius: '0.5rem' } : {},
    }}>
      {props.children}
    </Container>
  </StyledButton>
}

export default EditorButton;
