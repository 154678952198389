import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import entitiesReducers from '../features/reducers';
import security from '../features/security/SecuritySlice';

export const store = configureStore({
  reducer: {
    ...entitiesReducers,
    security,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Todo почему-то ему не нравияся поля типа Date, говорит что не сериализуемые.
      // Надо разбираться
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
