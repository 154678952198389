import styled from '@emotion/styled';

const LogisticsDropdownItem = styled.div`
  & {
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    font-weight: normal !important;
    text-shadow: none !important;
    transition: background-color 0.15s
  }
  &:not(.p-highlight):not(.p-disabled):hover {
    background-color: var(--surface-c);
  }
`

export default LogisticsDropdownItem;