/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EAuthResult } from './EAuthResult';
import {
    EAuthResultFromJSON,
    EAuthResultFromJSONTyped,
    EAuthResultToJSON,
} from './EAuthResult';

/**
 * 
 * @export
 * @interface MobileAuthResponse
 */
export interface MobileAuthResponse {
    /**
     * 
     * @type {string}
     * @memberof MobileAuthResponse
     */
    token?: string | null;
    /**
     * 
     * @type {EAuthResult}
     * @memberof MobileAuthResponse
     */
    result?: EAuthResult;
    /**
     * 
     * @type {string}
     * @memberof MobileAuthResponse
     */
    details?: string | null;
}

/**
 * Check if a given object implements the MobileAuthResponse interface.
 */
export function instanceOfMobileAuthResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MobileAuthResponseFromJSON(json: any): MobileAuthResponse {
    return MobileAuthResponseFromJSONTyped(json, false);
}

export function MobileAuthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileAuthResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'result': !exists(json, 'result') ? undefined : EAuthResultFromJSON(json['result']),
        'details': !exists(json, 'details') ? undefined : json['details'],
    };
}

export function MobileAuthResponseToJSON(value?: MobileAuthResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'result': EAuthResultToJSON(value.result),
        'details': value.details,
    };
}

