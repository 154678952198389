import React, { useState, useEffect, useCallback } from 'react';
import { ListOfLoadsApi, SystemSettingsApi, SystemSettings } from '../../generated-api';
import { apiFactory } from '../../shared';
import { ModalParameters } from '../../shared';
import CenteredSpinner from '../../components/CenteredSpinner';
import LogisticsDialog from '../../components/LogisticsDialog';
import LogisticsWeekCalendar from '../../components/form/datetime/LogisticsWeekCalendar';
import { showSuccessToast, showToast } from '../../components/LogisticsToast';
import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import FileSaver from 'file-saver';

export interface ListOfLoadsExportParameters extends ModalParameters<undefined> {

}

function ListOfLoadsExport(props: ListOfLoadsExportParameters) {
  const [selectedWeek, setSelectedWeek] = useState<Dayjs | null | undefined>(dayjs());
  const [loading, setLoading] = useState(props.loading);
  const [systemSettings, setSystemSettings] = useState<SystemSettings>();

  useEffect(() => {
    if (!props.visible) return;

    setLoading(true);

    apiFactory(SystemSettingsApi).apiSystemSettingsGet().then((loadedSystemSettings) => {
      if (loadedSystemSettings && loadedSystemSettings.length) {
        setSystemSettings(loadedSystemSettings[0])
      }
    }).catch(reason => {
      showToast({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong with preloading information on the form',
        life: 2000
      });
      console.error('Error on orders loading:', reason);
    })
    .finally(() => {
      setLoading(false);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.visible]);

  const handleClick = (e: MouseEvent) => {
    // @ts-ignore
    e.target!.nextSibling!.click();
  };

  const handleOnHide = () => {
    props.hide(undefined);
    setSelectedWeek(dayjs().tz());
    setLoading(false);
  };

  const handleExport = useCallback(async () => {
    try {
      setLoading(true);

      const currentWeekValueAsString = `${selectedWeek?.format('YY-w')}-`;
      const currentOrgAbbreviation = systemSettings?.abbreviation || 'PL';

      const response = await apiFactory(ListOfLoadsApi)
        .apiListOfLoadsWeekExportGet({ selectedWeek: currentWeekValueAsString, orgName: currentOrgAbbreviation });

      FileSaver.saveAs(response!, `list_of_load_${currentOrgAbbreviation}${currentWeekValueAsString.slice(0, -1)}.xlsx`);
      showSuccessToast('Data successfully exported');
    } catch(e: any) {
      showToast({
        severity: 'error',
        summary: 'Error',
        detail: 'Error on exporting data',
        life: 2000
      });
      throw e;
    } finally {
      setLoading(false);
    }
  }, [selectedWeek, systemSettings]);

  return (
    <LogisticsDialog
      visible={props.visible}
      onHide={handleOnHide}
      className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-4'
      dismissableMask
    >
      <header className='text-2xl w-full flex-wrap'>
        <span className='font-bold'>Export</span>
      </header>
      <main className='mt-6 mr-3'>
        <LogisticsWeekCalendar
          value={selectedWeek}
          onChange={(value) => {
            setSelectedWeek(value);

            // removing event listeners for week clicking events
            document.querySelectorAll<HTMLElement>('.ant-picker-cell-week').forEach((e) => {
              e.removeEventListener("click", handleClick);
            });
          }}
          setSelectedWeek={setSelectedWeek}
          handleClick={handleClick}
          className='w-full'
        />
      </main>
      <footer className='flex justify-content-center w-full gap-3 mt-6'>
        <Button label='Close' className='min-w-min w-6rem h-3rem'
          onClick={handleOnHide}></Button>
        <Button label='Download' className='p-button-success min-w-min w-6rem h-3rem' disabled={!selectedWeek}
          onClick={handleExport}></Button>
      </footer>
      <CenteredSpinner visible={loading} />
    </LogisticsDialog>
  );
}

export default ListOfLoadsExport;