/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Audit,
} from '../models';
import {
    AuditFromJSON,
    AuditToJSON,
} from '../models';

export interface ApiChangelogGetRequest {
    entityName?: string;
    entityId?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class ChangelogApi extends runtime.BaseAPI {

    /**
     */
    async apiChangelogGetRaw(requestParameters: ApiChangelogGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Audit>>> {
        const queryParameters: any = {};

        if (requestParameters.entityName !== undefined) {
            queryParameters['entityName'] = requestParameters.entityName;
        }

        if (requestParameters.entityId !== undefined) {
            queryParameters['entityId'] = requestParameters.entityId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/changelog`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuditFromJSON));
    }

    /**
     */
    async apiChangelogGet(requestParameters: ApiChangelogGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Audit>> {
        const response = await this.apiChangelogGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
