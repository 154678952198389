import { InputNumber } from 'primereact/inputnumber';
import VisibilityToggler from '../VisibilityToggler';

export interface PricesData {
  priceForBroker?: number | null;
  priceForDrive?: number | null;
  pricePerMileBr?: number | null;
  pricePerMileDr?: number | null;
  profit?: number | null;
  miles?: number | null;
}

type ValueType = number | undefined | null;

export interface PricesParameters {
  data: PricesData;
  minProfit?: number | null;
  onPricesChange: (data : PricesData) => void;
}
const reCalculateProfit = (data: PricesData) => {
  if (!data.priceForDrive || !data.priceForBroker) {
    data.profit = undefined;
    return;
  }

  data.profit = Math.ceil(
    ((data.priceForBroker - data.priceForDrive) * 100) / data.priceForBroker);
};

const getPerMileValue = (value: ValueType, miles: number | undefined | null) => {
  return value && miles
    ? parseFloat((value / miles).toFixed(2))
    : undefined;
};

const PricesBlock = ({data, minProfit, onPricesChange}: PricesParameters) => {
  const minProfitOrDefault = minProfit || 20;

  const onPriceForBrokerChange = (value: ValueType) => {
    if (!data.priceForBroker && !value) return;

    data.priceForBroker = value || undefined;
    data.pricePerMileBr = getPerMileValue(value, data.miles);
    reCalculateProfit(data);
    onPricesChange(data);
  };
  const onPriceForDriveChange = (value: ValueType) => {
    if (!data.priceForDrive && !value) return;

    data.priceForDrive = value || undefined;
    data.pricePerMileDr = getPerMileValue(value, data.miles);
    reCalculateProfit(data);
    onPricesChange(data);
  };
  const onPricePerMileBrChange = (value: ValueType) => {
    if (!data.pricePerMileBr && !value) return;

    data.pricePerMileBr = value;
    data.priceForBroker = value && data.miles ? value * data.miles : undefined;
    reCalculateProfit(data);
    onPricesChange(data);
  };
  const onPricePerMileDrChange = (value: ValueType) => {
    if (!data.pricePerMileDr && !value) return;

    data.pricePerMileDr = value;
    data.priceForDrive = value && data.miles ? value * data.miles : undefined;
    reCalculateProfit(data);
    onPricesChange(data);
  };

  const isProfitLessThanMinimum = (data.profit ?? 0) < minProfitOrDefault;
  const profitClass = isProfitLessThanMinimum
    ? 'text-red-500'
    : 'text-green-500';
  return <div className='grid'>
    <div className='col-6 p-fluid'>
      <div className='pt-4'>
        <label htmlFor='brokerPrice'>Price for Broker</label>
        <InputNumber 
          inputId='brokerPrice' 
          currency='USD'
          value={data.priceForBroker}
          mode="currency"
          locale="en-US"
          onChange={(e) => onPriceForBrokerChange(e.value)} />
      </div>
      <div className='pt-4'>
        <label htmlFor='brokerPricePerMile'>Price per mile</label>
        <InputNumber 
          inputId='brokerPricePerMile'
          currency='USD'
          suffix='  /mi' 
          value={data.pricePerMileBr}
          mode="currency"
          locale="en-US"
          onChange={(e) => onPricePerMileBrChange(e.value)} />
      </div>
    </div>
    <div className='col-6 p-fluid'>
      <div className='pt-4'>
        <label htmlFor='driverPrice'>Price for Driver</label>
        <InputNumber 
          inputId='driverPrice' 
          currency='USD'
          value={data.priceForDrive}
          mode="currency"
          locale="en-US"
          onChange={(e) => onPriceForDriveChange(e.value)} />
      </div>
      <div className='pt-4'>
        <label htmlFor='driverPricePerMile'>Price per mile</label>
        <InputNumber
          inputId='driverPricePerMile'
          currency='USD'
          suffix='  /mi'
          value={data.pricePerMileDr}
          mode="currency"
          locale="en-US"
          onChange={(e) => onPricePerMileDrChange(e.value)} />
      </div>
    </div>
    <div className='col-12 pt-1 flex justify-content-center font-bold'>
      <div className={'text-2xl ' + profitClass}>
        {data.profit !== undefined ? data.profit + '%' : ''}
      </div>
    </div>
    <VisibilityToggler visible={isProfitLessThanMinimum && data.profit !== undefined}>
      <div className='col-12 pt-1 flex justify-content-center font-bold'>
        <div className={'text-lg ' + profitClass}>
          {`Pay attention, your profit is less than the minimum set of ${minProfitOrDefault}%`}
        </div>
      </div>
    </VisibilityToggler>
  </div>;
};

export default PricesBlock;