/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleReservation
 */
export interface VehicleReservation {
    /**
     * 
     * @type {string}
     * @memberof VehicleReservation
     */
    dispatcherId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleReservation
     */
    vehicleId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleReservation
     */
    dispatcherLogin?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof VehicleReservation
     */
    bidCreationDate?: Date | null;
}

/**
 * Check if a given object implements the VehicleReservation interface.
 */
export function instanceOfVehicleReservation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleReservationFromJSON(json: any): VehicleReservation {
    return VehicleReservationFromJSONTyped(json, false);
}

export function VehicleReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleReservation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dispatcherId': !exists(json, 'dispatcherId') ? undefined : json['dispatcherId'],
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'dispatcherLogin': !exists(json, 'dispatcherLogin') ? undefined : json['dispatcherLogin'],
        'bidCreationDate': !exists(json, 'bidCreationDate') ? undefined : (json['bidCreationDate'] === null ? null : new Date(json['bidCreationDate'])),
    };
}

export function VehicleReservationToJSON(value?: VehicleReservation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dispatcherId': value.dispatcherId,
        'vehicleId': value.vehicleId,
        'dispatcherLogin': value.dispatcherLogin,
        'bidCreationDate': value.bidCreationDate === undefined ? undefined : (value.bidCreationDate === null ? null : value.bidCreationDate.toISOString()),
    };
}

