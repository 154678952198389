import React, { useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import PageButton from './PageButton';
import Dots from './Dots';
import { InputNumber } from 'primereact/inputnumber';
import { isMobile } from 'react-device-detect';

const settings = isMobile ? {
  maxElements: 5,
  elementsInRow: 3,
} : {
  maxElements: 7,
  elementsInRow: 5,
};

function LogisticsPagination(props: {
  onPageChange: (newPage: number) => any,
  totalRecords: number,
  recordsPerPage: number,
}) {
  const {
    totalRecords,
    recordsPerPage,
  } = props;

  // Первая странице имеет номер 1.
  const [page, setPage] = useState(1);
  const [userInputPage, setUserInputPage] = useState<number | null>(null);
  const totalPages = useMemo(
    () => Math.ceil(totalRecords / recordsPerPage),
    [totalRecords, recordsPerPage]);

  const handlePageChange = (pageCallback: (prev: number) => number) => {
    let newPage = pageCallback(page);
    if (newPage > totalPages) {
      newPage = totalPages;
    } else if (newPage < 1) {
      newPage = 1;
    }
    setPage(pageCallback);
    props.onPageChange(newPage);
  }

  const handleUserPageSubmit = () => {
    if (!userInputPage) return;

    if (userInputPage < 1 || userInputPage > totalPages) {
      setUserInputPage(null);
      return;
    }

    handlePageChange(_ => userInputPage);
    setUserInputPage(null);
  }

  let buttonLinks: JSX.Element[];
  // 5 первых страниц + многоточие + последняя.
  if (page >= 1 && page <= settings.elementsInRow - 1) {
    const startPages = settings.elementsInRow > totalPages
      ? totalPages
      : settings.elementsInRow;
    buttonLinks = Array.from(Array(startPages).keys()).map(index =>{
      return <PageButton
        page={index + 1}
        handlePageChange={handlePageChange}
        current={index + 1 === page}
        key={index + 1}
      />
    });

    if (totalPages > settings.maxElements) {
      buttonLinks.push(<Dots key="head dots"/>);
      buttonLinks.push(<PageButton
        page={totalPages}
        handlePageChange={handlePageChange}
        current={false}
        key={totalPages}
      />);
    }
  // Первая + многоточие + 5 последних страниц
  } else if (page > totalPages - settings.elementsInRow + 1 && page <= totalPages){
    buttonLinks = Array.from(Array(settings.elementsInRow).keys()).map(index => {
      const linkedPage = index + 1 - settings.elementsInRow + totalPages;
      return <PageButton
        page={linkedPage}
        handlePageChange={handlePageChange}
        current={page === linkedPage}
        key={linkedPage}
      />
    });

    buttonLinks = [
      <PageButton
        page={1}
        handlePageChange={handlePageChange}
        current={false}
        key={1}
      />,
      <Dots key="tail dots"/>,
      ...buttonLinks
    ];
  // Первая, многоточие, 4 ближайших, многоточие, последяя.
  } else {
    buttonLinks = [
      <PageButton page={1} key={1} handlePageChange={handlePageChange} />,
      <Dots key="head dots" />,
      <PageButton page={page - 1} key={page - 1} handlePageChange={handlePageChange}  />,
      <PageButton page={page} key={page} handlePageChange={handlePageChange} current />,
      <PageButton page={page + 1} key={page + 1} handlePageChange={handlePageChange} />,
      <Dots key="tail dots"/>,
      <PageButton page={totalPages} key={totalPages} handlePageChange={handlePageChange} />
    ];
  }
  return (
    <div className='flex flex-row justify-content-center align-items-center'>
      <Button
        icon={'pi pi-angle-left'}
        className="p-button-info p-button-text"
        aria-label="User"
        onClick={() => handlePageChange(page => page - 1)}
        disabled={page <= 1}
      />
      <span className="p-paginator-pages">
        {buttonLinks}
      </span>
      <Button
        icon={'pi pi-angle-right'}
        className="p-button-info p-button-text"
        aria-label="User"
        onClick={() => handlePageChange(page => page + 1)}
        disabled={page >= totalPages}
      />
      <Button
        onClick={handleUserPageSubmit}
        className='py-2 mr-2 bg-transparent border-gray-400 text-color hover:surface-400 font-bold'
      >
        Go to
      </Button>
      <InputNumber
        value={userInputPage}
        onChange={(e => setUserInputPage(e.value))}
        onKeyDown={(e) => { if (e.key === 'Enter') handleUserPageSubmit()}}
        inputClassName='flex w-4rem py-2'
      />
    </div>
  );
}

export default LogisticsPagination;