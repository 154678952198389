import React, { ForwardedRef, forwardRef, RefObject } from 'react';
import { ReactSketchCanvas, ReactSketchCanvasProps, ReactSketchCanvasRef } from 'react-sketch-canvas';
import { Button } from 'primereact/button';

const LogisticsCanvas = forwardRef((props: ReactSketchCanvasProps, ref: ForwardedRef<ReactSketchCanvasRef>) => {
  return <div className='relative'>
    <ReactSketchCanvas
      ref={ref}
      canvasColor={'#FFFFFF'}
      strokeColor={'#000000'}
      withTimestamp
      {...props}
    />
    <Button
      icon='pi pi-trash'
      className='p-button-plain p-button-text mx-1 border-none absolute right-15 top-15'
      onClick={e => {
        e.preventDefault();
        (ref as RefObject<ReactSketchCanvasRef>).current?.clearCanvas();
      }}
    />
  </div>
});

export default LogisticsCanvas;