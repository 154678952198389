import styled from '@emotion/styled';

export const RedCircle = styled.div`
  & {
    height: 1rem;
    width: 1rem;
    background-color: darkred;
    border-radius: 50%;
    display: inline-block;
    margin-right: 1rem;
  }
`;