/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Brokerage,
  BrokerageInformationResponse,
  CompanyInformationResponse,
  DispatcherStatisticsResponse,
  EWidgetChart,
  EWidgetPeriodType,
  LoadInformationResponse,
  OwnerInformationResponse,
  ProfitStatisticsResponse,
  RevenueByDispatcherResponse,
  TopVehiclesResponse,
  UserDto,
  WidgetOneResponse,
  WidgetParameters,
} from '../models';
import {
    BrokerageFromJSON,
    BrokerageToJSON,
    BrokerageInformationResponseFromJSON,
    BrokerageInformationResponseToJSON,
    CompanyInformationResponseFromJSON,
    CompanyInformationResponseToJSON,
    DispatcherStatisticsResponseFromJSON,
    DispatcherStatisticsResponseToJSON,
    EWidgetChartFromJSON,
    EWidgetChartToJSON,
    EWidgetPeriodTypeFromJSON,
    EWidgetPeriodTypeToJSON,
    LoadInformationResponseFromJSON,
    LoadInformationResponseToJSON,
    OwnerInformationResponseFromJSON,
    OwnerInformationResponseToJSON,
    ProfitStatisticsResponseFromJSON,
    ProfitStatisticsResponseToJSON,
    RevenueByDispatcherResponseFromJSON,
    RevenueByDispatcherResponseToJSON,
    TopVehiclesResponseFromJSON,
    TopVehiclesResponseToJSON,
    UserDtoFromJSON,
    UserDtoToJSON,
    WidgetOneResponseFromJSON,
    WidgetOneResponseToJSON,
    WidgetParametersFromJSON,
    WidgetParametersToJSON,
} from '../models';

export interface ApiDashboardBidsByHourGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardBrokerageInformationExportPostRequest {
    widgetParameters?: WidgetParameters;
}

export interface ApiDashboardBrokerageInformationPostRequest {
    widgetParameters?: WidgetParameters;
}

export interface ApiDashboardBrokeragesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardCompanyInformationGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardDispatcherStatisticsExportGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardDispatcherStatisticsGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardDispatchersDispatcherStatisticsGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardDispatchersGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardDispatchersOwnerInformationGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardDispatchersRevenueByDispatcherGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardDispatchersTopVehiclesGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardLoadInformationGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardOwnerInformationExportPostRequest {
    widgetParameters?: WidgetParameters;
}

export interface ApiDashboardOwnerInformationGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardProfitStatisticsGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardRevenueByDispatcherGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDashboardTopVehiclesGetRequest {
    periodType?: EWidgetPeriodType;
    from?: Date;
    to?: Date;
    dispatcherIds?: Array<string>;
    brokeragesIds?: Array<number>;
    charts?: Array<EWidgetChart>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class DashboardApi extends runtime.BaseAPI {

    /**
     */
    async apiDashboardBidsByHourGetRaw(requestParameters: ApiDashboardBidsByHourGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WidgetOneResponse>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/bidsByHour`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetOneResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDashboardBidsByHourGet(requestParameters: ApiDashboardBidsByHourGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WidgetOneResponse> {
        const response = await this.apiDashboardBidsByHourGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardBrokerageInformationExportPostRaw(requestParameters: ApiDashboardBrokerageInformationExportPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/brokerageInformation/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetParametersToJSON(requestParameters.widgetParameters),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiDashboardBrokerageInformationExportPost(requestParameters: ApiDashboardBrokerageInformationExportPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiDashboardBrokerageInformationExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardBrokerageInformationPostRaw(requestParameters: ApiDashboardBrokerageInformationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BrokerageInformationResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/brokerageInformation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetParametersToJSON(requestParameters.widgetParameters),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrokerageInformationResponseFromJSON));
    }

    /**
     */
    async apiDashboardBrokerageInformationPost(requestParameters: ApiDashboardBrokerageInformationPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BrokerageInformationResponse>> {
        const response = await this.apiDashboardBrokerageInformationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardBrokeragesGetRaw(requestParameters: ApiDashboardBrokeragesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Brokerage>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/brokerages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrokerageFromJSON));
    }

    /**
     */
    async apiDashboardBrokeragesGet(requestParameters: ApiDashboardBrokeragesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Brokerage>> {
        const response = await this.apiDashboardBrokeragesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardCompanyInformationGetRaw(requestParameters: ApiDashboardCompanyInformationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyInformationResponse>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/companyInformation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyInformationResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDashboardCompanyInformationGet(requestParameters: ApiDashboardCompanyInformationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyInformationResponse> {
        const response = await this.apiDashboardCompanyInformationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardDispatcherStatisticsExportGetRaw(requestParameters: ApiDashboardDispatcherStatisticsExportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/dispatcherStatistics/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiDashboardDispatcherStatisticsExportGet(requestParameters: ApiDashboardDispatcherStatisticsExportGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiDashboardDispatcherStatisticsExportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardDispatcherStatisticsGetRaw(requestParameters: ApiDashboardDispatcherStatisticsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DispatcherStatisticsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/dispatcherStatistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DispatcherStatisticsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDashboardDispatcherStatisticsGet(requestParameters: ApiDashboardDispatcherStatisticsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DispatcherStatisticsResponse> {
        const response = await this.apiDashboardDispatcherStatisticsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardDispatchersDispatcherStatisticsGetRaw(requestParameters: ApiDashboardDispatchersDispatcherStatisticsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/dispatchers/dispatcherStatistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     */
    async apiDashboardDispatchersDispatcherStatisticsGet(requestParameters: ApiDashboardDispatchersDispatcherStatisticsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDto>> {
        const response = await this.apiDashboardDispatchersDispatcherStatisticsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardDispatchersGetRaw(requestParameters: ApiDashboardDispatchersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/dispatchers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     */
    async apiDashboardDispatchersGet(requestParameters: ApiDashboardDispatchersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDto>> {
        const response = await this.apiDashboardDispatchersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardDispatchersOwnerInformationGetRaw(requestParameters: ApiDashboardDispatchersOwnerInformationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/dispatchers/ownerInformation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     */
    async apiDashboardDispatchersOwnerInformationGet(requestParameters: ApiDashboardDispatchersOwnerInformationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDto>> {
        const response = await this.apiDashboardDispatchersOwnerInformationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardDispatchersRevenueByDispatcherGetRaw(requestParameters: ApiDashboardDispatchersRevenueByDispatcherGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/dispatchers/revenueByDispatcher`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     */
    async apiDashboardDispatchersRevenueByDispatcherGet(requestParameters: ApiDashboardDispatchersRevenueByDispatcherGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDto>> {
        const response = await this.apiDashboardDispatchersRevenueByDispatcherGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardDispatchersTopVehiclesGetRaw(requestParameters: ApiDashboardDispatchersTopVehiclesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/dispatchers/topVehicles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     */
    async apiDashboardDispatchersTopVehiclesGet(requestParameters: ApiDashboardDispatchersTopVehiclesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDto>> {
        const response = await this.apiDashboardDispatchersTopVehiclesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardLoadInformationGetRaw(requestParameters: ApiDashboardLoadInformationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadInformationResponse>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/loadInformation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadInformationResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDashboardLoadInformationGet(requestParameters: ApiDashboardLoadInformationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadInformationResponse> {
        const response = await this.apiDashboardLoadInformationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardOwnerInformationExportPostRaw(requestParameters: ApiDashboardOwnerInformationExportPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/ownerInformation/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetParametersToJSON(requestParameters.widgetParameters),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiDashboardOwnerInformationExportPost(requestParameters: ApiDashboardOwnerInformationExportPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiDashboardOwnerInformationExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardOwnerInformationGetRaw(requestParameters: ApiDashboardOwnerInformationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OwnerInformationResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/ownerInformation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OwnerInformationResponseFromJSON));
    }

    /**
     */
    async apiDashboardOwnerInformationGet(requestParameters: ApiDashboardOwnerInformationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OwnerInformationResponse>> {
        const response = await this.apiDashboardOwnerInformationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardProfitStatisticsGetRaw(requestParameters: ApiDashboardProfitStatisticsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfitStatisticsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/profitStatistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfitStatisticsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDashboardProfitStatisticsGet(requestParameters: ApiDashboardProfitStatisticsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfitStatisticsResponse> {
        const response = await this.apiDashboardProfitStatisticsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardRevenueByDispatcherGetRaw(requestParameters: ApiDashboardRevenueByDispatcherGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RevenueByDispatcherResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/revenueByDispatcher`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RevenueByDispatcherResponseFromJSON));
    }

    /**
     */
    async apiDashboardRevenueByDispatcherGet(requestParameters: ApiDashboardRevenueByDispatcherGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RevenueByDispatcherResponse>> {
        const response = await this.apiDashboardRevenueByDispatcherGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDashboardTopVehiclesGetRaw(requestParameters: ApiDashboardTopVehiclesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TopVehiclesResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.periodType !== undefined) {
            queryParameters['periodType'] = requestParameters.periodType;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.dispatcherIds) {
            queryParameters['dispatcherIds'] = requestParameters.dispatcherIds;
        }

        if (requestParameters.brokeragesIds) {
            queryParameters['brokeragesIds'] = requestParameters.brokeragesIds;
        }

        if (requestParameters.charts) {
            queryParameters['charts'] = requestParameters.charts;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboard/topVehicles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TopVehiclesResponseFromJSON));
    }

    /**
     */
    async apiDashboardTopVehiclesGet(requestParameters: ApiDashboardTopVehiclesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TopVehiclesResponse>> {
        const response = await this.apiDashboardTopVehiclesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
