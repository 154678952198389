/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LoadedOrder } from './LoadedOrder';
import {
    LoadedOrderFromJSON,
    LoadedOrderFromJSONTyped,
    LoadedOrderToJSON,
} from './LoadedOrder';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ViewedLoadedOrder
 */
export interface ViewedLoadedOrder {
    /**
     * 
     * @type {number}
     * @memberof ViewedLoadedOrder
     */
    userId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof ViewedLoadedOrder
     */
    user?: User;
    /**
     * 
     * @type {number}
     * @memberof ViewedLoadedOrder
     */
    loadedOrderId?: number | null;
    /**
     * 
     * @type {LoadedOrder}
     * @memberof ViewedLoadedOrder
     */
    loadedOrder?: LoadedOrder;
    /**
     * 
     * @type {number}
     * @memberof ViewedLoadedOrder
     */
    id?: number;
}

/**
 * Check if a given object implements the ViewedLoadedOrder interface.
 */
export function instanceOfViewedLoadedOrder(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ViewedLoadedOrderFromJSON(json: any): ViewedLoadedOrder {
    return ViewedLoadedOrderFromJSONTyped(json, false);
}

export function ViewedLoadedOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewedLoadedOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'loadedOrderId': !exists(json, 'loadedOrderId') ? undefined : json['loadedOrderId'],
        'loadedOrder': !exists(json, 'loadedOrder') ? undefined : LoadedOrderFromJSON(json['loadedOrder']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ViewedLoadedOrderToJSON(value?: ViewedLoadedOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'loadedOrderId': value.loadedOrderId,
        'loadedOrder': LoadedOrderToJSON(value.loadedOrder),
        'id': value.id,
    };
}

