import styled from '@emotion/styled';
import { InputNumber } from 'primereact/inputnumber';
import { focusedInputStyle, inputStyle } from './Styles';

const StyledInputNumber = styled(InputNumber)`
    
  & {
    width: 100%;
  }
    
  &, & input {
    display: flex;
  }
  
  & input {
    ${inputStyle};
    flex-grow: 1;
  }

  & input:enabled:focus {
    ${focusedInputStyle}
  }
`
export default StyledInputNumber;