import { ModalParameters } from '../../../../shared';
import LogisticsDialog from '../../../LogisticsDialog';
import VisibilityToggler from '../../../VisibilityToggler';
import ImageEditor from './ImageEditor';
import styled from '@emotion/styled';

export interface FileEditorModalProps extends ModalParameters<string> {

}

const DebugWrapper = styled.div`
    //&, & * {
    //    outline: 1px solid red;
    //}
`

function ImageEditorModal(props: FileEditorModalProps) {
  return <LogisticsDialog
    visible={props.visible}
    onHide={() => props.hide(props.data)}
    className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-11 h-full'
  >
    <DebugWrapper className='grid logistics-dialog-edit-form__content h-full'>
      <VisibilityToggler visible={props.visible}>
        <ImageEditor
          key={props.data}
          imageUrl={props.data}
          onImageChange={url => {
            props.hide(url);
          }}
        />
      </VisibilityToggler>
    </DebugWrapper>
  </LogisticsDialog>
}

export default ImageEditorModal;