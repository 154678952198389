import LogisticsDialog from '../../../components/LogisticsDialog';
import { apiFactory, ModalParameters } from '../../../shared';
import { Button } from 'primereact/button';
import CenteredSpinner from '../../../components/CenteredSpinner';
import { useCallback, useState } from 'react';
import Field from '../../../components/form/Field';
import LogisticsCalendar from '../../../components/form/datetime/LogisticsCalendar';
import { RequestApi } from '../../../generated-api';
import FileSaver from 'file-saver';
import { showSuccessToast, showToast } from '../../../components/LogisticsToast';
import { FormikErrors, useFormik } from 'formik';
import ErrorBox from '../../../components/form/ErrorBox';
import dayjs from 'dayjs';

const blankError = 'This value should not be blank.';

interface RequestExportDates {
  dateFrom?: Date | undefined,
  dateTo?: Date | undefined,
}

export interface RequestExportParameters extends ModalParameters<undefined> {

}

function RequestExport(props: RequestExportParameters) {
  const [loading, setLoading] = useState(props.loading);

  const requestValidation = useFormik<RequestExportDates>({
    initialValues: {
      dateFrom: new Date(),
      dateTo: new Date(),
    },
    validate: data => {
      const requestErrors: FormikErrors<RequestExportDates> = {};
      const requestRequiredFields: RequestExportDates = {
        dateFrom: undefined as any,
        dateTo: undefined as any,
      }

      Object.keys(requestRequiredFields).forEach(field => {
        if (!(data as any)[field])
          (requestErrors as any)[field] = blankError;
      });

      if (data.dateFrom && data.dateTo && data?.dateFrom > data?.dateTo) {
        requestErrors.dateFrom = 'The "Date From" cannot be later than the "Date To"';
      }

      return requestErrors;
    },
    onSubmit: () => {},
  });

  const handleOnHide = () => {
    props.hide(undefined);
    setLoading(false);
  };

  const validate = useCallback(async () => {
    requestValidation.handleSubmit();
    const requestErrors = await requestValidation.validateForm(requestValidation.values);
    if (Object.keys(requestErrors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [requestValidation]);

  const handleExport = async () => {
    try {
      await validate();
      setLoading(true);
      const dateFrom = requestValidation.values.dateFrom;
      const dateTo = requestValidation.values.dateTo;
      const dateFromString = dayjs(dateFrom).format('YYYY-MM-DD');
      const dateToString = dayjs(dateTo).format('YYYY-MM-DD');
      const response = await apiFactory(RequestApi).apiRequestsExportGet({ dateFrom: dateFrom, dateTo: dateTo });
      FileSaver.saveAs(response!, `Request ${dateFromString} ${dateToString}.xlsx`);
      showSuccessToast('Data successfully exported');
    }
    catch (e: any) {
      showToast({
        severity: 'error',
        summary: 'Error',
        detail: 'Error on exporting data',
        life: 2000
      });
      throw e;
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <LogisticsDialog
      visible={props.visible}
      onHide={handleOnHide}
      className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-4'
      dismissableMask
    >
      <header className='text-2xl w-full flex-wrap'>
        <span className='font-bold'>Export</span>
      </header>
      <main className='mt-6 mr-3'>
        <Field label='Date From' required>
          <LogisticsCalendar
            value={requestValidation.values.dateFrom}
            onChange={newValue => {
              requestValidation.setValues(r => {return {...r, dateFrom: newValue}});
            }}
            disabled={false}
          />
          <ErrorBox>{requestValidation.errors.dateFrom}</ErrorBox>
        </Field>
        <Field label='Date To' required>
          <LogisticsCalendar
            value={requestValidation.values.dateTo}
            onChange={newValue => {
              requestValidation.setValues(r => {return {...r, dateTo: newValue}});
            }}
            disabled={false}
          />
          <ErrorBox>{requestValidation.errors.dateTo}</ErrorBox>
        </Field>
      </main>
      <footer className='flex justify-content-center w-full gap-3 mt-6'>
        <Button label='Close' className='min-w-min w-6rem h-3rem'
          onClick={handleOnHide}></Button>
        <Button label='Download' className='p-button-success min-w-min w-6rem h-3rem' disabled={false}
          onClick={handleExport}></Button>
      </footer>
      <CenteredSpinner visible={loading} />
    </LogisticsDialog>
  );
}

export default RequestExport;