import React from 'react';
import Logo from './Logo';
import Menu from './Menu';
import UserPanel from './UserPanel';
import { BrowserView, isMobile } from 'react-device-detect';

const ResponsiveAppBar = () => {
  return (
    <div className={`flex flex-row surface-b ${isMobile ? 'align-items-center' : ''}`} style={{colorScheme: 'light', background: 'var(--surface-b)'}}>
      <BrowserView>
        <Logo />
      </BrowserView>
      <Menu />
      <UserPanel />
    </div>
  );
};
export default ResponsiveAppBar;
