import React from 'react';
import useRecentDriverBids from './NewDriverBidsHook';

function LoadedOrderTimer() {
  // Wrap into separate component to not cause chaos on list
  useRecentDriverBids();
  return (
    <div></div>
  );
}

export default LoadedOrderTimer;