import {
  LoadedOrder,
  Bid,
  BidsApi,
  LoadedOrdersApi,
  GeoApi,
  Offer,
  OffersApi,
  VehicleForBid, VehicleReservation,
} from '../../../generated-api';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { PricesData } from '../../../components/prices/PricesBlock';
import { ModalParameters } from '../../../shared';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { apiFactory } from '../../../shared';
import { useFormik } from 'formik';
import { showErrorToast, showSuccessToast, showToast } from '../../../components/LogisticsToast';
import { getCurrentDispSetting, getSystemSetting } from '../../settings/SettingsSlice';
import useAuth from '../../login/AuthHook';
import { useHubConnection } from '../../../hooks/SignalR';
import { updateEntity } from '../LoadedOrdersSlice';

export interface LoadedOrderEditParameters extends ModalParameters<LoadedOrder> {
}

const getInitPrices = (data : LoadedOrder | undefined) : PricesData => {
  return {
    miles: data?.miles,
    profit: undefined
  }
}

export function useLoadedOrderEdit(props: LoadedOrderEditParameters) {
  const [loadedOrder, setLoadedOrder] = useState<LoadedOrder>(props.data);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [formBid,  setFormBid] = useState<Bid>({} as Bid);
  const [pricesData, setPricesData] = useState<PricesData>(getInitPrices(loadedOrder));
  const [loading, setLoading] = useState(props.loading);
  const [selectedUnit, setSelectedUnit] = useState<Offer>();
  const [loadingOffer, setLoadingOffer] = useState<Offer>({});
  const { auth } = useAuth();
  const hubConnection = useHubConnection();
  const systemSettings = useAppSelector(state => state.settings.system);
  const dispatcherSettings = useAppSelector(state => state.settings.disp);
  const [customTemplate, setCustomTemplate] =
    useState(dispatcherSettings.bidEmailTemplate || systemSettings.bidEmailTemplateS);
  const [filledTemplate, setFilledTemplate] = useState('');
  const dispatch = useAppDispatch();
  const ordersList = useAppSelector(state => state.loadedOrder.entities);
  const formik = useFormik<Bid>({
    initialValues: loadedOrder,
    validate: () => {},
    onSubmit: () => {},
  });

  const rawTemplate = useMemo(() => {
    if (!selectedUnit) return '';

    return customTemplate ||
      dispatcherSettings.bidEmailTemplate ||
      systemSettings.bidEmailTemplateS ||
      '';
  }, [customTemplate, systemSettings.bidEmailTemplateS, dispatcherSettings.bidEmailTemplate, selectedUnit]);


  useEffect(() => {
    if (!props.visible) return;

    setLoading(true);
    const loadSystemSettings = systemSettings.bidEmailTemplateS
      ? Promise.resolve()
      : dispatch(getSystemSetting({ id: 1 }));
    const loadDispatcherSettings = dispatcherSettings.bidEmailTemplate
      ? Promise.resolve()
      : dispatch(getCurrentDispSetting({}));
    const loadLoadedOrder = loadedOrder.id
      ? apiFactory(LoadedOrdersApi).apiLoadedOrdersIdGet({ id: loadedOrder.id} )
      : Promise.resolve();
    const loadOffers = apiFactory(OffersApi)
      .apiOffersSuitableForOrderGet(
        { loadedOrderId: loadedOrder?.id! });
    const loadOffersWithBids = apiFactory(OffersApi)
      .apiOffersWithDriverBidsGet(
        {loadedOrderId: loadedOrder?.id! });
    Promise.all([
      loadLoadedOrder, loadOffers, loadOffersWithBids, loadSystemSettings, loadDispatcherSettings
    ]).then(([loadedLoadedOrder, loadedOffers, loadedOffersWithDriverBids]) => {
      const offersWithoutBids = loadedOffers.filter(
        lo => !loadedOffersWithDriverBids.some(
          lowdb => lo.vehicleId === lowdb.vehicleId));
      const dispatcherOffers = offersWithoutBids
        .filter(offer => offer.vehicle?.dispatcher?.id === auth.account.id);
      const otherOffers = offersWithoutBids
        .filter(offer => offer.vehicle?.dispatcher?.id !== auth.account.id);
      setLoadedOrder(loadedLoadedOrder!);
      setOffers([...loadedOffersWithDriverBids, ...dispatcherOffers, ...otherOffers]);
      setPricesData(getInitPrices(loadedLoadedOrder!));

      if (!loadedLoadedOrder?.read) {
        hubConnection?.send('SendReadLoadedOrder', loadedLoadedOrder?.id)
      }
    }).catch(reason => {
      showErrorToast('Error on orders loading');
      console.error('Error on orders loading:', reason);
    })
    .finally(() => {
      setLoading(false);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const placeBidButtonDisabled =
    pricesData.priceForBroker === undefined
    || pricesData.priceForDrive === undefined
    || !selectedUnit
    || (selectedUnit.vehicle?.reservation && selectedUnit.vehicle?.reservation?.dispatcherId !== auth.account.id);

  const changePrices = (data: PricesData) => {
    setPricesData(Object.create(data));
    formik.setValues({ ...data, ...pricesData});
  };

  const hide = (newData? : LoadedOrder) => {
    const updatedOrder = newData || loadedOrder;
    const listOrder = ordersList.find(x => x.id === updatedOrder.id);
    if (listOrder) {
      // Do not replace list entity with edit entity - projections are different.
      dispatch(updateEntity( { ...listOrder, bids: updatedOrder.bids }));
    }

    props.hide(updatedOrder);
    setLoading(false);
  };

  const handleOnHide = useCallback(() => {
    hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedOrder]);

  const onPlaceBid = () => {
    if (pricesData?.priceForBroker === undefined || pricesData?.priceForDrive === undefined)
      return Promise.resolve();

    // selectedUnit!.selected = true;
    const bid: Bid = {
      priceForBroker: pricesData.priceForBroker,
      priceForDrive: pricesData.priceForDrive,
      pricePerMileBr: pricesData.pricePerMileBr,
      pricePerMileDr: pricesData.pricePerMileDr,
      profit: pricesData.profit,
      createdDate: new Date(),
      brokerText: filledTemplate,
      loadedOrderId: loadedOrder?.id,
      userId: auth.account.id,
      vehicleForBid: {
        vehicle: selectedUnit?.vehicle,
        vehicleId: selectedUnit?.vehicleId,
        miles: selectedUnit?.vehicleGroundMiles,
        flyMiles: selectedUnit?.vehicleFlyMiles,
        vehiclePoint: selectedUnit?.vehicle?.availableGeo,
      } as VehicleForBid,
      statusId: 1, // Accepted
      dockHigh: formBid.dockHigh,
      ttDrivers: formBid.ttDrivers,
      liftGate: formBid.liftGate,
      palletJack: formBid.palletJack,
      driverAssist: formBid.driverAssist,
      fullPPE: formBid.fullPPE,
    };

    setLoading(true);
    return apiFactory(BidsApi).apiBidsPost({bid}).then(() => {
      showSuccessToast('Bid was placed');
      hide({...loadedOrder, bids: [bid]});
    }).catch(reason => {
      if (reason.response.status === 424) {
        showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'You need to be logged in to Gmail. (See Settings page)',
          life: 4000
        });
      } else {
        showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Error on bid creation',
          life: 2000
        });
      }
      console.error('Error on bid creation:', reason);
    }).finally(() => {
      setLoading(false);
    });
  };

  const onSelectionChange = (selection: Offer | undefined) => {
    console.debug('on selection change');
    setSelectedUnit(selection);

    if (selection && !selection.vehicleGroundMiles) {
      setLoadingOffer(selection);
      apiFactory(GeoApi).apiGeoRouteDistancePost({twoPoints: { a: selection.vehicle?.availableGeo, b: loadedOrder.pickUpAtPoint }}).then((distance) => {
        const selectedOffer = {...selection, vehicleGroundMiles: Number(distance)} as Offer;
        setSelectedUnit(selectedOffer);
        const idx = offers.findIndex(offer => offer === selection);
        if (idx !== -1) {
          offers.splice(idx, 1, selectedOffer);
          setOffers(offers);
        }
      }).catch(() => {
        showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Error on getting route distance',
          life: 2000
        });
      }).finally(() => {
        setLoadingOffer({});
      });
    }
  };

  useEffect(() => {
    const updateReservation = (reservation: VehicleReservation) => {
      console.debug('received ReceiveUpdatedReservation message');
      setOffers(prev => {
        const reservedOffer = prev.find(x =>
          (x.vehicleId === reservation.vehicleId || x.vehicle?.id === reservation.vehicleId));
        if (reservedOffer?.vehicle) {
          reservedOffer.vehicle.reservation = reservation;
        }

        return [...prev];
      });
    }

    const removeReservation = (reservation: VehicleReservation) => {
      console.debug('received ReceiveExpiredReservation message');
      setOffers(prev => {
        const reservedOffer = prev.find(x =>
          (x.vehicleId === reservation.vehicleId || x.vehicle?.id === reservation.vehicleId));
        if (reservedOffer?.vehicle) {
          reservedOffer.vehicle.reservation = undefined;
        }

        return [...prev];
      });
    }

    hubConnection.on('ReceiveUpdatedReservation',updateReservation );
    hubConnection.on('ReceiveExpiredReservation',removeReservation );

    return () => {
      hubConnection.off('ReceiveUpdatedReservation',updateReservation);
      hubConnection.off('ReceiveExpiredReservation',removeReservation);
    }
  }, [hubConnection]);

  return {
    loadedOrder,
    offers,
    handleOnHide,
    selectedUnit,
    loadingOffer,
    onSelectionChange,
    changePrices,
    pricesData,
    formBid,
    setFormBid,
    rawTemplate,
    onPlaceBid,
    systemSettings,
    setCustomTemplate,
    setFilledTemplate,
    formik,
    placeBidButtonDisabled,
    loading
  } as const;
}

export type LoadedOrderEditData = ReturnType<typeof useLoadedOrderEdit>;