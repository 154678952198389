import React, { useMemo } from 'react';
import {
  CompanyInformationResponse, DashboardApi,
} from '../../../generated-api';
import DashboardPanel from '../components/DashboardPanel';
import WidgetToolbar from '../components/WidgetToolbar';
import styled from '@emotion/styled';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { IdName } from '../../../data-moqs/IdName';
import { nameof } from 'ts-simple-nameof';
import { apiFactory } from '../../../shared';
import useWidget from '../hooks/WidgetHook';
import { today } from '../../../shared/util/dateUtils';

function CompanyInformation() {
  const { data, filters, loadData, setFilters } = useWidget({
    initialFilters: {
      periodType: 'Day',
      from: today().toDate(),
      to: today().toDate(),
    },
    initialWidgetData: {},
    loadWidgetData: filters => apiFactory(DashboardApi).apiDashboardCompanyInformationGet(filters),
  });

  const joinedTableData = useMemo(() => {
    if (!data) return [];

    return Object.entries(data).map(([id, value]) => {
      const row = rows.find(x => x.id === id);
      if (!row) return undefined;

      return {
        id: row.title,
        name: row.format ? row.format(value || 0) : value
      } as IdName<string>;
    }).filter(x => x) as IdName<string>[];
  }, [data]);

  return <StyledDashboardPanel>
    <header className='text-3xl font-medium'>
      Company information
    </header>
    <span className='flex justify-content-end align-items-center flex-grow-1 gap-2'>
      <WidgetToolbar
        value={filters}
        onChange={setFilters}
        onFilter={loadData}
        visibility={{
          period: true,
          from: true,
          to: true,
          filterButton: true,
        }}
      />
    </span>
    <div className='flex flex-column pt-3'>
      <StyledTable
        dataSource={joinedTableData}
        columns={columns}
        pagination={false}
        size='small'
        bordered
        showHeader={false}
      />
    </div>
  </StyledDashboardPanel>
}

const StyledDashboardPanel = styled(DashboardPanel)`
  & {
    width: 35%;
    align-self: start;
  }
`

const StyledTable = styled(Table)`
  & {
    height: 373px;
    overflow: auto;
  }

  & .ant-table-content tr{
    font-weight: 500;
    font-size: 1.3rem;
  }
`

const columns: ColumnType<any>[] = [
  {
    title: '',
    dataIndex: nameof<IdName<string>>(x => x.id),
    key: nameof<IdName<string>>(x => x.id),
    width: 250,
  },
  {
    title: '',
    dataIndex: nameof<IdName<string>>(x => x.name),
    key: nameof<IdName<string>>(x => x.name),
    width: 150,
    align: 'center',
  },
];

const rows: { id: string, title: string, format?: (value: any) => string }[] = [
  {
    id: nameof<CompanyInformationResponse>(x => x.postedLoads),
    title: 'Posted Loads',
  },
  {
    id: nameof<CompanyInformationResponse>(x => x.bids),
    title: 'Bids',
  },
  {
    id: nameof<CompanyInformationResponse>(x => x.loads),
    title: 'Loads',
  },
  {
    id: nameof<CompanyInformationResponse>(x => x.postedLoads),
    title: 'Posted Loads',
  },
  {
    id: nameof<CompanyInformationResponse>(x => x.gross),
    title: 'Gross',
    format: x => '$' + x,
  },
  {
    id: nameof<CompanyInformationResponse>(x => x.revenue),
    title: 'Revenue',
    format: x => '$' + x,
  },
  {
    id: nameof<CompanyInformationResponse>(x => x.margin),
    title: 'Margin',
    format: (x: number) => Math.trunc(x) + '%',
  },
  {
    id: nameof<CompanyInformationResponse>(x => x.dispatchers),
    title: 'Dispatchers',
  },
  {
    id: nameof<CompanyInformationResponse>(x => x.vehicles),
    title: 'Vehicles',
  },
  {
    id: nameof<CompanyInformationResponse>(x => x.vehiclesAvailable),
    title: 'Vehicles available',
  },
];

export default CompanyInformation;
