/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EBidIssuerType = {
    None: 'None',
    Driver: 'Driver',
    Owner: 'Owner'
} as const;
export type EBidIssuerType = typeof EBidIssuerType[keyof typeof EBidIssuerType];


export function EBidIssuerTypeFromJSON(json: any): EBidIssuerType {
    return EBidIssuerTypeFromJSONTyped(json, false);
}

export function EBidIssuerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EBidIssuerType {
    return json as EBidIssuerType;
}

export function EBidIssuerTypeToJSON(value?: EBidIssuerType | null): any {
    return value as any;
}

