/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface VehicleForBid
 */
export interface VehicleForBid {
    /**
     * 
     * @type {number}
     * @memberof VehicleForBid
     */
    duration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleForBid
     */
    miles?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleForBid
     */
    flyMiles?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleForBid
     */
    marketPays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleForBid
     */
    relevance?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleForBid
     */
    selected?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleForBid
     */
    vehicleId?: number | null;
    /**
     * 
     * @type {Vehicle}
     * @memberof VehicleForBid
     */
    vehicle?: Vehicle;
    /**
     * 
     * @type {Point}
     * @memberof VehicleForBid
     */
    vehiclePoint?: Point;
    /**
     * 
     * @type {number}
     * @memberof VehicleForBid
     */
    id?: number;
}

/**
 * Check if a given object implements the VehicleForBid interface.
 */
export function instanceOfVehicleForBid(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleForBidFromJSON(json: any): VehicleForBid {
    return VehicleForBidFromJSONTyped(json, false);
}

export function VehicleForBidFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleForBid {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'miles': !exists(json, 'miles') ? undefined : json['miles'],
        'flyMiles': !exists(json, 'flyMiles') ? undefined : json['flyMiles'],
        'marketPays': !exists(json, 'marketPays') ? undefined : json['marketPays'],
        'relevance': !exists(json, 'relevance') ? undefined : json['relevance'],
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleFromJSON(json['vehicle']),
        'vehiclePoint': !exists(json, 'vehiclePoint') ? undefined : PointFromJSON(json['vehiclePoint']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function VehicleForBidToJSON(value?: VehicleForBid | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration': value.duration,
        'miles': value.miles,
        'flyMiles': value.flyMiles,
        'marketPays': value.marketPays,
        'relevance': value.relevance,
        'selected': value.selected,
        'vehicleId': value.vehicleId,
        'vehicle': VehicleToJSON(value.vehicle),
        'vehiclePoint': PointToJSON(value.vehiclePoint),
        'id': value.id,
    };
}

