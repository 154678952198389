/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DispatcherByHourResponse } from './DispatcherByHourResponse';
import {
    DispatcherByHourResponseFromJSON,
    DispatcherByHourResponseFromJSONTyped,
    DispatcherByHourResponseToJSON,
} from './DispatcherByHourResponse';

/**
 * 
 * @export
 * @interface BidByHourResponse
 */
export interface BidByHourResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof BidByHourResponse
     */
    hours?: Array<string> | null;
    /**
     * 
     * @type {Array<DispatcherByHourResponse>}
     * @memberof BidByHourResponse
     */
    data?: Array<DispatcherByHourResponse> | null;
}

/**
 * Check if a given object implements the BidByHourResponse interface.
 */
export function instanceOfBidByHourResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BidByHourResponseFromJSON(json: any): BidByHourResponse {
    return BidByHourResponseFromJSONTyped(json, false);
}

export function BidByHourResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidByHourResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(DispatcherByHourResponseFromJSON)),
    };
}

export function BidByHourResponseToJSON(value?: BidByHourResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hours': value.hours,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(DispatcherByHourResponseToJSON)),
    };
}

