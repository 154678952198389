import { Button, ButtonProps } from 'primereact/button';

function AddButton(props: ButtonProps) {
  return <Button
    icon="pi pi-plus"
    className="p-button-rounded p-button-info p-button-text"
    key="add"
    {...props}
  />;
}

export default AddButton;