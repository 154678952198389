import { isMobile } from 'react-device-detect';

const LogisticsToolbar = (props : {
  rightSideElements: JSX.Element[],
  leftSideElements?: JSX.Element[],
}) => {

  return (
    <div className={`flex ${isMobile ? 'flex-wrap' : ''}`}>
      <div className='flex justify-content-start align-items-center flex-grow-1 gap-2'>
        {props.leftSideElements}
      </div>
      <div className='flex justify-content-end align-items-center flex-grow-1'>
        {props.rightSideElements}
      </div>
    </div>
  );
};

export default LogisticsToolbar;