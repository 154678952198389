/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntityState } from './EntityState';
import {
    EntityStateFromJSON,
    EntityStateFromJSONTyped,
    EntityStateToJSON,
} from './EntityState';
import type { FieldChange } from './FieldChange';
import {
    FieldChangeFromJSON,
    FieldChangeFromJSONTyped,
    FieldChangeToJSON,
} from './FieldChange';

/**
 * 
 * @export
 * @interface Audit
 */
export interface Audit {
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    userLogin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    objectType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    objectPrimaryKey?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Audit
     */
    operationTime?: Date | null;
    /**
     * 
     * @type {EntityState}
     * @memberof Audit
     */
    operationType?: EntityState;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    source?: string | null;
    /**
     * 
     * @type {Array<FieldChange>}
     * @memberof Audit
     */
    serializedFields?: Array<FieldChange> | null;
    /**
     * 
     * @type {number}
     * @memberof Audit
     */
    id?: number;
}

/**
 * Check if a given object implements the Audit interface.
 */
export function instanceOfAudit(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuditFromJSON(json: any): Audit {
    return AuditFromJSONTyped(json, false);
}

export function AuditFromJSONTyped(json: any, ignoreDiscriminator: boolean): Audit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'userLogin': !exists(json, 'userLogin') ? undefined : json['userLogin'],
        'objectType': !exists(json, 'objectType') ? undefined : json['objectType'],
        'objectPrimaryKey': !exists(json, 'objectPrimaryKey') ? undefined : json['objectPrimaryKey'],
        'operationTime': !exists(json, 'operationTime') ? undefined : (json['operationTime'] === null ? null : new Date(json['operationTime'])),
        'operationType': !exists(json, 'operationType') ? undefined : EntityStateFromJSON(json['operationType']),
        'source': !exists(json, 'source') ? undefined : json['source'],
        'serializedFields': !exists(json, 'serializedFields') ? undefined : (json['serializedFields'] === null ? null : (json['serializedFields'] as Array<any>).map(FieldChangeFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function AuditToJSON(value?: Audit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userName': value.userName,
        'userLogin': value.userLogin,
        'objectType': value.objectType,
        'objectPrimaryKey': value.objectPrimaryKey,
        'operationTime': value.operationTime === undefined ? undefined : (value.operationTime === null ? null : value.operationTime.toISOString()),
        'operationType': EntityStateToJSON(value.operationType),
        'source': value.source,
        'serializedFields': value.serializedFields === undefined ? undefined : (value.serializedFields === null ? null : (value.serializedFields as Array<any>).map(FieldChangeToJSON)),
        'id': value.id,
    };
}

