import { uniq } from 'lodash';
import VehicleTypes from '../../data-moqs/VehicleTypes';
import './MatchColorBox.css'

interface MatchColorBoxProps {
  vehicleTypeIds : (number | null | undefined)[] | undefined
}

const MatchColorBox = (props : MatchColorBoxProps) => {
  const uniqueIds = uniq(props.vehicleTypeIds);
  const colors = {
    orange: uniqueIds.includes(VehicleTypes.CargoVan),
    blue: uniqueIds.includes(VehicleTypes.LargeStraight),
    green: uniqueIds.includes(VehicleTypes.SmallStraight),
  };

  return <div className='ml-2 color-strip'>
    <span className={`color-box orange ${colors.orange ? '' : 'hidden'}`}></span>
    <span className={`color-box blue ${colors.blue ? '' : 'hidden'}`}></span>
    <span className={`color-box green ${colors.green ? '' : 'hidden'}`}></span>
  </div>
}

export default MatchColorBox;