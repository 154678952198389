import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import Field from '../../components/form/Field';
import { DispSettings } from '../../generated-api';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { createDispSetting, getCurrentDispSetting, updateDispSetting } from './SettingsSlice';
import { showSuccessToast } from '../../components/LogisticsToast';
import CenteredSpinner from '../../components/CenteredSpinner';
import HtmlEditor from '../../components/html/HtmlEditor';
import LogisticsPanel from '../../components/LogisticsPanel';
import useAuth from '../login/AuthHook';
import StyledInputNumber from '../../components/form/StyledInputNumber';
import LogisticsGoogleAuth from '../../components/google/LogisticsGoogleAuth';
import useRights from '../../hooks/RightsHook';
import VisibilityToggler from '../../components/VisibilityToggler';

function DispatcherSettingsEdit() {
  const dispatch = useAppDispatch();
  const rights = useRights(security => security.settings);
  const { auth } = useAuth();
  const storedDispSettings = useAppSelector((state) => state.settings.disp);
  const [dispSetting, setDispSetting] = useState<DispSettings>(storedDispSettings);

  useEffect(() => {
    dispatch(getCurrentDispSetting({}))
      .then((response) => setDispSetting({...(response.payload as DispSettings)}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSaveDispSettingButtonClick() {
    if (dispSetting.id == null) {
      dispatch(createDispSetting({ dispSettings: dispSetting }))
        .then(() => showSuccessToast('Dispatcher setting saved!'));
    } else {
      dispatch(updateDispSetting({ id: dispSetting.id, dispSettings: dispSetting }))
        .then(() => showSuccessToast('Dispatcher setting saved!'));
    }
  }

  return (
    <LogisticsPanel header='Dispatcher Settings' toggleable>
      <div className='p-5'>
        <div className='grid'>
          <VisibilityToggler visible={rights.dispatcher || rights.googleAuth}>
            <div className='col-12'>
              <LogisticsGoogleAuth userLogin={auth.account.login} />
            </div>
          </VisibilityToggler>
          <VisibilityToggler visible={rights.dispatcher}>
            <div className='col-12'>
              <h2>Match settings</h2>
            </div>
            <div className='col-3'>
              <Field label='Max vehicle fly radius (miles)'>
                <StyledInputNumber
                  value={dispSetting.maxFlyRadius}
                  onChange={e => setDispSetting(s => {
                    return { ...s, maxFlyRadius: e.value };
                  })}
                />
              </Field>
            </div>
            <div className='col-12'>
              <Field label='Bid email template'>
                <HtmlEditor
                  value={dispSetting.bidEmailTemplate || ''}
                  onEditorChange={value => setDispSetting(ds => {
                    return {...ds, bidEmailTemplate: value}
                  })}
                />
              </Field>
            </div>
            <div className='col-12'>
              <Button
                label='SAVE SETTINGS'
                className='p-button-success min-w-min w-16rem h-4rem mr-1'
                onClick={handleSaveDispSettingButtonClick}>
              </Button>
            </div>
          </VisibilityToggler>
        </div>
      </div>
      <CenteredSpinner visible={storedDispSettings.loading} />
    </LogisticsPanel>
  );
}

export default DispatcherSettingsEdit;