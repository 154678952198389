/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bid } from './Bid';
import {
    BidFromJSON,
    BidFromJSONTyped,
    BidToJSON,
} from './Bid';

/**
 * 
 * @export
 * @interface BidReply
 */
export interface BidReply {
    /**
     * 
     * @type {string}
     * @memberof BidReply
     */
    messageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BidReply
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BidReply
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BidReply
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BidReply
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BidReply
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BidReply
     */
    initialMessageIdHeader?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BidReply
     */
    bidId?: number | null;
    /**
     * 
     * @type {Bid}
     * @memberof BidReply
     */
    bid?: Bid;
    /**
     * 
     * @type {boolean}
     * @memberof BidReply
     */
    incoming?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof BidReply
     */
    id?: number;
}

/**
 * Check if a given object implements the BidReply interface.
 */
export function instanceOfBidReply(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BidReplyFromJSON(json: any): BidReply {
    return BidReplyFromJSONTyped(json, false);
}

export function BidReplyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BidReply {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'initialMessageIdHeader': !exists(json, 'initialMessageIdHeader') ? undefined : json['initialMessageIdHeader'],
        'bidId': !exists(json, 'bidId') ? undefined : json['bidId'],
        'bid': !exists(json, 'bid') ? undefined : BidFromJSON(json['bid']),
        'incoming': !exists(json, 'incoming') ? undefined : json['incoming'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function BidReplyToJSON(value?: BidReply | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messageId': value.messageId,
        'text': value.text,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'initialMessageIdHeader': value.initialMessageIdHeader,
        'bidId': value.bidId,
        'bid': BidToJSON(value.bid),
        'incoming': value.incoming,
        'id': value.id,
    };
}

