import { AnyFunction } from '../../../../shared/interfaces/AnyFunction';
import React, { useMemo, useRef } from 'react';
import { NotificationArrowButton } from './NotificationArrowButton';
import { ETrackingStatus } from '../../../../generated-api';
import { classNames } from 'primereact/utils';
import './TrackingNotification.css';

export const TrackingNotification = (props: {
  id: number,
  number: string,
  status?: ETrackingStatus,
  onArrowClick: AnyFunction<any, any>
}) => {
  const ref = useRef<HTMLDivElement>({} as any);
  const notificationMessage = useMemo(
    () => `Tracking ${props.number} update required`,
    [props.number]);
  const backgroundClass = useMemo(() => classNames({
    '': !props.status || props.status === ETrackingStatus.NotSet,
    'tracking-attention': props.status === ETrackingStatus.Attention,
    'tracking-checking': props.status === ETrackingStatus.Checking,
    'tracking-loaded': props.status === ETrackingStatus.Loaded,
    'tracking-delivered': props.status === ETrackingStatus.Delivered,
    'tracking-hidden': props.status === ETrackingStatus.Hidden,
  }), [props.status]);

  const className = 'flex flex-row flex-grow-1 justify-content-evenly align-items-center text-2xl ' + backgroundClass;
  return <div
    className={className}
    ref={ref}
  >
    <i className='pi pi-exclamation-triangle text-2xl flex-grow-0 pr-4' />
    <span className='flex-grow-1'>{notificationMessage}</span>
    <NotificationArrowButton
      onClick={() => {
        props.onArrowClick();
        const closeButton = ref.current.parentElement!.querySelector('button.p-toast-icon-close') as HTMLButtonElement;
        closeButton.click();
      }}
      className='flex-grow-0 pl-4'
    >
      <i className='pi pi-angle-right text-2xl' />
    </NotificationArrowButton>
  </div>;
};