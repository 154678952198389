import { IdName } from '../../data-moqs/IdName';
import { EBusinessType } from '../../generated-api';

const businessTypes: IdName<EBusinessType>[] = [
  { id: EBusinessType.Individual, name: 'Individual/sole proprietor or single-member LLC' },
  { id: EBusinessType.CCorporation, name: 'C Corporation' },
  { id: EBusinessType.SCorporation, name: 'S Corporation' },
  { id: EBusinessType.Partnership, name: 'Partnership' },
  { id: EBusinessType.TrustEstate, name: 'Trust/Estate' },
  { id: EBusinessType.Llc, name: 'Limited Liability Company' },
  { id: EBusinessType.Other, name: 'Other' },
];

export default businessTypes;