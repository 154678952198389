/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmailLoginDto,
  JwtToken,
  LoginDto,
  MobileAuthResponse,
} from '../models';
import {
    EmailLoginDtoFromJSON,
    EmailLoginDtoToJSON,
    JwtTokenFromJSON,
    JwtTokenToJSON,
    LoginDtoFromJSON,
    LoginDtoToJSON,
    MobileAuthResponseFromJSON,
    MobileAuthResponseToJSON,
} from '../models';

export interface ApiAuthenticateMobilePostRequest {
    emailLoginDto?: EmailLoginDto;
}

export interface ApiAuthenticatePostRequest {
    loginDto?: LoginDto;
}

export interface ApiSendCodePostRequest {
    body?: string;
}

/**
 * 
 */
export class UserJwtApi extends runtime.BaseAPI {

    /**
     */
    async apiAuthenticateMobilePostRaw(requestParameters: ApiAuthenticateMobilePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileAuthResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/authenticate-mobile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailLoginDtoToJSON(requestParameters.emailLoginDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileAuthResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthenticateMobilePost(requestParameters: ApiAuthenticateMobilePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileAuthResponse> {
        const response = await this.apiAuthenticateMobilePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthenticatePostRaw(requestParameters: ApiAuthenticatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JwtToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/authenticate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginDtoToJSON(requestParameters.loginDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JwtTokenFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthenticatePost(requestParameters: ApiAuthenticatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JwtToken> {
        const response = await this.apiAuthenticatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSendCodePostRaw(requestParameters: ApiSendCodePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/send-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSendCodePost(requestParameters: ApiSendCodePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiSendCodePostRaw(requestParameters, initOverrides);
    }

}
