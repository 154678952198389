import {
  ColumnFilterElementTemplateOptions,
} from 'primereact/column';
import FilterProperties from '../FilterProperties';
import { FilterService } from 'primereact/api';
import DefaultServerFilterComponent from './DefaultServerFilterComponent';

export const serverFilterFunctionName = 'serverFilter';
const filterFunctionLabel = 'Server filter';

function serverFilterComponent(component?: (props: ColumnFilterElementTemplateOptions) => JSX.Element): FilterProperties {
  // Allow any row
  FilterService.register(serverFilterFunctionName, () => true);
  return {
    filterElement: component
      ? component
      : filterComponentProps => <DefaultServerFilterComponent {...filterComponentProps } />,
    filterMatchModeOptions: [{ value: serverFilterFunctionName, label: filterFunctionLabel}],
    // @ts-ignore
    filterMatchMode: serverFilterFunctionName,
  };
}

export default serverFilterComponent;