import React from 'react';

function Column(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const className = props.className || 'col-6';

  return (
     <div {...props} className={className}>
       {props.children}
     </div>
  );
}

export default Column;