import { BaseAPI, Configuration, ConfigurationParameters, ResponseContext } from '../../generated-api';
import { AuthenticationState, setSecondSession } from '../../features/login/LoginSlice';
import Env from "../../config/Env";
import { store } from '../../store/store';
import { AUTH_STATE_KEY } from '../../features/login/AuthHook';

export type TApiConstructor<T extends BaseAPI> = { new (config: Configuration): T };

export const getApiConfig = () => {
    const params: ConfigurationParameters = { basePath: Env.api.baseUrl };
    const authState = localStorage.getItem(AUTH_STATE_KEY);
    if (authState)
        params.accessToken = (JSON.parse(authState) as AuthenticationState).token;
    return new Configuration(params);
}

/**
 * Api clients cache.
 */
const cache = new Map();

/**
 * Config cache.
 */
let config: Configuration | undefined;

function getSpecificApi<T extends BaseAPI>(type: TApiConstructor<T>, force = false): T {
    if (!config || force)
        config = getApiConfig();
    return new type(config);
}

/**
 * Factory function to get api client with actual config.
 * @param type Type of api.
 * @param force Ignore cache if true.
 * @returns Instance of api type.
 */
export function apiFactory<T extends BaseAPI>(type: TApiConstructor<T>, force = false): T {
  if (cache.has(type) && !force)
      return cache.get(type) as T;
  const api = getSpecificApi(type, force)
    .withPostMiddleware((context: ResponseContext) => {
      if (context.response && context.response.status === 403 && context.response.headers.get('secondsession')) {
        // API calls are outside react,
        // but we have to tell components that user start second session.
        store.dispatch(setSecondSession(true));
      }
      return Promise.resolve(context.response);
  });

  cache.set(type, api);
  return api;
}

/**
 * Clears api clients cache.
 */
export function clearApiCache() {
    config = undefined;
    cache.clear();
}
