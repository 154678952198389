import styled from '@emotion/styled';
import { Slider, SliderProps } from 'primereact/slider';


const StyledSlider = styled(Slider)`
    .ant-tooltip {
        z-index: 2001;
    }
`;


export function EditorSlider(props: SliderProps) {
 return <StyledSlider
   min={-100}
   max={100}
   step={1}
   defaultValue={0}
   className='w-5'
   {...props}
 />
}
