import { WidgetFilters } from '../../components/WidgetToolbar';
import dayjs from 'dayjs';
import { EWidgetPeriodType } from '../../../../generated-api';

export function getPeriodFromWidgetFilters(exportFilters?: WidgetFilters) {
  const dateFrom = dayjs(exportFilters?.from);
  const dateTo = dayjs(exportFilters?.to);
  let dateFromString;
  let dateToString;
  if (exportFilters?.periodType === EWidgetPeriodType.Week) {
    dateFromString = dateFrom.format('YYYY-wo');
    dateToString = dateTo.format('YYYY-wo');
  } else if (exportFilters?.periodType === EWidgetPeriodType.Month) {
    dateFromString = dateFrom.format('YYYY-MM');
    dateToString = dateTo.format('YYYY-MM');
  } else {
    dateFromString = dateFrom.format('MM-DD-YYYY');
    dateToString = dateTo.format('MM-DD-YYYY');
  }
  return `${dateFromString} - ${dateToString}`;
};