/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ERequestStatus = {
    None: 'None',
    New: 'New',
    Approved: 'Approved',
    Rejected: 'Rejected',
    Ready: 'Ready',
    InProgress: 'InProgress'
} as const;
export type ERequestStatus = typeof ERequestStatus[keyof typeof ERequestStatus];


export function ERequestStatusFromJSON(json: any): ERequestStatus {
    return ERequestStatusFromJSONTyped(json, false);
}

export function ERequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ERequestStatus {
    return json as ERequestStatus;
}

export function ERequestStatusToJSON(value?: ERequestStatus | null): any {
    return value as any;
}

