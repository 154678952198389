/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehiclesAccess
 */
export interface VehiclesAccess {
    /**
     * 
     * @type {boolean}
     * @memberof VehiclesAccess
     */
    executeVehicleStats?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclesAccess
     */
    editOperativeFields?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclesAccess
     */
    create?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclesAccess
     */
    read?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclesAccess
     */
    update?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclesAccess
     */
    _delete?: boolean | null;
}

/**
 * Check if a given object implements the VehiclesAccess interface.
 */
export function instanceOfVehiclesAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehiclesAccessFromJSON(json: any): VehiclesAccess {
    return VehiclesAccessFromJSONTyped(json, false);
}

export function VehiclesAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehiclesAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'executeVehicleStats': !exists(json, 'executeVehicleStats') ? undefined : json['executeVehicleStats'],
        'editOperativeFields': !exists(json, 'editOperativeFields') ? undefined : json['editOperativeFields'],
        'create': !exists(json, 'create') ? undefined : json['create'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'update': !exists(json, 'update') ? undefined : json['update'],
        '_delete': !exists(json, 'delete') ? undefined : json['delete'],
    };
}

export function VehiclesAccessToJSON(value?: VehiclesAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'executeVehicleStats': value.executeVehicleStats,
        'editOperativeFields': value.editOperativeFields,
        'create': value.create,
        'read': value.read,
        'update': value.update,
        'delete': value._delete,
    };
}

