/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordChangeDto
 */
export interface PasswordChangeDto {
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeDto
     */
    currentPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangeDto
     */
    newPassword?: string | null;
}

/**
 * Check if a given object implements the PasswordChangeDto interface.
 */
export function instanceOfPasswordChangeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PasswordChangeDtoFromJSON(json: any): PasswordChangeDto {
    return PasswordChangeDtoFromJSONTyped(json, false);
}

export function PasswordChangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordChangeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPassword': !exists(json, 'currentPassword') ? undefined : json['currentPassword'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
    };
}

export function PasswordChangeDtoToJSON(value?: PasswordChangeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentPassword': value.currentPassword,
        'newPassword': value.newPassword,
    };
}

