import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import styled from '@emotion/styled';

const StyledCheckbox = styled(Checkbox)`
  &.p-checkbox,
  &.p-checkbox .p-checkbox-box {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  &.p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 0.9rem;
  }
`

function ServerActiveCheckbox(props: ColumnFilterElementTemplateOptions) {

  return (
    <div>
      <StyledCheckbox
        checked={props.value}
        onChange={e => props.filterApplyCallback(e.checked)}
      />
      <span className='ml-2'>Active</span>
    </div>
  );
}

export default ServerActiveCheckbox;