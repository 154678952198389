import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import Env from '../config/Env';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setHub } from '../features/notifications/NotificationsSlice';
import useAuth from '../features/login/AuthHook';

export function useHubConnection() {
  const hubUrl = Env.api.baseUrl + '/hub/';
  const hubs = useAppSelector(state => state.notification.hubs);
  const { auth } = useAuth();
  const dispatch = useAppDispatch();
  let hub = hubs?.find(x => x.baseUrl.endsWith(hubUrl));
  if (!hub) {
    hub = new HubConnectionBuilder()
      .withUrl(hubUrl, { withCredentials: false, accessTokenFactory: () => auth.token })
      .withAutomaticReconnect()
      .build();
    dispatch(setHub(hub));
  }

  return hub;
}

export function connect(hubConnection: HubConnection) {
  if (hubConnection.state === HubConnectionState.Disconnected) {
    return hubConnection.start()
      .catch((reason) => console.error(reason));
  }

  return Promise.resolve();
}

export function disconnect(hubConnection: HubConnection) {
  if (hubConnection.state === HubConnectionState.Connected) {
    return hubConnection.stop();
  }

  return Promise.resolve();
}