import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogProps } from 'primereact/dialog';
import VisibilityToggler from './VisibilityToggler';
import styled from '@emotion/styled';

const StyledDialog = styled(Dialog)`
  & .p-dialog-header {
    display: none;
  }
`

function LogisticsDialog(props: DialogProps) {
  const [existingOverlaysCount, setExistingOverlaysCount] = useState(0);
  const onMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    props.onHide();
  }

  useEffect(() => {
    if (!props.visible) return;
    const logisticsOverlayCount = document.querySelectorAll('.logistics-overlay').length;
    setExistingOverlaysCount(logisticsOverlayCount);
  }, [props.visible])

  const content = useMemo(() => {
    return (
      <div
        className='h-full'
        onClick={(e) => {
          const element = e.target as HTMLElement;
          if (element.tagName === 'A') return;
          e.preventDefault();
        }}
      >
        {props.children}
      </div>
    );
  }, [props.children]);

  return <>
    <VisibilityToggler visible={props.visible}>
      <div onMouseDown={onMouseDown} className='logistics-overlay' style={{zIndex: 1000 + existingOverlaysCount}}></div>
    </VisibilityToggler>
    <StyledDialog {...props} children={content} modal={false}/>
  </>
  ;
}

export default LogisticsDialog;
