/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ListOfLoadsActionsAccess } from './ListOfLoadsActionsAccess';
import {
    ListOfLoadsActionsAccessFromJSON,
    ListOfLoadsActionsAccessFromJSONTyped,
    ListOfLoadsActionsAccessToJSON,
} from './ListOfLoadsActionsAccess';

/**
 * 
 * @export
 * @interface ListOfLoadsAccess
 */
export interface ListOfLoadsAccess {
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoadsAccess
     */
    factoring?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoadsAccess
     */
    brokerageEditing?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoadsAccess
     */
    ownerBill?: boolean | null;
    /**
     * 
     * @type {ListOfLoadsActionsAccess}
     * @memberof ListOfLoadsAccess
     */
    actions?: ListOfLoadsActionsAccess;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoadsAccess
     */
    create?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoadsAccess
     */
    read?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoadsAccess
     */
    update?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoadsAccess
     */
    _delete?: boolean | null;
}

/**
 * Check if a given object implements the ListOfLoadsAccess interface.
 */
export function instanceOfListOfLoadsAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListOfLoadsAccessFromJSON(json: any): ListOfLoadsAccess {
    return ListOfLoadsAccessFromJSONTyped(json, false);
}

export function ListOfLoadsAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOfLoadsAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'factoring': !exists(json, 'factoring') ? undefined : json['factoring'],
        'brokerageEditing': !exists(json, 'brokerageEditing') ? undefined : json['brokerageEditing'],
        'ownerBill': !exists(json, 'ownerBill') ? undefined : json['ownerBill'],
        'actions': !exists(json, 'actions') ? undefined : ListOfLoadsActionsAccessFromJSON(json['actions']),
        'create': !exists(json, 'create') ? undefined : json['create'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'update': !exists(json, 'update') ? undefined : json['update'],
        '_delete': !exists(json, 'delete') ? undefined : json['delete'],
    };
}

export function ListOfLoadsAccessToJSON(value?: ListOfLoadsAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'factoring': value.factoring,
        'brokerageEditing': value.brokerageEditing,
        'ownerBill': value.ownerBill,
        'actions': ListOfLoadsActionsAccessToJSON(value.actions),
        'create': value.create,
        'read': value.read,
        'update': value.update,
        'delete': value._delete,
    };
}

