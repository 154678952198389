import React from 'react';
import { Driver, Vehicle, Request } from '../../../../generated-api';
import LogisticsDataGridBase from '../../../../components/data-grid/LogisticsDataGridBase';
import requestDriverListColumnDef from './RequestDriverListColumnDef';
import { useModal } from '../../../../hooks';
import DriverEdit from '../../../drivers/DriverEdit';
import { Button } from 'primereact/button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useRights from '../../../../hooks/RightsHook';

type RequestDriverListProps = {
  drivers?: Driver[] | null | undefined,
  setDriver: (Driver: Vehicle) => any,
  request: Request,
}

function RequestDriverList(props: RequestDriverListProps) {
  const driverModal = useModal<Driver>({});
  const rights = useRights(security => security.request);
  const addDriverButton = <Button
    disabled={!props.request?.id || !rights.update}
    className='p-button-success px-5 ml-2 text-base font-bold'
    onClick={() => driverModal.show({ request: props.request })}
  >
    <p className='my-0'>Add driver</p>
    <AddCircleOutlineIcon fontSize='medium' className='ml-1' />
  </Button>;

  const driverEdit = driverModal.visible
    ? <DriverEdit
      data={driverModal.data}
      loading={false}
      visible={driverModal.visible}
      openedFromRequest={true}
      hide={driver => {
        if (!driver) return driverModal.hide();

        props.setDriver(driver);
        driverModal.hide();
      }}
    />
    : <></>;
  return <>
    <LogisticsDataGridBase
      columns={requestDriverListColumnDef}
      entities={props.drivers}
      fullSize={false}
      rightHeaderElements={[
        addDriverButton,
      ]}
      onRowClick={e => driverModal.show(e.data)}
    />
    {driverEdit}
  </>
}

export default RequestDriverList;