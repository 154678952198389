import { IdName } from '../../../data-moqs/IdName';
import { EWidgetChart } from '../../../generated-api';

const chartCaptions : IdName<string>[] = [
  { id: EWidgetChart.Orders, name: 'Orders' },
  { id: EWidgetChart.OrdersLastYear, name: 'Orders (last year)' },
  { id: EWidgetChart.BidsByDispatchers, name: 'Bids by dispatcher' },
  { id: EWidgetChart.DriversProfit, name: 'Drivers profit' },
  { id: EWidgetChart.Profit, name: 'Profit' },
  { id: EWidgetChart.Margin, name: 'Margin' },
  { id: EWidgetChart.Loads, name: 'Loads' },
];

export default chartCaptions;