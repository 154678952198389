import styled from '@emotion/styled';
import { Panel } from 'primereact/panel';

const LogisticsPanel = styled(Panel)`
& {
  background-color: #017DE5;
  color: #ffffff;
}

& > .p-panel-header {
  background-color: inherit;
  color: inherit;
}
& > .p-panel-header > .p-panel-title {
  font-size: 1.25rem;
  padding: 10px;
}
`
export default LogisticsPanel;