/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EChangeType } from './EChangeType';
import {
    EChangeTypeFromJSON,
    EChangeTypeFromJSONTyped,
    EChangeTypeToJSON,
} from './EChangeType';
import type { LogisticsFile } from './LogisticsFile';
import {
    LogisticsFileFromJSON,
    LogisticsFileFromJSONTyped,
    LogisticsFileToJSON,
} from './LogisticsFile';
import type { Owner } from './Owner';
import {
    OwnerFromJSON,
    OwnerFromJSONTyped,
    OwnerToJSON,
} from './Owner';

/**
 * 
 * @export
 * @interface OwnerDocument
 */
export interface OwnerDocument {
    /**
     * 
     * @type {Owner}
     * @memberof OwnerDocument
     */
    owner?: Owner;
    /**
     * 
     * @type {number}
     * @memberof OwnerDocument
     */
    ownerId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof OwnerDocument
     */
    w9Date?: Date | null;
    /**
     * 
     * @type {LogisticsFile}
     * @memberof OwnerDocument
     */
    w9File?: LogisticsFile;
    /**
     * 
     * @type {boolean}
     * @memberof OwnerDocument
     */
    w9Signature?: boolean | null;
    /**
     * 
     * @type {EChangeType}
     * @memberof OwnerDocument
     */
    changeType?: EChangeType;
    /**
     * 
     * @type {number}
     * @memberof OwnerDocument
     */
    id?: number;
}

/**
 * Check if a given object implements the OwnerDocument interface.
 */
export function instanceOfOwnerDocument(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OwnerDocumentFromJSON(json: any): OwnerDocument {
    return OwnerDocumentFromJSONTyped(json, false);
}

export function OwnerDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'owner': !exists(json, 'owner') ? undefined : OwnerFromJSON(json['owner']),
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'w9Date': !exists(json, 'w9Date') ? undefined : (json['w9Date'] === null ? null : new Date(json['w9Date'])),
        'w9File': !exists(json, 'w9File') ? undefined : LogisticsFileFromJSON(json['w9File']),
        'w9Signature': !exists(json, 'w9Signature') ? undefined : json['w9Signature'],
        'changeType': !exists(json, 'changeType') ? undefined : EChangeTypeFromJSON(json['changeType']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function OwnerDocumentToJSON(value?: OwnerDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'owner': OwnerToJSON(value.owner),
        'ownerId': value.ownerId,
        'w9Date': value.w9Date === undefined ? undefined : (value.w9Date === null ? null : value.w9Date.toISOString()),
        'w9File': LogisticsFileToJSON(value.w9File),
        'w9Signature': value.w9Signature,
        'changeType': EChangeTypeToJSON(value.changeType),
        'id': value.id,
    };
}

