import React, { useMemo, useRef, useState } from 'react';
import StateFilterModel from './StateFilterModel';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { AutoComplete, AutoCompleteChangeParams, AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import useStateData from './../../../data-moqs/State';
import styled from '@emotion/styled';
import { focusedInputStyle, inputStyle } from '../../form/Styles';

const StyledAutocomplete = styled(AutoComplete)`
  & button {
    display: none;
  }

  & .p-autocomplete-multiple-container li.p-autocomplete-token {
    margin-bottom: .5px;
    align-items: flex-end;
    color: var(--text-color);
    background-color: var(--surface-300);
    font-family: Monaco, 'Courier New', monospace;
  }

  & .p-autocomplete-multiple-container {
    ${inputStyle}
  }

  &.p-autocomplete .p-autocomplete-multiple-container.p-focus {
    ${focusedInputStyle}
  }
`

function StateFilterInput(props: ColumnFilterElementTemplateOptions ) {
  const { value, filterApplyCallback } = props;
  const allStates = useStateData();
  const [input, setInput] = useState<string>('');
  const ref = useRef<AutoComplete>(null);

  const notSelectedStates = useMemo(() => {
    if (!value?.length) return allStates;

    return allStates.filter(
      state => !value.find(
        (selected: StateFilterModel) => selected.code === state.code))
  }, [value, allStates]);

  const suggestions = useMemo(() => {
    if (!input) return notSelectedStates;

    return notSelectedStates.filter(state => state.code.toLowerCase().startsWith(input.toLowerCase()));
  }, [notSelectedStates, input]);

  const handleInputChange = (event: AutoCompleteCompleteMethodParams) => {
    setInput(event.query || '');
  }

  const handleOnChange = (e: AutoCompleteChangeParams) => {
    const selectedValues = e.target.value as StateFilterModel[];
    filterApplyCallback(selectedValues);
    setInput('');
  }

  function handleOnFocus() {
    ref!.current!.show();
  }

  function handleOnClick() {
    ref!.current!.show();
  }

  function handleOnKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key !== 'Enter') return;
    if (!suggestions.length) return;

    e.preventDefault();
    ref!.current!.hide();
    const newValue = value ? [...value, suggestions[0]] : [suggestions[0]];
    filterApplyCallback(newValue);
  }

  return (
    <StyledAutocomplete
      ref={ref}
      multiple
      dropdown
      dropdownMode='current'
      suggestions={suggestions}
      completeMethod={handleInputChange}
      field="name"
      itemTemplate={(option : StateFilterModel) => option.name}
      selectedItemTemplate={(value: StateFilterModel) => value.code}
      value={value}
      onChange={(e) => handleOnChange(e)}
      onFocus={() => handleOnFocus()}
      onClick={() => handleOnClick()}
      onKeyUp={e => handleOnKeyUp(e)}
      placeholder='Start typing...'
    >
    </StyledAutocomplete>
  );
}

export default StateFilterInput;