import dayjs from 'dayjs';

export const formatDateTimeWithOptions = (rawDate: Date | undefined | null, options: Intl.DateTimeFormatOptions) => {
  if (!rawDate)
    return '';

  return new Intl.DateTimeFormat('en-US', options).format(rawDate);
};

export const formatTime = (rawDate: Date | undefined | null) => {
  if (!rawDate) return '';

  return dayjs(rawDate).tz().format('HH:mm A');
};

export const formatDateTime = (rawDate : Date | undefined | null) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  return formatDateTimeWithOptions(rawDate, options);
};

// Current timezone minus default timezone, in minutes
export const defaultTimezoneOffset =
  (date? : Date) => dayjs(date).utcOffset() - dayjs(date).tz().utcOffset();