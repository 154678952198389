import styled from '@emotion/styled';
import { DatePicker } from 'antd';

const StyledWeekCalendar = styled(DatePicker)`
& {
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
}
&.ant-picker .ant-picker-input >input {
  color: var(--gray-700);
}
.ant-picker-suffix {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  padding-bottom: 0.35rem;
}
.ant-picker-clear {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  padding-bottom: 0.35rem;
}
.ant-picker-input .ant-picker-suffix:hover {
  cursor: pointer;
}
&.not-valid-custom {
  border-color: red;
  border-inline-end-width: 0px;
}
&.ant-picker.disabled-style {
  background: transparent;
  cursor: default;
}
&.ant-picker-focused:hover:not(.not-valid-custom) {
  border-color: #6366f1;
}
&.ant-picker .ant-picker-input >input[disabled] {
  cursor: default;
  color: rgba(0, 0, 0, 0.35)
}
&:hover:not(.not-valid-custom) {
  border-color: #ced4da;
  border-inline-end-width: 0px;
}
`;

export default StyledWeekCalendar;