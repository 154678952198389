import { useState, useEffect } from 'react';
import { Audit, ChangelogApi } from '../../generated-api';
import { apiFactory } from '../../shared';
import { ModalParameters } from '../../shared';
import CenteredSpinner from '../../components/CenteredSpinner';
import LogisticsDialog from '../../components/LogisticsDialog';
import { Button } from 'primereact/button';
import ChangelogBlock from '../../components/ChangelogBlock';
import ListOfLoadsEditCaptions from './ListOfLoadsEditCaptions';

export interface ListOfLoadsChangelogParameters extends ModalParameters<number | undefined> {

}

function ListOfLoadsChangelog(props: ListOfLoadsChangelogParameters) {
  const [loading, setLoading] = useState(props.loading);
  const [changelog, setChangelog] = useState<Audit[]>([]);

  useEffect(() => {
    if (!props.visible) return;

    setLoading(true);

    apiFactory(ChangelogApi)
    .apiChangelogGet({ entityName: 'ListOfLoads', entityId: props.data })
    .then((loadedChangelog) => {
      setChangelog(loadedChangelog);
    })
    .finally(() => {
      setLoading(false);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.visible]);

  const handleOnHide = () => {
    props.hide(undefined);
    setLoading(false);
  };

  return (
    <LogisticsDialog
      visible={props.visible}
      onHide={handleOnHide}
      className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-4'
      dismissableMask
    >
      <header className='text-2xl w-full flex-wrap'>
        <span className='font-bold'>Changelog</span>
      </header>
      <main className='mt-6 mr-3'>
        <ChangelogBlock changelog={changelog} formCaptions={ListOfLoadsEditCaptions}></ChangelogBlock>
      </main>
      <footer className='flex justify-content-center w-full gap-3 mt-6'>
        <Button label='Close' className='min-w-min w-6rem h-3rem'
          onClick={handleOnHide}></Button>
      </footer>
      <CenteredSpinner visible={loading} />
    </LogisticsDialog>
  );
}

export default ListOfLoadsChangelog;