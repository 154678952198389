import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { apiFactory } from '../../../shared';
import {
  FileApi,
  Owner,
  RegistrationApi,
  Request,
  ResponseError,
  State,
  User,
} from '../../../generated-api';
import { showErrorToast, showSuccessToast } from '../../../components/LogisticsToast';
import { FormikErrors, useFormik } from 'formik';
import { logisticsConfirmDialog } from '../../../components/LogisticsConfifmDialog';
import { useNavigate } from 'react-router-dom';
const blankError = 'This value should not be blank.';

interface DocumentNameUrl {
  name: string,
  url: string,
}

export default function useRequestEditData(id?: number | null | undefined) {
  const [request, _setRequest] = useState<Request>({});
  const [owner, _setOwner] = useState<Owner>({});
  const [managers, setManagers] = useState<User[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<DocumentNameUrl[]>([]);
  const navigate = useNavigate();

  const requestValidation = useFormik<Request>({
    initialValues: request,
    validate: data => {
      const requestErrors: FormikErrors<Request> = {};
      const requestRequiredFields: Request = {
        user: undefined as any,
      }

      Object.keys(requestRequiredFields).forEach(field => {
        if (!(data as any)[field])
          (requestErrors as any)[field] = blankError;
      });

      return requestErrors;
    },
    onSubmit: () => {},
  });

  const ownerValidation = useFormik<Owner>({
    initialValues: owner,
    validate: data => {
      const ownerErrors: FormikErrors<Owner> = {};

      const ownerRequiredFields: Owner = {
        companyName: undefined as any,
        firstName: undefined as any,
        lastName: undefined as any,
        email: undefined as any,
        phones: undefined as any,
        employerId: undefined as any,
        address: undefined as any,
        city: undefined as any,
        state: undefined as any,
        zip: undefined as any,
        businessType: undefined as any,
      }

      Object.keys(ownerRequiredFields).forEach(field => {
        if (!(data as any)[field])
          (ownerErrors as any)[field] = blankError;
      });

      return ownerErrors;
    },
    onSubmit: () => {},
  });

  const validate = useCallback(async () => {
    requestValidation.handleSubmit();
    const requestErrors = await requestValidation.validateForm(request);
    if (Object.keys(requestErrors).length) {
      throw new Error('Fix errors and try again');
    }

    ownerValidation.handleSubmit();
    const ownerErrors = await ownerValidation.validateForm(owner);
    if (Object.keys(ownerErrors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [request, owner, requestValidation, ownerValidation]);

  const setRequest = useCallback((action: SetStateAction<Request>, validate: boolean = true) => {
    _setRequest(action);
    requestValidation.setValues(action, validate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOwner = useCallback((action: SetStateAction<Owner>, validate: boolean = true) => {
    _setOwner(action);
    ownerValidation.setValues(action, validate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFormData = async () => {
    try{
      const requestPromise = id
        ? apiFactory(RegistrationApi).apiRegistrationRequestGet({ id })
        : Promise.resolve({} as Request);
      setLoading(true);
      const [loadedRequest, managers, states] = await Promise.all([
        requestPromise,
        apiFactory(RegistrationApi).apiRegistrationManagersGet(),
        apiFactory(RegistrationApi).apiRegistrationStatesGet(),
      ]);
      setRequest(loadedRequest, false);
      const loadedOwner = loadedRequest?.owner
        ? {
          ...loadedRequest.owner,
          state: states.find(x => x.id === loadedRequest.owner!.stateId)}
        : {} as Owner;
      setOwner(loadedOwner, false);
      setManagers(managers);
      setStates(states);
      ownerValidation.resetForm({ values: loadedRequest.owner, touched: {}, errors: {}});
      requestValidation.resetForm({ values: loadedRequest, touched: {}, errors: {}});
      const hasUnsignedDocuments =
        !loadedOwner?.signature
        || loadedOwner?.ownerDocuments?.some(x => !x.w9Signature);
      if (!hasUnsignedDocuments)
        return;

      let documentPromises = [] as Promise<{blob: Blob, name: string}>[];
      if (!loadedOwner.signature) {
        const contractPromise = apiFactory(RegistrationApi)
          .apiRegistrationContractGet({ ownerId: loadedRequest.ownerId! })
          .then(blob => ({ blob, name: 'Contract'}));
        documentPromises.push(contractPromise)
      }

      const w9Promises = loadedOwner.ownerDocuments
        ?.filter(x => !x.w9Signature)
        .map(ownerDocument => {
          return apiFactory(FileApi).apiFileGet({
            id: ownerDocument.w9File?.id!,
            name: ownerDocument.w9File?.name!
          }).then(blob => ({ blob, name: 'W9'}));
        }) || [];
      documentPromises.push(...w9Promises);
      const documentResponses = await Promise.allSettled(documentPromises);
      const documentUrls = documentResponses.map(documentResponse => {
        if (documentResponse.status === 'fulfilled') {
          return { name: documentResponse.value.name, url: blobToUrl(documentResponse.value.blob )};
        } else {
          console.error(`Failed to load document: ${documentResponse.reason}`);
          return undefined;
        }
      }).filter(x => x) as DocumentNameUrl[];
      setDocuments(documentUrls);
    } catch {
      showErrorToast('Failed to load data')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClose = useCallback(() => {
    if (requestValidation.dirty || ownerValidation.dirty) {
      logisticsConfirmDialog({
        message: 'Save changes?',
        closable: false,
        accept() {
          save().then(() => navigate('/hr'));
        },
        reject() {
          navigate('/hr');
        }
      });
    } else {
      navigate('/hr');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestValidation, request]);

  const save = useCallback(async () => {
    const requestForSave = { ...request, owner} as Request;

    try {
      await validate();
      setLoading(true);

      if (!requestForSave.id) {
        try {
          const existingOwner : Owner = await apiFactory(RegistrationApi).apiRegistrationOwnerGet({ employerId: owner.employerId! });
          if (existingOwner) {
            logisticsConfirmDialog({
              message: 'This company already exists in the database! Use current information?',
              closable: false,
              async accept() {
                setOwner(existingOwner);
                requestForSave.owner = existingOwner;
                await saveLogic(requestForSave);
              },
              reject() {
              }
            });
          }
        } catch(e : ResponseError | any) {
          if ((e as ResponseError)?.response?.status === 404) {
            saveLogic(requestForSave);
          } else throw e;
        }
      } else {
        await saveLogic(requestForSave);
      }

    } catch (e: any) {
      showErrorToast(e.toString());
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, owner]);

  const saveLogic = async (requestForSave: Request) => {
    const response = await apiFactory(RegistrationApi)
      .apiRegistrationRequestPut({ request: requestForSave });
    showSuccessToast('Request saved');
    if (response) {
      setRequest(response);
      setOwner(response.owner || {});

      ownerValidation.resetForm({ values: response.owner, touched: {}, errors: {} });
      requestValidation.resetForm({ values: response, touched: {}, errors: {} });
    }
  }

  return {
    managers,
    states,
    setRequest,
    request,
    setOwner,
    owner,
    save,
    requestValidation,
    ownerValidation,
    loading,
    handleOnClose,
    documents,
    loadFormData,
  } as const;
}

export type RequestEditPublicWidget = ReturnType<typeof useRequestEditData>;


function blobToUrl(blob: Blob) {
  return URL.createObjectURL(
    blob.slice(0, blob.size, 'application/pdf'));
}
