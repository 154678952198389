import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useCallback, useEffect, useState } from 'react';
import CenteredSpinner from '../../components/CenteredSpinner';
import Column from '../../components/form/Column';
import Field from '../../components/form/Field';
import Row from '../../components/form/Row';
import StyledInputText from '../../components/form/StyledInputText';
import { logisticsConfirmDialog } from '../../components/LogisticsConfifmDialog';
import LogisticsDialog from '../../components/LogisticsDialog';
import { showSuccessToast, showToast } from '../../components/LogisticsToast';
import { Brokerage, BrokeragesApi } from '../../generated-api';
import { apiFactory, ModalParameters } from '../../shared';

export interface BrokerageEditParameters extends ModalParameters<Brokerage> {
}

interface BrokerageEditFormErrors {
  name?: string;
  contactAddress?: string;
}

function BrokerageEdit(props: BrokerageEditParameters) {
  const [brokerage, setBrokerage] = useState<Brokerage>(props?.data);
  const [doesNameExist, setDoesNameExist] = useState<boolean>(false);
  const [stateLoading, setLoading] = useState(props?.loading);

  useEffect(() => {
    if (!props?.visible) return;
    setLoading(true);
    const brokerageApi = apiFactory(BrokeragesApi);
    const brokeragePromise = props.data.id
      ? brokerageApi.apiBrokeragesIdGet({ id: props.data.id })
      : Promise.resolve(brokerage);

    Promise.all([
      brokeragePromise,
    ]).then(([
      loadedBrokerage,
    ]) => {
      setBrokerage(loadedBrokerage);
    }).catch(() => showToast(
      { severity: 'error', summary: 'Error', detail: 'Something went wrong with preloading information on the form'}))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik<Brokerage>({
    initialValues: {
      name: props.data.name,
      contactAddress: props.data.contactAddress
    },
    validate: (data) => {
      const errors: BrokerageEditFormErrors = {};
      const blankError = 'This value should not be blank.';

      if (!data.name) {
        errors.name = blankError;
      }

      if (doesNameExist) {
        errors.name = 'Brokerage with the same name already exists.';
      }

      return errors;
    },
    onSubmit: () => {}
  });

    // To prevent annoying TS warning, making this assignment.
  // See https://stackoverflow.com/questions/72039345/typescript-type-of-input-parameter-formik-error-and-formik-touched-prime-react
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;

  const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: string) => {
    return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
  };

  const validate = useCallback(async () => {
    formik.handleSubmit();
    const errors = await formik.validateForm(formik.values);
    if (Object.keys(errors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [formik]);

  const hide = props.hide;

  const onSubmit = async () => {
    try {
      await validate();
      setLoading(true);

      const response = brokerage.id
      ? await apiFactory(BrokeragesApi)
        .apiBrokeragesIdPut({id: brokerage.id, brokerage: brokerage})
      : await apiFactory(BrokeragesApi)
        .apiBrokeragesPost({ brokerage: brokerage});

      showSuccessToast('Brokerage was saved');

      formik.resetForm({ values: { ...formik.values }, touched: {} });
      hide(response || brokerage);
      setLoading(false);
    } catch (error: any) {
      showToast({ severity: 'error', summary: 'Error', detail: error.toString() });
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const handleOnHide = useCallback(() => {
    if (formik.dirty) {
      logisticsConfirmDialog({
        message: 'Save changes?',
        closable: false,
        accept() {
          onSubmit();
        },
        reject() {
          hide(undefined);
        }
      });
    } else {
      hide(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide, brokerage, formik]);

  return (
  <>
    <LogisticsDialog
      visible={props?.visible}
      onHide={handleOnHide}
      className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-4'
    >
      <header className='text-2xl w-full flex-wrap mb-5'>
          <span className='font-bold'>Brokerage</span>
        </header>
      <main className='grid logistics-dialog-edit-form__content'>
        <Row>
          <Column>
            <Field label='Name' required>
              <StyledInputText
                // @ts-ignore
                value={brokerage.name}
                onChange={e => {
                  formik.setFieldValue('name', e.target.value, true)
                  setBrokerage(b => {
                    return { ...b, name: e.target.value };
                  })
                }}
                className={classNames({ 'p-invalid': isFormFieldValid('name') })}
                disabled={false}
                onBlur={e => {
                  if (formik.initialValues.name !== formik.values.name) {
                    apiFactory(BrokeragesApi).apiBrokeragesExistingNameNameGet({ name: formik.values.name || '' })
                      .then(doesExist => {
                        // boolean result in fact returns as a string, hence this conversion
                        setDoesNameExist(doesExist.toString() === 'true');
                        setTimeout(() => formik.setFieldTouched('name', true));
                      });
                  }
                }}
              />
              {getFormErrorMessage('name')}
            </Field>
          </Column>
          <Column>
            <Field label='Contact address'>
              <StyledInputText 
                // @ts-ignore
                value={brokerage.contactAddress}
                onChange={e => {
                  formik.setFieldValue('contactAddress', e.target.value, true)
                  setBrokerage(b => {
                    return { ...b, contactAddress: e.target.value };
                  })
                }}
                className={classNames({ 'p-invalid': isFormFieldValid('contactAddress') })}
                disabled={false}
              />
              {getFormErrorMessage('contactAddress')}
            </Field>
          </Column>
        </Row>
        <footer className='flex justify-content-center w-full gap-3'>
          <Button label='CLOSE' className='min-w-min w-6rem h-3rem mr-3'
            onClick={handleOnHide}></Button>
          <Button label='SAVE' className='p-button-success min-w-min w-6rem h-3rem '
            onClick={onSubmit}></Button>
        </footer>
        <CenteredSpinner visible={stateLoading} />
      </main>
    </LogisticsDialog>
  </>
  );
}

export default BrokerageEdit;