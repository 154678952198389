import React, { useEffect, useMemo } from 'react';
import WidgetToolbar, { WidgetFilters } from '../components/WidgetToolbar';
import DashboardPanel from '../components/DashboardPanel';
import {
  DashboardApi,
  EWidgetChart,
  RevenueByDispatcherResponse,
} from '../../../generated-api';
import { apiFactory } from '../../../shared';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { nameof } from 'ts-simple-nameof';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import useWidget from '../hooks/WidgetHook';
import { today } from '../../../shared/util/dateUtils';
import { getDashboardDispatchersRevenueByDispatcher } from '../DashboardSlice';

function RevenueByDispatcher() {
  const appDispatch = useAppDispatch();
  const dispatchers = useAppSelector(state => state.dashboard.dispatcherState?.revenueByDispatcher);
  const initialFilters : WidgetFilters = {
    periodType: 'Day',
    from: today().toDate(),
    to: today().toDate(),
    charts: [EWidgetChart.Orders],
    dispatcherIds: dispatchers?.map(x => x.id || '') || [],
  };

  const {filters, setFilters, data, loadData} = useWidget({
    initialFilters: initialFilters,
    initialWidgetData: [],
    loadWidgetData: filters => apiFactory(DashboardApi)
      .apiDashboardRevenueByDispatcherGet(filters),
    usesDispatchers: true,
    dispatcherState: state => state.dashboard.dispatcherState?.revenueByDispatcher,
    getDispatcherSliceAction: getDashboardDispatchersRevenueByDispatcher,
  });

  useEffect(() => {
    appDispatch(getDashboardDispatchersRevenueByDispatcher(initialFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const joinedTableData = useMemo(() => {
    return data.map(x => {
      const dispatcher = dispatchers?.find(d => d.id === x.dispatcher);
      const dispatcherName = dispatcher ? `${dispatcher.firstName} ${dispatcher.lastName}` : x.dispatcher;

      return { ...x, dispatcher: dispatcherName } as RevenueByDispatcherResponse;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <DashboardPanel>
    <header className='flex'>
      <span className='text-3xl font-medium white-space-nowrap flex justify-content-start align-items-center flex-grow-1 gap-2'>
        Revenue by dispatcher
      </span>
      <span className='flex justify-content-end align-items-center flex-grow-1 gap-2'>
        <WidgetToolbar
          value={filters}
          dispatcherState={(state) => state.dashboard.dispatcherState?.revenueByDispatcher}
          onChange={setFilters}
          onFilter={loadData}
          visibility={{
            period: true,
            from: true,
            to: true,
            dispatchers: true,
            filterButton: true,
          }}
        />
      </span>
    </header>
    <div className='flex flex-column pt-3'>
      <StyledTable
        dataSource={joinedTableData}
        columns={columns}
        pagination={false}
        size='small'
        bordered
        summary={(pageData : readonly RevenueByDispatcherResponse[]) => {
          let totalBids = 0;
          let totalLoads = 0;
          let totalProfit = 0;
          let totalGross = 0;
          let totalMargin = 0;
          let totalConversion = 0;

          pageData.forEach(({ bids }) => {
            totalBids += bids || 0;
          });
          pageData.forEach(({ loads }) => {
            totalLoads += loads || 0;
          });
          pageData.forEach(({ profit }) => {
            totalProfit += profit || 0;
          });
          pageData.forEach(({ gross }) => {
            totalGross += gross || 0;
          });
          
          totalMargin = safeDivide(totalProfit, totalGross);
          totalConversion = safeDivide(totalLoads, totalBids);


          return <>
            <Table.Summary.Row>
              <Table.Summary.Cell align='center' index={0}>
                <b>All</b>
                </Table.Summary.Cell>
              <Table.Summary.Cell align='center' index={1}>
                <b>{totalBids}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell align='center' index={2}>
                <b>{totalLoads}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell align='center' index={3}>
                <b>{formatMoney(totalProfit)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell align='center' index={4}>
                <b>{formatPercent(totalMargin)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell align='center' index={5}>
                <b>{formatPercent(totalConversion)}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        }}
      />
    </div>
  </DashboardPanel>
}

const StyledTable = styled(Table)`
  & {
    height: 400px;
    overflow: auto;
  }
`

const columns: ColumnType<RevenueByDispatcherResponse>[] = [
  {
    title: 'Dispatcher',
    dataIndex: nameof<RevenueByDispatcherResponse>(x => x.dispatcher),
    key: nameof<RevenueByDispatcherResponse>(x => x.dispatcher),
    width: 200,
    sorter: (a, b) => a.dispatcher!.localeCompare(b.dispatcher!)
  },
  {
    title: 'Bids',
    dataIndex: nameof<RevenueByDispatcherResponse>(x => x.bids),
    key: nameof<RevenueByDispatcherResponse>(x => x.bids),
    align: 'center',
    width: 50,
    sorter: (a, b) => (a.bids || 0) - (b.bids || 0)
  },
  {
    title: 'Loads',
    dataIndex: nameof<RevenueByDispatcherResponse>(x => x.loads),
    key: nameof<RevenueByDispatcherResponse>(x => x.loads),
    align: 'center',
    width: 50,
    sorter: (a, b) => (a.loads || 0) - (b.loads || 0)
  },
  {
    title: 'Profit',
    dataIndex: nameof<RevenueByDispatcherResponse>(x => x.profit),
    key: nameof<RevenueByDispatcherResponse>(x => x.profit),
    align: 'center',
    width: 100,
    render: formatMoney,
    sorter: (a, b) => (a.profit || 0) - (b.profit || 0)
  },
  {
    title: 'Margin',
    dataIndex: nameof<RevenueByDispatcherResponse>(x => x.margin),
    key: nameof<RevenueByDispatcherResponse>(x => x.margin),
    align: 'center',
    width: 50,
    render: formatPercent,
    sorter: (a, b) => (a.margin || 0)  - (b.margin || 0) 
  },
  {
    title: 'Conversion',
    dataIndex: nameof<RevenueByDispatcherResponse>(x => x.conversion),
    key: nameof<RevenueByDispatcherResponse>(x => x.conversion),
    align: 'center',
    width: 100,
    render: formatPercent,
    sorter: (a, b) => (a.conversion || 0)  - (b.conversion || 0) 
  },
];

function formatMoney(data: number) {
  return '$' + Math.trunc(data || 0);
};

function formatPercent(value?: number | null | undefined) {
  if (!value) return '0%';

  return (value * 100).toFixed(2) + '%';
}

function safeDivide(first?: number, second?:  number) {
  if (!second || second === 0) {
    return 0;
  }

  return (first || 0) / second;
}

export default RevenueByDispatcher;
