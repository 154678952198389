import React, { useMemo } from 'react';
import { RedCircle } from './RedCircle';
import { LinkLikeSpan } from './LinkLikeSpan';
import { OwnerDocumentsSignedNotification } from '../../../../generated-api';

function OwnerDocumentsSignedFormatter(props: { jsonData: string }) {
  const data = useMemo(
    () => JSON.parse(props.jsonData) as OwnerDocumentsSignedNotification,
    [props.jsonData]);

  return <>
    <RedCircle />
    <span>
      Request&nbsp;
      <LinkLikeSpan onClick={() => window.open(window.location.origin + '/request/' + data.requestId?.toString() || '')}>
        #{data.requestId || ''}&nbsp;
      </LinkLikeSpan>
      contract signed
    </span>
  </>
}

export default OwnerDocumentsSignedFormatter;