import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button`
  & {
    background-color: transparent;
    border-width: 0;
  }

  &:hover {
    background-color: var(--surface-d);
  }
`

const PageButton = (props: {
  page: number,
  handlePageChange: (callback: (newPage: number) => number) => any,
  current?: boolean,
}) => {

  return (
    <StyledButton
      type="button"
      onClick={() => props.handlePageChange(_ => props.page)}
      className={'p-3 border-none cursor-pointer font-bold ' +
                  (props.current ? 'text-color' : 'text-color-secondary opacity-60')}
    >
      {props.page}
      <span role="presentation" className="p-ink"></span>
    </StyledButton>
  );
};

export default PageButton;