import { Button } from 'primereact/button';
import { useEffect, useMemo, useState } from 'react';
import StyledInputText from '../../components/form/StyledInputText';
import LogisticsDialog from '../../components/LogisticsDialog';
import { showSuccessToast } from '../../components/LogisticsToast';
import { UserDto, UsersApi } from '../../generated-api';
import { apiFactory, ModalParameters } from '../../shared';
import copy from 'copy-to-clipboard';

export interface UserReferralLinkPageParameters extends ModalParameters<UserDto> {

}

function UserReferralLinkPage(props : UserReferralLinkPageParameters) {
  const [user, setUser] = useState<UserDto>(props.data);
  const refLink = useMemo(() => {
    return user.refLink ? `${window.location.origin}/referral/${user.refLink}` : '';
  }, [user.refLink]);

  useEffect(() => {
    apiFactory(UsersApi).apiAdminUsersLoginGet({login: props.data.login!}).then((loadedUser) => setUser(loadedUser));
  }, [props.data.login]);

  return <>
    <LogisticsDialog
      visible={props.visible}
      onHide={() => props.hide(undefined)}
      className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-3'
    >
      <main className='logistics-dialog-edit-form__content'>
        <div className="grid">
          <div className="col-6">
            <header className='text-2xl w-full flex-wrap mb-2'>
              <span className='font-bold'>Link</span>
            </header>
          </div>
          <div className="col-6 flex flex-row-reverse">
          <Button icon='pi pi-times' className='p-button-rounded p-button-danger p-button-text' aria-label='Close' onClick={() => props.hide(undefined)}/>
          </div>
        </div>
        
        <div className='text-xl'>Your Referral Link</div>
        <div className='flex mb-4'>
          <StyledInputText 
            value={refLink}
            readOnly
          />
          <Button
            icon='pi pi-copy'
            className='p-button-lg p-button-text p-button-plain'
            onClick={() => {
              copy(refLink);
              showSuccessToast('Copied to clipboard.');
            }
            }
          />
        </div>
      </main>
    </LogisticsDialog>
  </>;
}

export default UserReferralLinkPage;