import React, { useMemo} from 'react';
import { Badge } from 'primereact/badge';
import { useAppSelector } from '../../hooks';

const BrokerRepliesBadge = () => {
  const notifications = useAppSelector(state => state.notification);
  const unreadReplies = useMemo(() => {
    if (!notifications?.unreadBidsCount) {
      return '';
    }

    if (notifications.unreadBidsCount <= 0) {
      return '';
    }

    if (notifications.unreadBidsCount > 99) {
      return '99+';
    }

    return notifications.unreadBidsCount.toString();
  }, [notifications]);


  return (
    <Badge
      value={unreadReplies}
      severity='danger'
      className={unreadReplies ? 'opacity-80' : 'opacity-0'}
    />
  );
};

export default BrokerRepliesBadge;