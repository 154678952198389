import LogisticsDataGrid from '../../components/data-grid/LogisticsDataGrid';
import { deleteUser, getUsersPage } from './UsersSlice';
import AddButton from '../../components/data-grid/buttons/AddButton';
import UserEdit from './UserEdit';
import { RootState } from '../../store/store';
import userColumns from './UsersColumnDef';
import { useAppDispatch, useModal } from '../../hooks';
import { EUserStatus, UserDto } from '../../generated-api';
import LogisticsPanel from '../../components/LogisticsPanel';
import { logisticsConfirmDialog } from '../../components/LogisticsConfifmDialog';
import { Button } from 'primereact/button';
import useRights from '../../hooks/RightsHook';

const selectState = (state: RootState) => state.users;

function UsersList() {
  const userModal = useModal<UserDto>({ login: '', activated: false });
  const dispatch = useAppDispatch();
  const rights = useRights(security => security.settings);
  
  const removeActionButton = (rowData: UserDto) => <Button
    icon='pi pi-trash'
    disabled={!rights.userDeletion}
    className='p-button-lg p-button-rounded p-button-text p-button-plain hover:text-red-400'
    onClick={(e) => {
      if (!rowData?.id) return;

      logisticsConfirmDialog({
        message: 'Are you sure?',
        closable: false,
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        accept() {
          dispatch(deleteUser(rowData));
        },
      });
    }}
  />

  const columns = userColumns(removeActionButton);

  const handleHide = () => {
    userModal.setData({...userModal.data, id: null});
    userModal.hide();
  };

  return (
    <LogisticsPanel header='User List' toggleable>
      <LogisticsDataGrid
        id='usersList'
        selectState={selectState}
        getEntitiesPage={getUsersPage}
        columns={columns}
        leftHeaderElements={[
          <AddButton onClick={() => userModal.show({ login: '', status: EUserStatus.Active, activated: true })} />
        ]}
        onRowClick={e => userModal.show(e.data)}
        others={{
          selectionMode: 'single',
        }}
        fullSize={false}
      />
      <UserEdit
        key={userModal.data.id}
        data={userModal.data}
        loading={false}
        visible={userModal.visible}
        hide={handleHide}
      />
    </LogisticsPanel>
  );
}

export default UsersList;