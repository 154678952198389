import React, { ReactNode, useMemo } from 'react';
import MenuItem from './MenuItem';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import EmailIcon from '@mui/icons-material/Email';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import BrokerRepliesBadge from './BrokerRepliesBadge';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonIcon from '@mui/icons-material/Person';
import useRights from '../../hooks/RightsHook';
import { BrowserView, MobileView } from 'react-device-detect';
import BurgerMenu from './BurgerMenu';

export interface AppMenuItem {
  label: string;
  url: string;
  icon: JSX.Element;
  badge?: ReactNode;
}

function Menu() {
  const rights = useRights(security => security.menu);

  const menuItems = useMemo(() => {
    if (!rights) return [];

    let res: AppMenuItem[] = [];
    if (rights.dashboard)
      res.push({
        label: 'Dashboard',
        url: '/dashboard',
        icon: <BarChartRoundedIcon />
      });

    if (rights.dispatch)
      res.push({
        label: 'Dispatch',
        url: '/loaded-orders',
        icon: <EmailIcon />
      });

    if (rights.bids)
      res.push({
        label: 'Bids',
        url: '/bids',
        icon: <LocalAtmIcon />,
        badge: <BrokerRepliesBadge />,
      });

    if (rights.vehicles)
      res.push({
        label: 'Vehicles',
        url: '/vehicles',
        icon: <LocalShippingRoundedIcon />
      });

    if (rights.listOfLoads)
      res.push({
        label: 'List of Loads',
        url: '/list-of-loads',
        icon: <ListAltIcon/>
      });

    if (rights.tracking)
      res.push({
        label: 'Tracking',
        url: '/tracking',
        icon: <DescriptionRoundedIcon />
      });

    if (rights.hr)
      res.push({
        label: 'HR',
        url: '/hr',
        icon: <PersonIcon />,
      });

    if (rights.settings)
      res.push({
        label: 'Settings',
        url: '/settings',
        icon: <SettingsRoundedIcon />
      });

    return res;
  }, [rights]);

  return (
  <>
    <MobileView>
      <BurgerMenu items={menuItems}/>
    </MobileView>
    <BrowserView className="flex flex-row">
      {menuItems.map(appMenuItem => <MenuItem {...appMenuItem} key={appMenuItem.label} />)}
    </BrowserView>
  </>
  );
}

export default Menu;