import React, { useMemo, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { LogisticsDataGridBaseParameters } from './LogisticsDataGridParameters';
import { DataTable, DataTableHeaderTemplateType } from 'primereact/datatable';
import LogisticsToolbar from './LogisticsToolbar';
import ActionMenu from './actions/ActionMenu';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export interface RowsWithId {
  id : number;
}

function LogisticsDataGridBase<TEntity>(props: LogisticsDataGridBaseParameters<TEntity>) {
  const {
    others,
    onRowClick,
    leftHeaderElements,
    rightHeaderElements,
    actions,
    hideActions = false,
    columns,
    fullSize = true,
    entities,
  } = props;

  const [selectedRows, setSelectedRows] = useState<RowsWithId[]>();
  const ref = useRef<DataTable>(null);

  const heightStyle = useMemo(() => {
    if (!fullSize) return {};

    const element = ref?.current?.getElement();
    if (!element) return;

    const topLeft = getOffset(element);
    const height = window.innerHeight - topLeft.top;
    return { height: height + 'px' };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullSize, ref, ref?.current]);

  const actionMenu = useMemo(() => {
    return <ActionMenu
      actions={actions}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      hide={hideActions}
    />
  }, [actions, selectedRows, setSelectedRows, hideActions]);

  const checkboxColumn = useMemo(() => {
    if (!props.addSelectionCheckbox) return null;

    return <Column selectionMode="multiple" style={{maxWidth: '40px'}}></Column>;
  }, [props.addSelectionCheckbox]);

  const header : DataTableHeaderTemplateType = (
    <LogisticsToolbar
      rightSideElements={[
        actionMenu,
        ...(rightHeaderElements || []),
      ]}
      leftSideElements={leftHeaderElements}
    />
  );

  const StyledDataTable = fullSize ? FullPageDataTable : CompactDataTable;

  return <StyledDataTable
    {...others}
    scrollable
    size="small"
    ref={ref}
    filterDisplay="row"
    sortMode="multiple"
    responsiveLayout="scroll"
    stripedRows
    onRowClick={onRowClick}
    value={entities || undefined}
    header={header}
    style={heightStyle}
    className='bg-gray'
    selection={selectedRows}
    onSelectionChange={(e) => setSelectedRows(e.value)}
  >
    {checkboxColumn}
    {
      columns.map(column => {
        return <Column
          key={column.field}
          {...column}
        >
        </Column>
      })
    }
  </StyledDataTable>
}

function getOffset(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}

const styles = css`
  & {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse
  }
  th, td {
    word-wrap: break-word;
    // Fixme: overflow should be controlled from outside
    //overflow: hidden !important;
  }

  & .p-column-header-content {
    flex: 1 0 0;
  }

  & .p-datatable-header {
    background-color: var(--surface-a);
  }
  
  & .p-datatable-wrapper .p-filter-column {
    border-bottom-width: 0;
  }
  
  & .p-datatable-tbody > tr:nth-of-type(1) td {
    border-top-width: 0;
  }
  
  &.p-datatable-sm .p-datatable-tbody > tr > td.colored {
    padding: 0;
  }
  
  & thead.p-datatable-thead > tr > th {
    border-top-width: 0;
  }
  
  .add-min-width {
    min-width: 150px;
  }
  
  & .p-column-filter .p-column-filter-element {
    order: 2;
  }

  & .p-column-filter .p-column-filter-menu-button {
    order: 1;
    margin-left: 0;
  }

  & .p-column-filter .p-column-filter-clear-button {
    order: 3;
  }

  &.p-datatable .p-datatable-tbody > tr:hover {
    background-color: #e9ecef;
  }
`

const FullPageDataTable = styled(DataTable)`
  ${styles};
  max-width: 100%;
  &.p-datatable {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
  }

  & .p-datatable-footer {
    flex-grow: 0;
    margin-top: auto;
  }

  & .p-datatable-wrapper {
    overflow-y: auto;
    flex-grow: 1;
    height: auto;
  }

  & .p-datatable-header {
    flex-grow: 0;
  }
`

export const CompactDataTable = styled(DataTable)`
  ${styles}
`;


export default LogisticsDataGridBase;