import { Toast, ToastMessageType } from 'primereact/toast';
import { OverlayService } from 'primereact/overlayservice';
import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

const toastEvent = 'toast' as any;
export const showToast = (message: ToastMessageType) => {
  OverlayService.emit(toastEvent, message as any);
}

export const showSuccessToast = (message: string) => {
  const messageProps: ToastMessageType = {
    severity: 'success',
    summary: message,
    life: 2000,
  };
  showToast(messageProps);
}

export const showErrorToast = (message?: string) => {
  const errorMessage = message || 'Something went wrong';
  const messageProps: ToastMessageType = {
    severity: 'error',
    summary: errorMessage,
    life: 3000,
  };
  showToast(messageProps);
}

const StyledToast = styled(Toast)`
  &.p-toast {
    max-width: 32rem;
    cursor: default;
  }
`

export default function LogisticsToast() {
  const toastRef = useRef<Toast>(null);

  const handleToastEvent = (message: ToastMessageType) => {
    if (!toastRef?.current) {
      console.error('Toast used before initialization');
      return;
    }

    toastRef.current.show(message);
  }

  useEffect(() => {
    OverlayService.on(toastEvent, handleToastEvent);

    return () => OverlayService.off(toastEvent, handleToastEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <StyledToast ref={toastRef} position='bottom-right' />
}