import React, { useRef } from 'react';
import { AnyFunction } from '../../../../shared/interfaces/AnyFunction';
import { NotificationArrowButton } from './NotificationArrowButton';

function BidReplyNotification(props: {
  id: number,
  onArrowClick: AnyFunction<any, any>
}) {
  const ref = useRef<HTMLDivElement>({} as any);
  return <div
    className='flex flex-row flex-grow-1 justify-content-evenly align-items-center text-2xl'
    ref={ref}
  >
    <i className='pi pi-check-circle text-2xl flex-grow-0 pr-4' />
    <span className='flex-grow-1'>New reply has been received!</span>
    <NotificationArrowButton
      onClick={() => {
        props.onArrowClick();
        const closeButton = ref.current.parentElement!.querySelector('button.p-toast-icon-close') as HTMLButtonElement;
        closeButton.click();
      }}
      className='flex-grow-0 pl-4'
    >
      <i className='pi pi-angle-right text-2xl' />
    </NotificationArrowButton>
  </div>;
}

export default BidReplyNotification;