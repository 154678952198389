import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiFactory } from '../../shared';
import { ConfigurationDto, ConfigurationApi } from '../../generated-api';

export type ConfigurationState = ConfigurationDto & {
  loaded: boolean;
};

const initialState: ConfigurationState = {
  loaded: false,
};

export const setConfiguration = createAction(
  'configuration/setConfiguration',
  (data: ConfigurationState) => ({payload: data})
);

export const resetConfiguration = createAction(
  'configuration/resetConfiguration',
)

export const loadConfiguration = createAsyncThunk(
  'configuration/loadConfiguration',
  async () => {
    const conf = await apiFactory(ConfigurationApi).apiConfigurationGet();
    return { payload: conf };
});

export const ConfigurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setConfiguration, (state, action) => {
        return { ...state, ...action.payload };
      })
      .addCase(resetConfiguration, () => {
        return initialState;
      })
      .addCase(loadConfiguration.fulfilled, (state, action) => {
        return { ...state, ...action.payload.payload, loaded: true };
      });
  },
});

export default ConfigurationSlice.reducer;
