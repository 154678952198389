/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BidAccess } from './BidAccess';
import {
    BidAccessFromJSON,
    BidAccessFromJSONTyped,
    BidAccessToJSON,
} from './BidAccess';
import type { DriverAccess } from './DriverAccess';
import {
    DriverAccessFromJSON,
    DriverAccessFromJSONTyped,
    DriverAccessToJSON,
} from './DriverAccess';
import type { ListOfLoadsAccess } from './ListOfLoadsAccess';
import {
    ListOfLoadsAccessFromJSON,
    ListOfLoadsAccessFromJSONTyped,
    ListOfLoadsAccessToJSON,
} from './ListOfLoadsAccess';
import type { LoadedOrderAccess } from './LoadedOrderAccess';
import {
    LoadedOrderAccessFromJSON,
    LoadedOrderAccessFromJSONTyped,
    LoadedOrderAccessToJSON,
} from './LoadedOrderAccess';
import type { MenuAccess } from './MenuAccess';
import {
    MenuAccessFromJSON,
    MenuAccessFromJSONTyped,
    MenuAccessToJSON,
} from './MenuAccess';
import type { OwnerAccess } from './OwnerAccess';
import {
    OwnerAccessFromJSON,
    OwnerAccessFromJSONTyped,
    OwnerAccessToJSON,
} from './OwnerAccess';
import type { RequestAccess } from './RequestAccess';
import {
    RequestAccessFromJSON,
    RequestAccessFromJSONTyped,
    RequestAccessToJSON,
} from './RequestAccess';
import type { SettingsAccess } from './SettingsAccess';
import {
    SettingsAccessFromJSON,
    SettingsAccessFromJSONTyped,
    SettingsAccessToJSON,
} from './SettingsAccess';
import type { TrackingAccess } from './TrackingAccess';
import {
    TrackingAccessFromJSON,
    TrackingAccessFromJSONTyped,
    TrackingAccessToJSON,
} from './TrackingAccess';
import type { UnitAccess } from './UnitAccess';
import {
    UnitAccessFromJSON,
    UnitAccessFromJSONTyped,
    UnitAccessToJSON,
} from './UnitAccess';
import type { VehiclesAccess } from './VehiclesAccess';
import {
    VehiclesAccessFromJSON,
    VehiclesAccessFromJSONTyped,
    VehiclesAccessToJSON,
} from './VehiclesAccess';

/**
 * 
 * @export
 * @interface Access
 */
export interface Access {
    /**
     * 
     * @type {BidAccess}
     * @memberof Access
     */
    bid?: BidAccess;
    /**
     * 
     * @type {DriverAccess}
     * @memberof Access
     */
    driver?: DriverAccess;
    /**
     * 
     * @type {ListOfLoadsAccess}
     * @memberof Access
     */
    listOfLoads?: ListOfLoadsAccess;
    /**
     * 
     * @type {LoadedOrderAccess}
     * @memberof Access
     */
    loadedOrder?: LoadedOrderAccess;
    /**
     * 
     * @type {MenuAccess}
     * @memberof Access
     */
    menu?: MenuAccess;
    /**
     * 
     * @type {OwnerAccess}
     * @memberof Access
     */
    owner?: OwnerAccess;
    /**
     * 
     * @type {SettingsAccess}
     * @memberof Access
     */
    settings?: SettingsAccess;
    /**
     * 
     * @type {VehiclesAccess}
     * @memberof Access
     */
    vehicle?: VehiclesAccess;
    /**
     * 
     * @type {RequestAccess}
     * @memberof Access
     */
    request?: RequestAccess;
    /**
     * 
     * @type {UnitAccess}
     * @memberof Access
     */
    unit?: UnitAccess;
    /**
     * 
     * @type {TrackingAccess}
     * @memberof Access
     */
    tracking?: TrackingAccess;
}

/**
 * Check if a given object implements the Access interface.
 */
export function instanceOfAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AccessFromJSON(json: any): Access {
    return AccessFromJSONTyped(json, false);
}

export function AccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): Access {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bid': !exists(json, 'bid') ? undefined : BidAccessFromJSON(json['bid']),
        'driver': !exists(json, 'driver') ? undefined : DriverAccessFromJSON(json['driver']),
        'listOfLoads': !exists(json, 'listOfLoads') ? undefined : ListOfLoadsAccessFromJSON(json['listOfLoads']),
        'loadedOrder': !exists(json, 'loadedOrder') ? undefined : LoadedOrderAccessFromJSON(json['loadedOrder']),
        'menu': !exists(json, 'menu') ? undefined : MenuAccessFromJSON(json['menu']),
        'owner': !exists(json, 'owner') ? undefined : OwnerAccessFromJSON(json['owner']),
        'settings': !exists(json, 'settings') ? undefined : SettingsAccessFromJSON(json['settings']),
        'vehicle': !exists(json, 'vehicle') ? undefined : VehiclesAccessFromJSON(json['vehicle']),
        'request': !exists(json, 'request') ? undefined : RequestAccessFromJSON(json['request']),
        'unit': !exists(json, 'unit') ? undefined : UnitAccessFromJSON(json['unit']),
        'tracking': !exists(json, 'tracking') ? undefined : TrackingAccessFromJSON(json['tracking']),
    };
}

export function AccessToJSON(value?: Access | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bid': BidAccessToJSON(value.bid),
        'driver': DriverAccessToJSON(value.driver),
        'listOfLoads': ListOfLoadsAccessToJSON(value.listOfLoads),
        'loadedOrder': LoadedOrderAccessToJSON(value.loadedOrder),
        'menu': MenuAccessToJSON(value.menu),
        'owner': OwnerAccessToJSON(value.owner),
        'settings': SettingsAccessToJSON(value.settings),
        'vehicle': VehiclesAccessToJSON(value.vehicle),
        'request': RequestAccessToJSON(value.request),
        'unit': UnitAccessToJSON(value.unit),
        'tracking': TrackingAccessToJSON(value.tracking),
    };
}

