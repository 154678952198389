/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnitAccess
 */
export interface UnitAccess {
    /**
     * 
     * @type {boolean}
     * @memberof UnitAccess
     */
    create?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UnitAccess
     */
    read?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UnitAccess
     */
    update?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UnitAccess
     */
    _delete?: boolean | null;
}

/**
 * Check if a given object implements the UnitAccess interface.
 */
export function instanceOfUnitAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UnitAccessFromJSON(json: any): UnitAccess {
    return UnitAccessFromJSONTyped(json, false);
}

export function UnitAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnitAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'create': !exists(json, 'create') ? undefined : json['create'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'update': !exists(json, 'update') ? undefined : json['update'],
        '_delete': !exists(json, 'delete') ? undefined : json['delete'],
    };
}

export function UnitAccessToJSON(value?: UnitAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'create': value.create,
        'read': value.read,
        'update': value.update,
        'delete': value._delete,
    };
}

