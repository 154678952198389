import React, { useMemo } from 'react';
import { MdCloseFullscreen, MdFullscreen } from 'react-icons/md';
import { Button } from 'primereact/button';
import { StoreApi, UseBoundStore } from 'zustand/esm';
import { CustomPdfStore } from '../LogisticsPdf';

type FullscreenProps = {
  useCustomStore:  UseBoundStore<StoreApi<CustomPdfStore>>,
}

function Fullscreen(props: FullscreenProps) {
  const fullScreen = props.useCustomStore(s => s.fullscreen);
  const toggleFullscreen = props.useCustomStore(s => s.toggleFullscreen);

  const icon = useMemo(() => {
    return fullScreen
      ? <MdCloseFullscreen />
      : <MdFullscreen />;
  }, [fullScreen]);

  return <Button
    className='p-button-text p-button-secondary px-1'
    onClick={() => toggleFullscreen()}
  >
    {icon}
  </Button>
}

export default Fullscreen;