import {
  LoadedOrder,
} from '../../../generated-api';
import { Button } from 'primereact/button';
import LoadedOrderDataBlock from '../../../components/LoadedOrderDataBlock';
import LoadedOrderVehiclesBlock from '../../../components/LoadedOrderVehiclesBlock';
import PricesBlock from '../../../components/prices/PricesBlock';
import CenteredSpinner from '../../../components/CenteredSpinner';
import LogisticsDialog from '../../../components/LogisticsDialog';
import MailEditor from '../MailEditor';
import { BrowserView, MobileView } from 'react-device-detect';
import { LoadedOrderEditData, LoadedOrderEditParameters, useLoadedOrderEdit } from './hook';
import dayjs from 'dayjs';
import BidCheckboxes from '../BidCheckboxes';

function viewOnMapLink(data : LoadedOrder) {
  const link = `https://www.google.com/maps/dir/?api=1&origin=${encodeURI(data.pickUpAt!)}&destination=${encodeURI(data.deliverTo!)}`;
  return (
  <a
    className='loaded-order-map-link text-lg pl-4'
    target="_blank"
    rel="noreferrer"
    href={link}>
      View on Map
  </a>)
};

export const LoadedOrderEdit = (props: LoadedOrderEditParameters) => {
  const form = useLoadedOrderEdit(props);

  return (
    <LogisticsDialog
      visible={props.visible}
      onHide={form.handleOnHide}
      className='full-height-dialog w-11'>
        <BrowserView className='flex flex-row h-full'>
          {OrderBlock(form)}
          {OffersBlock(form)}
        </BrowserView>
        <MobileView>
          {OrderBlock(form)}
          {OffersBlock(form)}
        </MobileView>
      <CenteredSpinner visible={form.loading} />
    </LogisticsDialog>
  );
};

function OffersBlock(form: LoadedOrderEditData) {
  return <>
    <BrowserView className='flex flex-grow-1 flex-column overflow-y-auto overflow-x-hidden'>
      <LoadedOrderVehiclesBlock
        data={form.offers}
        loadedOrder={form.loadedOrder}
        selected={{ ...form.selectedUnit }}
        loading={form.loadingOffer}
        onSelectionChange={form.onSelectionChange}
        extraHeaderContent={viewOnMapLink(form.loadedOrder)} />
      <div className='grid'>
        <div className='px-3 col-4'>
          <p>
            <span className='text-2xl'>Broker email: {form.loadedOrder?.contactEmail}</span>
          </p>
          <p>
            <span className='text-2xl font-bold'>Bid placement ({form.loadedOrder?.miles} mi)</span>
          </p>
          <PricesBlock 
            onPricesChange={form.changePrices}
            minProfit={form.systemSettings.minProfit}
            data={form.pricesData} />
          <BidCheckboxes formBid={form.formBid} setFormBid={form.setFormBid} />
        </div>
        <div className='px-3 col-8'>
          <div className='h-30rem'>
            <MailEditor
              rawTemplate={form.rawTemplate}
              variables={{
                Eta: form.loadedOrder.eta || '',
                Dimensions: form.selectedUnit?.vehicle?.boxDimensions?.join('x') || form.loadedOrder.dimensions || '',
                PayLoad: form.selectedUnit?.vehicle?.payload?.toString() || '',
                McNumber: form.systemSettings.mcNumber?.toString() || '',
                Miles: (form.selectedUnit?.vehicleGroundMiles || form.selectedUnit?.vehicleFlyMiles)?.toString() || '',
                PriceForBroker: form.pricesData.priceForBroker?.toString() || '',
                DockHigh: form.formBid.dockHigh ? 'Dock high' : '',
                TtDrivers: form.formBid.ttDrivers ? 'TRUE TEAM DRIVERS' : '',
                LiftGate: form.formBid.liftGate ? 'Lift gate' : '',
                PalletJack: form.formBid.palletJack ? 'Pallet jack' : '',
                DriverAssist: form.formBid.driverAssist ? 'Driver assist' : '',
                FullPpe: form.formBid.fullPPE ? 'FULL PPE' : '',
              }}
              onRawTemplateChange={value => {
                form.setCustomTemplate(value);
              } }
              onFilledTemplateChange={value => {
                form.setFilledTemplate(value);
                form.formik.setValues({ ...form.loadedOrder, brokerText: value });
              } } />
          </div>
          <div className='flex justify-content-between pt-4'>
            <Button label='PLACE BID' disabled={form.placeBidButtonDisabled}
              className='p-button-success min-w-min w-8rem h-4rem mr-1'
              onClick={form.onPlaceBid}></Button>
            <Button label='CLOSE' className='p-button-secondary min-w-min w-8rem h-4rem'
              onClick={form.handleOnHide}></Button>
          </div>
        </div>
      </div>
    </BrowserView>
    <MobileView>
      <div style={{overflowY: "scroll" }}>
        <LoadedOrderVehiclesBlock
          data={form.offers}
          loadedOrder={form.loadedOrder}
          selected={{ ...form.selectedUnit }}
          loading={form.loadingOffer}
          onSelectionChange={form.onSelectionChange}
          extraHeaderContent={viewOnMapLink(form.loadedOrder)} />
      </div>
          <p>
            <span className='text-2xl'>Broker email: {form.loadedOrder?.contactEmail}</span>
          </p>
          <p>
            <span className='text-2xl font-bold'>Bid placement ({form.loadedOrder?.miles} mi)</span>
          </p>
          <PricesBlock
            onPricesChange={form.changePrices}
            minProfit={form.systemSettings.minProfit}
            data={form.pricesData} />
          <BidCheckboxes formBid={form.formBid} setFormBid={form.setFormBid} />
          <div className='h-30rem'>
            <MailEditor
              rawTemplate={form.rawTemplate}
              variables={{
                Eta: form.loadedOrder.eta || '',
                Dimensions: form.selectedUnit?.vehicle?.boxDimensions?.join('x') || form.loadedOrder.dimensions || '',
                PayLoad: form.selectedUnit?.vehicle?.payload?.toString() || '',
                McNumber: form.systemSettings.mcNumber?.toString() || '',
                Miles: (form.selectedUnit?.vehicleGroundMiles || form.selectedUnit?.vehicleFlyMiles)?.toString() || '',
                PriceForBroker: form.pricesData.priceForBroker?.toString() || '',
                DockHigh: form.formBid.dockHigh ? 'Dock high' : '',
                TtDrivers: form.formBid.ttDrivers ? 'TRUE TEAM DRIVERS' : '',
                LiftGate: form.formBid.liftGate ? 'Lift gate' : '',
                PalletJack: form.formBid.palletJack ? 'Pallet jack' : '',
                DriverAssist: form.formBid.driverAssist ? 'Driver assist' : '',
                FullPpe: form.formBid.fullPPE ? 'FULL PPE' : '',
              }}
              onRawTemplateChange={value => {
                form.setCustomTemplate(value);
              } }
              onFilledTemplateChange={value => {
                form.setFilledTemplate(value);
                form.formik.setValues({ ...form.loadedOrder, brokerText: value });
              } } />
          </div>
          <div className='flex justify-content-between pt-4 pb-4'>
            <Button label='PLACE BID' disabled={form.placeBidButtonDisabled}
              className='p-button-success min-w-min w-8rem h-4rem mr-1'
              onClick={form.onPlaceBid}></Button>
            <Button label='CLOSE' className='p-button-secondary min-w-min w-8rem h-4rem'
              onClick={form.handleOnHide}></Button>
          </div>
    </MobileView>
  </>
  ;
}

function OrderBlock(form: LoadedOrderEditData) {
  const orderNumber = form.loadedOrder.source === 'Ivia' ? `Ref.# ${form.loadedOrder?.orderNumber}` : `#${form.loadedOrder?.orderNumber}`;

  return <>
    <BrowserView style={{ flexBasis: '25%', maxWidth: '25%' }}>
      <div className='text-2xl font-bold'>Order {orderNumber}</div>
      <div className='text-lg mt-2 logistics-main-date'>
        Received {dayjs(form.loadedOrder?.receivedDate).tz().format('MMM D, h:mm A')} from {form.loadedOrder?.source}
      </div>
      <LoadedOrderDataBlock {...form.loadedOrder} />
    </BrowserView>
    <MobileView>
      <div className='flex justify-content-between align-items-baseline flex-wrap'>
        <div className='text-xl font-bold'>Order {orderNumber}</div>
        <div className='text-base text-right mt-2 logistics-main-date'>
          Received {dayjs(form.loadedOrder?.receivedDate).tz().format('MMM D, h:mm A')} from {form.loadedOrder?.source}
        </div>
      </div>
      <LoadedOrderDataBlock {...form.loadedOrder} />
    </MobileView>
  </>;
}

export default LoadedOrderEdit;