/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface TwoPoints
 */
export interface TwoPoints {
    /**
     * 
     * @type {Point}
     * @memberof TwoPoints
     */
    a?: Point;
    /**
     * 
     * @type {Point}
     * @memberof TwoPoints
     */
    b?: Point;
}

/**
 * Check if a given object implements the TwoPoints interface.
 */
export function instanceOfTwoPoints(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TwoPointsFromJSON(json: any): TwoPoints {
    return TwoPointsFromJSONTyped(json, false);
}

export function TwoPointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TwoPoints {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'a': !exists(json, 'a') ? undefined : PointFromJSON(json['a']),
        'b': !exists(json, 'b') ? undefined : PointFromJSON(json['b']),
    };
}

export function TwoPointsToJSON(value?: TwoPoints | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'a': PointToJSON(value.a),
        'b': PointToJSON(value.b),
    };
}

