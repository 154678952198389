import React, { useMemo, useState } from 'react';
import { ModalParameters } from '../../../shared';
import LogisticsDialog from '../../LogisticsDialog';
import StyledInputText from '../../form/StyledInputText';
import { Button } from 'primereact/button';
import { TFilterPreset } from './FilterPresetsHook';
import { FormikErrors, useFormik } from 'formik';
import Field from '../../form/Field';
import ErrorBox from '../../form/ErrorBox';
import { showErrorToast } from '../../LogisticsToast';

export interface FilterSaverParameters extends ModalParameters<TFilterPreset | undefined> {

}

type FilterSaverFields = {
  name?: string,
}
function FilterSaver(props: FilterSaverParameters) {
  const [filterName, setFilerName] = useState('');
  const validation = useFormik<FilterSaverFields>({
    initialValues: {},
    validate: data => {
      const errors: FormikErrors<FilterSaverFields> = {};
      const blankError = 'Enter filter name';
      if (!data.name)
        errors.name = blankError;

      return errors;
    },
    onSubmit: () => {},
  });
  const filters = useMemo(() => {
    if (!props.data?.filters) return <></>;

    const rows = Object.keys(props.data.filters).map(field => {
      if (!props.data?.filters) return <></>;

      const filter = props.data.filters[field];

      if (filter.formattedValue === undefined
          || filter.formattedValue === null
          || filter.formattedValue === '') {
        return <></>;
      }

      return <tr key={field}>
        <td>{`${filter.caption}:`}</td>
        <td>{filter.formattedValue}</td>
      </tr>
    });

    return <table><tbody>{rows}</tbody></table>
  }, [props.data]);

  const hide = async () => {
    const errors = await validation.validateForm();
    if (errors.name) {
      showErrorToast('Preset name missing');
      return;
    }

    props.hide({...props.data!, name: filterName})
  }

  return (
    <LogisticsDialog
      visible={props.visible}
      onHide={() => props.hide(undefined)}
      className='w-4'
      header={() => 'Filter name'}
      closable
    >
      <main className='flex flex-column px-5'>
        <Field label={''} required>
          <StyledInputText
            value={filterName}
            onChange={e => {
              setFilerName(e.target.value);
              validation.setValues({name: e.target.value});
            }}
            className='mb-3'
            placeholder='Enter filter name...'
          />
          <ErrorBox>{validation.errors.name}</ErrorBox>
        </Field>
        {filters}
        <Button
          className='p-button-text'
          label='Save'
          onClick={hide}
        />
      </main>
    </LogisticsDialog>
  );
}

export default FilterSaver;