/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EEquipment = {
    None: 'None',
    Bulkhead: 'Bulkhead',
    Dolly: 'Dolly',
    Straps: 'Straps',
    Ppe: 'PPE',
    TempControl: 'TempControl',
    AirRide: 'AirRide',
    LiftGate: 'LiftGate',
    PalletJack: 'PalletJack',
    Tent: 'Tent',
    SideLoading: 'SideLoading',
    LoadBars: 'LoadBars',
    ETracks: 'ETracks'
} as const;
export type EEquipment = typeof EEquipment[keyof typeof EEquipment];


export function EEquipmentFromJSON(json: any): EEquipment {
    return EEquipmentFromJSONTyped(json, false);
}

export function EEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EEquipment {
    return json as EEquipment;
}

export function EEquipmentToJSON(value?: EEquipment | null): any {
    return value as any;
}

