import { IdName } from './IdName';
import { VehicleActions } from '../generated-api';

const VehicleActions2: IdName<VehicleActions>[] = [
  { id: VehicleActions.Free, name: 'Free' },
  { id: VehicleActions.Favorites, name: 'Favorites' },
  { id: VehicleActions.Virtual, name: 'Virtual' },
  { id: VehicleActions.FromSylectus, name: 'From Sylectus' },
  { id: VehicleActions.ReservedBySomeone, name: 'Reserved by someone' },
  { id: VehicleActions.ReservedByYou, name: 'Reserved by you' },
  { id: VehicleActions.Temporary, name: 'Temporary' },
]

export default VehicleActions2;