/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VehicleType,
} from '../models';
import {
    VehicleTypeFromJSON,
    VehicleTypeToJSON,
} from '../models';

export interface ApiVehicleTypesAllGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehicleTypesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehicleTypesIdDeleteRequest {
    id: number;
}

export interface ApiVehicleTypesIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehicleTypesIdPutRequest {
    id: number;
    vehicleType?: VehicleType;
}

export interface ApiVehicleTypesPermittedGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehicleTypesPostRequest {
    vehicleType?: VehicleType;
}

/**
 * 
 */
export class VehicleTypesApi extends runtime.BaseAPI {

    /**
     */
    async apiVehicleTypesAllGetRaw(requestParameters: ApiVehicleTypesAllGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleType>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicle-types/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleTypeFromJSON));
    }

    /**
     */
    async apiVehicleTypesAllGet(requestParameters: ApiVehicleTypesAllGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleType>> {
        const response = await this.apiVehicleTypesAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehicleTypesGetRaw(requestParameters: ApiVehicleTypesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleType>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicle-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleTypeFromJSON));
    }

    /**
     */
    async apiVehicleTypesGet(requestParameters: ApiVehicleTypesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleType>> {
        const response = await this.apiVehicleTypesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehicleTypesIdDeleteRaw(requestParameters: ApiVehicleTypesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVehicleTypesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicle-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiVehicleTypesIdDelete(requestParameters: ApiVehicleTypesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiVehicleTypesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiVehicleTypesIdGetRaw(requestParameters: ApiVehicleTypesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVehicleTypesIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicle-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTypeFromJSON(jsonValue));
    }

    /**
     */
    async apiVehicleTypesIdGet(requestParameters: ApiVehicleTypesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleType> {
        const response = await this.apiVehicleTypesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehicleTypesIdPutRaw(requestParameters: ApiVehicleTypesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVehicleTypesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicle-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleTypeToJSON(requestParameters.vehicleType),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiVehicleTypesIdPut(requestParameters: ApiVehicleTypesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiVehicleTypesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiVehicleTypesPermittedGetRaw(requestParameters: ApiVehicleTypesPermittedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleType>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicle-types/Permitted`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleTypeFromJSON));
    }

    /**
     */
    async apiVehicleTypesPermittedGet(requestParameters: ApiVehicleTypesPermittedGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleType>> {
        const response = await this.apiVehicleTypesPermittedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehicleTypesPostRaw(requestParameters: ApiVehicleTypesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleType>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicle-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleTypeToJSON(requestParameters.vehicleType),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleTypeFromJSON(jsonValue));
    }

    /**
     */
    async apiVehicleTypesPost(requestParameters: ApiVehicleTypesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleType> {
        const response = await this.apiVehicleTypesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
