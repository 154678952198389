import { 
  ApiVehiclesUnitsGetRequest, 
  Driver,
  EDriverOrder,
  EStatusHR,
  Vehicle,
} from '../../../generated-api';
import dayjs from 'dayjs';
import LogisticsColumnProps from '../../../components/data-grid/LogisticsColumnProps';
import serverFilterComponent from '../../../components/filters/server/ServerFilter';
import EditableDropdown from '../../../components/form/EditableDropdown';
import { CSSProperties } from 'react';

function formatDate(value: Date | null | undefined) {
  if (!value) return '';

  return dayjs(value).tz().format('MM/DD/YYYY')
}

const unitStatuses = [
  {
    id: EStatusHR.New,
    caption: 'new',
    color: '#a4c2f4',
  },
  {
    id: EStatusHR.Approved,
    caption: 'approved',
    color: '#98fb98',
  },
  {
    id: EStatusHR.Rejected,
    caption: 'rejected',
    color: '#f36c6c',
  },
  {
    id: EStatusHR.Ready,
    caption: 'ready',
    color: '#ffb273',
  },
  {
    id: EStatusHR.InProgress,
    caption: 'in progress',
    color: '#bc9fe3',
  }
];

function statusTemplate(status: EStatusHR | null | undefined){
  if (!status) return <></>;

  const format = unitStatuses.find(x => x.id === status);
  if (!format) return <></>;

  return <div
    style={{display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: format.color}}
  >
    <span style={{ padding: '0.5rem'}}>
      {format.caption}
    </span>
  </div>
}

export default function unitsColumnDef() : LogisticsColumnProps[] {
  return [
    {
      field: 'id',
      header: '#',
      style:  colStyle(),
      sortable: true,
    },
    {
      field: 'drivers',
      header: 'Driver Full Name',
      style:  colStyle(),
      body: (rowData: Vehicle) => {
        if (!rowData.drivers?.length) return <></>;
        const drivers = [
          rowData.drivers.find(d => d.order === EDriverOrder.First),
          rowData.drivers.find(d => d.order === EDriverOrder.Second),
        ].filter(x => x) as Driver[];
        if (drivers.length) {
          const driver = drivers[0];
          var driverValue = driver.firstName || driver.lastName ? `${driver.firstName} ${driver.lastName}` : '';
          return driverValue;
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'vehicle',
      header: 'Vehicle',
      style:  colStyle(),
      sortable: true,
      body: (rowData: Vehicle) => `${rowData.vehicleMake} ${rowData.vehicleModel}`,
      serverSortFields: ['vehicleMake', 'vehicleModel'],
    },
    {
      field: 'phone',
      header: 'Phone',
      style:  colStyle(),
      body: (rowData: Vehicle) => {
        if (!rowData.drivers?.length) return <></>;
        const drivers = [
          rowData.drivers.find(d => d.order === EDriverOrder.First),
          rowData.drivers.find(d => d.order === EDriverOrder.Second),
        ].filter(x => x) as Driver[];
        if (drivers.length) {
          const driver = drivers[0];
          var driverValue = driver.phones && driver.phones.length > 0 ? `${driver.phones[0]}` : '';
          return driverValue;
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'licenseExpiryDate',
      header: 'Reg. Exp',
      style:  colStyle(),
      sortable: true,
      body: (rowData: Vehicle) => formatDate(rowData.licenseExpiryDate),
    },
    {
      field: 'inseranceExp',
      header: 'Insurance Exp',
      style: colStyle(),
      sortable: true,
      body: (rowData: Vehicle) => formatDate(rowData.insuranceExpires),
      serverSortFields: ['insuranceExpires'],
    },
    {
      field: 'licenceExpires',
      header: 'License Exp',
      style:  colStyle(),
      body: (rowData: Vehicle) => {
        if (!rowData.drivers?.length) return <></>;
        const drivers = [
          rowData.drivers.find(d => d.order === EDriverOrder.First),
          rowData.drivers.find(d => d.order === EDriverOrder.Second),
        ].filter(x => x) as Driver[];
        if (drivers.length) {
          const driver = drivers[0];
          var driverValue = formatDate(driver.licenceExpires);
          return driverValue;
        } else {
          return <></>;
        }
      }
    },
    {
      field: 'comment',
      header: 'Comment',
      style:  colStyle(),
      sortable: true,
      body: (rowData: Vehicle) => rowData.comment,
    },
    {
      field: 'statusHR',
      header: 'Status',
      style:  colStyle(),
      sortable: true,
      filter: true,
      showClearButton: false,
      showFilterMenu: false,
      ...serverFilterComponent(props => <EditableDropdown
        options={unitStatuses}
        optionLabel='caption'
        renderOption={option => option.caption}
        renderSelected={option => option?.caption}
        value={props.value}
        onChange={newValue => props.filterApplyCallback(newValue)}
        renderEmptyOption={() => 'All'}
        renderEmptySelected={() => 'All'}
      />),
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiVehiclesUnitsGetRequest
      ) : ApiVehiclesUnitsGetRequest => {
        return { ...serverFilterModel, statusHR: filterMetaData.value?.id };
      },
      body: (rowData: Vehicle) => statusTemplate(rowData.statusHR),
      serverSortFields: ['statusHR'],
    },
    {
      field: 'manager',
      header: 'Manager',
      style:  colStyle(),
      sortable: true,
      body: (rowData: Vehicle) => `${rowData.request?.user?.firstName} ${rowData.request?.user?.lastName}`,
      serverSortFields: ['request.user.firstName', 'request.user.lastName'],
    },

  ];
}

const colStyle = (flexWidth?: number | string) => {
  return {
    flex: 1,
    flexBasis: flexWidth,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;
}