import { IdName } from './IdName';
import { EUserStatus } from '../generated-api';

const UserStatus: IdName<string>[] = [
  { id: EUserStatus.Active, name: 'Active' },
  { id: EUserStatus.Blocked, name: 'Blocked' },
];


export const eUserStatusToUserStatus = (userStatus: EUserStatus | undefined | null) =>
  userStatus ? UserStatus.find(x => x.id === userStatus.toString()) : undefined;

export default UserStatus;
