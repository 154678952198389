/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogisticsFile
 */
export interface LogisticsFile {
    /**
     * 
     * @type {string}
     * @memberof LogisticsFile
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogisticsFile
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogisticsFile
     */
    path?: string | null;
}

/**
 * Check if a given object implements the LogisticsFile interface.
 */
export function instanceOfLogisticsFile(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LogisticsFileFromJSON(json: any): LogisticsFile {
    return LogisticsFileFromJSONTyped(json, false);
}

export function LogisticsFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogisticsFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'path': !exists(json, 'path') ? undefined : json['path'],
    };
}

export function LogisticsFileToJSON(value?: LogisticsFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'path': value.path,
    };
}

