import { AppMenuItem } from './Menu';
import { Menu as PrimeMenu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { useRef } from 'react';

const BurgerMenu = (props: {items : AppMenuItem[]}) => {
  const menuLeftRef = useRef<PrimeMenu>(null);

  return <>
    <Button icon="pi pi-bars text-2xl" className="ml-2 p-button-text p-button-plain" onClick={(event) => menuLeftRef?.current?.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />
    <PrimeMenu model={props.items} popup ref={menuLeftRef} id="popup_menu_left" />
  </>
}

export default BurgerMenu;