/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MenuAccess
 */
export interface MenuAccess {
    /**
     * 
     * @type {boolean}
     * @memberof MenuAccess
     */
    dashboard?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MenuAccess
     */
    dispatch?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MenuAccess
     */
    bids?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MenuAccess
     */
    vehicles?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MenuAccess
     */
    listOfLoads?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MenuAccess
     */
    tracking?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MenuAccess
     */
    settings?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MenuAccess
     */
    hr?: boolean | null;
}

/**
 * Check if a given object implements the MenuAccess interface.
 */
export function instanceOfMenuAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MenuAccessFromJSON(json: any): MenuAccess {
    return MenuAccessFromJSONTyped(json, false);
}

export function MenuAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboard': !exists(json, 'dashboard') ? undefined : json['dashboard'],
        'dispatch': !exists(json, 'dispatch') ? undefined : json['dispatch'],
        'bids': !exists(json, 'bids') ? undefined : json['bids'],
        'vehicles': !exists(json, 'vehicles') ? undefined : json['vehicles'],
        'listOfLoads': !exists(json, 'listOfLoads') ? undefined : json['listOfLoads'],
        'tracking': !exists(json, 'tracking') ? undefined : json['tracking'],
        'settings': !exists(json, 'settings') ? undefined : json['settings'],
        'hr': !exists(json, 'hr') ? undefined : json['hr'],
    };
}

export function MenuAccessToJSON(value?: MenuAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboard': value.dashboard,
        'dispatch': value.dispatch,
        'bids': value.bids,
        'vehicles': value.vehicles,
        'listOfLoads': value.listOfLoads,
        'tracking': value.tracking,
        'settings': value.settings,
        'hr': value.hr,
    };
}

