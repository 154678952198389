/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DispSettings,
} from '../models';
import {
    DispSettingsFromJSON,
    DispSettingsToJSON,
} from '../models';

export interface ApiDispSettingsCurrentGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDispSettingsGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDispSettingsIdDeleteRequest {
    id: number;
}

export interface ApiDispSettingsIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDispSettingsIdPutRequest {
    id: number;
    dispSettings?: DispSettings;
}

export interface ApiDispSettingsPostRequest {
    dispSettings?: DispSettings;
}

/**
 * 
 */
export class DispSettingsApi extends runtime.BaseAPI {

    /**
     */
    async apiDispSettingsCurrentGetRaw(requestParameters: ApiDispSettingsCurrentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DispSettings>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/disp-settings/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DispSettingsFromJSON(jsonValue));
    }

    /**
     */
    async apiDispSettingsCurrentGet(requestParameters: ApiDispSettingsCurrentGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DispSettings> {
        const response = await this.apiDispSettingsCurrentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDispSettingsGetRaw(requestParameters: ApiDispSettingsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DispSettings>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/disp-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DispSettingsFromJSON));
    }

    /**
     */
    async apiDispSettingsGet(requestParameters: ApiDispSettingsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DispSettings>> {
        const response = await this.apiDispSettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDispSettingsIdDeleteRaw(requestParameters: ApiDispSettingsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDispSettingsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/disp-settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDispSettingsIdDelete(requestParameters: ApiDispSettingsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiDispSettingsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDispSettingsIdGetRaw(requestParameters: ApiDispSettingsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DispSettings>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDispSettingsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/disp-settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DispSettingsFromJSON(jsonValue));
    }

    /**
     */
    async apiDispSettingsIdGet(requestParameters: ApiDispSettingsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DispSettings> {
        const response = await this.apiDispSettingsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDispSettingsIdPutRaw(requestParameters: ApiDispSettingsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDispSettingsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/disp-settings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DispSettingsToJSON(requestParameters.dispSettings),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDispSettingsIdPut(requestParameters: ApiDispSettingsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiDispSettingsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDispSettingsPostRaw(requestParameters: ApiDispSettingsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DispSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/disp-settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DispSettingsToJSON(requestParameters.dispSettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DispSettingsFromJSON(jsonValue));
    }

    /**
     */
    async apiDispSettingsPost(requestParameters: ApiDispSettingsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DispSettings> {
        const response = await this.apiDispSettingsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
