import { useCallback, useEffect, useState } from 'react';
import LogisticsDialog from '../../components/LogisticsDialog';
import { showSuccessToast, showToast } from '../../components/LogisticsToast';
import { apiFactory, ModalParameters } from '../../shared';
import { Button } from 'primereact/button';
import CenteredSpinner from '../../components/CenteredSpinner';
import LogisticsWeekCalendar from '../../components/form/datetime/LogisticsWeekCalendar';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { ListOfLoadsApi, Owner } from '../../generated-api';
import { FormikErrors, useFormik } from 'formik';
import Field from '../../components/form/Field';
import ErrorBox from '../../components/form/ErrorBox';
import StyledMultiselect from '../../components/form/StyledMultiselect';
import FileSaver from 'file-saver';

const blankError = 'This value should not be blank.';

interface ListOfLoadsOwnerBillFields {
  week?: Dayjs | null | undefined,
  owners?: Owner[] | undefined,
}

export interface ListOfLoadsOwnerBillParameters extends ModalParameters<undefined> {

}

function ListOfLoadsOwnerBill(props: ListOfLoadsOwnerBillParameters) {
  const [owners, setOwners] = useState<Owner[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<Dayjs | null | undefined>(dayjs());
  const [loading, setLoading] = useState(props.loading);

  const ownerBillValidation = useFormik<ListOfLoadsOwnerBillFields>({
    initialValues: {
      week: dayjs(),
      owners: [],
    },
    validate: data => {
      const requestErrors: FormikErrors<ListOfLoadsOwnerBillFields> = {};
      const requestRequiredFields: ListOfLoadsOwnerBillFields = {
        week: undefined as any,
        owners: undefined as any,
      }

      if (!data.owners || data.owners.length === 0) {
        requestErrors.owners = blankError;
      }

      Object.keys(requestRequiredFields).forEach(field => {
        if (!(data as any)[field])
          (requestErrors as any)[field] = blankError;
      });

      return requestErrors;
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!props?.visible) return;
    setLoading(true);
    const lolApi = apiFactory(ListOfLoadsApi);
    const selectedWeekValueAsString = `${ownerBillValidation.values.week?.format('YY-w')}`;

    Promise.all([
      lolApi.apiListOfLoadsOwnerBillOwnersGet({selectedWeek: selectedWeekValueAsString})
    ]).then(([
      loadedOwners,
    ]) => {
      setOwners(loadedOwners);
    }).catch(() => showToast(
      { severity: 'error', summary: 'Error', detail: 'Something went wrong with preloading information on the form'}))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  useEffect(() => {
    if (!props?.visible) return;
    const selectedWeekValueAsString = `${ownerBillValidation.values.week?.format('YY-w')}`;
    apiFactory(ListOfLoadsApi)
      .apiListOfLoadsOwnerBillOwnersGet({selectedWeek: selectedWeekValueAsString})
      .then(loadedOwners => {
        ownerBillValidation.setFieldValue("owners", []);
        setOwners(loadedOwners);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerBillValidation.values.week, props.visible]);


  const handleClick = (e: MouseEvent) => {
    // @ts-ignore
    e.target!.nextSibling!.click();
  };

  const handleOnHide = () => {
    props.hide(undefined);
    setLoading(false);
  };

  const validate = useCallback(async () => {
    ownerBillValidation.handleSubmit();
    const requestErrors = await ownerBillValidation.validateForm(ownerBillValidation.values);
    if (Object.keys(requestErrors).length) {
      throw new Error('Fix errors and try again');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerBillValidation, ownerBillValidation.values]);

  const handleExport = useCallback(async () => {
    try {
      await validate();
      setLoading(true);

      const selectedWeekValueAsString = `${ownerBillValidation.values.week?.format('YY-w')}`;
      const ownersIds = ownerBillValidation.values.owners?.map(o => o.id!);

      const response = await apiFactory(ListOfLoadsApi)
        .apiListOfLoadsOwnerBillExportSelectedWeekPost({ selectedWeek: selectedWeekValueAsString, requestBody: ownersIds });
      FileSaver.saveAs(response, `owner_bills_${selectedWeekValueAsString}.zip`);
      showSuccessToast('Data successfully exported');
    } catch(e: any) {
      showToast({
        severity: 'error',
        summary: 'Error',
        detail: 'Error on exporting data',
        life: 2000
      });
      throw e;
    } finally {
      setLoading(false);
    }
  }, [ownerBillValidation.values, validate]);

  const handleSend = useCallback(async () => {
    try {
      await validate();
      setLoading(true);

      const selectedWeekValueAsString = `${ownerBillValidation.values.week?.format('YY-w')}`;
      const ownersIds = ownerBillValidation.values.owners?.map(o => o.id!);

      const response = await apiFactory(ListOfLoadsApi)
       .apiListOfLoadsOwnerBillSendSelectedWeekPost({ selectedWeek: selectedWeekValueAsString, requestBody: ownersIds });
      showToast({
        severity: 'success',
        summary: response,
        life: 60000,
        style: {whiteSpace: "pre-line"}
      });
    } catch(e: any) {
      showToast({
        severity: 'error',
        summary: 'Error',
        detail: 'Error on sending bills. Gmail authorization is required on the Settings page.',
        sticky: true,
      });
      throw e;
    } finally {
      setLoading(false);
    }
  }, [ownerBillValidation.values, validate]);

  return (
    <LogisticsDialog
      visible={props.visible}
      onHide={handleOnHide}
      className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-4'
      dismissableMask
    >
      <header className='text-2xl w-full flex-wrap'>
        <span className='font-bold'>Owner Bill</span>
      </header>
      <main className='mt-6 mr-3'>
        <Field label='Week' required>
          <LogisticsWeekCalendar
            value={selectedWeek}
            onChange={(value) => {
              setSelectedWeek(value);
              ownerBillValidation.setFieldValue('week', value);
              // removing event listeners for week clicking events
              document.querySelectorAll<HTMLElement>('.ant-picker-cell-week').forEach((e) => {
                e.removeEventListener("click", handleClick);
              });
            }}
            setSelectedWeek={setSelectedWeek}
            handleClick={handleClick}
            className='w-full'
          />
        </Field>
        <Field label='Owners' required>
          <StyledMultiselect
            value={ownerBillValidation.values.owners}
            onChange={e =>
              ownerBillValidation.setFieldValue('owners', e.value as Owner[])
            }
            options={owners}
            optionLabel='name'
            itemTemplate={(owner: Owner) => owner.companyName}
            selectedItemTemplate={(owner: Owner) => {
              return <span>{owner ? owner?.companyName : "Owners"}</span>;
            }}
            placeholder='Owners'
            maxSelectedLabels={9999}
            className='w-full'
            filter
          />
        </Field>
        <ErrorBox>{ownerBillValidation.errors.owners}</ErrorBox>
      </main>
      <footer className='flex justify-content-center w-full gap-3 mt-6'>
        <Button label='Close' className='min-w-min w-8rem h-3rem'
          onClick={handleOnHide}></Button>
        <Button label='Export Bill' className='p-button-success min-w-min w-8rem h-3rem'
          onClick={handleExport}></Button>
        <Button label='Send Bill' className='p-button-success min-w-min w-8rem h-3rem'
        onClick={handleSend}></Button>
      </footer>
      <CenteredSpinner visible={loading} />
    </LogisticsDialog>
  );
}

export default ListOfLoadsOwnerBill;