/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestActionsAccess
 */
export interface RequestActionsAccess {
    /**
     * 
     * @type {boolean}
     * @memberof RequestActionsAccess
     */
    approve?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestActionsAccess
     */
    deleteUnit?: boolean | null;
}

/**
 * Check if a given object implements the RequestActionsAccess interface.
 */
export function instanceOfRequestActionsAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RequestActionsAccessFromJSON(json: any): RequestActionsAccess {
    return RequestActionsAccessFromJSONTyped(json, false);
}

export function RequestActionsAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestActionsAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approve': !exists(json, 'approve') ? undefined : json['approve'],
        'deleteUnit': !exists(json, 'deleteUnit') ? undefined : json['deleteUnit'],
    };
}

export function RequestActionsAccessToJSON(value?: RequestActionsAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approve': value.approve,
        'deleteUnit': value.deleteUnit,
    };
}

