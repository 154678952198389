/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeletedOrder } from './DeletedOrder';
import {
    DeletedOrderFromJSON,
    DeletedOrderFromJSONTyped,
    DeletedOrderToJSON,
} from './DeletedOrder';
import type { Driver } from './Driver';
import {
    DriverFromJSON,
    DriverFromJSONTyped,
    DriverToJSON,
} from './Driver';
import type { EBidIssuerType } from './EBidIssuerType';
import {
    EBidIssuerTypeFromJSON,
    EBidIssuerTypeFromJSONTyped,
    EBidIssuerTypeToJSON,
} from './EBidIssuerType';
import type { LoadedOrder } from './LoadedOrder';
import {
    LoadedOrderFromJSON,
    LoadedOrderFromJSONTyped,
    LoadedOrderToJSON,
} from './LoadedOrder';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface DriverBid
 */
export interface DriverBid {
    /**
     * 
     * @type {number}
     * @memberof DriverBid
     */
    driverPrice?: number | null;
    /**
     * 
     * @type {Vehicle}
     * @memberof DriverBid
     */
    vehicle?: Vehicle;
    /**
     * 
     * @type {number}
     * @memberof DriverBid
     */
    vehicleId?: number | null;
    /**
     * 
     * @type {Driver}
     * @memberof DriverBid
     */
    driver?: Driver;
    /**
     * 
     * @type {number}
     * @memberof DriverBid
     */
    driverId?: number | null;
    /**
     * 
     * @type {LoadedOrder}
     * @memberof DriverBid
     */
    loadedOrder?: LoadedOrder;
    /**
     * 
     * @type {number}
     * @memberof DriverBid
     */
    loadedOrderId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DriverBid
     */
    availableCity?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DriverBid
     */
    availableDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DriverBid
     */
    miles?: number | null;
    /**
     * 
     * @type {User}
     * @memberof DriverBid
     */
    issuer?: User;
    /**
     * 
     * @type {string}
     * @memberof DriverBid
     */
    issuerId?: string | null;
    /**
     * 
     * @type {DeletedOrder}
     * @memberof DriverBid
     */
    deletedOrder?: DeletedOrder;
    /**
     * 
     * @type {number}
     * @memberof DriverBid
     */
    deletedOrderId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DriverBid
     */
    archived?: boolean | null;
    /**
     * 
     * @type {EBidIssuerType}
     * @memberof DriverBid
     */
    type?: EBidIssuerType;
    /**
     * 
     * @type {string}
     * @memberof DriverBid
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DriverBid
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DriverBid
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DriverBid
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DriverBid
     */
    id?: number;
}

/**
 * Check if a given object implements the DriverBid interface.
 */
export function instanceOfDriverBid(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DriverBidFromJSON(json: any): DriverBid {
    return DriverBidFromJSONTyped(json, false);
}

export function DriverBidFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverBid {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driverPrice': !exists(json, 'driverPrice') ? undefined : json['driverPrice'],
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleFromJSON(json['vehicle']),
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'driver': !exists(json, 'driver') ? undefined : DriverFromJSON(json['driver']),
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'loadedOrder': !exists(json, 'loadedOrder') ? undefined : LoadedOrderFromJSON(json['loadedOrder']),
        'loadedOrderId': !exists(json, 'loadedOrderId') ? undefined : json['loadedOrderId'],
        'availableCity': !exists(json, 'availableCity') ? undefined : json['availableCity'],
        'availableDate': !exists(json, 'availableDate') ? undefined : (json['availableDate'] === null ? null : new Date(json['availableDate'])),
        'miles': !exists(json, 'miles') ? undefined : json['miles'],
        'issuer': !exists(json, 'issuer') ? undefined : UserFromJSON(json['issuer']),
        'issuerId': !exists(json, 'issuerId') ? undefined : json['issuerId'],
        'deletedOrder': !exists(json, 'deletedOrder') ? undefined : DeletedOrderFromJSON(json['deletedOrder']),
        'deletedOrderId': !exists(json, 'deletedOrderId') ? undefined : json['deletedOrderId'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'type': !exists(json, 'type') ? undefined : EBidIssuerTypeFromJSON(json['type']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function DriverBidToJSON(value?: DriverBid | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driverPrice': value.driverPrice,
        'vehicle': VehicleToJSON(value.vehicle),
        'vehicleId': value.vehicleId,
        'driver': DriverToJSON(value.driver),
        'driverId': value.driverId,
        'loadedOrder': LoadedOrderToJSON(value.loadedOrder),
        'loadedOrderId': value.loadedOrderId,
        'availableCity': value.availableCity,
        'availableDate': value.availableDate === undefined ? undefined : (value.availableDate === null ? null : value.availableDate.toISOString()),
        'miles': value.miles,
        'issuer': UserToJSON(value.issuer),
        'issuerId': value.issuerId,
        'deletedOrder': DeletedOrderToJSON(value.deletedOrder),
        'deletedOrderId': value.deletedOrderId,
        'archived': value.archived,
        'type': EBidIssuerTypeToJSON(value.type),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'id': value.id,
    };
}

