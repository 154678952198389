import { useCallback, useEffect, useState } from 'react';
import { FileApi, LogisticsFile } from '../../../../generated-api';
import { UploadFile } from 'antd/es/upload/interface';
import { apiFactory } from '../../../../shared';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import FileSaver from 'file-saver';
import { RcFile } from 'antd/lib/upload';
import { FilePreviewWidget } from '../preview/filePreviewWidget';
import useRefState from '../../../../hooks/RefStateHook';
import heic2any from 'heic2any';
import useFileFetch from '../preview/useFileFetch';
import { isImage } from '../util';

interface useFileUploadProps {
  value?: LogisticsFile[],
  filePreviewWidget: FilePreviewWidget,
  onChange: (value: LogisticsFile[]) => any,
}

const emptyArray = [] as LogisticsFile[];

function useFileUpload(props: useFileUploadProps){
  const [files, setFiles] = useState(props.value?.length
    ? props.value.map(x => wrapExistingFile(x))
    : []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [logisticsFiles, setLogisticsFiles] = useRefState(props.value || emptyArray, props.onChange);

  const sendFileToServer = useCallback(async (options: UploadRequestOption) => {
    try {
      let file: Blob;
      if (options.file instanceof Blob) {
        file = options.file;
      } else {
        file = await fetch(options.file).then(res => res.blob());
      }

      const response = await apiFactory(FileApi).apiFilePost({ formFile: file});
      const uploadedFile = wrapExistingFile(response, file);

      if (uploadedFile.fileName && uploadedFile.fileName.match(/\.(heic|heif)$/)) {
        const heicPreview = await heic2any({ blob: file, toType: 'image/jpeg'});
        if (heicPreview instanceof Blob) {
          uploadedFile.thumbUrl = URL.createObjectURL(heicPreview);
        } else if (heicPreview.length) {
          uploadedFile.thumbUrl = URL.createObjectURL(heicPreview[0]);
        }
      }

      // Fixme: blob has no filename inside if sent after editing
      uploadedFile.fileName = options.filename;
      uploadedFile.name = options.filename!;
      uploadedFile.response!.name = options.filename;

      if (options.onSuccess) {
        options.onSuccess(uploadedFile);
      }

      if (uploadedFile)
      return uploadedFile;
    } catch (error: any) {
      if (options.onError)
        options.onError(error);
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadFile = useCallback(async (file: UploadFile<LogisticsFile>) => {
    // Todo: check if file already in browser and dont fetch it
    const response = await apiFactory(FileApi)
      .apiFileGet({ id: (file.response as LogisticsFile).id!});
    FileSaver.saveAs(response, (file.response as LogisticsFile).name!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPreUploadedFile = useCallback((options: UploadRequestOption<any>) => {
    const url = URL.createObjectURL(options.file as Blob);
    // Not yet uploaded to server, but ok for preview
    const preUploadedFile = {
      uid: (options.file as RcFile).uid,
      url: url,
      fileName: (options.file as RcFile).name,
      originFileObj: options.file,
      status: 'uploading',
      response: {
        name: options.file,
      }
    }  as UploadFile<LogisticsFile>;

    setFiles(prev => prev.concat(preUploadedFile));

    return preUploadedFile;
  }, [setFiles]);

  const updateUploadedFile = useCallback((preUploadedUid: string, uploadedFile: UploadFile<LogisticsFile>) => {
    setFiles(prev => {
      const updatedFiles = prev.map(x => {
        return x.uid === preUploadedUid
          ? {
            ...x,
            status: 'success',
            thumbUrl: uploadedFile.thumbUrl,
            response: { ...x.response, id: uploadedFile.response?.id} } as UploadFile<LogisticsFile>
          : x;
      });

      return updatedFiles;
    })
  }, [setFiles]);

  const fetchFile = useFileFetch();
  const fetchFile2 = async (file: UploadFile<LogisticsFile>) => {
    try {
      const blob = await fetchFile(file);

      file.url = URL.createObjectURL(blob);
      file.originFileObj = blob as RcFile;

      if (!file.fileName || !file.fileName.match(/\.(heic|heif)$/))
        return;

      const heicPreview = await heic2any({ blob: blob, toType: 'image/jpeg'});
      if (heicPreview instanceof Blob) {
        file.thumbUrl = URL.createObjectURL(heicPreview);
      } else if (heicPreview.length) {
        file.thumbUrl = URL.createObjectURL(heicPreview[0]);
      }
    } catch (error: any) {
      console.error('Failed to fetch file', error);
    }
  }

  // Update incoming files and fetch missing ones
  useEffect(() => {
    if (!props.value?.length) {
      setFiles([]);
      return;
    }

    const updatedFiles = props.value.map(logisticsFile => {
      const existingFile = files.find(x => x.response?.id === logisticsFile.id);
      return existingFile ? existingFile : wrapExistingFile(logisticsFile);
    });
    setFiles(updatedFiles);

    updatedFiles.filter(x => !x.url && isImage(x.fileName)).forEach(file => {
      fetchFile2(file).then(() => {
        setFiles(old => old.map(oldFile => {
          return file !== oldFile ? oldFile : file;
        }));
      });
    });
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [props.value]);

  const joinPreview = props.filePreviewWidget(state => state.joinPreview);
  const leavePreview = props.filePreviewWidget(state => state.leavePreview);
  useEffect(() => {
    joinPreview(files);

    return () => {
      leavePreview(files);
    }

  }, [files, joinPreview, leavePreview]);

  const uploadFileRequest = async (options: UploadRequestOption<any>) => {
    const preUploadedFile = addPreUploadedFile(options);
    const uploadedFile = await sendFileToServer({ ...options, filename: preUploadedFile.fileName });
    if (!uploadedFile?.response?.id)
      return;

    updateUploadedFile(preUploadedFile.uid, uploadedFile);
    setLogisticsFiles(old => old.concat(uploadedFile.response!));
  };

  const replaceFile = async (oldFile: UploadFile<LogisticsFile>, options: UploadRequestOption<any>) => {
    if (!(typeof options.file === 'string' || (options.file as any) instanceof String)) {
      console.error('options.file must be a string url');
      return;
    }

    // Not yet uploaded to server, but ok for preview
    const preUploadedFile = {
      uid: oldFile.uid,
      url: options.file,
      fileName: oldFile.fileName,
      originFileObj: options.file, // Seems like this value is not used explicitly
      status: 'uploading',
      response: {
        name: oldFile.fileName,
      }
    }  as UploadFile<LogisticsFile>;

    setFiles(prev => prev.map(file => {
      if (file.uid === oldFile.uid || file === oldFile) {
        return preUploadedFile;
      }

      return file;
    }));

    // Fixme: blob has no filename inside if sent after editing
    const uploadedFile = await sendFileToServer({ ...options, filename: oldFile.fileName });
    if (!uploadedFile?.response?.id) {
      console.warn('nothing returned after file upload');
      return;
    }

    updateUploadedFile(preUploadedFile.uid, uploadedFile);

    setLogisticsFiles(old => old.map(file => {
      if (file.id === oldFile.response?.id) {
        return uploadedFile.response!
      }

      return file;
    }));
  };

  const onRemove = useCallback((file: UploadFile<LogisticsFile>) => {
    if (!file || !props.value?.length || !file.response) return;

    setLogisticsFiles( old => old.filter(x => x.id !== file.response!.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return {
    files,
    addPreUploadedFile,
    sendFileToServer,
    downloadFile,
    updateUploadedFile,
    uploadFileRequest,
    onRemove,
    replaceFile,
  };
}

export function wrapExistingFile(file: LogisticsFile, blob?: Blob) {
  let fileUrl : string | undefined;
  if (blob) {
    fileUrl = URL.createObjectURL(blob);
  }
  return {
    response: file,
    name: file.name,
    fileName: file.name,
    uid: `rc-${file.id}`,
    status: 'uploading',
    url: fileUrl,
    originFileObj: blob,
  } as UploadFile<LogisticsFile>;
}

export default useFileUpload;