import styled from '@emotion/styled';
import { InputMask } from 'primereact/inputmask';
import { focusedInputStyle, inputStyle } from './Styles';

const StyledInputMask = styled(InputMask)`
  & {
    ${inputStyle}
  }

  &:enabled:focus {
    ${focusedInputStyle}
  }

  &.p-invalid:focus {
    border-color: red;
  }
`

export default StyledInputMask;