import React, { useCallback } from 'react';
import { PickerProps } from 'antd/es/date-picker/generatePicker';
import { Dayjs } from 'dayjs';
import StyledWeekCalendar from '../../../components/form/datetime/StyledWeekCalendar';
import { defaultTimezoneOffset } from '../../../shared';

function DashboardWeekPicker(props: PickerProps<Dayjs>) {
  const { onChange } = props;

  const timezonedOnChange = useCallback((value: Dayjs | null, dateString: string) => {
    if (!onChange) return;

    if (!value) {
      onChange(value, dateString);
      return;
    }

    // Return date converted to user timezone.
    const newValue = value
      .startOf('day')
      .add(defaultTimezoneOffset(), 'minute');

    // Todo: dateString is untouched and wont match newValue.
    onChange(newValue, dateString);
  }, [onChange]);

  return <StyledWeekCalendar
    {...props }
    onChange={timezonedOnChange}
  />
}

export default DashboardWeekPicker;