import React from 'react';
import useAuth from '../../features/login/AuthHook';
import { history } from '../../shared';
import AppBar from '../header/AppBar';
import { Navigate, Outlet } from 'react-router-dom';
import VisibilityToggler from '../../components/VisibilityToggler';
import PopupNotifications from '../../features/notifications/popup/PopupNotifications';
import SecondSessionWarning from '../../features/login/SecondSessionWarning';
import SettingsInvalidator from '../../features/settings/SettingsInvalidator';
import AutoRedirect from './AutoRedirect';
import IdleLogout from '../../features/login/IdleLogout';
import HubConnector from './HubConnector';

function PrivateApp() {
  const { auth } = useAuth();

  if (!auth.isAuthenticated) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: history.location }} />
  }

  return <VisibilityToggler visible={auth.isAuthenticated}>
    <AppBar />
    <AutoRedirect />
    <Outlet />
    <PopupNotifications />
    <SecondSessionWarning />
    <SettingsInvalidator />
    <IdleLogout />
    <HubConnector />
  </VisibilityToggler>
}



export default PrivateApp;