import { memo, useMemo } from 'react';
import { LogisticsFile } from '../../../../generated-api';
import LogisticsFilesUpload from './LogisticsFilesUpload';
import { FilePreviewWidget } from '../preview/filePreviewWidget';

export interface LogisticsFileUploadProperties {
  value?: LogisticsFile,
  onChange: (newValue: LogisticsFile | undefined) => any,
  disabled?: boolean,
  filePreviewWidget?: FilePreviewWidget,
}

const LogisticsFileUpload = memo((props: LogisticsFileUploadProperties) => {
  const files = useMemo(() => props.value ? [props.value] : [], [props.value]);

  return <LogisticsFilesUpload
    value={files}
    disabled={props.disabled}
    maxCount={1}
    filePreviewWidget={props.filePreviewWidget}
    onChange={newValue => {
      if (newValue.length === 2) {
        props.onChange(newValue[1]);
      } else if (newValue.length === 1){
        props.onChange(newValue[0])
      } else {
        props.onChange(undefined);
      }
    }}
  />
});



export default LogisticsFileUpload;

