import { BaseAPI } from '../../generated-api';
import { apiFactory, TApiConstructor } from './api-factory';

export default function paginationApiFactory<T extends BaseAPI>(
  baseApi: TApiConstructor<T>,
  setTotalCount: (totalCount: number) => any
) {
  return apiFactory(baseApi).withPostMiddleware(async context => {
    const headers = context.response.headers;
    if (headers.has('X-Total-Count')) {
      setTotalCount(parseInt(headers.get('X-Total-Count')!));
    }
    return context.response;
  });
}