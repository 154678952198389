/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Brokerage,
} from '../models';
import {
    BrokerageFromJSON,
    BrokerageToJSON,
} from '../models';

export interface ApiBrokeragesAllGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBrokeragesExistingNameNameGetRequest {
    name: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBrokeragesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBrokeragesIdDeleteRequest {
    id: number;
}

export interface ApiBrokeragesIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBrokeragesIdPutRequest {
    id: number;
    brokerage?: Brokerage;
}

export interface ApiBrokeragesNamesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBrokeragesPostRequest {
    brokerage?: Brokerage;
}

/**
 * 
 */
export class BrokeragesApi extends runtime.BaseAPI {

    /**
     */
    async apiBrokeragesAllGetRaw(requestParameters: ApiBrokeragesAllGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Brokerage>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/brokerages/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrokerageFromJSON));
    }

    /**
     */
    async apiBrokeragesAllGet(requestParameters: ApiBrokeragesAllGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Brokerage>> {
        const response = await this.apiBrokeragesAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBrokeragesExistingNameNameGetRaw(requestParameters: ApiBrokeragesExistingNameNameGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling apiBrokeragesExistingNameNameGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/brokerages/existing-name/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiBrokeragesExistingNameNameGet(requestParameters: ApiBrokeragesExistingNameNameGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiBrokeragesExistingNameNameGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBrokeragesGetRaw(requestParameters: ApiBrokeragesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Brokerage>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/brokerages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrokerageFromJSON));
    }

    /**
     */
    async apiBrokeragesGet(requestParameters: ApiBrokeragesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Brokerage>> {
        const response = await this.apiBrokeragesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBrokeragesIdDeleteRaw(requestParameters: ApiBrokeragesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBrokeragesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/brokerages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBrokeragesIdDelete(requestParameters: ApiBrokeragesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiBrokeragesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBrokeragesIdGetRaw(requestParameters: ApiBrokeragesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Brokerage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBrokeragesIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/brokerages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrokerageFromJSON(jsonValue));
    }

    /**
     */
    async apiBrokeragesIdGet(requestParameters: ApiBrokeragesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Brokerage> {
        const response = await this.apiBrokeragesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBrokeragesIdPutRaw(requestParameters: ApiBrokeragesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBrokeragesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/brokerages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BrokerageToJSON(requestParameters.brokerage),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBrokeragesIdPut(requestParameters: ApiBrokeragesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiBrokeragesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBrokeragesNamesGetRaw(requestParameters: ApiBrokeragesNamesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Brokerage>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/brokerages/names`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrokerageFromJSON));
    }

    /**
     */
    async apiBrokeragesNamesGet(requestParameters: ApiBrokeragesNamesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Brokerage>> {
        const response = await this.apiBrokeragesNamesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBrokeragesPostRaw(requestParameters: ApiBrokeragesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Brokerage>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/brokerages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BrokerageToJSON(requestParameters.brokerage),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrokerageFromJSON(jsonValue));
    }

    /**
     */
    async apiBrokeragesPost(requestParameters: ApiBrokeragesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Brokerage> {
        const response = await this.apiBrokeragesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
