import { useEffect, useRef } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { FileApi, LogisticsFile, RequestContext } from '../../../../generated-api';
import { apiFactory } from '../../../../shared';

function useFileFetch() {
  const httpAbortControllers = useRef<AbortController[]>([]);

  const fetch = async (file: UploadFile<LogisticsFile>) => {
    if (!file.response?.id)
      throw new Error('missing file.response.id');

    const controller = new AbortController();
    httpAbortControllers.current = httpAbortControllers.current.concat(controller);
    try {
      const fileContent = await apiFactory(FileApi)
        .withPreMiddleware((context: RequestContext) => {
          return Promise.resolve({ ...context, init: { ...context.init, signal: controller.signal }});
        })
        .apiFileGet({ id: file.response!.id });

      return fileContent;
    } finally {
      httpAbortControllers.current.splice(httpAbortControllers.current.indexOf(controller), 1);
    }
  }

  // Cancel all running requests
  useEffect(() => {
    const controllers = [...httpAbortControllers.current];
    return () => {
      controllers.forEach(controller => {
        try {
          controller.abort();
        } catch {
          // Do not care
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpAbortControllers.current]);

  return fetch;
}

export default useFileFetch;