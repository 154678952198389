/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EGender = {
    Male: 'Male',
    Female: 'Female'
} as const;
export type EGender = typeof EGender[keyof typeof EGender];


export function EGenderFromJSON(json: any): EGender {
    return EGenderFromJSONTyped(json, false);
}

export function EGenderFromJSONTyped(json: any, ignoreDiscriminator: boolean): EGender {
    return json as EGender;
}

export function EGenderToJSON(value?: EGender | null): any {
    return value as any;
}

