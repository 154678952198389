import { Dispatch, SetStateAction } from 'react';
import { Bid } from '../../generated-api';
import { Checkbox } from 'primereact/checkbox';

interface BidCheckboxesProps {
  formBid: Bid;
  setFormBid: Dispatch<SetStateAction<Bid>>;
}

const BidCheckboxes = (props : BidCheckboxesProps) => {
  const [formBid, setFormBid] = [props.formBid, props.setFormBid]
  return <div className='grid'>
  <div className='col-6'>
    <div className='field-checkbox'>
      <Checkbox
        checked={formBid.dockHigh}
        onChange={e => {
          setFormBid(bid => {
            return { ...bid, dockHigh: e.checked };
          });
        } } />
      <label>Dock high</label>
    </div>
  </div>
  <div className='col-6'>
    <div className='field-checkbox'>
      <Checkbox
        checked={formBid.ttDrivers}
        onChange={e => {
          setFormBid(bid => {
            return { ...bid, ttDrivers: e.checked };
          });
        } } />
      <label>TRUE TEAM DRIVERS</label>
    </div>
  </div>
  <div className='col-6'>
    <div className='field-checkbox'>
      <Checkbox
        checked={formBid.liftGate}
        onChange={e => {
          setFormBid(bid => {
            return { ...bid, liftGate: e.checked };
          });
        } } />
      <label>Lift gate</label>
    </div>
  </div>
  <div className='col-6'>
    <div className='field-checkbox'>
      <Checkbox
        checked={formBid.palletJack}
        onChange={e => {
          setFormBid(bid => {
            return { ...bid, palletJack: e.checked };
          });
        } } />
      <label>Pallet Jack</label>
    </div>
  </div>
  <div className='col-6'>
    <div className='field-checkbox'>
      <Checkbox
        checked={formBid.driverAssist}
        onChange={e => {
          setFormBid(bid => {
            return { ...bid, driverAssist: e.checked };
          });
        } } />
      <label>Driver assist</label>
    </div>
  </div>
  <div className='col-6'>
    <div className='field-checkbox'>
      <Checkbox
        checked={formBid.fullPPE}
        onChange={e => {
          setFormBid(bid => {
            return { ...bid, fullPPE: e.checked };
          });
        } } />
      <label>FULL PPE</label>
    </div>
  </div>
</div>;
}

export default BidCheckboxes;