import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { apiFactory } from '../../shared';
import LogisticsDialog from '../../components/LogisticsDialog';
import { ModalParameters } from '../../shared';
import { Owner, OwnersApi, State, StatesApi, Vehicle } from '../../generated-api';
import Field from '../../components/form/Field';
import StyledInputText from '../../components/form/StyledInputText';
import { Button } from 'primereact/button';
import { useAppDispatch, useModal } from '../../hooks';
import { updateEntity } from './OwnersSlice';
import EditableDropdown from '../../components/form/EditableDropdown';
import LogisticsLink from '../../components/LogisticsLink';
import VehicleEdit from '../vehicles/VehicleEdit';
import { FormikErrors, useFormik } from 'formik';
import ErrorBox from '../../components/form/ErrorBox';
import CenteredSpinner from '../../components/CenteredSpinner';
import Row from '../../components/form/Row';
import Column from '../../components/form/Column';
import { logisticsConfirmDialog } from '../../components/LogisticsConfifmDialog';
import { showSuccessToast, showToast } from '../../components/LogisticsToast';
import useRights from '../../hooks/RightsHook';
import VisibilityToggler from '../../components/VisibilityToggler';
import LogisticsPhoneInput from '../../components/LogisticsPhoneInput';

export interface OwnerEditParameters extends ModalParameters<Owner> {

}

interface OwnerWithTwoPhones extends Owner {
  phoneOne?: string,
  phoneTwo?: string,
}

const getPhone = (driver: Owner, index: number) =>
  driver.phones ? driver.phones[index] : undefined;

const replacePhone = (driver: Owner, phone: string, index: number) => {
  const phones = driver.phones ? [...driver.phones] : [];
  phones[index] = phone;
  return phones;
}

function OwnerEdit(props: OwnerEditParameters) {
  const { visible } = props;
  const [owner, setOwner] = useState<Owner>(props.data);
  const [savedOwner, setSavedOwner] = useState<Owner>();
  const [states, setStates] = useState<State[]>([]);
  const dispatch = useAppDispatch();
  const vehicleModal = useModal<Vehicle>({});
  const [loading, setLoading] = useState(false);
  const rights = useRights(security => security.owner);
  const validation = useFormik<OwnerWithTwoPhones>({
    initialValues: owner,
    validate: data => {
      const errors : FormikErrors<OwnerWithTwoPhones> = {};
      const blankError = 'This value should not be blank.';
      const telephoneError = 'This is not a correct telephone number.';

      const requiredFields: Owner = {
        companyName: undefined,
        firstName: undefined,
        lastName: undefined,
        address: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
        email: undefined,
        phones: undefined,
        federalTaxpayerId: undefined,
      };

      Object.keys(requiredFields).forEach(field => {
        if (!(data as any)[field])
          (errors as any)[field] = blankError;
      });

      if (!data.phones![0]) {
        errors.phoneOne = blankError;
      }
      else if (data.phones![0] && (data.phones![0] || "").replace(/ /g, "").length < 11) {
          errors.phoneOne = telephoneError;
      }

      if (data.phones![1] && (data.phones![1] || "").replace(/ /g, "").length < 11) {
          errors.phoneTwo = telephoneError;
      }

      return errors;
    },
    onSubmit: () => {},
  });
  const createDate = owner.createdDate
    ? dayjs.tz(owner.createdDate).format('MMM DD YYYY')
    : '';

  useEffect(() => {
    if (!visible) return;

    setLoading(true);
    const ownerPromise = props.data.id
      ? apiFactory(OwnersApi).apiOwnersIdGet({ id: props.data.id})
      : Promise.resolve(owner);
    Promise.all([
      ownerPromise,
      apiFactory(StatesApi).apiStatesCodesGet(),
    ]).then(([loadedOwner, loadedStates]) => {
      setOwner(loadedOwner);
      setStates(loadedStates);
    }).catch(() => showToast(
      { severity: 'error', summary: 'Error', detail: 'Something went wrong'}))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue = useCallback((setState: SetStateAction<Owner>) => {
    validation.setValues(setState);
    setOwner(setState);
  }, [validation]);

  const validate = useCallback(async () => {
    validation.handleSubmit();
    const errors = await validation.validateForm(owner);
    if (Object.keys(errors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [owner, validation]);

  const hide = useCallback((value: Owner | undefined) => {
    if (value) {
      dispatch(updateEntity(value));
    }

    props.hide(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedOwner]);

  const save = useCallback(async () => {
    try {
      await validate();
      setLoading(true);
      const response = owner.id
        ? await apiFactory(OwnersApi)
          .apiOwnersIdPut({ id: owner.id!, owner: owner})
          .then(() => Promise.resolve<undefined>(undefined))
        : await apiFactory(OwnersApi).apiOwnersPost({ owner: owner });
      showSuccessToast('Owner saved');
      setSavedOwner(response || owner);
      if (response) {
        setOwner(response);
      }

      validation.resetForm({ values: response || owner, touched: {} });
      hide(response || owner);
    } catch (e: any) {
      showToast({severity: 'error', summary: 'Error', detail: e.toString()});
    } finally {
      setLoading(false)
    }
  }, [owner, validate, validation, hide]);

  const handleOnHide = useCallback(() => {
    if (!rights.update && !rights.create) {
      hide(savedOwner);
      return;
    }

    if (validation.dirty) {
      logisticsConfirmDialog({
        message: 'Save changes?',
        closable: false,
        accept() {
          save().then(() => hide(savedOwner))
        },
        reject() {
          hide(undefined);
        }
      });
    } else {
      hide(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide, owner, validation, rights]);

  const vehicles = owner.vehicles
    ? owner.vehicles.map(vehicle => <LogisticsLink
        className='text-purple-600'
        key={vehicle.id}
        onClick={() => vehicleModal.show(vehicle)}
      >
        #{vehicle.id}&nbsp;
      </LogisticsLink>
    )
    : <></>;

  const vehicleEdit = useMemo(() => {
    return vehicleModal.data && vehicleModal.visible
      ? <VehicleEdit
        data={vehicleModal.data}
        loading={false}
        visible={vehicleModal.visible}
        hide={() => {
          vehicleModal.setData(undefined as any);
          vehicleModal.hide();
        }}
      />
      : <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleModal.data, vehicleModal.visible]);

  return (
    <>
      {vehicleEdit}
      <LogisticsDialog
        visible={props.visible}
        onHide={handleOnHide}
        className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-8'
      >
        <header className='text-2xl w-full flex-wrap'>
          <span className='font-bold'>Owner #V{owner.id}</span>
          <span className='mx-4'>|</span>
          Vehicles:&nbsp;
          {vehicles}
        </header>
        <div className='text-lg mt-2 mb-5'>
          Added by {owner.createdBy} {createDate}
        </div>
        <main className='grid logistics-dialog-edit-form__content'>
          <Row>
            <Column>
              <Field label='Company name' required>
                <StyledInputText
                  // @ts-ignore
                  value={owner.companyName}
                  disabled={!rights.update}
                  onChange={e => setValue(o => {
                    return { ...o, companyName: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.companyName}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='E-mail address' required>
                <StyledInputText
                  // @ts-ignore
                  value={owner.email}
                  disabled={!rights.update}
                  onChange={e => setValue(o => {
                    return { ...o, email: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.email}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='First name' required>
                <StyledInputText
                  // @ts-ignore
                  value={owner.firstName}
                  disabled={!rights.update}
                  onChange={e => setValue(o => {
                    return { ...o, firstName: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.firstName}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='Cell phone 1' required>
                <LogisticsPhoneInput
                  country={'us'}
                  value={getPhone(owner, 0)}
                  disabled={!rights.update}
                  onChange={number => setValue(d => {
                    return { ...d, phones: replacePhone(owner, number, 0) };
                  })}
                />
                <ErrorBox>{validation.errors.phoneOne}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='Last name' required>
                <StyledInputText
                  // @ts-ignore
                  value={owner.lastName}
                  disabled={!rights.update}
                  onChange={e => setValue(o => {
                    return { ...o, lastName: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.lastName}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='Cell phone 2'>
                <LogisticsPhoneInput
                  country={'us'}
                  value={getPhone(owner, 1)}
                  disabled={!rights.update}
                  onChange={number => setValue(d => {
                    return { ...d, phones: replacePhone(owner, number, 1) };
                  })}
                />
                <ErrorBox>{validation.errors.phoneTwo}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='Address' required>
                <StyledInputText
                  // @ts-ignore
                  value={owner.address}
                  disabled={!rights.update}
                  onChange={e => setValue(o => {
                    return { ...o, address: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.address}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='Federal tax payer id' required>
                <StyledInputText
                  // @ts-ignore
                  value={owner.federalTaxpayerId}
                  disabled={!rights.update}
                  onChange={e => setValue(o => {
                    return { ...o, federalTaxpayerId: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.federalTaxpayerId}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='City' required>
                <StyledInputText
                  // @ts-ignore
                  value={owner.city}
                  disabled={!rights.update}
                  onChange={e => setValue(o => {
                    return { ...o, city: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.city}</ErrorBox>
              </Field>
            </Column>
            <Column>

            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='State' required>
                <EditableDropdown
                  options={states}
                  optionLabel='name'
                  renderOption={state => state.code}
                  renderSelected={state => state?.code}
                  renderEmptySelected={() => '-'}
                  value={owner.state}
                  disabled={!rights.update}
                  onChange={newValue => setValue(o => {
                    return { ...o, state: newValue };
                  })}
                  filter
                />
                <ErrorBox>{validation.errors.state}</ErrorBox>
              </Field>
            </Column>
            <Column>

            </Column>
          </Row>
          <Row>
            <Column>
              <Field label='Zip' required>
                <StyledInputText
                  // @ts-ignore
                  value={owner.zip}
                  disabled={!rights.update}
                  onChange={e => setValue(o => {
                    return { ...o, zip: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.zip}</ErrorBox>
              </Field>
            </Column>
            <Column>

            </Column>
          </Row>
        </main>
        <footer className='flex justify-content-center w-full gap-3'>
          <VisibilityToggler visible={rights.update || rights.create} >
            <Button
              label='SAVE'
              className='p-button-success'
              onClick={save}
            />
          </VisibilityToggler>
          <Button
            label='CLOSE'
            onClick={handleOnHide}
          />
        </footer>
        <CenteredSpinner visible={loading} />
      </LogisticsDialog>
    </>
  );
}

export default OwnerEdit;