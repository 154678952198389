/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EStatusHR = {
    None: 'None',
    New: 'New',
    Approved: 'Approved',
    Rejected: 'Rejected',
    Ready: 'Ready',
    InProgress: 'InProgress'
} as const;
export type EStatusHR = typeof EStatusHR[keyof typeof EStatusHR];


export function EStatusHRFromJSON(json: any): EStatusHR {
    return EStatusHRFromJSONTyped(json, false);
}

export function EStatusHRFromJSONTyped(json: any, ignoreDiscriminator: boolean): EStatusHR {
    return json as EStatusHR;
}

export function EStatusHRToJSON(value?: EStatusHR | null): any {
    return value as any;
}

