import { ApiDashboardDispatchersDispatcherStatisticsGetRequest, ApiDashboardDispatchersOwnerInformationGetRequest, ApiDashboardDispatchersRevenueByDispatcherGetRequest, ApiDashboardDispatchersTopVehiclesGetRequest, Brokerage, DashboardApi, UserDto } from '../../generated-api';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { apiFactory } from '../../shared';

export type DashboardDispatchersState = {
  all?: UserDto[],
  revenueByDispatcher?: UserDto[],
  topVehicles?: UserDto[],
  dispatcherStatistics?: UserDto[],
  ownerInformation?: UserDto[],
}

export type DashboardState = {
  dispatcherState?: DashboardDispatchersState,
  brokerages?: Brokerage[],
  loading: boolean,
}

const initialState : DashboardState = {
  dispatcherState: {},
  brokerages: [],
  loading: false,
}

export const getDashboardDispatchers = createAsyncThunk(
  "dashboard/get_dispatchers",
  async () => {
    return await apiFactory(DashboardApi).apiDashboardDispatchersGet();
  }
);

export const getDashboardDispatchersRevenueByDispatcher = createAsyncThunk(
  "dashboard/get_dispatchers/revenueByDispatcher",
  async (param: ApiDashboardDispatchersRevenueByDispatcherGetRequest) => {
    return await apiFactory(DashboardApi).apiDashboardDispatchersRevenueByDispatcherGet(param);
  }
);

export const getDashboardDispatchersOwnerInformation = createAsyncThunk(
  "dashboard/get_dispatchers/ownerInformation",
  async (param: ApiDashboardDispatchersOwnerInformationGetRequest) => {
    return await apiFactory(DashboardApi).apiDashboardDispatchersOwnerInformationGet(param);
  }
);

export const getDashboardDispatchersTopVehicles = createAsyncThunk(
  "dashboard/get_dispatchers/topVehicles",
  async (param: ApiDashboardDispatchersTopVehiclesGetRequest) => {
    return await apiFactory(DashboardApi).apiDashboardDispatchersTopVehiclesGet(param);
  }
);

export const getDashboardDispatchersDispatcherStatistics = createAsyncThunk(
  "dashboard/get_dispatchers/dispatcherStatistics",
  async (param: ApiDashboardDispatchersDispatcherStatisticsGetRequest) => {
    return await apiFactory(DashboardApi).apiDashboardDispatchersDispatcherStatisticsGet(param);
  }
);

export const getDashboardBrokerages = createAsyncThunk(
  "dashboard/get_brokerages",
  async () => {
    return await apiFactory(DashboardApi).apiDashboardBrokeragesGet();
  }
);

export const DashboardSlice = createSlice({
    name: 'Dashboard',
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder.addMatcher(isPending(getDashboardDispatchers), state => {
        return { ...state, loading: true };
      }).addMatcher(isFulfilled(getDashboardDispatchers), (state, action) => {
        const dispatchers = action.payload;
        return { ...state, loading: false, dispatcherState: { ...state.dispatcherState, all: dispatchers } };
      }).addMatcher(isRejected(getDashboardDispatchers), (state) => {
        return { ...state, loading: false, dispatcherState: { ...state.dispatcherState, all: [] } };
      });
      builder.addMatcher(isPending(getDashboardDispatchersRevenueByDispatcher), state => {
        return { ...state, loading: true };
      }).addMatcher(isFulfilled(getDashboardDispatchersRevenueByDispatcher), (state, action) => {
        const dispatchers = action.payload;
        return { ...state, loading: false, dispatcherState: { ...state.dispatcherState, revenueByDispatcher: dispatchers } };
      }).addMatcher(isRejected(getDashboardDispatchersRevenueByDispatcher), (state) => {
        return { ...state, loading: false, dispatcherState: { ...state.dispatcherState, revenueByDispatcher: [] } };
      });
      builder.addMatcher(isPending(getDashboardDispatchersOwnerInformation), state => {
        return { ...state, loading: true };
      }).addMatcher(isFulfilled(getDashboardDispatchersOwnerInformation), (state, action) => {
        const dispatchers = action.payload;
        return { ...state, loading: false, dispatcherState: { ...state.dispatcherState, ownerInformation: dispatchers } };
      }).addMatcher(isRejected(getDashboardDispatchersOwnerInformation), (state) => {
        return { ...state, loading: false, dispatcherState: { ...state.dispatcherState, ownerInformation: [] } };
      });
      builder.addMatcher(isPending(getDashboardDispatchersTopVehicles), state => {
        return { ...state, loading: true };
      }).addMatcher(isFulfilled(getDashboardDispatchersTopVehicles), (state, action) => {
        const dispatchers = action.payload;
        return { ...state, loading: false, dispatcherState: { ...state.dispatcherState, topVehicles: dispatchers } };
      }).addMatcher(isRejected(getDashboardDispatchersTopVehicles), (state) => {
        return { ...state, loading: false, dispatcherState: { ...state.dispatcherState, topVehicles: [] } };
      });
      builder.addMatcher(isPending(getDashboardDispatchersDispatcherStatistics), state => {
        return { ...state, loading: true };
      }).addMatcher(isFulfilled(getDashboardDispatchersDispatcherStatistics), (state, action) => {
        const dispatchers = action.payload;
        return { ...state, loading: false, dispatcherState: { ...state.dispatcherState, dispatcherStatistics: dispatchers } };
      }).addMatcher(isRejected(getDashboardDispatchersDispatcherStatistics), (state) => {
        return { ...state, loading: false, dispatcherState: { ...state.dispatcherState, dispatcherStatistics: [] } };
      });
      builder.addMatcher(isPending(getDashboardBrokerages), state => {
        return { ...state, loading: true };
      }).addMatcher(isFulfilled(getDashboardBrokerages), (state, action) => {
        const brokerages = action.payload;
        return { ...state, loading: false, brokerages };
      }).addMatcher(isRejected(getDashboardBrokerages), (state) => {
        return { ...state, loading: false, brokerages: [] };
      });
    }
});

export default DashboardSlice.reducer;