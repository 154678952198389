/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EDockSize = {
    None: 'None',
    True: 'True',
    Ramps: 'Ramps',
    LandingGear: 'LandingGear',
    No: 'No'
} as const;
export type EDockSize = typeof EDockSize[keyof typeof EDockSize];


export function EDockSizeFromJSON(json: any): EDockSize {
    return EDockSizeFromJSONTyped(json, false);
}

export function EDockSizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EDockSize {
    return json as EDockSize;
}

export function EDockSizeToJSON(value?: EDockSize | null): any {
    return value as any;
}

