/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EWidgetChart = {
    None: 'None',
    Orders: 'Orders',
    OrdersLastYear: 'OrdersLastYear',
    BidsByDispatchers: 'BidsByDispatchers',
    DriversProfit: 'DriversProfit',
    Profit: 'Profit',
    Margin: 'Margin',
    Loads: 'Loads'
} as const;
export type EWidgetChart = typeof EWidgetChart[keyof typeof EWidgetChart];


export function EWidgetChartFromJSON(json: any): EWidgetChart {
    return EWidgetChartFromJSONTyped(json, false);
}

export function EWidgetChartFromJSONTyped(json: any, ignoreDiscriminator: boolean): EWidgetChart {
    return json as EWidgetChart;
}

export function EWidgetChartToJSON(value?: EWidgetChart | null): any {
    return value as any;
}

