import React, { useEffect } from 'react';
import BidsByHour from './widgets/BidsByHour';
import { useAppDispatch } from '../../hooks';
import { getDashboardDispatchers, getDashboardBrokerages } from './DashboardSlice';
import RevenueByDispatcher from './widgets/RevenueByDispatcher';
import TopVehicles from './widgets/TopVehicles';
import OwnerInformation from './widgets/OwnerInformation';
import Weather from './widgets/Weather';
import BrokerageInformation from './widgets/BrokerageInformation';
import CompanyInformation from './widgets/CompanyInformation';
import ProfitStatistics from './widgets/ProfitStatistics';
import LoadInformation from './widgets/LoadInformation';
import DispatchersStatistics from './widgets/DispatchersStatistics';
import LogisticsPanel from '../../components/LogisticsPanel';

function DashboardPage() {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch(getDashboardDispatchers());
    appDispatch(getDashboardBrokerages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className='flex flex-column overflow-y-scroll' style={{height: '95%'}}>
    <LogisticsPanel className='surface-50 text-900' header='Weather and Load Information' toggleable collapsed>
      <div className='flex flex-row'>
        <Weather />
        <div className='flex-grow-1'>
          <LoadInformation />
        </div>
      </div>
    </LogisticsPanel>
    <ProfitStatistics />
    <RevenueByDispatcher />
    <div className='flex flex-row'>
      <CompanyInformation />
      <div className='flex-grow-1'>
        <TopVehicles />
      </div>
    </div>
    <BidsByHour/>
    <DispatchersStatistics />
    <div className='flex flex-row'>
      <BrokerageInformation />
      <div className='flex-grow-1'>
        <OwnerInformation />
      </div>
    </div>
  </div>
}

export default DashboardPage;
