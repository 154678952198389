/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FieldChange
 */
export interface FieldChange {
    /**
     * 
     * @type {string}
     * @memberof FieldChange
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FieldChange
     */
    _enum?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FieldChange
     */
    from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FieldChange
     */
    to?: string | null;
}

/**
 * Check if a given object implements the FieldChange interface.
 */
export function instanceOfFieldChange(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FieldChangeFromJSON(json: any): FieldChange {
    return FieldChangeFromJSONTyped(json, false);
}

export function FieldChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        '_enum': !exists(json, 'enum') ? undefined : json['enum'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
    };
}

export function FieldChangeToJSON(value?: FieldChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'enum': value._enum,
        'from': value.from,
        'to': value.to,
    };
}

