import styled from '@emotion/styled';

export const LinkLikeSpan = styled.span`
  & {
    cursor:pointer;
    color:blue;
    text-decoration:underline;
  }

  &:hover {
    text-shadow: 0.2px 0.2px 0.2px #555;
  }
`;