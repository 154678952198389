/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Notification } from './Notification';
import {
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
} from './Notification';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface NotificationStatus
 */
export interface NotificationStatus {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationStatus
     */
    read?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationStatus
     */
    deleted?: boolean | null;
    /**
     * 
     * @type {User}
     * @memberof NotificationStatus
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof NotificationStatus
     */
    userId?: string | null;
    /**
     * 
     * @type {Notification}
     * @memberof NotificationStatus
     */
    notification?: Notification;
    /**
     * 
     * @type {number}
     * @memberof NotificationStatus
     */
    notificationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationStatus
     */
    id?: number;
}

/**
 * Check if a given object implements the NotificationStatus interface.
 */
export function instanceOfNotificationStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationStatusFromJSON(json: any): NotificationStatus {
    return NotificationStatusFromJSONTyped(json, false);
}

export function NotificationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'read': !exists(json, 'read') ? undefined : json['read'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'notification': !exists(json, 'notification') ? undefined : NotificationFromJSON(json['notification']),
        'notificationId': !exists(json, 'notificationId') ? undefined : json['notificationId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function NotificationStatusToJSON(value?: NotificationStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'read': value.read,
        'deleted': value.deleted,
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'notification': NotificationToJSON(value.notification),
        'notificationId': value.notificationId,
        'id': value.id,
    };
}

