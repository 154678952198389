import LogisticsColumnProps from '../../components/data-grid/LogisticsColumnProps';
import serverFilterComponent from '../../components/filters/server/ServerFilter';
import ServerWeekCalendar from '../../components/filters/server/ServerWeekCalendar';
import {
  ApiTrackingsGetRequest,
  ETrackingOption,
  ETrackingStatus,
  Tracking,
} from '../../generated-api';
import React from 'react';
import dayjs from 'dayjs';
import { Checkbox } from 'primereact/checkbox';
import TrackingStatus from '../../data-moqs/TrackingStatus';
import TrackingOptions from '../../data-moqs/TrackingOptions';
import CallButton from '../../components/calls/CallButton';

export default function useColumns() : LogisticsColumnProps[] {
  return [
    {
      field: 'number',
      header: '#',
      style: { flex: 1 },
      sortable: true,
      filter: true,
      ...serverFilterComponent(props => <ServerWeekCalendar {...props} weekStorageValueName='tracking_week' key='week_filter' />),
      showClearButton: false,
      showFilterMenu: false,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiTrackingsGetRequest): ApiTrackingsGetRequest => {
        return { ...serverFilterModel, number: filterMetaData.value };
      },
    },
    {
      field: 'createTime',
      header: 'Date',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) => rowData.createTime
          ? dayjs.tz(rowData.createTime).format('MM/DD/YYYY')
          : '',
    },
    {
      field: 'shipper',
      header: 'Shipper',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) =>
        `${rowData.pickUpAtCity || ''}, ${rowData.pickUpAtState || ''}`,
      serverSortFields: ['pickUpAtCity', 'pickUpAtState'],
    },
    {
      field: 'receiver',
      header: 'Receiver ',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) =>
        `${rowData.deliverToCity || ''}, ${rowData.deliverToState || ''}`,
      serverSortFields: ['deliverToCity', 'deliverToState'],
    },
    {
      field: 'driver',
      header: 'Driver ',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) =>
        `${rowData.driver?.firstName || ''} ${rowData.driver?.lastName || ''}`,
      serverSortFields: ['driver.firstName', 'driver.lastName'],
    },
    {
      field: 'driver.phone',
      header: 'Phones',
      style: { maxWidth: '7rem' },
      body: (rowData:  Tracking) => <CallButton
        label='Call'
        className='px-5 bg-green-400'
        callable={rowData.driver}
      />,
    },
    {
      field: 'mail',
      header: 'Mail',
      style: { flex: 1 },
      sortable: true,
      filter: false,
    },
    {
      field: 'period',
      header: 'Period ',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) => rowData.period?.substring(0, 5),
    },
    {
      field: 'lastTime',
      header: 'Last time',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) => rowData.lastTime
        ? dayjs.tz(rowData.lastTime).format('MM/DD/YYYY hh:mm A')
        : '',
    },
    {
      field: 'nextTime',
      header: 'Next time',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) => rowData.nextTime
        ? dayjs.tz(rowData.nextTime).format('MM/DD/YYYY hh:mm A')
        : '',
    },
    {
      field: 'trackerComment',
      header: 'Comment ',
      style: { flex: 1 },
      sortable: true,
      filter: false,
    },
    {
      field: 'linkTracking',
      header: 'Life360/Link ',
      style: { flex: 1 },
      sortable: true,
      filter: false,
    },
    {
      field: 'freight',
      header: 'Freight',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) => {
        const value = rowData.freight || false;
        return <Checkbox checked={value} readOnly />;
      }
    },
    {
      field: 'owner',
      header: 'Owner',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) =>
        `${rowData.owner?.firstName || ''} ${rowData.owner?.lastName || ''}`,
      serverSortFields: ['owner.firstName', 'owner.lastName'],
    },
    {
      field: 'option',
      header: 'Option',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) => {
        const value = rowData.option || ETrackingOption.NotSet;
        if (value === ETrackingOption.NotSet) return <></>;

        return TrackingOptions.find(x => x.id === value)?.name;
      }
    },
    {
      field: 'dispatcher',
      header: 'Dispatcher',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) =>
        `${rowData.dispatcher?.firstName || ''} ${rowData.dispatcher?.lastName || ''}`,
      serverSortFields: ['dispatcher.firstName', 'dispatcher.lastName'],
    },
    {
      field: 'dispatcherComment',
      header: 'Comment',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      serverSortFields: ['dispatcherComment'],
    },
    {
      field: 'brokerage',
      header: 'Brokerage',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) => rowData.brokerage?.name || '',
      serverSortFields: ['brokerage.name'],
    },
    {
      field: 'mp',
      header: 'MP',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) => {
        const value = rowData.mp || false;
        return <Checkbox checked={value} readOnly />;
      }
    },
    {
      field: 'rateConfirmation',
      header: 'RateConfirmation',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) => rowData.rateConfirmation ? 'Loaded' : 'Not loaded'
    },
    {
      field: 'status',
      header: 'Status',
      style: { flex: 1 },
      sortable: true,
      filter: false,
      body: (rowData: Tracking) => {
        const value = rowData.status || ETrackingStatus.NotSet;
        if (value === ETrackingStatus.NotSet) return <></>;

        return TrackingStatus.find(x => x.id === value)?.name;
      }
    },
  ];
}
