const RequestEditCaptions = {
    'RingDate': 'Ring date',
    'NextRingDate': 'Next ring date',
    'User': 'Manager',
    'Comment': 'Comment',
    'Owner.CompanyName': 'Full Company Name',
    'Owner.FirstName': 'Applicant first name',
    'Owner.LastName': 'Applicant last name',
    'Owner.Email': 'Email',
    'Owner.Phones': 'Company Phone',
    'Owner.EmployerId': 'Employer ID',
    'Owner.Address': 'Address Line',
    'Owner.City': 'City',
    'Owner.State': 'State',
    'Owner.Zip': 'ZIP Code',
    'Owner.Birth': 'Date of Birth',
    'Owner.EmergencyPhone': 'Emergency phone',
    'Owner.BusinessType': 'Business type',
    'Owner.TaxExempt': 'Tax exempt?',
    'Owner.ExemptPayeeCode': 'Exempt payee code (if any)',
    'Owner.FATCA': 'Exemption from FATCA reporting code (if any)',
    'Owner.DepositCompanyName': 'Company Name',
    'Owner.DepositCityStateZip': 'City, State And Zip Code',
    'Owner.DepositAddress': 'Street Address',
    'Owner.BlankName': 'Bank Name',
    'Owner.AccountingNumber': 'Accounting Number',
    'Owner.RoutingNumber': 'Routing Number',
    'Owner.W9Form': 'W-9 form',
    'Owner.VoidCheck': 'Void check',
    'Owner.Contract': 'Contract',
};

export default RequestEditCaptions;