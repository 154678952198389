import React, { memo, useMemo, useState } from 'react';
import VisibilityToggler from '../../components/VisibilityToggler';
import { EMailTemplateVariables } from '../../generated-api';
import HtmlEditor from '../../components/html/HtmlEditor';
import { Button } from 'primereact/button';

export type MailEditorProps = {
  rawTemplate: string,
  onRawTemplateChange: (newValue: string) => any,
  onFilledTemplateChange: (newValue: string) => any,
  variables: MailTemplateVariablesValues,
  disabled?: boolean | null | undefined,
}

const defaultHeight = '30rem';

export type MailTemplateVariablesValues = {
  [Property in keyof typeof EMailTemplateVariables]: string
}

const MailEditor = memo((props: MailEditorProps) =>  {
  const [edit, setEdit] = useState(false);

  const filledTemplate = useMemo(
    () => {
      // Refill and return immediately on all changes.
      // Very inefficient when there are any inline images
      const a = fillTemplate(props.rawTemplate, props.variables);
      props.onFilledTemplateChange(a);
      return a;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.rawTemplate, props.variables]);

  return <>
    <div className='relative'>
      <VisibilityToggler visible={!edit || props.disabled}>
        <HtmlEditor
          value={filledTemplate}
          onChange={() => {}}
          disabled
          init={{
            toolbar: false,
            menubar: false,
            height: defaultHeight,
          }}
        />
        <VisibilityToggler visible={!props.disabled}>
          <Button
            icon='pi pi-pencil'
            className='p-button-plain p-button-text mx-1 border-none absolute right-15 top-15'
            onClick={e => {
              e.preventDefault();
              setEdit(true);
            }}
          />
        </VisibilityToggler>
      </VisibilityToggler>
      <VisibilityToggler visible={edit && !props.disabled}>
        <HtmlEditor
          value={props.rawTemplate}
          onEditorChange={props.onRawTemplateChange}
          init={{
            height: defaultHeight,
          }}
        />
        <Button
          icon='pi pi-sign-out'
          className='p-button-plain p-button-text mx-1 border-none absolute right-15 top-25'
          onClick={e => {
            e.preventDefault();
            setEdit(false);
          }}
        />
      </VisibilityToggler>
    </div>
  </>;
}, (prev, next) => {
  if (prev.rawTemplate !== next.rawTemplate) return false;

  return Object.keys(prev.variables).every(variable => {
    return (prev.variables as any)[variable] === (next.variables as any)[variable];
  });
});

function fillTemplate (rawTemplate: string, variables: MailTemplateVariablesValues) {
  let newFormattedTemplate = rawTemplate;
  Object.keys(variables).forEach(variable => {
    const variableName = ((EMailTemplateVariables as any)[variable] as string).toLowerCase();
    const variableValue = (variables as any)[variable] as string;
    const regex = new RegExp(`{${variableName}}`, 'i');
    newFormattedTemplate = newFormattedTemplate.replace(regex, variableValue);
  });

  return newFormattedTemplate;
}

export default MailEditor;