/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface FilterPreset
 */
export interface FilterPreset {
    /**
     * 
     * @type {string}
     * @memberof FilterPreset
     */
    componentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FilterPreset
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FilterPreset
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FilterPreset
     */
    userId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof FilterPreset
     */
    user?: User;
    /**
     * 
     * @type {number}
     * @memberof FilterPreset
     */
    id?: number;
}

/**
 * Check if a given object implements the FilterPreset interface.
 */
export function instanceOfFilterPreset(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FilterPresetFromJSON(json: any): FilterPreset {
    return FilterPresetFromJSONTyped(json, false);
}

export function FilterPresetFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterPreset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'componentId': !exists(json, 'componentId') ? undefined : json['componentId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function FilterPresetToJSON(value?: FilterPreset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'componentId': value.componentId,
        'name': value.name,
        'value': value.value,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'id': value.id,
    };
}

