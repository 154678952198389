/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Driver,
  EStatusHR,
} from '../models';
import {
    DriverFromJSON,
    DriverToJSON,
    EStatusHRFromJSON,
    EStatusHRToJSON,
} from '../models';

export interface ApiDriversAllGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDriversForRequestGetRequest {
    requestId?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDriversFullNamesAndPhoneGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDriversFullNamesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDriversGetRequest {
    active?: boolean;
    id?: number;
    sylectusId?: number;
    login?: string;
    name?: string;
    address?: string;
    email?: string;
    owner?: string;
    statusHR?: EStatusHR;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDriversIdDeleteRequest {
    id: number;
}

export interface ApiDriversIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDriversIdPutRequest {
    id: number;
    driver?: Driver;
}

export interface ApiDriversLicenseImageToTextGetRequest {
    imageId?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDriversPostRequest {
    driver?: Driver;
}

export interface ApiDriversVehicleDropdownGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiDriversVehicleDropdownWithFilesRequestIdGetRequest {
    requestId: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class DriversApi extends runtime.BaseAPI {

    /**
     */
    async apiDriversAllGetRaw(requestParameters: ApiDriversAllGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Driver>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverFromJSON));
    }

    /**
     */
    async apiDriversAllGet(requestParameters: ApiDriversAllGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Driver>> {
        const response = await this.apiDriversAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDriversForRequestGetRaw(requestParameters: ApiDriversForRequestGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Driver>>> {
        const queryParameters: any = {};

        if (requestParameters.requestId !== undefined) {
            queryParameters['requestId'] = requestParameters.requestId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers/forRequest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverFromJSON));
    }

    /**
     */
    async apiDriversForRequestGet(requestParameters: ApiDriversForRequestGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Driver>> {
        const response = await this.apiDriversForRequestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDriversFullNamesAndPhoneGetRaw(requestParameters: ApiDriversFullNamesAndPhoneGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Driver>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers/fullNamesAndPhone`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverFromJSON));
    }

    /**
     */
    async apiDriversFullNamesAndPhoneGet(requestParameters: ApiDriversFullNamesAndPhoneGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Driver>> {
        const response = await this.apiDriversFullNamesAndPhoneGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDriversFullNamesGetRaw(requestParameters: ApiDriversFullNamesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Driver>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers/fullNames`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverFromJSON));
    }

    /**
     */
    async apiDriversFullNamesGet(requestParameters: ApiDriversFullNamesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Driver>> {
        const response = await this.apiDriversFullNamesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDriversGetRaw(requestParameters: ApiDriversGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Driver>>> {
        const queryParameters: any = {};

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.sylectusId !== undefined) {
            queryParameters['sylectusId'] = requestParameters.sylectusId;
        }

        if (requestParameters.login !== undefined) {
            queryParameters['login'] = requestParameters.login;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.statusHR !== undefined) {
            queryParameters['statusHR'] = requestParameters.statusHR;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverFromJSON));
    }

    /**
     */
    async apiDriversGet(requestParameters: ApiDriversGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Driver>> {
        const response = await this.apiDriversGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDriversIdDeleteRaw(requestParameters: ApiDriversIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDriversIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDriversIdDelete(requestParameters: ApiDriversIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiDriversIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDriversIdGetRaw(requestParameters: ApiDriversIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Driver>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDriversIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverFromJSON(jsonValue));
    }

    /**
     */
    async apiDriversIdGet(requestParameters: ApiDriversIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Driver> {
        const response = await this.apiDriversIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDriversIdPutRaw(requestParameters: ApiDriversIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Driver>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDriversIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DriverToJSON(requestParameters.driver),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverFromJSON(jsonValue));
    }

    /**
     */
    async apiDriversIdPut(requestParameters: ApiDriversIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Driver> {
        const response = await this.apiDriversIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDriversLicenseImageToTextGetRaw(requestParameters: ApiDriversLicenseImageToTextGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Driver>> {
        const queryParameters: any = {};

        if (requestParameters.imageId !== undefined) {
            queryParameters['imageId'] = requestParameters.imageId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers/licenseImageToText`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverFromJSON(jsonValue));
    }

    /**
     */
    async apiDriversLicenseImageToTextGet(requestParameters: ApiDriversLicenseImageToTextGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Driver> {
        const response = await this.apiDriversLicenseImageToTextGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDriversPostRaw(requestParameters: ApiDriversPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Driver>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverToJSON(requestParameters.driver),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverFromJSON(jsonValue));
    }

    /**
     */
    async apiDriversPost(requestParameters: ApiDriversPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Driver> {
        const response = await this.apiDriversPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDriversVehicleDropdownGetRaw(requestParameters: ApiDriversVehicleDropdownGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Driver>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers/vehicleDropdown`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverFromJSON));
    }

    /**
     */
    async apiDriversVehicleDropdownGet(requestParameters: ApiDriversVehicleDropdownGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Driver>> {
        const response = await this.apiDriversVehicleDropdownGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDriversVehicleDropdownWithFilesRequestIdGetRaw(requestParameters: ApiDriversVehicleDropdownWithFilesRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Driver>>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling apiDriversVehicleDropdownWithFilesRequestIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/drivers/vehicleDropdownWithFiles/{requestId}`.replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverFromJSON));
    }

    /**
     */
    async apiDriversVehicleDropdownWithFilesRequestIdGet(requestParameters: ApiDriversVehicleDropdownWithFilesRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Driver>> {
        const response = await this.apiDriversVehicleDropdownWithFilesRequestIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
