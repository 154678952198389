/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BidByHourResponse } from './BidByHourResponse';
import {
    BidByHourResponseFromJSON,
    BidByHourResponseFromJSONTyped,
    BidByHourResponseToJSON,
} from './BidByHourResponse';
import type { OrdersByHourResponse } from './OrdersByHourResponse';
import {
    OrdersByHourResponseFromJSON,
    OrdersByHourResponseFromJSONTyped,
    OrdersByHourResponseToJSON,
} from './OrdersByHourResponse';

/**
 * 
 * @export
 * @interface WidgetOneResponse
 */
export interface WidgetOneResponse {
    /**
     * 
     * @type {Array<OrdersByHourResponse>}
     * @memberof WidgetOneResponse
     */
    orders?: Array<OrdersByHourResponse> | null;
    /**
     * 
     * @type {Array<OrdersByHourResponse>}
     * @memberof WidgetOneResponse
     */
    ordersLastYear?: Array<OrdersByHourResponse> | null;
    /**
     * 
     * @type {BidByHourResponse}
     * @memberof WidgetOneResponse
     */
    bidsByHour?: BidByHourResponse;
}

/**
 * Check if a given object implements the WidgetOneResponse interface.
 */
export function instanceOfWidgetOneResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WidgetOneResponseFromJSON(json: any): WidgetOneResponse {
    return WidgetOneResponseFromJSONTyped(json, false);
}

export function WidgetOneResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetOneResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orders': !exists(json, 'orders') ? undefined : (json['orders'] === null ? null : (json['orders'] as Array<any>).map(OrdersByHourResponseFromJSON)),
        'ordersLastYear': !exists(json, 'ordersLastYear') ? undefined : (json['ordersLastYear'] === null ? null : (json['ordersLastYear'] as Array<any>).map(OrdersByHourResponseFromJSON)),
        'bidsByHour': !exists(json, 'bidsByHour') ? undefined : BidByHourResponseFromJSON(json['bidsByHour']),
    };
}

export function WidgetOneResponseToJSON(value?: WidgetOneResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orders': value.orders === undefined ? undefined : (value.orders === null ? null : (value.orders as Array<any>).map(OrdersByHourResponseToJSON)),
        'ordersLastYear': value.ordersLastYear === undefined ? undefined : (value.ordersLastYear === null ? null : (value.ordersLastYear as Array<any>).map(OrdersByHourResponseToJSON)),
        'bidsByHour': BidByHourResponseToJSON(value.bidsByHour),
    };
}

