import React from 'react';
import LogisticsDataGrid from '../../components/data-grid/LogisticsDataGrid';
import { getOwnersPage as getOwnerEntities } from './OwnersSlice';
import useOwnersColumns from './OwnersColumnDef';
import VisibilityToggler from '../../components/VisibilityToggler';
import AddButton from '../../components/data-grid/buttons/AddButton';
import serverFilterComponent, { serverFilterFunctionName } from '../../components/filters/server/ServerFilter';
import ServerActiveCheckbox from '../../components/filters/server/ServerActiveCheckbox';
import { ApiOwnersGetRequest, Owner } from '../../generated-api';
import { RootState } from '../../store/store';
import useRights from '../../hooks/RightsHook';
import { useModal } from '../../hooks';
import OwnerEdit from './OwnerEdit';

const selectOwner = (state: RootState) => state.owner;

function OwnersList() {
  const ownerModal = useModal<Owner>({});
  const ownerRights = useRights(security => security.owner);

  const ownerEdit = ownerModal.visible
    ? <OwnerEdit
      data={ownerModal.data}
      loading={false}
      visible={ownerModal.visible}
      hide={ownerModal.hide}
    />
    : <></>;

  return (
    <>
      <LogisticsDataGrid
        selectState={selectOwner}
        getEntitiesPage={getOwnerEntities}
        columns={useOwnersColumns()}
        leftHeaderElements={[
          <VisibilityToggler visible={ownerRights.create}>
            <AddButton onClick={() => ownerModal.show({})} />
          </VisibilityToggler>
        ]}
        id='ownersList'
        onRowClick={e => ownerModal.show(e.data)}
        externalFilters={[
          {
            ...serverFilterComponent(props => <ServerActiveCheckbox {...props} key='active_filter' />),
            serverFilterRequest: (filterMetaData, serverFilters) => {
              return { ...serverFilters, active: filterMetaData.value } as ApiOwnersGetRequest;
            },
            field: 'active'
          },
        ]}
        alwaysDefaultFilterMeta={{
          // @ts-ignore
          'active': { value: true, matchMode: serverFilterFunctionName},
        }}
        others={{
          selectionMode: 'single',
        }}
      />
      {ownerEdit}
    </>
  );
}

export default OwnersList;