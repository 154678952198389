/**
 * Copied from
 * https://github.com/primefaces/primereact/blob/master/components/lib/confirmdialog/ConfirmDialog.js
 */
import * as React from 'react';
import { OverlayService } from 'primereact/overlayservice';
import { ConfirmDialogOptions, ConfirmDialogProps } from 'primereact/confirmdialog';
import { classNames, IconUtils, ObjectUtils } from 'primereact/utils';
import { localeOption } from 'primereact/api';
import { useUnmountEffect, useUpdateEffect } from 'primereact/hooks';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
// @ts-ignore
import { Portal } from 'primereact/portal';


const confirmDialogEvent = 'confirm-dialog' as any;

export const logisticsConfirmDialog = (props: ConfirmDialogProps = {}) => {
  props = { ...props, ...{ visible: props.visible === undefined ? true : props.visible } };
  props.visible && OverlayService.emit(confirmDialogEvent, props as any);

  const show = (updatedProps = {}) => {
    OverlayService.emit(confirmDialogEvent, { ...props, ...updatedProps, ...{ visible: true } } as any);
  };

  const hide = () => {
    OverlayService.emit(confirmDialogEvent, { visible: false } as any);
  };

  return { show, hide };
};

export const LogisticsConfirmDialog = React.memo(
  React.forwardRef((props: ConfirmDialogOptions , ref) => {
    const [visibleState, setVisibleState] = React.useState(props.visible);
    const [reshowState, setReshowState] = React.useState(false);
    const confirmProps = React.useRef(null);
    const getCurrentProps = () => confirmProps.current || props;
    const getPropValue = (key: string) => (confirmProps.current || props)[key];
    const callbackFromProp = (key: string, ...param: any[]) => ObjectUtils.getPropValue(getPropValue(key), param);

    const acceptLabel = getPropValue('acceptLabel') || localeOption('accept', '');
    const rejectLabel = getPropValue('rejectLabel') || localeOption('reject','');

    const accept = () => {
      callbackFromProp('accept');
      hide('accept');
    };

    const reject = () => {
      callbackFromProp('reject');
      hide('reject');
    };

    const show = () => {
      setVisibleState(true);
    };

    const hide = (result = 'cancel') => {
      setVisibleState(false);
      callbackFromProp('onHide', result);
    };

    const onEnterPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        accept();
      }
    }

    const confirm = (updatedProps: ConfirmDialogOptions) => {
      if (updatedProps.tagKey === props.tagKey) {
        const isVisibleChanged = visibleState !== updatedProps.visible;
        const targetChanged = getPropValue('target') !== updatedProps.target;

        if (targetChanged && !props.target) {
          hide();
          confirmProps.current = updatedProps as any;
          setReshowState(true);
        } else if (isVisibleChanged) {
          confirmProps.current = updatedProps as any;
          updatedProps.visible ? show() : hide();
        }
      }
    };

    React.useEffect(() => {
      props.visible ? show() : hide();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    React.useEffect(() => {
      if (!props.target && !props.message) {
        OverlayService.on(confirmDialogEvent, confirm);
      }

      return () => {
        OverlayService.off(confirmDialogEvent, confirm);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.target]);

    useUpdateEffect(() => {
      reshowState && show();
    }, [reshowState]);

    useUnmountEffect(() => {
      OverlayService.off(confirmDialogEvent, confirm);
    });

    React.useImperativeHandle(ref, () => ({
      props,
      confirm
    }));

    const createFooter = () => {
      const acceptClassName = classNames('p-confirm-dialog-accept', getPropValue('acceptClassName'));
      const rejectClassName = classNames(
        'p-confirm-dialog-reject',
        {
          'p-button-text': !getPropValue('rejectClassName')
        },
        getPropValue('rejectClassName')
      );
      const content = (
        <div>
          <Button label={rejectLabel} icon={getPropValue('rejectIcon')} className={rejectClassName} onClick={reject} />
          <Button label={acceptLabel} icon={getPropValue('acceptIcon')} className={acceptClassName} onClick={accept} autoFocus />
        </div>
      );

      if (getPropValue('footer')) {
        const defaultContentOptions = {
          accept,
          reject,
          acceptClassName,
          rejectClassName,
          acceptLabel,
          rejectLabel,
          element: content,
          props: getCurrentProps()
        };

        return ObjectUtils.getJSXElement(getPropValue('footer'), defaultContentOptions);
      }

      return content;
    };

    const createElement = () => {
      const currentProps = getCurrentProps();
      const className = classNames('p-confirm-dialog', getPropValue('className'));
      //@ts-ignore
      const otherProps = ObjectUtils.findDiffKeys(currentProps, LogisticsConfirmDialog.defaultProps);
      const message = ObjectUtils.getJSXElement(getPropValue('message'), currentProps);
      const icon = IconUtils.getJSXIcon(getPropValue('icon'), { className: 'p-confirm-dialog-icon' }, { props: currentProps });
      const footer = createFooter();

      return (
        <div onKeyUp={onEnterPressed}>
          <Dialog visible={visibleState} {...otherProps} className={className} footer={footer} onHide={hide} breakpoints={getPropValue('breakpoints')}>
            {icon}
            <span className="p-confirm-dialog-message">{message}</span>
          </Dialog>
        </div>
      );
    };

    const element = createElement();

    return <Portal element={element} appendTo={getPropValue('appendTo')} />
  })
);

LogisticsConfirmDialog.displayName = 'ConfirmDialog';
//@ts-ignore
LogisticsConfirmDialog.defaultProps = {
  __TYPE: 'ConfirmDialog',
  tagKey: undefined,
  visible: undefined,
  message: null,
  rejectLabel: null,
  acceptLabel: null,
  icon: null,
  rejectIcon: null,
  acceptIcon: null,
  rejectClassName: null,
  acceptClassName: null,
  className: null,
  appendTo: null,
  footer: null,
  breakpoints: null,
  onHide: null,
  accept: null,
  reject: null
};