import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { ModalParameters } from '../../shared';
import { Driver, DriversApi, EGender, LogisticsFile, Owner, State, StatesApi } from '../../generated-api';
import LogisticsDialog from '../../components/LogisticsDialog';
import { Button } from 'primereact/button';
import dayjs from 'dayjs';
import EditableDropdown from '../../components/form/EditableDropdown';
import Gender from '../../data-moqs/Gender';
import { apiFactory } from '../../shared';
import Field from '../../components/form/Field';
import StyledInputText from '../../components/form/StyledInputText';
import LogisticsCalendar from '../../components/form/datetime/LogisticsCalendar';
import { useAppDispatch, useModal } from '../../hooks';
import { updateEntity } from './DriversSlice';
import LogisticsLink from '../../components/LogisticsLink';
import OwnerEdit from '../owners/OwnerEdit';
import OwnerSelect from '../owners/OwnerSelect';
import { FormikErrors, useFormik } from 'formik';
import ErrorBox from '../../components/form/ErrorBox';
import CenteredSpinner from '../../components/CenteredSpinner';
import Row from '../../components/form/Row';
import Column from '../../components/form/Column';
import { logisticsConfirmDialog } from '../../components/LogisticsConfifmDialog';
import { showSuccessToast, showToast } from '../../components/LogisticsToast';
import useRights from '../../hooks/RightsHook';
import VisibilityToggler from '../../components/VisibilityToggler';
import LogisticsPhoneInput from '../../components/LogisticsPhoneInput';
import Header from '../../components/form/Header';
import LogisticsFilesUpload from '../../components/form/files/upload/LogisticsFilesUpload';
import useFilePreview from '../../components/form/files/preview/filePreviewWidget';
import OcrStatusBar, { OcrStatus } from './components/OcrStatusBar';
import LogisticsDateOnly from '../../components/form/datetime/LogisticsDateOnly';
import LogisticsFilePreview from '../../components/form/files/preview/LogisticsFilePreview';

export interface DriverEditParameters extends ModalParameters<Driver> {
  openedFromRequest: boolean,
}

interface DriverWithTwoPhones extends Driver {
  phoneOne?: string,
  phoneTwo?: string,
}

const getPhone = (driver: Driver, index: number) =>
  driver.phones ? driver.phones[index] : undefined;

const replacePhone = (driver: Driver, phone: string, index: number) => {
  const phones = driver.phones ? [...driver.phones] : [];
  phones[index] = phone;
  return phones;
}

const genderToGender = (gender: EGender | undefined | null) =>
  gender ? Gender.find(x => x.id === gender.toString()) : undefined;

function DriverEdit(props : DriverEditParameters) {
  const { visible } = props;
  const [driver, setDriver] = useState<Driver>(props.data);
  const [savedDriver, setSavedDriver] = useState<Driver>();
  const [states, setStates] = useState<State[]>([]);
  const dispatch = useAppDispatch();
  const ownerEditModal = useModal<Owner>({});
  const ownerSelectModal = useModal(undefined);
  const [loading, setLoading] = useState(false);
  const rights = useRights(security => security.driver);
  const rightsUpdate = rights.update || (props.openedFromRequest && rights.requestUpdate);
  const rightsCreate = rights.create || (props.openedFromRequest && rights.requestUpdate);
  const filePreviewWidget = useFilePreview();
  const [ocrStatus, setOcrStatus] = useState<OcrStatus>('none');
  const validation = useFormik<DriverWithTwoPhones >({
    initialValues: driver,
    validate: data => {
      const errors : FormikErrors<DriverWithTwoPhones > = {};
      const blankError = 'This value should not be blank.';
      const telephoneError = 'This is not a correct telephone number.';

      const requiredFields: Driver = {
        gender: undefined,
        firstName: undefined,
        lastName: undefined,
        address: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
        email: undefined,
        phones: undefined,
        birthDate: undefined,
      };

      Object.keys(requiredFields).forEach(field => {
        if (!(data as any)[field])
          (errors as any)[field] = blankError;
      });

      if (!data.phones || !data.phones[0]) {
        errors.phoneOne = blankError;
      } else if (data.phones && data.phones[0] && (data.phones![0] || "").replace(/ /g, "").length < 11) {
          errors.phoneOne = telephoneError;
      }

      if (data.phones && data.phones[1] && (data.phones![1] || "").replace(/ /g, "").length < 11) {
          errors.phoneTwo = telephoneError;
      }
      
      return errors;
    },
    onSubmit: () => {},
  });
  const owner = driver.vehicle?.owner;

  const createDate = driver.createdDate
    ? dayjs.tz(driver.createdDate).format('MMM DD YYYY')
    : '';

  const setValue = useCallback((setState: SetStateAction<Driver>) => {
    validation.setValues(setState);
    setDriver(setState);
  }, [validation]);

  const validate = useCallback(async () => {
    validation.handleSubmit();
    const errors = await validation.validateForm(driver);
    if (Object.keys(errors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [driver, validation]);

  const hide = useCallback((value: Driver | undefined) => {
    if (value) {
      dispatch(updateEntity(value));
    }

    props.hide(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = useCallback(async () => {
    try {
      await validate();
      setLoading(true);
      const response = driver.id
        ? await apiFactory(DriversApi).apiDriversIdPut({ id: driver.id!, driver: driver })
          .then(() => Promise.resolve<Driver | undefined>(undefined))
        : await apiFactory(DriversApi).apiDriversPost({ driver: driver });
      showSuccessToast('Driver saved');
      setSavedDriver(response || driver);
      if (response) {
        setDriver(response);
      }

      validation.resetForm({ values: response || driver, touched: {} });
      hide(response || driver);
    } catch (e: any) {
     showToast({severity: 'error', summary: 'Error', detail: e.toString()});
      throw e;
    } finally {
      setLoading(false);
    }
  }, [driver, validate, validation, hide]);

  const handleOnHide = useCallback(() => {
    if (!rightsUpdate && !rightsCreate) {
      hide(savedDriver);
      return;
    }

    if (validation.dirty) {
      logisticsConfirmDialog({
        message: 'Save changes?',
        closable: false,
        accept() {
          save();
        },
        reject() {
          hide(undefined);
        }
      });
    } else {
      hide(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide, driver, validation, rights]);

  const recognizeLicense = async (licenseFiles: LogisticsFile[] | null | undefined) => {
    if (!licenseFiles || licenseFiles.length <= (driver.licenseFile?.length || 0))
      return;

    const lastLicense = licenseFiles[licenseFiles.length - 1];
    setOcrStatus('progress');
    try {
      const recognizedDriver = await apiFactory(DriversApi)
        .apiDriversLicenseImageToTextGet({ imageId: lastLicense.id!});
      setValue(old => {
        return {
          ...old,
          gender: old.gender || recognizedDriver.gender,
          licenseNumber: old.licenseNumber || recognizedDriver.licenseNumber,
          firstName: old.firstName || recognizedDriver.firstName,
          lastName: old.lastName || recognizedDriver.lastName,
          licenceExpires: old.licenceExpires || recognizedDriver.licenceExpires,
          birthDate: old.birthDate || recognizedDriver.birthDate,
          licenseType: old.licenseType || recognizedDriver.licenseType,
          state: old.state || recognizedDriver.state,
          licenseState: old.licenseState || recognizedDriver.licenseState,
          zip: old.zip || recognizedDriver.zip,
          city: old.city || recognizedDriver.city,
          address: old.address || recognizedDriver.address,
        } as Driver;
      });
      setOcrStatus('success');
    } catch (error: any) {
      setOcrStatus('fail');
      console.error(error);
    }
  }

  useEffect(() => {
    if (!visible) return;

    setLoading(true)
    const driverPromise = props.data.id
      ? apiFactory(DriversApi).apiDriversIdGet({ id: props.data.id })
      : Promise.resolve(driver);
    Promise.all([
      driverPromise,
      apiFactory(StatesApi).apiStatesCodesGet(),
    ]).then(([loadedDriver, loadedStates]) => {
      setDriver(loadedDriver);
      setStates(loadedStates);
      validation.resetForm({ values: loadedDriver, touched: {}});
    }).catch(() => showToast(
      { severity: 'error', summary: 'Error', detail: 'Something went wrong'}))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);

  const ownerEdit = useMemo(() => {
    return ownerEditModal.visible && driver.vehicle?.owner
      ? <OwnerEdit
        data={{ ...driver.vehicle.owner }}
        loading={false}
        visible={ownerEditModal.visible}
        hide={owner => {
          if (owner && driver.vehicle) {
            driver.vehicle.owner = owner;
          }

          ownerEditModal.hide();
        }} />
      : <></>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ownerEditModal.visible, driver.vehicle?.owner]);

  const ownerBlock = useMemo(() => {
    if (!driver.vehicle?.owner) return <></>;

    return <>
      Owner:&nbsp;
      <LogisticsLink className='text-purple-600' onClick={() => ownerEditModal.show(owner!)}>
        {` ${owner?.companyName} (${owner?.firstName} ${owner?.lastName})`}
      </LogisticsLink>
    </>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver.vehicle?.owner]);

  const ownerSelect = useMemo(() => {
    if (!ownerSelectModal.visible) return <></>;

    return <OwnerSelect
      data={{}}
      loading={false}
      visible={ownerSelectModal.visible}
      hide={owner => {
        ownerSelectModal.hide();

        if (!owner) return;

        setValue(driver => {
          return {
            ...driver,
            firstName: owner.firstName || '',
            lastName: owner.lastName || '',
            zip: owner.zip || '',
            phones: owner.phones,
            city: owner.city || '',
            email: owner.email || '',
            state: owner.state,
            stateId: owner.stateId,
            address: owner.address || '',
            active: owner.active || null,
          }
        });
      }}
    />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerSelectModal.visible]);

  return (
    <>
      {ownerEdit}
      {ownerSelect}
      <LogisticsDialog
        visible={props.visible}
        onHide={handleOnHide}
        className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-8'
      >
        <header className='text-2xl w-full flex-wrap'>
          <span className='font-bold'>Driver #V{driver.id}</span>
          <span className='mx-4'>|</span>
          {ownerBlock}
          <Button
            hidden={!rightsUpdate}
            className='p-button-info p-button-text'
            label='copy info from owner'
            onClick={() => ownerSelectModal.show(undefined)}
          />
        </header>
        <div className='text-lg mt-2 mb-5'>
          Added by {driver.createdBy} {createDate}
        </div>
        <main className='grid logistics-dialog-edit-form__content'>
          <div className="ml-4">
            <Header>Driver info</Header>  
          </div>
          <Row>
            <Column className='col-4'>
              <Field label='Gender' required>
                <EditableDropdown
                  options={Gender}
                  optionLabel='name'
                  renderOption={gender => gender.name}
                  renderSelected={gender => gender?.name}
                  renderEmptySelected={() => '-'}
                  value={genderToGender(driver.gender)}
                  disabled={!rightsUpdate}
                  onChange={newValue => setValue(d => {
                    return { ...d, gender: newValue.id as any };
                  })}
                />
                <ErrorBox>{validation.errors.gender}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='First name' required>
                <StyledInputText
                  // @ts-ignore
                  value={driver.firstName}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, firstName: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.firstName}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='Last name' required>
                <StyledInputText
                  // @ts-ignore
                  value={driver.lastName}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, lastName: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.lastName}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label='Driver login'>
                <StyledInputText
                  // @ts-ignore
                  value={driver.driverLogin}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, driverLogin: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.driverLogin}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='E-mail address' required>
                <StyledInputText
                  // @ts-ignore
                  value={driver.email}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, email: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.email}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='Birth date' required>
                <LogisticsDateOnly
                  value={driver.birthDate}
                  disabled={!rightsUpdate}
                  onChange={newValue => setValue(d => {
                    return { ...d, birthDate: newValue };
                  })}
                />
                <ErrorBox>{validation.errors.birthDate}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label='Date hired' >
                <LogisticsDateOnly
                  value={driver.dateHired}
                  disabled={!rightsUpdate}
                  onChange={newValue => setValue(d => {
                    return { ...d, dateHired: newValue };
                  })}
                />
                <ErrorBox>{validation.errors.dateHired}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='Cell phone 1' required>
                <LogisticsPhoneInput
                  country={'us'}
                  value={getPhone(driver, 0)}
                  disabled={!rightsUpdate}
                  onChange={number => setValue(d => {
                    return { ...d, phones: replacePhone(driver, number, 0) };
                  })}
                />
                <ErrorBox>{validation.errors.phoneOne}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='Cell phone 2'>
                <LogisticsPhoneInput
                  country={'us'}
                  value={getPhone(driver, 1)}
                  disabled={!rightsUpdate}
                  onChange={number => setValue(d => {
                    return { ...d, phones: replacePhone(driver, number, 1) };
                  })}
                />
                <ErrorBox>{validation.errors.phoneTwo}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label='Address' required>
                <StyledInputText
                  // @ts-ignore
                  value={driver.address}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, address: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.address}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='City' required>
                <StyledInputText
                  // @ts-ignore
                  value={driver.city}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, city: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.city}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='State' required>
                <EditableDropdown
                  options={states}
                  optionLabel='name'
                  renderOption={state => state.code}
                  renderSelected={state => state?.code}
                  renderEmptySelected={() => '-'}
                  value={driver.state}
                  disabled={!rightsUpdate}
                  onChange={newValue => setValue(d => {
                    return { ...d, state: newValue };
                  })}
                  filter
                />
                <ErrorBox>{validation.errors.state}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label='Emergency contact name'>
                <StyledInputText
                  // @ts-ignore
                  value={driver.emergencyContactName}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, emergencyContactName: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.emergencyContactName}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='Emergency contact phone'>
                <StyledInputText
                  // @ts-ignore
                  value={driver.emergencyContactPhone}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, emergencyContactPhone: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.emergencyContactPhone}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='SSN'>
                <StyledInputText
                  // @ts-ignore
                  value={driver.ssn}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, ssn: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.ssn}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label='ZIP' required>
                <StyledInputText
                  // @ts-ignore
                  value={driver.zip}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, zip: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.zip}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <div className="ml-4">
            <Header>Driver License</Header>  
          </div>
          <Row>
            <Column className='col-4'>
              <Field label='License Number'>
                <StyledInputText
                  // @ts-ignore
                  value={driver.licenseNumber}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, licenseNumber: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.licenseNumber}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='License State'>
                <EditableDropdown
                  options={states}
                  optionLabel='name'
                  renderOption={state => state.code}
                  renderSelected={state => state?.code}
                  renderEmptySelected={() => '-'}
                  value={driver.licenseState}
                  disabled={!rightsUpdate}
                  onChange={newValue => setValue(d => {
                    return { ...d, licenseState: newValue };
                  })}
                  filter
                />
                <ErrorBox>{validation.errors.licenseState}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label='License Type'>
                <StyledInputText
                  // @ts-ignore
                  value={driver.licenseType}
                  disabled={!rightsUpdate}
                  onChange={e => setValue(d => {
                    return { ...d, licenseType: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.licenseType}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label='Driver license expires'>
                <LogisticsCalendar
                  value={driver.licenceExpires}
                  disabled={!rightsUpdate}
                  onChange={newValue => setValue(d => {
                    return { ...d, licenceExpires: newValue };
                  })}
                />
                <ErrorBox>{validation.errors.licenceExpires}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <VisibilityToggler visible={rights.documents}>
            <div className="ml-4">
              <Header>Documents<OcrStatusBar status={ocrStatus} /></Header>
            </div>
            <Row>
              <Column className='col-4'>
                <Field label='Driver license'>
                  <LogisticsFilesUpload
                    value={driver.licenseFile}
                    disabled={!rightsUpdate}
                    filePreviewWidget={filePreviewWidget}
                    onChange={newFiles => {
                      setValue(d => {
                        return { ...d, licenseFile: newFiles };
                      });
                      recognizeLicense(newFiles);
                    }}
                  />
                </Field>
              </Column>
              <Column className='col-4'>
                <Field label='Driving Record'>
                  <LogisticsFilesUpload
                    value={driver.drRecordFile}
                    disabled={!rightsUpdate}
                    filePreviewWidget={filePreviewWidget}
                    onChange={newFiles => setValue(d => {
                      return { ...d, drRecordFile: newFiles };
                    })}
                  />
                </Field>
              </Column>
              <Column className='col-4'>
                <Field label='Green card or Passport'>
                  <LogisticsFilesUpload
                    value={driver.passport}
                    disabled={!rightsUpdate}
                    filePreviewWidget={filePreviewWidget}
                    onChange={newFiles => setValue(d => {
                      return { ...d, passport: newFiles };
                    })}
                  />
                </Field>
              </Column>
            </Row>
          </VisibilityToggler>          
        </main>
        <footer className='flex justify-content-center w-full gap-3'>
          <VisibilityToggler visible={rightsUpdate || rightsCreate}>
            <Button
              label='SAVE'
              className='p-button-success'
              onClick={save}
            />
          </VisibilityToggler>
          <Button
            label='CLOSE'
            onClick={handleOnHide}
          />
        </footer>
        <CenteredSpinner visible={loading} />
        <LogisticsFilePreview filePreviewWidget={filePreviewWidget} />
      </LogisticsDialog>
    </>
  );
}

export default DriverEdit;