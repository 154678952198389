/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Owner,
  Request,
  State,
  Step3Documents,
  User,
  Vehicle,
} from '../models';
import {
    OwnerFromJSON,
    OwnerToJSON,
    RequestFromJSON,
    RequestToJSON,
    StateFromJSON,
    StateToJSON,
    Step3DocumentsFromJSON,
    Step3DocumentsToJSON,
    UserFromJSON,
    UserToJSON,
    VehicleFromJSON,
    VehicleToJSON,
} from '../models';

export interface ApiRegistrationCompanyNameGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRegistrationContractGetRequest {
    ownerId?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRegistrationManagersGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRegistrationOwnerGetRequest {
    employerId?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRegistrationRequestGetRequest {
    id?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRegistrationRequestPutRequest {
    request?: Request;
}

export interface ApiRegistrationSignDocumentsPostRequest {
    signature?: Blob;
    ownerId?: number;
    ownerDocumentIds?: Array<number>;
}

export interface ApiRegistrationStage1PostRequest {
    userReferralId?: string;
    request?: Request;
}

export interface ApiRegistrationStage2PostRequest {
    vehicle?: Vehicle;
}

export interface ApiRegistrationStage3PostRequest {
    step3Documents?: Step3Documents;
}

export interface ApiRegistrationStage4PostRequest {
    ownerId?: number;
}

export interface ApiRegistrationStatesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiRegistrationW9GetRequest {
    ownerId?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class RegistrationApi extends runtime.BaseAPI {

    /**
     */
    async apiRegistrationCompanyNameGetRaw(requestParameters: ApiRegistrationCompanyNameGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/companyName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiRegistrationCompanyNameGet(requestParameters: ApiRegistrationCompanyNameGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiRegistrationCompanyNameGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationContractGetRaw(requestParameters: ApiRegistrationContractGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.ownerId !== undefined) {
            queryParameters['ownerId'] = requestParameters.ownerId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/contract`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiRegistrationContractGet(requestParameters: ApiRegistrationContractGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiRegistrationContractGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationManagersGetRaw(requestParameters: ApiRegistrationManagersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/managers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async apiRegistrationManagersGet(requestParameters: ApiRegistrationManagersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.apiRegistrationManagersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationOwnerGetRaw(requestParameters: ApiRegistrationOwnerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Owner>> {
        const queryParameters: any = {};

        if (requestParameters.employerId !== undefined) {
            queryParameters['employerId'] = requestParameters.employerId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/owner`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnerFromJSON(jsonValue));
    }

    /**
     */
    async apiRegistrationOwnerGet(requestParameters: ApiRegistrationOwnerGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Owner> {
        const response = await this.apiRegistrationOwnerGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationRequestGetRaw(requestParameters: ApiRegistrationRequestGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Request>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/request`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async apiRegistrationRequestGet(requestParameters: ApiRegistrationRequestGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Request> {
        const response = await this.apiRegistrationRequestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationRequestPutRaw(requestParameters: ApiRegistrationRequestPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Request>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/request`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async apiRegistrationRequestPut(requestParameters: ApiRegistrationRequestPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Request> {
        const response = await this.apiRegistrationRequestPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationSignDocumentsPostRaw(requestParameters: ApiRegistrationSignDocumentsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.signature !== undefined) {
            formParams.append('signature', requestParameters.signature as any);
        }

        if (requestParameters.ownerId !== undefined) {
            formParams.append('ownerId', requestParameters.ownerId as any);
        }

        if (requestParameters.ownerDocumentIds) {
            requestParameters.ownerDocumentIds.forEach((element) => {
                formParams.append('ownerDocumentIds', element as any);
            })
        }

        const response = await this.request({
            path: `/api/registration/signDocuments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistrationSignDocumentsPost(requestParameters: ApiRegistrationSignDocumentsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiRegistrationSignDocumentsPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiRegistrationStage1PostRaw(requestParameters: ApiRegistrationStage1PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Request>> {
        const queryParameters: any = {};

        if (requestParameters.userReferralId !== undefined) {
            queryParameters['userReferralId'] = requestParameters.userReferralId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/stage1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async apiRegistrationStage1Post(requestParameters: ApiRegistrationStage1PostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Request> {
        const response = await this.apiRegistrationStage1PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationStage2PostRaw(requestParameters: ApiRegistrationStage2PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/stage2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleToJSON(requestParameters.vehicle),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     */
    async apiRegistrationStage2Post(requestParameters: ApiRegistrationStage2PostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.apiRegistrationStage2PostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationStage3PostRaw(requestParameters: ApiRegistrationStage3PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/stage3`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Step3DocumentsToJSON(requestParameters.step3Documents),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistrationStage3Post(requestParameters: ApiRegistrationStage3PostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiRegistrationStage3PostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiRegistrationStage4PostRaw(requestParameters: ApiRegistrationStage4PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.ownerId !== undefined) {
            queryParameters['ownerId'] = requestParameters.ownerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/stage4`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistrationStage4Post(requestParameters: ApiRegistrationStage4PostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiRegistrationStage4PostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiRegistrationStatesGetRaw(requestParameters: ApiRegistrationStatesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<State>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StateFromJSON));
    }

    /**
     */
    async apiRegistrationStatesGet(requestParameters: ApiRegistrationStatesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<State>> {
        const response = await this.apiRegistrationStatesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationW9GetRaw(requestParameters: ApiRegistrationW9GetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.ownerId !== undefined) {
            queryParameters['ownerId'] = requestParameters.ownerId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/registration/w9`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiRegistrationW9Get(requestParameters: ApiRegistrationW9GetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiRegistrationW9GetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
