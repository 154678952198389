import React, { useState } from 'react';
import EditableDropdown from '../../../components/form/EditableDropdown';

const foundAtOptions = [
  'YouTube',
  'FaceBook',
  'Indeed',
  'Google ads',
]
function FinalStage() {
  const [foundAt, setFoundAt] = useState('');
  return <div className='flex flex-column align-items-center gap-3'>
    <header className="text-2xl flex-wrap">
      <span className="font-bold">Registration is complete</span>
    </header>
    <div>Thank you for choosing us!</div>
    <i className='pi pi-check-circle text-8xl text-green-300' />
    <span>You can view all your signed documents in our portal</span>
    <span>How did you find about us?</span>
    <EditableDropdown
      options={foundAtOptions}
      optionLabel='-'
      renderOption={option => option}
      renderSelected={option => option}
      renderEmptySelected={() => 'Select an item'}
      value={foundAtOptions.find(x => x === foundAt)}
      onChange={value => setFoundAt(value)}
    />
  </div>
}

export default FinalStage;