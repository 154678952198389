import styled from '@emotion/styled';
import { InputMask } from 'primereact/inputmask';
import { focusedInputStyle } from './Styles';

const UnstyledInputMask = styled(InputMask)`
  & {
    display: block;
    flex-basis: unset;
    flex-grow: unset;
    flex-shrink: unset;
    border-width: 0;
    width: 0px;
  }

  &:enabled:focus {
    ${focusedInputStyle}
  }
`

export default UnstyledInputMask;