import { Bid, BidsApi } from '../../generated-api';
import { createEntitySlice, EntityState, IQueryParams, PageResponse } from '../../shared';
import { apiFactory } from '../../shared';
import { createAction, createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import paginationApiFactory from '../../shared/util/paginationApiFactory';

const defaultValue: Readonly<Bid> = {
  read: false,
};

const initialState: EntityState<Bid> = {
  loading: false,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
}

export const getBidsPage = createAsyncThunk(
  "Bid/fetch_entity_page",
  async ({ page, size, sort } : IQueryParams) => {
    let totalCount = 0;
    const api = paginationApiFactory(BidsApi, total => totalCount = total);
    const entities = await api.apiBidsGet({ page, size, sort });
    return { entities, totalCount} as PageResponse<Bid>;
  }
);

export const createEntity = createAsyncThunk(
  "Bid/create_entity",
  async (entity: Bid) => {
    return await apiFactory(BidsApi).apiBidsPost({bid: entity});
  }
);

export const markAsRead = createAction(
  'Bid/mark_as_read',
  (bidId: number) => ({ payload: bidId }),
);

export const markAsUnRead = createAction(
  'Bid/mark_as_un_read',
  (bidId: number) => ({ payload: bidId }),
);

export const hasNewReplies = createAction(
  'Bid/has_new_replies',
  (bidId: number) => ({ payload: bidId }),
);

export const repliesViewed = createAction(
  'Bid/replies_viewed',
  (bidId: number) => ({ payload: bidId }),
);

export const readAll = createAction(
  'Bid/read_all',
  () => ({ payload: undefined }),
);

const setReadFlag = (state: EntityState<Bid>, bidId: number, flagValue: boolean) => {
  const bid = state.entities?.find(x => x.id === bidId);
  if (!bid) {
    return state;
  }

  const newEntities = (state.entities || []).map(entity => {
    if (entity.id !== bidId) {
      return entity;
    }

    return { ...entity, read: flagValue };
  });

  return { ...state, entities: newEntities };
}

const setHasNewRepliesFlag = (state: EntityState<Bid>, bidId: number, flagValue: boolean) => {
  const bid = state.entities?.find(x => x.id === bidId);
  if (!bid) {
    return state;
  }

  const newEntities = (state.entities || []).map(entity => {
    if (entity.id !== bidId) {
      return entity;
    }

    return { ...entity, hasNewReplies: flagValue };
  });

  return { ...state, entities: newEntities };
}

export const BidsSlice = createEntitySlice({
  name: 'Bids',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(markAsRead, (state, action) => {
        const bidId = action.payload;
        return setReadFlag(state, bidId, true);
      })
      .addCase(markAsUnRead, (state, action) => {
        const bidId = action.payload;
        return setReadFlag(state, bidId, false);
      })
      .addCase(hasNewReplies, (state, action) => {
        const bidId = action.payload;
        return setHasNewRepliesFlag(state, bidId, true);
      })
      .addCase(repliesViewed, (state, action) => {
        const bidId = action.payload;
        return setHasNewRepliesFlag(state, bidId, false);
      })
      .addCase(readAll, state => {
        state.entities.forEach(bid => {
          bid.read = true;
          bid.hasNewReplies = false;
        });

        return state;
      })
      .addMatcher(isFulfilled(getBidsPage), (state, action) => {
      const data = action.payload;
      return {
        ...state,
        loading: false,
        entities: data.entities,
        totalItems: data.totalCount,
      };
    })
    .addMatcher(isPending(createEntity),
      (state) => {
        return {
          ...state,
          errorMessage: undefined,
          updateSuccess: false,
          updating: true,
        };
      }
    )
    .addMatcher(isFulfilled(createEntity),
      (state, action) => {
        const data = action.payload;
        return {
          ...state,
          updating: false,
          loading: false,
          updateSuccess: true,
          entity: data,
        };
      }
    )
  }
});

export default BidsSlice.reducer;

