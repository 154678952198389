import React from 'react';
import { Outlet } from 'react-router-dom';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import 'primeflex/primeflex.css'; //another core css
import './css/App.css';
import './css/PrimereactExtended.css'
import './css/BackgroundColours.css'
import { LogisticsConfirmDialog } from '../components/LogisticsConfifmDialog';
import LogisticsToast from '../components/LogisticsToast';
import { ConfigProvider } from 'antd';
// Added dayjs locale import according to the solution on the issue about changing starting day of the week
// https://github.com/ant-design/ant-design/issues/5605
import 'dayjs/locale/en-gb';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import locale from 'antd/lib/locale-provider/en_GB';
import dayjs from 'dayjs';
import Env from '../config/Env';

dayjs.locale('en-gb');
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(Env.app.timezone);

function AppConfig() {
  return <ConfigProvider
    locale={locale}
    theme={{
      components: {
        DatePicker: {
          colorPrimary: '#00b96b',
          fontSize: 11.75,
        },
      },
    }}
  >
    <Outlet />
    <LogisticsConfirmDialog />
    <LogisticsToast />
  </ConfigProvider>
}

export default AppConfig;