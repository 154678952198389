import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    & {
        position: relative;
    }
    
    & .tooltip-box {
        position: absolute;
        bottom: 110%;
        left: 50%;
        transform: translate(-50%, 0);

        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        text-align: center;
        padding: 0;
        border-radius: 6px;
        z-index: 1;
        white-space: nowrap;

        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover .tooltip-box {
        opacity: 1;
    }
`

type LogisticsTooltipProps = {
  label: React.ReactNode;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function LogisticsTooltip(props: LogisticsTooltipProps) {
  return <Container>
    <div className='tooltip-box'>
      {props.label}
    </div>
    {props.children}
  </Container>
}

export default LogisticsTooltip;