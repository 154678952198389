import styled from '@emotion/styled';
import { Spin, UploadFile } from 'antd';
import { Galleria } from 'primereact/galleria';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LogisticsFile } from '../../../../generated-api';
import LogisticsPdf from '../pdf/LogisticsPdf';
import { FilePreviewWidget } from './filePreviewWidget';
import { LoadingOutlined } from '@ant-design/icons';
import useFileFetch from './useFileFetch';
import heic2any from 'heic2any';

const StyledGalleria = styled(Galleria)`
  & .p-galleria-item-wrapper {
    flex-grow: 1;
    min-height: 0;
    position: static;
  }
  & .p-galleria-item-container {
    position: static;
  }
  & .p-galleria-thumbnail-container {
    display: flex;
    flex-grow: 0;
  }
  & .p-galleria-thumbnail-items {
      display: flex;
      align-items: baseline;
      justify-content: space-evenly;
  }
  & .p-galleria-content {
    height: 100vh;
    max-height: 100vh;
  }
  & .p-galleria-thumbnail-items-container *,
  & .p-galleria-thumbnail-container * {
    user-select: none;
  }
`

export interface LogisticsFilePreviewProps {
  filePreviewWidget?: FilePreviewWidget,
}

export default function LogisticsFilePreview(props : LogisticsFilePreviewProps) {
  // Disable all dependencies checks because i have no idea anymore how to manage them properly.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fileGroups = props.filePreviewWidget
    ? props.filePreviewWidget(state => state.files)
    : [];
  const [downloadedFiles, setDownloadedFiles] = useState<UploadFile<LogisticsFile>[]>([]);
  const files = useMemo(() => {
    const incomingFiles =  fileGroups.flat();
    incomingFiles.forEach(file => {
      if (file.url) return;

      const downloadedFile = downloadedFiles.find(x => x.uid === file.uid);
      file.url = downloadedFile?.url;
      file.thumbUrl = downloadedFile?.thumbUrl;
    });

    return incomingFiles;
  }, [fileGroups, downloadedFiles]);

  const activeFile = props.filePreviewWidget
    ? props.filePreviewWidget(state => state.activeFile)
    : undefined;
  const endPreview = props.filePreviewWidget
    ? props.filePreviewWidget(state => state.closePreview)
    : () => { };
  const galleriaRef = useRef<Galleria>(null);
  const [index, setIndex] = useState(0);

  const findInitialIndex = (activeFile?: UploadFile<LogisticsFile>) => {
    if (!activeFile)
      return 0;

    const indexByRef = files.indexOf(activeFile);
    if (indexByRef !== -1)
      return indexByRef;

    const indexById = files.findIndex(x => x.uid === activeFile!.uid);
    if (indexById !== -1)
      return indexById;

    return 0;
  };

  const fetchFile = useFileFetch();
  const fetchFiles = () => {
    for (const file of files) {
      if (file.thumbUrl || file.url) continue;
      fetchFile(file)
        .then(blob => {
          const fetchedFile = {
            ...file,
            originFileObj: blob,
            url: URL.createObjectURL(blob),
          } as UploadFile<LogisticsFile>;
          return fetchedFile;
        })
        .then(fetchedFile => {
          if (!file.fileName?.match(/\.(heic|heif)$/))
            return fetchedFile;

          return heic2any({ blob: fetchedFile.originFileObj!, toType: 'image/jpeg'})
            .then(heicPreview => {
              if (heicPreview instanceof Blob) {
                fetchedFile.thumbUrl = URL.createObjectURL(heicPreview);
              } else if (heicPreview.length) {
                fetchedFile.thumbUrl = URL.createObjectURL(heicPreview[0]);
              }

              return fetchedFile;
            });
        })
        .then(fetchedFile => {
          setDownloadedFiles(prev => prev.concat(fetchedFile));
        })
        .catch(reason => {
          console.error('failed to fetch file', reason);
        });
    }
  }

  // Show/hide galleria
  const isPreviewing = props.filePreviewWidget
    ? props.filePreviewWidget(state => state.isPreviewing)
    : false;
  useEffect(() => {
    if (isPreviewing) {
      const initialIndex = findInitialIndex(activeFile);
      setIndex(initialIndex);
      galleriaRef.current?.show();
      fetchFiles();
    } else {
      galleriaRef.current?.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviewing, activeFile]);

  return <StyledGalleria
    ref={galleriaRef}
    value={files}
    item={itemTemplate}
    thumbnail={thumbnailTemplate}
    fullScreen
    showItemNavigators
    activeIndex={index}
    onItemChange={(e) => setIndex(e.index)}
    style={{ maxWidth: '80%' }}
    numVisible={10}
    onHide={() => endPreview()}
  />
};

const Spinner = () => <Spin indicator={<LoadingOutlined spin />} />;

const thumbnailTemplate = (item : UploadFile<LogisticsFile>) => {
  const url = item.thumbUrl || item.url;
  if (isImage(item.fileName) && url)
    return <img src={url} alt='' style={{ width: '90%', maxHeight: '100px', display: 'flex' }} />;

  if (url && isPdf(item.fileName))
    return <span className='text-white-alpha-90'>{item.fileName}</span>;

  return <Spinner />
}

const itemTemplate = (item : UploadFile<LogisticsFile>) => {
  const url = item.thumbUrl || item.url;
  if (isImage(item.fileName) && url)
    return <img src={url} alt='' style={{ maxWidth: '100%', maxHeight: '100%' }} />;

  if (url && isPdf(item.fileName))
    return <LogisticsPdf url={url} scale={'page-fit'} height={'99%'} width={'100vw'} key={url}/>;

  return <Spinner />
}

function isImage(filename?: string | null) {
  if (!filename)
    return false;

  const regExp = new RegExp(/\.(jpg|jpeg|png|gif|heic|heif)$/i);
  return regExp.test(filename);
}

function isPdf(filename?: string | null) {
  if (!filename)
    return false;

  const regExp = new RegExp(/\.pdf$/i);
  return regExp.test(filename);
}
