import React from 'react';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import StyledInputNumber from '../../form/StyledInputNumber';

function ServerInputText(props: ColumnFilterElementTemplateOptions) {
  return (
    <StyledInputNumber
      value={props.value || null}
      onChange={e => props.filterApplyCallback(e.value?.toString() || '')}
      inputClassName='w-full'
      placeholder='Filter...'
    />
  );
}

export default ServerInputText;