import { useState } from "react";

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import './Login.css'

import { history } from '../../shared';
import useAuth from './AuthHook';

export const Login = (props: any) => {
  const { auth, login } = useAuth();
  if (auth.isAuthenticated) {
    // Redirect to default tab if already logged in
    history.navigate('/');
  }
  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleClick = () => {
    const rememberMe = true;
    login(userName, password, rememberMe);
  };

  return (
    <div className="flex align-items-center justify-content-center overflow-hidden absolute card-container">
      <div className="p-card align-items-center justify-content-center p-4 shadow-2 border-round w-2 min-w-max max-w-25rem sm:w-full">
          <div className="card-header">Log in</div>
          <div className="card-content">
              <span className="p-float-label card-field">
                <InputText id="userName" type="text" className="w-full" value={userName} onChange={(e) => setUsername(e.target.value)} />
                <label htmlFor="userName">Username</label>
              </span>

              <span className="p-float-label card-field">
                <InputText id="password" type="password" className="w-full" value={password} onChange={(e) => setPassword(e.target.value)} />
                <label htmlFor="password">Password</label>
              </span>

              <Button label="LET'S GO" className="p-button-text w-full" onClick={handleClick} />
          </div>
      </div>
    </div>
  );
};

export default Login;