/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ESource,
  LoadedOrder,
} from '../models';
import {
    ESourceFromJSON,
    ESourceToJSON,
    LoadedOrderFromJSON,
    LoadedOrderToJSON,
} from '../models';

export interface ApiLoadedOrdersArchiveGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiLoadedOrdersGetRequest {
    pickUpAt?: Array<string>;
    deliverTo?: Array<string>;
    vehicle?: string;
    miles?: string;
    match?: string;
    brokerage?: string;
    source?: Array<ESource>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiLoadedOrdersIdDeleteRequest {
    id: number;
}

export interface ApiLoadedOrdersIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiLoadedOrdersIdPutRequest {
    id: number;
    loadedOrder?: LoadedOrder;
}

export interface ApiLoadedOrdersMarkAsReadGetRequest {
    id?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiLoadedOrdersPostRequest {
    loadedOrder?: LoadedOrder;
}

export interface ApiLoadedOrdersRecentGetRequest {
    pickUpAt?: Array<string>;
    deliverTo?: Array<string>;
    vehicle?: string;
    miles?: string;
    match?: string;
    brokerage?: string;
    source?: Array<ESource>;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class LoadedOrdersApi extends runtime.BaseAPI {

    /**
     */
    async apiLoadedOrdersArchiveGetRaw(requestParameters: ApiLoadedOrdersArchiveGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/loaded-orders/archive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiLoadedOrdersArchiveGet(requestParameters: ApiLoadedOrdersArchiveGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiLoadedOrdersArchiveGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiLoadedOrdersGetRaw(requestParameters: ApiLoadedOrdersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LoadedOrder>>> {
        const queryParameters: any = {};

        if (requestParameters.pickUpAt) {
            queryParameters['pickUpAt'] = requestParameters.pickUpAt;
        }

        if (requestParameters.deliverTo) {
            queryParameters['deliverTo'] = requestParameters.deliverTo;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.miles !== undefined) {
            queryParameters['miles'] = requestParameters.miles;
        }

        if (requestParameters.match !== undefined) {
            queryParameters['match'] = requestParameters.match;
        }

        if (requestParameters.brokerage !== undefined) {
            queryParameters['brokerage'] = requestParameters.brokerage;
        }

        if (requestParameters.source) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/loaded-orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoadedOrderFromJSON));
    }

    /**
     */
    async apiLoadedOrdersGet(requestParameters: ApiLoadedOrdersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LoadedOrder>> {
        const response = await this.apiLoadedOrdersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLoadedOrdersIdDeleteRaw(requestParameters: ApiLoadedOrdersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiLoadedOrdersIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/loaded-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiLoadedOrdersIdDelete(requestParameters: ApiLoadedOrdersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiLoadedOrdersIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiLoadedOrdersIdGetRaw(requestParameters: ApiLoadedOrdersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadedOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiLoadedOrdersIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/loaded-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadedOrderFromJSON(jsonValue));
    }

    /**
     */
    async apiLoadedOrdersIdGet(requestParameters: ApiLoadedOrdersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadedOrder> {
        const response = await this.apiLoadedOrdersIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLoadedOrdersIdPutRaw(requestParameters: ApiLoadedOrdersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiLoadedOrdersIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/loaded-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LoadedOrderToJSON(requestParameters.loadedOrder),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiLoadedOrdersIdPut(requestParameters: ApiLoadedOrdersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiLoadedOrdersIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiLoadedOrdersMarkAsReadGetRaw(requestParameters: ApiLoadedOrdersMarkAsReadGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/loaded-orders/markAsRead`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiLoadedOrdersMarkAsReadGet(requestParameters: ApiLoadedOrdersMarkAsReadGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiLoadedOrdersMarkAsReadGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiLoadedOrdersPostRaw(requestParameters: ApiLoadedOrdersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadedOrder>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/loaded-orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoadedOrderToJSON(requestParameters.loadedOrder),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadedOrderFromJSON(jsonValue));
    }

    /**
     */
    async apiLoadedOrdersPost(requestParameters: ApiLoadedOrdersPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadedOrder> {
        const response = await this.apiLoadedOrdersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLoadedOrdersRecentGetRaw(requestParameters: ApiLoadedOrdersRecentGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LoadedOrder>>> {
        const queryParameters: any = {};

        if (requestParameters.pickUpAt) {
            queryParameters['pickUpAt'] = requestParameters.pickUpAt;
        }

        if (requestParameters.deliverTo) {
            queryParameters['deliverTo'] = requestParameters.deliverTo;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.miles !== undefined) {
            queryParameters['miles'] = requestParameters.miles;
        }

        if (requestParameters.match !== undefined) {
            queryParameters['match'] = requestParameters.match;
        }

        if (requestParameters.brokerage !== undefined) {
            queryParameters['brokerage'] = requestParameters.brokerage;
        }

        if (requestParameters.source) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/loaded-orders/recent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoadedOrderFromJSON));
    }

    /**
     */
    async apiLoadedOrdersRecentGet(requestParameters: ApiLoadedOrdersRecentGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LoadedOrder>> {
        const response = await this.apiLoadedOrdersRecentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
