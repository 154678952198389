import { useRef } from 'react';

// Wrap state with mutable ref
// and synchronize asynchronous updateStateCallback calls. 
function useRefState<TState>(
  state: TState,
  updateStateCallback: (state: TState) => any
) {
  const stateRef = useRef<TState>();
  stateRef.current = state;

  const update = (cb : (old: TState) => TState) => {
    stateRef.current = cb(stateRef.current!);
    updateStateCallback(stateRef.current);
  }
  return [stateRef.current, update] as const;
}

export default useRefState;