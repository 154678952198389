import { ApiAdminUsersGetRequest, UserDto, UsersApi } from '../../generated-api';
import { apiFactory, createEntitySlice, EntityState, PageResponse } from '../../shared';
import { createAction, createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import paginationApiFactory from '../../shared/util/paginationApiFactory';

const defaultValue: Readonly<UserDto> = {
  login: '',
  activated: false
 };

const initialState: EntityState<UserDto> = {
  loading: false,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
}

export const getUsersPage = createAsyncThunk(
  "User/fetch_entity_page",
  async (params: ApiAdminUsersGetRequest) => {
    let totalCount = 0;
    const api = paginationApiFactory(UsersApi, total => totalCount = total);
    const entities = await api.apiAdminUsersGet(params);
    return { entities, totalCount } as PageResponse<UserDto>;
  }
);

export const updateUser = createAction(
  'User/update_entity',
  (entity: UserDto) => {
    return { payload: entity };
  }
);

export const deleteUser = createAsyncThunk(
  "User/delete_entity",
  async (entity: UserDto) => {
    await apiFactory(UsersApi).apiAdminUsersDeleteLoginPut({login: entity.login!});
    return { entity };
  }
)

export const UsersSlice = createEntitySlice({
  name: 'Users',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateUser, (state, action) => {
      const entity = action.payload;
      const listEntity = state.entities.find(x => x.id === entity.id);
      let result : EntityState<UserDto>;
      if (!listEntity) {
        result = {
          ...state,
          entities: [...state.entities, entity]
        };
      } else {
        result = {
          ...state,
          entities: state.entities.map(x => x === listEntity ? entity : x),
        };
      }

      return result;
    }).addMatcher(isFulfilled(deleteUser), (state, action) => {
      const entity = action.payload.entity;
      const listEntity = state.entities.find(x => x.id === entity.id);
      let result : EntityState<UserDto>;
      if (listEntity) {
        const listWithoutEntity = state.entities.filter(x => x.id !== listEntity.id);
        result = {
          ...state,
          entities: listWithoutEntity,
        };
      } else {
        result = {
          ...state,
          entities: state.entities,
        };
      }

      return result;
    }).addMatcher(isFulfilled(getUsersPage), (state, action) => {
      const data = action.payload;

      return {
        ...state,
        loading: false,
        entities: data.entities,
        totalItems: data.totalCount,
      }
    })
  }
});

export default UsersSlice.reducer;