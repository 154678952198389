import React, { useEffect } from 'react';
import { connect, useHubConnection } from '../../hooks/SignalR';

function HubConnector() {
  const hubConnection = useHubConnection();
  useEffect(() => {
    connect(hubConnection);
  });

  return <></>;
}

export default HubConnector;