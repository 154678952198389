/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ENotificationType } from './ENotificationType';
import {
    ENotificationTypeFromJSON,
    ENotificationTypeFromJSONTyped,
    ENotificationTypeToJSON,
} from './ENotificationType';
import type { NotificationStatus } from './NotificationStatus';
import {
    NotificationStatusFromJSON,
    NotificationStatusFromJSONTyped,
    NotificationStatusToJSON,
} from './NotificationStatus';
import type { NotificationTarget } from './NotificationTarget';
import {
    NotificationTargetFromJSON,
    NotificationTargetFromJSONTyped,
    NotificationTargetToJSON,
} from './NotificationTarget';

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    date?: Date;
    /**
     * 
     * @type {ENotificationType}
     * @memberof Notification
     */
    type?: ENotificationType;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    data?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    read?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    deleted?: boolean | null;
    /**
     * 
     * @type {Array<NotificationTarget>}
     * @memberof Notification
     */
    targets?: Array<NotificationTarget> | null;
    /**
     * 
     * @type {Array<NotificationStatus>}
     * @memberof Notification
     */
    notificationStatuses?: Array<NotificationStatus> | null;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    id?: number;
}

/**
 * Check if a given object implements the Notification interface.
 */
export function instanceOfNotification(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'type': !exists(json, 'type') ? undefined : ENotificationTypeFromJSON(json['type']),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'targets': !exists(json, 'targets') ? undefined : (json['targets'] === null ? null : (json['targets'] as Array<any>).map(NotificationTargetFromJSON)),
        'notificationStatuses': !exists(json, 'notificationStatuses') ? undefined : (json['notificationStatuses'] === null ? null : (json['notificationStatuses'] as Array<any>).map(NotificationStatusFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'type': ENotificationTypeToJSON(value.type),
        'data': value.data,
        'read': value.read,
        'deleted': value.deleted,
        'targets': value.targets === undefined ? undefined : (value.targets === null ? null : (value.targets as Array<any>).map(NotificationTargetToJSON)),
        'notificationStatuses': value.notificationStatuses === undefined ? undefined : (value.notificationStatuses === null ? null : (value.notificationStatuses as Array<any>).map(NotificationStatusToJSON)),
        'id': value.id,
    };
}

