import styled from '@emotion/styled';

export const NotificationArrowButton = styled.button`
  &, &:active, &:hover {
    all: unset;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &:active, &:hover {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    background-origin: padding-box;
  }
`;