import { ApiVehiclesUnitsGetRequest, Vehicle, VehiclesApi } from '../../../generated-api';
import { createEntitySlice, EntityState, PageResponse } from '../../../shared';
import { createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import paginationApiFactory from '../../../shared/util/paginationApiFactory';

const defaultValue: Readonly<Vehicle> = {

}

const initialState: EntityState<Vehicle> = {
  loading: false,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
}

export const getUnitsPage = createAsyncThunk(
  "unit/fetch_page",
  async (param: ApiVehiclesUnitsGetRequest) => {
    let totalCount = 0;
    const api = paginationApiFactory(VehiclesApi, total => totalCount = total);
    const entities = await api.apiVehiclesUnitsGet(param);
    return { entities, totalCount } as PageResponse<Vehicle>;
  }
);

export const UnitSlice = createEntitySlice({
    name: 'unit',
    initialState,
    extraReducers(builder) {
      builder
        .addMatcher(isFulfilled(getUnitsPage), (state, action) => {
          const data = action.payload;

          return {
            ...state,
            loading: false,
            entities: data.entities,
            totalItems: data.totalCount,
          }
        });
    }
  },
);

export default UnitSlice.reducer;