import React, { useCallback, useEffect, useMemo } from 'react';
import { DashboardApi } from '../../../generated-api';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { apiFactory } from '../../../shared';
import DashboardPanel from '../components/DashboardPanel';
import WidgetToolbar, { WidgetFilters } from '../components/WidgetToolbar';
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import colorPalette from '../components/DispatcherStatisticsColorPalette';
import { Formatter, Payload } from 'recharts/types/component/DefaultTooltipContent';
import useWidget from '../hooks/WidgetHook';
import { today } from '../../../shared/util/dateUtils';
import FileSaver from 'file-saver';
import { showSuccessToast } from '../../../components/LogisticsToast';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { getDashboardDispatchersDispatcherStatistics } from '../DashboardSlice';
import { getPeriodFromWidgetFilters } from './utils/widgets-utils';

dayjs.extend(advancedFormat);

const loadsSuffix = '_loads';

function DispatchersStatistics() {
  const appDispatch = useAppDispatch();
  const dispatchers = useAppSelector(state => state.dashboard.dispatcherState?.dispatcherStatistics);
  const initialFilters : WidgetFilters = {
    periodType: 'Week',
    from: today().add(-11, 'week').toDate(),
    to: today().toDate(),
    dispatcherIds: dispatchers?.map(x => x.id || '') || [],
  };

  const { data, filters, loadData, setFilters } = useWidget({
    initialFilters: initialFilters,
    initialWidgetData: {},
    loadWidgetData: filters => apiFactory(DashboardApi)
      .apiDashboardDispatcherStatisticsGet(filters),
    usesDispatchers: true,
    dispatcherState: state => state.dashboard.dispatcherState?.dispatcherStatistics,
    getDispatcherSliceAction: getDashboardDispatchersDispatcherStatistics,
  });

  useEffect(() => {
    appDispatch(getDashboardDispatchersDispatcherStatistics(initialFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const joinedChartData = useMemo(() => {
    if (!data?.periods?.length) return [];

    return data.periods.map((period, index) => {
      let dataForPeriod = { period };
      (dataForPeriod as any)[period + loadsSuffix] = data.totalLoads
        ? data.totalLoads[index]
        : 0;
      data.dispatchers?.forEach(dispatcherData => {
        const profits = dispatcherData?.loadAndProfitsByPeriod?.map(lap => lap.profit);
        const loads = dispatcherData?.loadAndProfitsByPeriod?.map(lap => lap.load);
        (dataForPeriod as any)[dispatcherData.dispatcherId as any] = profits
          ? profits[index]
          : 0;
        (dataForPeriod as any)[dispatcherData.dispatcherId + loadsSuffix] = loads
          ? loads[index]
          : 0;
      });

      return dataForPeriod;
    });
  }, [data]);

  const lastPartsOfColumns = useMemo(() => {
    const dispatcherIds = dispatchers?.map(d => d.id);
    return joinedChartData.map(period => {
      const fieldsInReverse = Object.keys(period).filter(key => dispatcherIds?.some(d => d === key)).reverse();
      let firstNotNull;
      for (var field of fieldsInReverse) {
        // @ts-ignore
        if (Number(period[field]) !== 0) {
          firstNotNull = field;
          break;
        }
      }
      return firstNotNull;
    });
  }, [joinedChartData, dispatchers]);

  const dispatchersBars = useMemo(() => {
    if (!filters?.dispatcherIds?.length || !dispatchers) return <></>;

    return filters.dispatcherIds.map((dispatcherId, index) => {
      const dispatcher = dispatchers.find(d => d.id === dispatcherId);
      if (!dispatcher) return <></>;

      const opacityLevels = ["", "b3", "80"]; // 100%, 70%, 50%
      const currentOpacity = opacityLevels[Math.floor(index / colorPalette.length) % opacityLevels.length];
      const color = colorPalette[index % (colorPalette.length - 1)] + currentOpacity;

      return <Bar
        dataKey={dispatcherId}
        name={`${dispatcher.firstName} ${dispatcher.lastName}`}
        stackId='a'
        key={dispatcherId}
        fill={color}
      >
        { joinedChartData.map((entry, index) => {
          const dispatcherIdOfLastPartOfColumn = lastPartsOfColumns[index];
          return (
          // @ts-ignore
          <Cell key={`cell-${index}`} radius={dispatcherId === dispatcherIdOfLastPartOfColumn ? [5, 5, 0, 0] : undefined} />
        )})}
      </Bar>
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.dispatcherIds, lastPartsOfColumns]);

  const tooltipValueFormatter: Formatter<number, string> = useCallback((value, name, props) => {
    const loads = props.payload[props.dataKey + loadsSuffix] || 0;
    return `${value} (${loads})`;
  }, []);

  const tooltipLabelFormatter = useCallback((label: any, payload: Array<Payload<number, string>>) => {
    const loads = payload.find(x => x)?.payload[label + loadsSuffix] || 0;
    return `${label} (${loads})`;
  }, []);

  const onExport = async (exportFilters?: WidgetFilters) => {
    const response = await apiFactory(DashboardApi).apiDashboardDispatcherStatisticsExportGet(exportFilters);
    const period = getPeriodFromWidgetFilters(exportFilters);
    FileSaver.saveAs(response!, `Dispatch Statistics ${period}.xlsx`);
    showSuccessToast('Data successfully exported');
  };

  return <DashboardPanel>
    <header className='flex'>
        <span className='text-3xl font-medium white-space-nowrap flex justify-content-start align-items-center flex-grow-1 gap-2'>
          Dispatcher statistics
        </span>
      <span className='flex justify-content-end align-items-center flex-grow-1 gap-2'>
          <WidgetToolbar
            value={filters}
            dispatcherState={(state) => state.dashboard.dispatcherState?.dispatcherStatistics}
            onChange={setFilters}
            onExport={onExport}
            onFilter={loadData}
            visibility={{
              period: true,
              from: true,
              to: true,
              dispatchers: true,
              filterButton: true,
            }}
          />
        </span>
    </header>
    <ResponsiveContainer width='100%' height={400}>
      <BarChart data={joinedChartData || []}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='period' interval={0} />
        <YAxis  />
        <Tooltip
          formatter={tooltipValueFormatter}
          labelFormatter={tooltipLabelFormatter}
        />
        <Legend />
        {dispatchersBars}
      </BarChart>
    </ResponsiveContainer>
  </DashboardPanel>
}

export default DispatchersStatistics;