/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Driver } from './Driver';
import {
    DriverFromJSON,
    DriverFromJSONTyped,
    DriverToJSON,
} from './Driver';
import type { ERequestStatus } from './ERequestStatus';
import {
    ERequestStatusFromJSON,
    ERequestStatusFromJSONTyped,
    ERequestStatusToJSON,
} from './ERequestStatus';
import type { Owner } from './Owner';
import {
    OwnerFromJSON,
    OwnerFromJSONTyped,
    OwnerToJSON,
} from './Owner';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface Request
 */
export interface Request {
    /**
     * 
     * @type {Date}
     * @memberof Request
     */
    regDate?: Date | null;
    /**
     * 
     * @type {ERequestStatus}
     * @memberof Request
     */
    status?: ERequestStatus;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    app?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Request
     */
    ringDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Request
     */
    nextRingDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    units?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Request
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Request
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    ownerId?: number | null;
    /**
     * 
     * @type {Owner}
     * @memberof Request
     */
    owner?: Owner;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    userId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof Request
     */
    user?: User;
    /**
     * 
     * @type {Array<Vehicle>}
     * @memberof Request
     */
    vehicles?: Array<Vehicle> | null;
    /**
     * 
     * @type {Array<Driver>}
     * @memberof Request
     */
    drivers?: Array<Driver> | null;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    id?: number;
}

/**
 * Check if a given object implements the Request interface.
 */
export function instanceOfRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RequestFromJSON(json: any): Request {
    return RequestFromJSONTyped(json, false);
}

export function RequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): Request {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regDate': !exists(json, 'regDate') ? undefined : (json['regDate'] === null ? null : new Date(json['regDate'])),
        'status': !exists(json, 'status') ? undefined : ERequestStatusFromJSON(json['status']),
        'app': !exists(json, 'app') ? undefined : json['app'],
        'ringDate': !exists(json, 'ringDate') ? undefined : (json['ringDate'] === null ? null : new Date(json['ringDate'])),
        'nextRingDate': !exists(json, 'nextRingDate') ? undefined : (json['nextRingDate'] === null ? null : new Date(json['nextRingDate'])),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'units': !exists(json, 'units') ? undefined : json['units'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'owner': !exists(json, 'owner') ? undefined : OwnerFromJSON(json['owner']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'vehicles': !exists(json, 'vehicles') ? undefined : (json['vehicles'] === null ? null : (json['vehicles'] as Array<any>).map(VehicleFromJSON)),
        'drivers': !exists(json, 'drivers') ? undefined : (json['drivers'] === null ? null : (json['drivers'] as Array<any>).map(DriverFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function RequestToJSON(value?: Request | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regDate': value.regDate === undefined ? undefined : (value.regDate === null ? null : value.regDate.toISOString()),
        'status': ERequestStatusToJSON(value.status),
        'app': value.app,
        'ringDate': value.ringDate === undefined ? undefined : (value.ringDate === null ? null : value.ringDate.toISOString()),
        'nextRingDate': value.nextRingDate === undefined ? undefined : (value.nextRingDate === null ? null : value.nextRingDate.toISOString()),
        'comment': value.comment,
        'units': value.units,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'ownerId': value.ownerId,
        'owner': OwnerToJSON(value.owner),
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'vehicles': value.vehicles === undefined ? undefined : (value.vehicles === null ? null : (value.vehicles as Array<any>).map(VehicleToJSON)),
        'drivers': value.drivers === undefined ? undefined : (value.drivers === null ? null : (value.drivers as Array<any>).map(DriverToJSON)),
        'id': value.id,
    };
}

