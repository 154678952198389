import LogisticsColumnProps from '../../../components/data-grid/LogisticsColumnProps';
import {
  ApiRequestsGetRequest,
  ERequestStatus,
  Request,
} from '../../../generated-api';
import CallButton from '../../../components/calls/CallButton';
import React, { CSSProperties } from 'react';
import dayjs from 'dayjs';
import serverFilterComponent from '../../../components/filters/server/ServerFilter';
import EditableDropdown from '../../../components/form/EditableDropdown';
import RowActionButton, { RowActionItem } from '../../../components/data-grid/actions/RowActionButton';
import { ColumnSortParams } from 'primereact/column';
import { MdEditDocument } from 'react-icons/md';
import styled from '@emotion/styled';

const DocumentSigned = styled(MdEditDocument)`
  & {
    width: 1rem;
    height: 1rem;
`

function formatDate(value: Date | null | undefined) {
  if (!value) return '';

  return dayjs(value).tz().format('MM/DD/YYYY')
}

function formatDateTime(value: Date | null | undefined){
  if (!value) return '';

  return dayjs(value).tz().format('MM/DD/YYYY hh:mm A');
}

const requestStatuses = [
  {
    id: ERequestStatus.New,
    caption: 'new',
    color: '#a4c2f4',
  },
  {
    id: ERequestStatus.Approved,
    caption: 'approved',
    color: '#98fb98',
  },
  {
    id: ERequestStatus.Rejected,
    caption: 'rejected',
    color: '#f36c6c',
  },
  {
    id: ERequestStatus.Ready,
    caption: 'ready',
    color: '#ffb273',
  },
  {
    id: ERequestStatus.InProgress,
    caption: 'in progress',
    color: '#bc9fe3',
  },
];

function statusTemplate(status: ERequestStatus | null | undefined){
  if (!status) return <></>;

  const format = requestStatuses.find(x => x.id === status);
  if (!format) return <></>;

  return <div
    style={{display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: format.color}}
  >
    <span style={{ padding: '0.5rem'}}>
      {format.caption}
    </span>
  </div>
}

function docBody(rowData: Request) {
  const ownersDocuments = rowData.owner?.ownerDocuments;
  const totalDocuments = (ownersDocuments?.length || 0) + (rowData.owner?.contract ? 1 : 0);
  const signed = (ownersDocuments?.filter(od => od.w9Signature).length || 0) + (rowData.owner?.signature ? 1 : 0);
  const columnText = `${signed}/${totalDocuments}`;
  const colorOfNumbersClass = totalDocuments === signed && totalDocuments !== 0 ? 'sign-green-color' : 'sign-red-color';
  return <div className={`flex align-items-start ${colorOfNumbersClass}`}>
    <span style={{lineHeight: 1, textWrap: 'nowrap'}}>{columnText}</span>
    <DocumentSigned/>
  </div>;
}

export default function requestsColumnDef(actions: RowActionItem<Request>[]) : LogisticsColumnProps[] {
  return [
    {
      field: 'id',
      header: 'R#',
      style: colStyle('4rem'),
      sortable: true,
    },
    {
      field: 'companyName',
      header: 'Company name',
      style: colStyle('20rem'),
      sortable: true,
      body: (rowData: Request) =>
        `${rowData.owner?.companyName || ''}`,
      serverSortFields: ['owner.companyName'],
    },
    {
      field: 'applicantName',
      header: 'Applicant name',
      style: colStyle('12rem'),
      sortable: true,
      body: (rowData: Request) =>
        `${rowData.owner?.firstName || ''} ${rowData.owner?.lastName || ''}`,
      serverSortFields: ['owner.firstName', 'owner.lastName'],
    },
    {
      field: 'email',
      header: 'Email',
      style: colStyle('12rem'),
      sortable: true,
      body: (rowData: Request) =>
        `${rowData.owner?.email || ''}`,
      serverSortFields: ['owner.email'],
    },
    {
      field: 'phone',
      header: 'Phone',
      style: colStyle('6rem'),
      sortable: false,
      body: (rowData: Request) => <CallButton
        label='Call'
        className='px-4 bg-green-400'
        callable={rowData.owner}
      />,
    },
    {
      field: 'units',
      header: 'Units',
      style: colStyle('3rem'),
      sortable: true,
      sortFunction: (e : ColumnSortParams) => {
        // return rows as we got them from server without any additional sorting
        return e.data;
      },
      serverSortFields: ['units'],
    },
    {
      field: 'regDate',
      header: 'Reg. Date',
      style: colStyle('8rem'),
      sortable: true,
      body: (rowData: Request) => formatDate(rowData.regDate),
    },
    {
      field: 'app',
      header: 'App',
      style: colStyle('12rem'),
      sortable: true,
    },
    {
      field: 'ringDate',
      header: 'Ring Date',
      style: colStyle('11rem'),
      sortable: true,
      body: (rowData: Request) => formatDateTime(rowData.ringDate),
    },
    {
      field: 'nextRingDate',
      header: 'Next Ring Date',
      style: colStyle('11rem'),
      sortable: true,
      body: (rowData: Request) => formatDateTime(rowData.nextRingDate),
    },
    {
      field: 'comment',
      header: 'Comment',
      style: colStyle('17rem'),
      sortable: true,
    },
    {
      field: 'status',
      header: 'Status',
      style: colStyle('6rem'),
      sortable: true,
      sortFunction: (e : ColumnSortParams) => {
        // return rows as we got them from server without any additional sorting
        return e.data;
      },
      serverSortFields: ['status'],
      filter: true,
      showClearButton: false,
      showFilterMenu: false,
      ...serverFilterComponent(props => <EditableDropdown
        options={requestStatuses}
        optionLabel='caption'
        renderOption={option => option.caption}
        renderSelected={option => option?.caption}
        value={props.value}
        onChange={newValue => props.filterApplyCallback(newValue)}
        renderEmptyOption={() => 'All'}
        renderEmptySelected={() => 'All'}
      />),
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiRequestsGetRequest
      ) : ApiRequestsGetRequest => {
        return { ...serverFilterModel, status: filterMetaData.value?.id };
      },
      body: (rowData: Request) => statusTemplate(rowData.status),
    },
    {
      field: 'manager',
      header: 'Manager',
      style: colStyle('10rem'),
      sortable: true,
      body: (rowData: Request) =>
        `${rowData.user?.firstName || ''} ${rowData.user?.lastName || ''}`,
      serverSortFields: ['user.firstName', 'user.lastName'],
    },
    {
      field: 'doc',
      header: 'Doc',
      style: colStyle('3rem'),
      sortable: true,
      body: (rowData: Request) => docBody(rowData),
      serverSortFields: ['user.firstName', 'user.lastName'],
    },
    {
      field: 'actions',
      header: 'Actions',
      style: colStyle('3rem'),
      sortable: false,
      body: (rowData: Request) => <RowActionButton rowData={rowData} actionItems={actions} />,
    }
  ]
}

const colStyle = (flexWidth?: number | string) => {
  return {
    flex: 1,
    flexBasis: flexWidth,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  } as CSSProperties;
}
