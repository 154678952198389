/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  State,
} from '../models';
import {
    StateFromJSON,
    StateToJSON,
} from '../models';

export interface ApiStatesAllGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiStatesCodesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiStatesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiStatesIdDeleteRequest {
    id: number;
}

export interface ApiStatesIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiStatesIdPutRequest {
    id: number;
    state?: State;
}

export interface ApiStatesNamesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiStatesPostRequest {
    state?: State;
}

/**
 * 
 */
export class StatesApi extends runtime.BaseAPI {

    /**
     */
    async apiStatesAllGetRaw(requestParameters: ApiStatesAllGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<State>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/states/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StateFromJSON));
    }

    /**
     */
    async apiStatesAllGet(requestParameters: ApiStatesAllGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<State>> {
        const response = await this.apiStatesAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStatesCodesGetRaw(requestParameters: ApiStatesCodesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<State>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/states/codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StateFromJSON));
    }

    /**
     */
    async apiStatesCodesGet(requestParameters: ApiStatesCodesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<State>> {
        const response = await this.apiStatesCodesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStatesGetRaw(requestParameters: ApiStatesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<State>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StateFromJSON));
    }

    /**
     */
    async apiStatesGet(requestParameters: ApiStatesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<State>> {
        const response = await this.apiStatesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStatesIdDeleteRaw(requestParameters: ApiStatesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStatesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiStatesIdDelete(requestParameters: ApiStatesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiStatesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiStatesIdGetRaw(requestParameters: ApiStatesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<State>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStatesIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StateFromJSON(jsonValue));
    }

    /**
     */
    async apiStatesIdGet(requestParameters: ApiStatesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<State> {
        const response = await this.apiStatesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStatesIdPutRaw(requestParameters: ApiStatesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStatesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/states/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StateToJSON(requestParameters.state),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiStatesIdPut(requestParameters: ApiStatesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiStatesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiStatesNamesGetRaw(requestParameters: ApiStatesNamesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<State>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/states/names`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StateFromJSON));
    }

    /**
     */
    async apiStatesNamesGet(requestParameters: ApiStatesNamesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<State>> {
        const response = await this.apiStatesNamesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStatesPostRaw(requestParameters: ApiStatesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<State>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/states`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StateToJSON(requestParameters.state),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StateFromJSON(jsonValue));
    }

    /**
     */
    async apiStatesPost(requestParameters: ApiStatesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<State> {
        const response = await this.apiStatesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
