import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiDispSettingsCurrentGetRequest, ApiDispSettingsIdPutRequest, ApiDispSettingsPostRequest, ApiSystemSettingsIdGetRequest, ApiSystemSettingsIdPutRequest, ApiSystemSettingsPostRequest, DispSettings, DispSettingsApi, SystemSettings, SystemSettingsApi } from '../../generated-api';
import { apiFactory } from '../../shared';
import {
  isPendingAction,
  isFulfilledAction,
  isRejectedAction,
  LoadableState, ErrorableState,
} from '../../shared';

interface SettingsState extends ErrorableState {
  system: LoadableState & SystemSettings,
  disp: LoadableState & DispSettings,
}

export const getSystemSetting = createAsyncThunk(
  "SystemSettings/get_system_setting",
  async (params: ApiSystemSettingsIdGetRequest) => {
    return await apiFactory(SystemSettingsApi).apiSystemSettingsIdGet(params);
  }
);

export const createSystemSetting = createAsyncThunk(
  'SystemSettings/create_system_setting',
  async (params: ApiSystemSettingsPostRequest) => {
    return await apiFactory(SystemSettingsApi).apiSystemSettingsPost(params);
  },
);

export const updateSystemSetting = createAsyncThunk(
  'SystemSettings/update_system_setting',
  async (params: ApiSystemSettingsIdPutRequest) => {
    return await apiFactory(SystemSettingsApi).apiSystemSettingsIdPut(params);
  }
);

export const getCurrentDispSetting = createAsyncThunk(
  "DispSettings/get_current_disp_setting",
  async (params: ApiDispSettingsCurrentGetRequest) => {
    const setting = await apiFactory(DispSettingsApi).apiDispSettingsCurrentGet(params);
    return setting.id === 0 ? null : setting;
  }
);

export const createDispSetting = createAsyncThunk(
  "DispSettings/create_disp_setting",
  async (params: ApiDispSettingsPostRequest) => {
    return await apiFactory(DispSettingsApi).apiDispSettingsPost(params);
  }
);

export const updateDispSetting = createAsyncThunk(
  "DispSettings/update_disp_setting",
  async (params: ApiDispSettingsIdPutRequest) => {
    await  apiFactory(DispSettingsApi).apiDispSettingsIdPut(params);
    return params.dispSettings;
  }
);

const defaultSystemSetting: SystemSettings = { 
  companyName: '', 
  cShortName: '',
  abbreviation: '',
  mcNumber: 0,
  maxFlyRadius: 0,
  bidEmailTemplateS: '' };

const defaultDispSetting: DispSettings = { 
  bidEmailTemplate: '', 
  maxFlyRadius: 0, };

const initialState: SettingsState = {
  system: { ...defaultSystemSetting, loading: false },
  disp: { ...defaultDispSetting, loading: false },
};

const isSystemSettingsAction = (action: AnyAction) => {
  return action.type.startsWith("SystemSettings");
}

const isDispatcherSetting = (action: AnyAction) => {
  return action.type.startsWith("DispSettings");
}

export const SettingsSlice = createSlice({
  name: 'Settings',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(
        action => isPendingAction(action) && isSystemSettingsAction(action),
        state => {
          state.system.loading = true;
        })
      .addMatcher(
        action => isPendingAction(action) && isDispatcherSetting(action),
        state => {
          state.disp.loading = true;
        })
      .addMatcher(
        action => isFulfilledAction(action) && isSystemSettingsAction(action),
        (state, action) => {
          state.system = { ...(action.payload as SystemSettings), loading: false };
      })
      .addMatcher(
        action => isFulfilledAction(action) && isDispatcherSetting(action),
        (state, action) => {
          state.disp = { ...(action.payload as DispSettings), loading: false };
        })
      .addMatcher(
        action => isRejectedAction(action) && isSystemSettingsAction(action),
        (state, action) => {
          state.system.loading = false;
          state.errorMessage = action.error.message;
      })
      .addMatcher(
        action => isRejectedAction(action) && isDispatcherSetting(action),
        (state, action) => {
          state.disp.loading = false;
          state.errorMessage = action.error.message;
        });
  },
});

export default SettingsSlice.reducer;