import LogisticsDataGrid from '../../../components/data-grid/LogisticsDataGrid';
import unitsColumnDef from './UnitsColumnDef';
import { getUnitsPage as getUnitsEntities } from './UnitSlice';
import { ApiVehiclesUnitsGetRequest, Vehicle } from '../../../generated-api';
import serverFilterComponent, { serverFilterFunctionName } from '../../../components/filters/server/ServerFilter';
import { RootState } from '../../../store/store';
import { useModal } from '../../../hooks';
import UnitEdit from '../request-edit/units/UnitEdit';
import GlobalFilter from '../../../components/filters/server/GlobalFilter';

const selectUnit = (state: RootState) => state.units;
const columns = unitsColumnDef();

function UnitsList() {
  const vehicleModal = useModal<Vehicle>({});

  const vehicleEdit = vehicleModal.visible
    ? <UnitEdit
      data={vehicleModal.data}
      visible={vehicleModal.visible}
      loading={false}
      hide={vehicleModal.hide}
    />
    : <></>;

  return (
    <>
      <LogisticsDataGrid
        id='unitsList'
        selectState={selectUnit}
        columns={columns}
        getEntitiesPage={getUnitsEntities}
        onRowClick={e => vehicleModal.show(e.data)}
        externalFilters={[
          {
            field: 'global',
            ...serverFilterComponent(props => <GlobalFilter {...props} />),
            serverFilterRequest: (filterMetaData, serverFilterModel: ApiVehiclesUnitsGetRequest) => {
              return { ...serverFilterModel, global: filterMetaData.value} as  ApiVehiclesUnitsGetRequest;
            },
          },
        ]}
        storedDefaultFilterMeta={{
          global: { value: ``, matchMode: serverFilterFunctionName as any },
        }}
        others={{
          columnResizeMode: "expand",
          resizableColumns: true
        }}
      />
      {vehicleEdit}
    </>
  );
}

export default UnitsList;
