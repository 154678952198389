/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DispatcherProfitResponse } from './DispatcherProfitResponse';
import {
    DispatcherProfitResponseFromJSON,
    DispatcherProfitResponseFromJSONTyped,
    DispatcherProfitResponseToJSON,
} from './DispatcherProfitResponse';

/**
 * 
 * @export
 * @interface DispatcherStatisticsResponse
 */
export interface DispatcherStatisticsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof DispatcherStatisticsResponse
     */
    periods?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DispatcherStatisticsResponse
     */
    totalLoads?: Array<number> | null;
    /**
     * 
     * @type {Array<DispatcherProfitResponse>}
     * @memberof DispatcherStatisticsResponse
     */
    dispatchers?: Array<DispatcherProfitResponse> | null;
}

/**
 * Check if a given object implements the DispatcherStatisticsResponse interface.
 */
export function instanceOfDispatcherStatisticsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DispatcherStatisticsResponseFromJSON(json: any): DispatcherStatisticsResponse {
    return DispatcherStatisticsResponseFromJSONTyped(json, false);
}

export function DispatcherStatisticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DispatcherStatisticsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'periods': !exists(json, 'periods') ? undefined : json['periods'],
        'totalLoads': !exists(json, 'totalLoads') ? undefined : json['totalLoads'],
        'dispatchers': !exists(json, 'dispatchers') ? undefined : (json['dispatchers'] === null ? null : (json['dispatchers'] as Array<any>).map(DispatcherProfitResponseFromJSON)),
    };
}

export function DispatcherStatisticsResponseToJSON(value?: DispatcherStatisticsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'periods': value.periods,
        'totalLoads': value.totalLoads,
        'dispatchers': value.dispatchers === undefined ? undefined : (value.dispatchers === null ? null : (value.dispatchers as Array<any>).map(DispatcherProfitResponseToJSON)),
    };
}

