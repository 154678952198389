import { useEffect } from 'react';
import LogisticsDialog from '../../components/LogisticsDialog';
import { Button } from 'primereact/button';
import styled from '@emotion/styled';
import { useHubConnection } from '../../hooks/SignalR';
import useAuth from './AuthHook';

const warningMessage = 'It seems that this account is already logged in on another device. Terminate the current session for this account and log in on this device instead?';

const StyledLogisticsDialog = styled(LogisticsDialog)`
  &.p-dialog {
    width: 30rem;
  }

  & .p-dialog-content {
    font-size: 1.2rem;
  }
`

function SecondSessionWarning() {
  const { auth, logout, setSecondSession } = useAuth();
  const hubConnection = useHubConnection();

  const setSecondSessionTrue = (userid: string) => {
    setSecondSession(true);
    logout();
  };

  useEffect(() => {
    hubConnection.on('Logout', setSecondSessionTrue);
  });

  if (!auth.isSecondSession) {
    return null;
  }

  const hide = () => {
    logout();
  };

  const logIn = async () => {
    setSecondSession(false);
    await hubConnection.invoke('Logout');
    window.location.reload();
  };

  return <StyledLogisticsDialog
    onHide={hide}
    visible
  >
    <main className='flex flex-column mb-3'>
      <span>{warningMessage}</span>
    </main>
    <footer className='flex justify-content-end gap-2'>
      <Button
        className='w-5rem p-button-text'
        label='Cancel'
        onClick={hide}
      />
      <Button
        className='w-5rem'
        label='Ok'
        onClick={logIn}
      />
    </footer>
  </StyledLogisticsDialog>
}

export default SecondSessionWarning;