import { create } from 'zustand';
import { useMemo } from 'react';
import _ from 'lodash';

interface ImageState {
  rotation?: number,
  crop?: { x1: number; y1: number; x2: number; y2: number },
  brightness?: number,
  contrast?: number,
  exposure?: number,
}

interface EditCommand {
  id: number,
  delta: ImageState,
}

interface EditStorage {
  initialState: ImageState,
  currentState: ImageState,
  history: EditCommand[],
  currentHistoryId: number,
  _do: (delta: ImageState) => void,
  _undo: () => void,
  _doTemp: (delta: ImageState) => void,
}

const initialId = 0;

const storage = (initialState?: ImageState) => create<EditStorage>((set, get) => {
    const initialState2 = initialState || {
      crop: undefined,
      rotation: 0,
      exposure: 0,
      brightness: 0,
      contrast: 0,
    };

    return ({
      initialState: initialState2,
      currentHistoryId: initialId,
      currentState: initialState2,
      history: [],
      _do: (delta: ImageState) => set(prev => {
        const lastCommand = prev.history.length
          ? prev.history.find(x => x.id === prev.currentHistoryId)
          : undefined;

        const fixedDelta = fixup(delta);
        const newCommand: EditCommand = {
          delta: fixedDelta,
          id: (lastCommand?.id || initialId) + 1,
        };

        let result = {
          currentState: _.merge({}, prev.currentState, fixedDelta), // Zustand only does shallow merge
          currentHistoryId: newCommand.id,
        } as EditStorage;

        if (lastCommand) {
          const lastCommandIndex = prev.history.findIndex(x => x.id === lastCommand.id);
          const newHistory = lastCommandIndex === prev.history.length - 1
            ? prev.history.concat(newCommand)
            : prev.history.slice(0, lastCommandIndex + 1).concat(newCommand);
          result.history = newHistory;
        } else {
          result.history = [newCommand];
        }

        return result;
      }, false),
      _undo: () => set(prev => {
        if (!prev.history.length) {
          console.warn('Noting to undo');
          return {};
        }

        const lastCommand = prev.history.find(x => x.id === prev.currentHistoryId)!;
        const lastCommandIndex = prev.history.findIndex(x => x.id === lastCommand.id);
        const preLastCommand = lastCommandIndex > 0
          ? prev.history[lastCommandIndex - 1]
          : undefined;

        return {
          currentState: preLastCommand?.delta ?
            _.merge({}, prev.currentState, preLastCommand.delta)
            : prev.initialState,
          currentHistoryId: preLastCommand?.id || initialId,
        } as EditStorage;

      }),
      _doTemp: (delta) => set(prev => {
        const fixedDelta = fixup(delta);
        return { currentState: _.merge({}, prev.currentState, fixedDelta) };
      }),
    });
});


function useImageState(initialState?: ImageState) {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const useStorage = useMemo(() => storage(initialState), []);

  return useStorage;
}

function fixup(imageState: ImageState) {
  if (!imageState) return imageState;

  if (imageState.rotation) {
    const rotation = imageState.rotation;
    if (rotation > 360 || rotation < -360) {
      imageState.rotation = rotation % 360;
    } else if (rotation > 180) {
      imageState.rotation = -360 + rotation;
    } else if (rotation < -180) {
      imageState.rotation = 360 + rotation;
    }
  }

  return imageState;
}

export default useImageState;