import { ModalParameters } from '../../../../shared';
import {
  Driver,
  DriversApi, EDockSize, EDriverOrder,
  EEquipment,
  State,
  StatesApi,
  Vehicle,
  VehiclesApi,
  VehicleType,
  VehicleTypesApi,
} from '../../../../generated-api';
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import LogisticsDialog from '../../../../components/LogisticsDialog';
import EditableDropdown from '../../../../components/form/EditableDropdown';
import { apiFactory } from '../../../../shared';
import StyledInputText from '../../../../components/form/StyledInputText';
import Field from '../../../../components/form/Field';
import { Button } from 'primereact/button';
import { updateEntity } from '../../../vehicles/VehiclesSlice';
import { ModalWidget, useAppDispatch, useModal } from '../../../../hooks';
import DriverEdit from '../../../drivers/DriverEdit';
import { FormikErrors, useFormik } from 'formik';
import ErrorBox from '../../../../components/form/ErrorBox';
import CenteredSpinner from '../../../../components/CenteredSpinner';
import Row from '../../../../components/form/Row';
import Column from '../../../../components/form/Column';
import { logisticsConfirmDialog } from '../../../../components/LogisticsConfifmDialog';
import { showErrorToast, showSuccessToast, showToast } from '../../../../components/LogisticsToast';
import Header from '../../../../components/form/Header';
import Equipment from '../../../../data-moqs/Equipment';
import DockSize from '../../../../data-moqs/DockSize';
import LogisticsFilesUpload from '../../../../components/form/files/upload/LogisticsFilesUpload';
import { MultiSelect } from 'primereact/multiselect';
import { IdName } from '../../../../data-moqs/IdName';
import FileSaver from 'file-saver';
import InputNumberWithButtons from '../../../../components/form/InputNumberWithButtons';
import useRights from '../../../../hooks/RightsHook';
import VisibilityToggler from '../../../../components/VisibilityToggler';
import useFilePreview from '../../../../components/form/files/preview/filePreviewWidget';
import LogisticsDateOnly from '../../../../components/form/datetime/LogisticsDateOnly';
import LogisticsFilePreview from '../../../../components/form/files/preview/LogisticsFilePreview';

export interface VehicleEditParameters extends ModalParameters<Vehicle> {

}

type DriverOrder = 'First' | 'Second';

const driverFullName = (driver: Driver | undefined) =>
  driver ? `${driver.firstName} ${driver.lastName} #${driver.id}` : '';

const getDriver = (vehicle: Vehicle, order: DriverOrder) => {
  return vehicle.drivers ? vehicle.drivers.find(d => d.order === order) : undefined;
}

const replaceDriver = (vehicle: Vehicle, newDriver: Driver, order: DriverOrder) => {
  let drivers = vehicle.drivers ? [...vehicle.drivers] : [];
  let previousDriver = drivers.find(d => d.order === order);

  // Release previous driver
  if (previousDriver) {
    drivers = drivers.filter(d => d !== previousDriver);
    if (previousDriver.id === newDriver?.id) {
      // Do nothing, same ids means driver was edited.
    } else if (previousDriver.vehicleId === vehicle.id) {
      // Detach driver from vehicle
      previousDriver = { ...previousDriver, order: EDriverOrder.None };
      drivers.push(previousDriver);
    } else {
      // Driver was never attached to vehicle so forget them.
    }
  }

  if (newDriver) {
    newDriver.order = order;
    // Reassign to another position
    let alreadyAssignedDriver = drivers.find(d =>
      d.id === newDriver.id
      && d.order !== newDriver.order
      && d.order !== EDriverOrder.None);
    // Pick from stash
    let driverFromStash = drivers.find(d =>
      d.id === newDriver.id
      && d.order === EDriverOrder.None);
    if (alreadyAssignedDriver) {
      alreadyAssignedDriver.order = order;
    } else if (driverFromStash) {
      driverFromStash.order = order;
    } else {
      drivers.push(newDriver);
    }
  }

  return { ...vehicle, drivers: drivers };
}

const dimensionsToArray = (value: string) => {
  if (!value.match(/\d+x\d+x\d+/)) return [];

  return value.split('x').map(num => parseInt(num));
}

const dimensionsToString = (value: number[] | null | undefined) => {
  if (!value || value.length < 3) return '';

  return `${value[0]}x${value[1]}x${value[2]}`;
}

const startYear = 2000;
const endYear = new Date().getFullYear();
const years : number[] = [];
for (var i = startYear; i <= endYear; i++) {
  years.push(i);
}

const equipmentToEquipment = (equipment: EEquipment[] | undefined | null) =>
  equipment?.length ? Equipment.filter(idstring => equipment.some(e => e.toString() === idstring.id)) : undefined;

const dockSizeToDockSize = (size: EDockSize | undefined | null) =>
  size ? DockSize.find(x => x.id === size.toString()) : undefined;

const vinIsNotUniqueMessage = 'This VIN code is already in the system.';

function UnitEdit(props: VehicleEditParameters) {
  const { visible } = props;
  const [vehicle, setVehicle] = useState<Vehicle>(props.data);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [boxDimensions, setBoxDimensions] = useState(dimensionsToString(vehicle.boxDimensions));
  const [vinIsNotUnique, setVinIsNotUnique] = useState<boolean>(false);
  const driver1Modal = useModal<Driver>({});
  const driver2Modal = useModal<Driver>({});
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const rights = useRights(security => security.unit);
  const filePreviewWidget = useFilePreview();
  const validation = useFormik<Vehicle>({
    initialValues: vehicle,
    validate: data => {
      const errors : FormikErrors<Vehicle> = {};
      const blankError = 'This value should not be blank.';
      const notCorrectError = 'Value is not correct.';

      const requiredFields: Vehicle = {
        vehicleMake: undefined,
        vehicleType: undefined,
        vehicleModel: undefined,
        vehicleYear: undefined,
        licensePlate: undefined,
        licenceState: undefined,
        licenseExpiryDate: undefined,
        gvw: undefined,
        vin: undefined,
        payload: undefined,
        insuranceExpires: undefined,
        widthDoor: undefined,
        heightDoor: undefined,
        dockSize: undefined,
      };

      if (!data.equipment || data.equipment.length === 0) { 
        errors.equipment = blankError;
      }

      if (!data.boxDimensions || data.boxDimensions.length === 0) {
        errors.boxDimensions = blankError;
      }

      Object.keys(requiredFields).forEach(field => {
        if (!(data as any)[field])
          (errors as any)[field] = blankError;
      });

      if (data.boxDimensions && data.boxDimensions.length < 3) {
        errors.boxDimensions = notCorrectError;
      }

      if (vinIsNotUnique) {
        errors.vin = vinIsNotUniqueMessage;
      }

      return errors;
    },
    onSubmit: () => {},
  });

  const firstDriver = useMemo(() => 
  { 
    return getDriver(vehicle, 'First')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle.drivers]);

  useEffect(() => {
    if (!visible) return;

    setLoading(true);
    const vehiclePromise = props.data.id
      ? apiFactory(VehiclesApi).apiVehiclesIdGet({ id: props.data.id})
      : Promise.resolve(vehicle);

    const driversPromise = props.data?.request?.id
      ? apiFactory(DriversApi).apiDriversVehicleDropdownWithFilesRequestIdGet({ requestId: props.data.request.id})
      : Promise.resolve([]);

    Promise.all([
      vehiclePromise,
      driversPromise,
      apiFactory(VehicleTypesApi).apiVehicleTypesPermittedGet(),
      apiFactory(StatesApi).apiStatesCodesGet(),
    ]).then(([
        loadedVehicle,
        loadedDrivers,
        vehicleTypes,
        loadedStates,
    ]) => {
      if (loadedVehicle.vin) {
        apiFactory(VehiclesApi).apiVehiclesCheckVinUniquenessVinGet({vin: loadedVehicle.vin, vehicleId: loadedVehicle.id!})
          .then(isUniqueString => {
            const isUnique = isUniqueString.toString() === 'true';
            setVinIsNotUnique(!isUnique);
          });
      }
      setVehicle(loadedVehicle);
      if (!props.data?.request?.id && loadedVehicle?.request?.id) {
        apiFactory(DriversApi).apiDriversVehicleDropdownWithFilesRequestIdGet({ requestId: loadedVehicle.request.id })
          .then(driversFromLoadedVehicles => setDrivers(driversFromLoadedVehicles));
      } else {
        setDrivers(loadedDrivers);
      }
      setVehicleTypes(vehicleTypes);
      setStates(loadedStates);

      validation.resetForm({ values: loadedVehicle, touched: {}});
    }).catch(() => showErrorToast('Something went wrong'))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const setValue = useCallback((setState: SetStateAction<Vehicle>) => {
    validation.setValues(setState);
    setVehicle(setState);
  }, [validation]);

  const hide = useCallback((vehicle? : Vehicle) => {
    if (vehicle) {
      dispatch(updateEntity(vehicle));
    }

    props.hide(vehicle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = useCallback(async () => {
    validation.handleSubmit();
    const errors = await validation.validateForm(vehicle);
    if (Object.keys(errors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [vehicle, validation]);

  const save = useCallback(async () => {
    try {
      await validate();
      setLoading(true)
      vehicle.drivers = vehicle.drivers?.filter(x => x);

      const response = vehicle.id
        ? await apiFactory(VehiclesApi)
          .apiVehiclesUnitIdPut({ id: vehicle.id!, vehicle: vehicle })
        : await apiFactory(VehiclesApi)
          .apiVehiclesUnitPost({ vehicle: vehicle });
      showSuccessToast('Unit saved');
      if (response) {
        setVehicle(response);
      }

      validation.resetForm({ values: response || vehicle, touched: {} });
      hide(response || vehicle);
    } catch (e: any) {
      showToast({severity: 'error', summary: 'Error', detail: e.toString()});
    } finally {
      setLoading(false);
    }
  }, [vehicle, validate, validation, hide]);

  const handleOnHide = useCallback(() => {
    if (!rights.update && !rights.create) {
      hide();
      return;
    }

    if (validation.dirty) {
      logisticsConfirmDialog({
        message: 'Save changes?',
        closable: false,
        accept() {
          save();
        },
        reject() {
          hide();
        }
      });
    } else {
      hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide, vehicle, validation]);

  const downloadAllFiles = useCallback(async () => {
    const response = await apiFactory(VehiclesApi).apiVehiclesUnitFilesAllGet({ id: vehicle.id!});
    FileSaver.saveAs(response, 'unit-files.zip');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVinBlur = async (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const fieldValue = e.currentTarget.value;
    if (fieldValue) {
      apiFactory(VehiclesApi).apiVehiclesCheckVinUniquenessVinGet({vin: fieldValue, vehicleId: vehicle.id})
        .then(isUniqueString => {
          const isUnique = isUniqueString.toString() === 'true';
          setVinIsNotUnique(!isUnique);
        })
        .catch((error: any) => {
          showErrorToast("Failed to check the uniqueness of the Vin field.")
          setVinIsNotUnique(true);
          throw error;
        })
        .finally(async () => {
          setTimeout(() => validation.setFieldTouched('vin', true));
        });
    }
  }

  const driverEdit = (modal: ModalWidget<Driver>, order: DriverOrder) => modal.visible
    ? <DriverEdit
      data={modal.data}
      loading={false}
      visible={modal.visible}
      openedFromRequest={true}
      hide={editedDriver => {
        if (!editedDriver) {
          modal.hide();
          return;
        }

        setVehicle(v =>
          replaceDriver(v, editedDriver, order));
        const driverIsNew = !drivers.find(x => x.id === editedDriver.id);
        setDrivers(oldDrivers => {
          return driverIsNew
            ? [editedDriver, ...oldDrivers]
            : [...oldDrivers].map(oldDriver => {
                return oldDriver.id === modal.data.id
                  ? editedDriver
                  : oldDriver;
              });
        });
        modal.hide();
      }}
    />
    : <></>;

  const driver1Edit = driverEdit(driver1Modal, 'First');
  const driver2Edit = driverEdit(driver2Modal, 'Second');

  return (
    <>
      {driver1Edit}
      {driver2Edit}
      <LogisticsDialog
        visible={props.visible}
        onHide={handleOnHide}
        className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-6'
      >
        <header className='text-2xl font-bold'>
          Unit #V{vehicle.id}
        </header>
        <main className='grid logistics-dialog-edit-form__content mt-2'>
          <div className="ml-4">
            <Header>Driver info</Header>  
          </div>
          <Row>
            <Column>
              <Field label='Driver #1'>
                <EditableDropdown
                  options={drivers}
                  optionLabel='firstName'
                  renderOption={driverFullName}
                  renderSelected={driverFullName}
                  renderEmptyOption={() => 'No driver'}
                  renderEmptySelected={() => 'No driver'}
                  value={getDriver(vehicle, 'First')}
                  openEditing={value => driver1Modal.show(value)}
                  openAdding={() => driver1Modal.show({})}
                  onChange={newValue => setValue(v =>
                    replaceDriver(v, newValue, 'First')
                  )}
                  filter
                  disabled={!rights.update}
                />
                <ErrorBox>{validation.errors.drivers}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label='Driver #2'>
                <EditableDropdown
                  options={drivers}
                  optionLabel='firstName'
                  renderOption={driverFullName}
                  renderSelected={driverFullName}
                  renderEmptyOption={() => 'No driver'}
                  renderEmptySelected={() => 'No driver'}
                  openEditing={value => driver2Modal.show(value)}
                  openAdding={() => driver2Modal.show({})}
                  value={getDriver(vehicle, 'Second')}
                  onChange={newValue => setValue(v =>
                    replaceDriver(v, newValue, 'Second')
                  )}
                  filter
                  disabled={!rights.update}
                />
              </Field>
            </Column>
          </Row>
          <div className="ml-4">
            <Header>Vehicle info</Header>  
          </div>
          <Row>
            <div className="col-3">
              <Field label='Vehicle make' required>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.vehicleMake}
                  disabled={!rights.update}
                  onChange={e => setValue(v => {
                    return { ...v, vehicleMake: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.vehicleMake}</ErrorBox>
              </Field>
            </div>
            <div className="col-3">
              <Field label='Vehicle model' required>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.vehicleModel}
                  disabled={!rights.update}
                  onChange={e => setValue(v => {
                    return { ...v, vehicleModel: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.vehicleModel}</ErrorBox>
              </Field>
            </div>
            <div className="col-3">
              <Field label='Vehicle type' required>
                <EditableDropdown
                  options={vehicleTypes}
                  optionLabel='name'
                  renderOption={type => type.name}
                  renderSelected={type => type?.name}
                  renderEmptySelected={() => '-'}
                  value={vehicle.vehicleType}
                  disabled={!rights.update}
                  onChange={newValue => {
                    setValue(v => { return { ...v, vehicleType: newValue }});
                  }}
                  filter
                />
                <ErrorBox>{validation.errors.vehicleType}</ErrorBox>
              </Field>
            </div>
            <div className="col-3">
              <Field label='Vehicle year' required>
                <EditableDropdown
                  options={years}
                  optionLabel=''
                  renderOption={x => x}
                  renderSelected={x => x}
                  renderEmptySelected={() => '-'}
                  value={vehicle.vehicleYear}
                  disabled={!rights.update}
                  onChange={newValue => setValue(v => {
                    return { ...v, vehicleYear: newValue };
                  })}
                  filter
                />
                <ErrorBox>{validation.errors.vehicleYear}</ErrorBox>
              </Field>
            </div>
          </Row>
          <Row>
            <div className="col-3">
              <Field label='License Plate' required>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.licensePlate}
                  disabled={!rights.update}
                  onChange={e => setValue(v => {
                    return { ...v, licensePlate: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.licensePlate}</ErrorBox>
              </Field>
            </div>
            <div className="col-3">
              <Field label='Licence state' required>
                <EditableDropdown
                  options={states}
                  optionLabel='name'
                  renderOption={state => state.code}
                  renderSelected={state => state?.code}
                  renderEmptySelected={() => '-'}
                  value={vehicle.licenceState}
                  disabled={!rights.update}
                  onChange={newValue => setValue(v => {
                    return { ...v, licenceState: newValue };
                  })}
                  filter
                />
                <ErrorBox>{validation.errors.licenceState}</ErrorBox>
              </Field>
            </div>
            <div className="col-3">
              <Field label='License Expiry Date' required>
                <LogisticsDateOnly
                  value={vehicle.licenseExpiryDate}
                  disabled={!rights.update}
                  onChange={newValue => setValue(v => {
                    return { ...v, licenseExpiryDate: newValue };
                  })}
                />
                <ErrorBox>{validation.errors.licenseExpiryDate}</ErrorBox>
              </Field>
            </div>
            <div className="col-3">
              <Field label='GVW(lbs)' required>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.gvw}
                  disabled={!rights.update}
                  onChange={e => setValue(v => {
                    return { ...v, gvw: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.gvw}</ErrorBox>
              </Field>
            </div>
          </Row>
          <Row>
            <div className="col-4">
              <Field label='MC and USDOT'>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.mc}
                  disabled={!rights.update}
                  onChange={e => setValue(v => {
                    return { ...v, mc: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.mc}</ErrorBox>
              </Field>
            </div>
            <div className="col-4">
              <Field label='VIN Number' required>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.vin}
                  onBlur={onVinBlur}
                  disabled={!rights.update}
                  onChange={e => setValue(v => {
                    return { ...v, vin: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.vin}</ErrorBox>
              </Field>
            </div>
            <div className="col-4">
              <Field label='Payload (lbs)' required>
                <InputNumberWithButtons
                  value={vehicle.payload}
                  disabled={!rights.update}
                  placeholder='Payload (lbs)'
                  onChange={e => setValue(v => {
                    return { ...v, payload: e.value || 0 };
                  })}
                />
                <ErrorBox>{validation.errors.payload}</ErrorBox>
              </Field>
            </div>
          </Row>
          <Row>
            <div className="col-4">
              <Field label='Insurance Company'>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.insuranceCompany}
                  disabled={!rights.update}
                  onChange={e => setValue(v => {
                    return { ...v, insuranceCompany: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.insuranceCompany}</ErrorBox>
              </Field>
            </div>
            <div className="col-4">
              <Field label='Policy No'>
                <StyledInputText
                  // @ts-ignore
                  value={vehicle.policyNo}
                  disabled={!rights.update}
                  onChange={e => setValue(v => {
                    return { ...v, policyNo: e.target.value };
                  })}
                />
                <ErrorBox>{validation.errors.policyNo}</ErrorBox>
              </Field>
            </div>
            <div className="col-4">
              <Field label='Insurance Expires' required>
                <LogisticsDateOnly
                  value={vehicle.insuranceExpires}
                  disabled={!rights.update}
                  onChange={newValue => setValue(v => {
                    return { ...v, insuranceExpires: newValue };
                  })}
                />
                <ErrorBox>{validation.errors.insuranceExpires}</ErrorBox>
              </Field>
            </div>
          </Row>
          <Row>
            <div className="col-4">
              <Field label='Box Dims (LxWxH)' required>
                <StyledInputText
                  value={boxDimensions}
                  disabled={!rights.update}
                  placeholder='_x_x_'
                  onChange={e => {
                    setValue(v => {
                      return { ...v, boxDimensions: dimensionsToArray(e.target.value) };
                    });
                    setBoxDimensions(e.target.value);
                  }}
                />
                <ErrorBox>{validation.errors.boxDimensions}</ErrorBox>
              </Field>
            </div>
            <div className="col-4">
              <Field label='Door Open Width' required>
                <InputNumberWithButtons
                  value={vehicle.widthDoor}
                  disabled={!rights.update}
                  placeholder='Door Open Width'
                  onChange={e => setValue(v => {
                    return { ...v, widthDoor: e.value || 0 };
                  })}
                />
                <ErrorBox>{validation.errors.widthDoor}</ErrorBox>
              </Field>
            </div>
            <div className="col-4">
              <Field label='Door Open Height' required>
                <InputNumberWithButtons
                  value={vehicle.heightDoor}
                  disabled={!rights.update}
                  placeholder='Door Open Height'
                  onChange={e => setValue(v => {
                    return { ...v, heightDoor: e.value || 0 };
                  })}
                />
                <ErrorBox>{validation.errors.heightDoor}</ErrorBox>
              </Field>
            </div>
          </Row>
          <Row>
            <Column>
              <Field label='Equipment' required>
                <MultiSelect
                  value={equipmentToEquipment(vehicle.equipment)}
                  disabled={!rights.update}
                  onChange={e => setValue(v => {
                    return { ...v, equipment: ((e.value as IdName<string>[]).map(x => x.id)) as EEquipment[] };
                  })}
                  options={Equipment}
                  optionLabel='id'
                  itemTemplate={option => option.name}
                  placeholder='Equipment'
                  maxSelectedLabels={20}
                  className='w-full'
                  filter
                />
              </Field>
              <ErrorBox>{validation.errors.equipment}</ErrorBox>
            </Column>
            <Column>
              <Field label='Dock size' required>
                <EditableDropdown
                  options={DockSize}
                  optionLabel='id'
                  renderOption={option => option.name}
                  renderSelected={size => size?.name}
                  renderEmptySelected={() => '-'}
                  value={dockSizeToDockSize(vehicle.dockSize)}
                  disabled={!rights.update}
                  onChange={newStatus => setValue(v => {
                    return { ...v, dockSize: newStatus.id as any };
                  })}
                  filter
                />
                <ErrorBox>{validation.errors.dockSize}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <div className="ml-4">
            <Header>Documents Upload</Header>  
          </div>
          <div className='text-xl w-full flex-wrap mb-2 ml-4 font-bold'>
            Driver
          </div>
          <Row>
            <div className="col-4">
              <Field label='Driver license'>
                <LogisticsFilesUpload
                  disabled={!firstDriver || !rights.update}
                  value={firstDriver?.licenseFile}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => setValue(d => {
                    return { ...d, drivers: [ { ...d.drivers![0], licenseFile: newFiles } ]};
                  })}
                />
              </Field>
            </div>
            <div className="col-4">
              <Field label='Driving Record'>
                <LogisticsFilesUpload
                  disabled={!firstDriver || !rights.update}
                  value={firstDriver?.drRecordFile}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => setValue(d => {
                    return { ...d, drivers: [ { ...d.drivers![0], drRecordFile: newFiles } ]};
                  })}
                />
              </Field>
            </div>
            <div className="col-4">
              <Field label='Green card or Passport'>
                <LogisticsFilesUpload
                  disabled={!firstDriver || !rights.update}
                  value={firstDriver?.passport}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => setValue(d => {
                    return { ...d, drivers: [ { ...d.drivers![0], passport: newFiles } ]};
                  })}
                />
              </Field>
            </div>
          </Row>
          <div className='text-xl w-full flex-wrap mb-2 ml-4 font-bold'>
            Vehicle
          </div>
          <Row>
            <div className="col-4">
              <Field label='Insurance'>
                <LogisticsFilesUpload
                  value={vehicle.insuranceFile}
                  disabled={!rights.update}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => setValue(d => {
                    return { ...d, insuranceFile: newFiles };
                  })}
                />
              </Field>
            </div>
            <div className="col-4">
              <Field label='Vehicle registration'>
                <LogisticsFilesUpload
                  value={vehicle.vehRegFile}
                  disabled={!rights.update}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => setValue(d => {
                    return { ...d, vehRegFile: newFiles };
                  })}
                />
              </Field>
            </div>
            <div className="col-4">
              <Field label='MC and USDOT'>
                <LogisticsFilesUpload
                  value={vehicle.mcFail}
                  disabled={!rights.update}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => setValue(d => {
                    return { ...d, mcFail: newFiles };
                  })}
                />
              </Field>
            </div>
          </Row>
          <Row>
            <div className="col-4">
              <Field label='Photo Left Side'>
                <LogisticsFilesUpload
                  value={vehicle.photoLS}
                  disabled={!rights.update}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => setValue(d => {
                    return { ...d, photoLS: newFiles };
                  })}
                />
              </Field>
            </div>
            <div className="col-4">
              <Field label='Photo Right Side'>
                <LogisticsFilesUpload
                  value={vehicle.photoRS}
                  disabled={!rights.update}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => setValue(d => {
                    return { ...d, photoRS: newFiles };
                  })}
                />
              </Field>
            </div>
            <div className="col-4">
              <Field label='Cargo space photo'>
                <LogisticsFilesUpload
                  value={vehicle.photoCargoSpace}
                  disabled={!rights.update}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => setValue(d => {
                    return { ...d, photoCargoSpace: newFiles };
                  })}
                />
              </Field>
            </div>
          </Row>
          <div className='text-xl w-full flex-wrap mb-2 ml-4 font-bold'>
            Unit
          </div>
          <Row>
            <div className="col-12">
              <Field label='Extra attachments file'>
                <LogisticsFilesUpload
                  value={vehicle.extraAttachFile}
                  disabled={!rights.update}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => setValue(d => {
                    return { ...d, extraAttachFile: newFiles };
                  })}
                />
              </Field>
            </div>
          </Row>
        </main>
        <footer className='flex justify-content-end w-full gap-3 pr-4'>
          <VisibilityToggler visible={rights.update || rights.create}>
            <Button
              disabled={!vehicle.id}
              label='Download Files'
              onClick={downloadAllFiles}
            />
          </VisibilityToggler>
          <VisibilityToggler visible={rights.update || rights.create}>
            <Button
              label='SAVE'
              className='p-button-success'
              onClick={save}
            />
          </VisibilityToggler>
          <Button
            label='CLOSE'
            onClick={handleOnHide}
          />
        </footer>
        <CenteredSpinner visible={loading} />
        <LogisticsFilePreview filePreviewWidget={filePreviewWidget} />
      </LogisticsDialog>
    </>
  );
}

export default UnitEdit;