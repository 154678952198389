import React, { useState } from 'react';
import { TabPanel } from 'primereact/tabview';
import LogisticsTabs from '../../components/form/LogisticsTabs';
import RequestList from './request-list/RequestList';
import UnitsList from './units-list/UnitList';

function HRPage() {
  const [tabIndex, setTabIndex] = useState(0);

  const requestTab = <TabPanel header="Requests" >
    <RequestList />
  </TabPanel>;

  const unitsTab = <TabPanel header="Units" >
    <UnitsList />
  </TabPanel>;

  return <LogisticsTabs
    activeIndex={tabIndex}
    onTabChange={(e) => setTabIndex(e.index)}
  >
    {requestTab}
    {unitsTab}
  </LogisticsTabs>
}

export default HRPage;