import React, { useMemo, useRef } from 'react';
import { NotificationArrowButton } from './NotificationArrowButton';
import { AnyFunction } from '../../../../shared/interfaces/AnyFunction';
import styled from '@emotion/styled';
import { DriverBid } from '../../../../generated-api';

const Container = styled.div `
  .p-toast-message-content:has(&) {
    background-color: #bde4ff;
  }
`

function NewDriverBidNotification(props: {
  driverBid: DriverBid,
  onArrowClick: AnyFunction<any, any>,
}) {
  const ref = useRef<HTMLDivElement>({} as any);
  const message = useMemo(() => {
    const company = props.driverBid?.vehicle?.owner?.companyName || '';
    const dispatcher = props.driverBid?.vehicle?.dispatcher?.userName || '';
    const vehicle = props.driverBid?.vehicle?.id || props.driverBid?.vehicleId || '';

    return `New driver bid from ${company}, Unit #${vehicle}. Disp.: ${dispatcher}`;
  }, [props.driverBid]);

  return <Container
    className='flex flex-row flex-grow-1 justify-content-evenly align-items-center text-2xl'
    ref={ref}
  >
    <i className='pi pi-check-circle text-2xl flex-grow-0 pr-4' />
    <span className='flex-grow-1'>{message}</span>
    <NotificationArrowButton
      onClick={() => {
        props.onArrowClick();
        const closeButton = ref.current.parentElement!.querySelector('button.p-toast-icon-close') as HTMLButtonElement;
        closeButton.click();
      }}
      className='flex-grow-0 pl-4'
    >
      <i className='pi pi-angle-right text-2xl' />
    </NotificationArrowButton>
  </Container>;
}

export default NewDriverBidNotification;