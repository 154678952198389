import React, { useMemo } from 'react';
import DashboardPanel from '../components/DashboardPanel';
import WidgetToolbar from '../components/WidgetToolbar';
import {
  DashboardApi,
  EWidgetChart,
  EWidgetPeriodType,
} from '../../../generated-api';
import { useAppSelector } from '../../../hooks';
import { apiFactory } from '../../../shared';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, Tooltip, XAxis, YAxis, Cell } from 'recharts';
import colorPalette from '../components/BidsByHourColorPalette';
import useWidget from '../hooks/WidgetHook';
import { today } from '../../../shared/util/dateUtils';
import ResponsiveContainerWithPadding from '../../../components/ReponsiveContainerWithPadding';

const charts = [
  EWidgetChart.Orders,
  EWidgetChart.OrdersLastYear,
  EWidgetChart.BidsByDispatchers,
];

function BidsByHour() {
  const { data, filters, loadData, setFilters } = useWidget({
    initialFilters: {
      periodType: 'Day',
      from: today().toDate(),
      to: today().toDate(),
      charts: [EWidgetChart.Orders],
    },
    initialWidgetData: {},
    loadWidgetData: filters => apiFactory(DashboardApi).apiDashboardBidsByHourGet(filters as any),
    usesDispatchers: true,
  });
  const dispatchers = useAppSelector(state => state.dashboard.dispatcherState?.all);

  const joinedChartData = useMemo(() => {
    const xAxis = data.orders?.length
      ? data.orders.map(x => x.hour)
      : data.ordersLastYear?.length
        ? data.ordersLastYear.map(x => x.hour)
        : data.bidsByHour?.hours || [];

    if (!xAxis.length) return [];

    return xAxis.map((hour, index) => {
      let hourData = {
        hour,
        order: data.orders?.find(x => x.hour === hour)?.data,
        orderLastYear: data.ordersLastYear?.find(x => x.hour === hour)?.data,
      };

      if (data.bidsByHour?.data?.length) {
        data.bidsByHour?.data.forEach(dispatcherResponse => {
          // @ts-ignore
          hourData[dispatcherResponse.name] = dispatcherResponse.data[index];
        });
      }

      return hourData;
    });
  }, [data]);

  const lastPartsOfColumns = useMemo(() => {
    if (!dispatchers || dispatchers?.length < 1)
      return;

    const dispatchersInReverseAlphabetical = [...dispatchers].sort((a, b) => {
      const aName = `${a.firstName} ${a.lastName}`;
      const bName = `${b.firstName} ${b.lastName}`;
      return aName.localeCompare(bName);
    }).reverse().filter(d => d.id).map(d => d.id!);
    return joinedChartData.map(period => {
      const periodDataFields = Object.keys(period);
      const dispatchersInData = dispatchersInReverseAlphabetical.filter(id => periodDataFields?.some(df => df === id));
      let firstNotNull;
      for (var field of dispatchersInData) {
        // @ts-ignore
        if (Number(period[field]) !== 0) {
          firstNotNull = field;
          break;
        }
      }
      return firstNotNull;
    });
  }, [joinedChartData, dispatchers]);

  const [ordersChart, ordersLastYearChart, bidsByDispatcherChart] = useMemo(() => {
    if (!filters.charts) return [false, false, false];

    return [
      filters.charts.includes(EWidgetChart.Orders),
      filters.charts.includes(EWidgetChart.OrdersLastYear),
      filters.charts.includes(EWidgetChart.BidsByDispatchers),
    ];
  }, [filters.charts]);

  const ordersBar = useMemo(() => {
    if (!ordersChart) return <></>;

    return <Bar dataKey='order' name='Orders' stackId='a' fill={colorPalette.orders} radius={[5, 5, 0, 0]}>
      <LabelList dataKey='order' position='top' fill={colorPalette.orders}/>
    </Bar>;
  }, [ordersChart]);

  const ordersLastYearBar = useMemo(() => {
    if (!ordersLastYearChart) return <></>;

    return <Bar dataKey='orderLastYear' name='Orders (last year)' stackId='b' fill={colorPalette.ordersLastYear} radius={[5, 5, 0, 0]}/>
  }, [ordersLastYearChart]);

  const dispatcherBars = useMemo(() => {
    if (!bidsByDispatcherChart) return <></>;

    return filters.dispatcherIds?.map((dispatcherId, index) => {
      const dispatcher = dispatchers?.find(d => d.id === dispatcherId);
      if (!dispatcher) return <></>;

      const opacityLevels = ["", "b3", "80"]; // 100%, 70%, 50%
      const currentOpacity = opacityLevels[Math.floor(index / colorPalette.bidsColors.length) % opacityLevels.length];
      const color = colorPalette.bidsColors[index % (colorPalette.bidsColors.length - 1)] + currentOpacity;

      return <Bar
        dataKey={dispatcherId}
        name={`${dispatcher.firstName} ${dispatcher.lastName}`}
        stackId='c'
        key={dispatcherId}
        fill={color}>
          { joinedChartData.map((entry, index) => {
            const dispatcherIdOfLastPartOfColumn = lastPartsOfColumns ? lastPartsOfColumns[index] : undefined;
            return (
            // @ts-ignore
            <Cell key={`cell-${index}`} radius={dispatcherId === dispatcherIdOfLastPartOfColumn ? [5, 5, 0, 0] : undefined} />
          )})}
        </Bar>
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidsByDispatcherChart, lastPartsOfColumns, filters.dispatcherIds]);
  return (
    <DashboardPanel>
      <header className='flex'>
        <span className='text-3xl font-medium white-space-nowrap flex justify-content-start align-items-center flex-grow-1 gap-2'>
          Bids by hour
        </span>
        <span className='flex justify-content-end align-items-center flex-grow-1 gap-2'>
          <WidgetToolbar
            value={filters}
            onChange={setFilters}
            onFilter={loadData}
            charts={charts}
            visibility={{
              period: true,
              from: true,
              to: true,
              dispatchers: bidsByDispatcherChart,
              filterButton: true,
              options: true,
            }}
          />
        </span>
      </header>
      <ResponsiveContainerWithPadding width='100%' height={400}>
        <BarChart data={joinedChartData || []}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='hour' interval={0} tick={CustomizedGroupTick}/>
          <YAxis domain={filters.periodType === EWidgetPeriodType.Month ? [0, 'dataMax + 500'] : undefined} />
          <Tooltip />
          <Legend />
          {ordersBar}
          {ordersLastYearBar}
          {dispatcherBars}
        </BarChart>
      </ResponsiveContainerWithPadding>
    </DashboardPanel>
  );
}

const CustomizedGroupTick = (props: any) => {
  const {x, y, payload } = props;
  const [from, to] = (payload.value as string).split(' - ');

  return (
    <g>
      <text x={x - 20} y={y + 9} fontSize='0.8rem' textAnchor='start'>
        {from + '-'}
      </text>
      <text x={x - 20} y={y + 20} fontSize='0.8rem' textAnchor='start'>
        {to}
      </text>
    </g>
  );
};

export default BidsByHour;