import { useRef, useMemo } from 'react';
import { ConfigProvider, Typography, DatePicker } from 'antd';
import StyledWeekCalendar from './StyledWeekCalendar';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';

const { Link } = Typography;

export interface WeekCalendarProps {
  value: Dayjs | null | undefined,
  onChange: ((value: Dayjs | null, dateString: string) => void),
  setSelectedWeek?: React.Dispatch<React.SetStateAction<Dayjs | null | undefined>>
  filterApplyCallback?: ColumnFilterElementTemplateOptions["filterApplyCallback"],
  className?: string | undefined,
  handleClick: (e: MouseEvent) => void,
  disabled?: boolean | undefined,
  onEditOrCreateForm?: boolean | undefined,
  onListForm?: boolean | undefined,
}

function LogisticsWeekCalendar(props: WeekCalendarProps) {
  const {
    value,
    onChange,
    setSelectedWeek,
    filterApplyCallback,
    className,
    handleClick,
    disabled,
    onEditOrCreateForm,
    onListForm,
  } = props;

  const weekCalendarRef = useRef<any>(null);

  /**
   * For Edit And Create Form it changes 2 properties, for Export form it changes 1 property and for listForm it's default.
   */
  const themeObject = useMemo (() => {
    const baseThemeChange = {
      components: {
        DatePicker: {
          zIndexPopup: 9999,
          ...(onEditOrCreateForm && { borderRadius: 0 }),
        },
      },
    }
    return onListForm ? {} : baseThemeChange
  }, [onEditOrCreateForm, onListForm])

  const weekCalendar = useMemo(() => {
    const weekNumberClicker = (open: boolean) => {
      document.querySelectorAll<HTMLElement>('.ant-picker-cell-week').forEach((e) => {
        // Remember that handleClick function should be the same in the onChange event, where u removing event listener.
        if (open) e.addEventListener("click", handleClick);
      });
    };

    return onEditOrCreateForm
      ? <StyledWeekCalendar
          picker='week'
          placeholder=''
          value={value}
          onChange={onChange}
          disabled={disabled}
          className={className}
          renderExtraFooter={() =>
            <Link className='my-2 mx-3' onClick={() => {
              onChange(dayjs().tz(), dayjs().tz().format('gggg-wo'));
              weekCalendarRef?.current?.blur();
            }}>
              Current week
            </Link>
          }
          onOpenChange={weekNumberClicker}
          ref={weekCalendarRef}
        />
      : <DatePicker
          picker='week'
          allowClear={false}
          renderExtraFooter={() => {
            return onListForm
              ? <Link className='my-2 mx-3' onClick={() => {
                if (setSelectedWeek) setSelectedWeek(dayjs().tz());
                if (filterApplyCallback) filterApplyCallback(`${dayjs().tz().format('YY-w')}-`);
                weekCalendarRef?.current?.blur();
              }}>
                Current week
              </Link>
              : <Link className='my-2 mx-3' onClick={() => {
                if (setSelectedWeek) setSelectedWeek(dayjs().tz());
                weekCalendarRef?.current?.blur();
              }}>
                Current week
              </Link>
          }}
          value={value}
          onChange={onChange}
          onOpenChange={weekNumberClicker}
          ref={weekCalendarRef}
          className={className}
        />;
  }, [onEditOrCreateForm, onListForm, onChange, className, disabled, value, handleClick, setSelectedWeek, filterApplyCallback])

  return (
    <ConfigProvider
      theme={themeObject}
    >
      {weekCalendar}
    </ConfigProvider>
  );
}

export default LogisticsWeekCalendar;