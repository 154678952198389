import { LoadedOrder } from '../../generated-api';
import { FilterService } from 'primereact/api';
import stateFilter from '../../components/filters/state/StateFilterOperator';
import FlightIcon from '@mui/icons-material/Flight';
import { ApiLoadedOrdersGetRequest } from '../../generated-api';
import LogisticsColumnProps from '../../components/data-grid/LogisticsColumnProps';
import StateFilterModel from '../../components/filters/state/StateFilterModel';
import DefaultServerFilterComponent from '../../components/filters/server/DefaultServerFilterComponent';
import ServerInputNumber from '../../components/filters/server/ServerInputNumber';
import { DataTableFilterMetaData } from 'primereact/datatable';
import dayjs from 'dayjs';
import MatchColorBox from './MatchColorBox';

function formatDate(rawDate : Date | undefined | null){
  if (!rawDate)
    return '';

  return dayjs(rawDate).tz().format('MMM D, H:mm A');
}

function matchBodyTemplate(rowData: LoadedOrder) {
  const matchCount = rowData.matchCount || 0;
  const recentUnitsCount = rowData.recentUnits || 0;
  const milesUnit = rowData.milesUnit || 0;
  const vehicleTypeIds = rowData.vehicleTypeIds || [];
  const text = `${matchCount} / ${recentUnitsCount} (`
  return <>
    <span style={{textWrap: 'nowrap'}}>{text}</span>
    <FlightIcon fontSize='small'/>{milesUnit}&nbsp;mi)
    <MatchColorBox vehicleTypeIds={vehicleTypeIds}/>
  </>
}

function pickUpAtTemplate(rowData: LoadedOrder) {
  const pickUpDate = dayjs(rowData.pickUpDate).tz().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
  const receivedDate = dayjs(rowData.receivedDate).tz().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
  const pickUpPlace = rowData.pickUpAt;
  const plusOne = pickUpDate.isAfter(receivedDate) && pickUpDate.diff(receivedDate, 'day') > 0 ? <span style={{color: "#f36c6c"}}> + 1</span> : "";
  return <>
    <span>{pickUpPlace}{plusOne}</span>
  </>
}

function stateFilterToString(filterMetaData: DataTableFilterMetaData) {
  const value = filterMetaData.value as StateFilterModel[];
  if (!value?.length) return '';


  return value
    .map(stateFilter => stateFilter.code)
    .join(', ');
}

export default function loadedOrdersColumnDef() : LogisticsColumnProps[] {
  FilterService.register('custom', () => {
    return true;
  })
  return [
    {
      field: 'receivedDate',
      header: 'Received',
      style: { flex: '1 0 10em' },
      sortable: false,
      body: (rowData: LoadedOrder) => formatDate(rowData.receivedDate),
    },
    {
      field: 'pickUpAt',
      header: 'Pick-Up At',
      style: { flex: '1 0 10em' },
      body: pickUpAtTemplate,
      sortable: false,
      filter: true,
      showFilterMatchModes: false,
      showFilterMenu: false,
      filterField: 'pickUpAt',
      ...stateFilter(),
      filterValueToString: stateFilterToString,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiLoadedOrdersGetRequest) : ApiLoadedOrdersGetRequest => {
        return {
          ...serverFilterModel,
          pickUpAt: (filterMetaData.value as StateFilterModel[]).map(filterValue => {
            return filterValue.code;
          }),
        };
      },
    },
    {
      field: 'deliverTo',
      header: 'Deliver To',
      style: { flex: '1 0 10em' },
      sortable: false,
      filter: true,
      showFilterMatchModes: false,
      showFilterMenu: false,
      ...stateFilter(),
      filterValueToString: stateFilterToString,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiLoadedOrdersGetRequest) : ApiLoadedOrdersGetRequest => {
        return {
          ...serverFilterModel,
          deliverTo: (filterMetaData.value as StateFilterModel[]).map(filterValue => {
            return filterValue.code;
          }),
        };
      },
    },
    {
      field: 'vehicleType',
      header: 'Vehicle',
      style: { flex: '1 0 10em' },
      sortable: false,
      filter: true,
      filterElement: options => <DefaultServerFilterComponent {...options} />,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiLoadedOrdersGetRequest) : ApiLoadedOrdersGetRequest => {
        return {
          ...serverFilterModel,
          vehicle: `${filterMetaData.matchMode},${filterMetaData.value}`
        };
      },
      body: (rowData: LoadedOrder) => `${rowData.vehicleType}`,
      filterMatchModeOptions: [
        { value: 'contains', label: 'Contains' },
        { value: 'notContains', label: 'Not Contains' },
        { value: 'equals', label: 'Equals' },
        { value: 'notEquals', label: 'Not Equals' },
        { value: 'startsWith', label: 'Starts with' },
        { value: 'endsWith', label: 'Ends with' },
      ],
    },
    {
      field: 'miles',
      header: 'Miles',
      style: { flex: '0.5 0 4em' },
      sortable: false,
      filter: true,
      filterElement: options => <ServerInputNumber {...options} />,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiLoadedOrdersGetRequest) : ApiLoadedOrdersGetRequest => {
        return {
          ...serverFilterModel,
          miles: `${filterMetaData.matchMode},${filterMetaData.value}`
        };
      },
      filterMatchModeOptions: [
        { value: 'gt', label: '>' },
        { value: 'lt', label: '<' },
      ],
      filterMatchMode: 'gt',
    },
    {
      field: 'matchCount',
      header: 'Match',
      style: { flex: '0.5 0 12em' },
      sortable: false,
      body: matchBodyTemplate,
      filter: true,
      filterElement: options => <ServerInputNumber {...options} />,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiLoadedOrdersGetRequest) : ApiLoadedOrdersGetRequest => {
        return {
          ...serverFilterModel,
          match: `${filterMetaData.matchMode},${filterMetaData.value}`
        };
      },
      filterMatchModeOptions: [
        { value: 'gt', label: '>' },
        { value: 'lt', label: '<' },
      ],
      filterMatchMode: 'gt',
    },
    {
      field: 'brokerage',
      header: 'Brokerage',
      style: { flex: '1 0 10em' },
      sortable: false,
      filter: true,
      filterElement: options => <DefaultServerFilterComponent {...options} />,
      serverFilterRequest: (
        filterMetaData,
        serverFilterModel: ApiLoadedOrdersGetRequest) : ApiLoadedOrdersGetRequest => {
        return {
          ...serverFilterModel,
          brokerage: `${filterMetaData.matchMode},${filterMetaData.value}`
        };
      },
      filterMatchModeOptions: [
        { value: 'contains', label: 'Contains' },
        { value: 'notContains', label: 'Not Contains' },
        { value: 'equals', label: 'Equals' },
        { value: 'notEquals', label: 'Not Equals' },
        { value: 'startsWith', label: 'Starts with' },
        { value: 'endsWith', label: 'Ends with' },
      ],
      body: (rowData: LoadedOrder) => {
        const source = rowData.source ?
          `(${rowData.source.charAt(0)}) ` :
          '';
        return `${source}${rowData.brokerage || ''}`;
      },
    },
  ];
};

