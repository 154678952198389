import useBrowserPageStorage from '../../../hooks/BrowserStorageHook';
import { DataTableFilterMetaData } from 'primereact/datatable';
import { useLocalStorage } from 'primereact/hooks';
import { useCallback, useEffect } from 'react';
import { apiFactory } from '../../../shared';
import { FilterPreset, FilterPresetsApi } from '../../../generated-api';


export type TFilter = DataTableFilterMetaData & {
  caption: string,
  formattedValue: string,
}

export type TFilters = {
  [key: string]: TFilter,
} | undefined;

export type TFilterPreset = FilterPreset & {
  filters: TFilters
} | undefined;

export default function useFilterPresets(componentId: string)
  : [
  TFilterPreset[],
  TFilterPreset,
  (value: TFilterPreset) => any,
  (value: TFilterPreset) => Promise<void>,
  (value: TFilterPreset) => Promise<void>,
] {
  const currentPresetKey = componentId + '_currentPreset';
  const allPresetsKey = componentId + '_presets';
  const [currentPreset, setCurrentPreset]
    = useBrowserPageStorage<TFilterPreset>(currentPresetKey, undefined);
  const [allPresets, setAllPresets]
    = useLocalStorage<TFilterPreset[]>([], allPresetsKey);

  const reloadPresets = useCallback((loadedFilterPresets: FilterPreset[]) => {
    const parserFilterPresets = loadedFilterPresets.map(loadedFilterPreset => {
      return {
        ...loadedFilterPreset,
        filters: jsonToFilters(loadedFilterPreset.value)
      } as TFilterPreset;
    });

    const newCurrentPreset = parserFilterPresets
      .filter(x => x)
      .find(x => x!.id === currentPreset?.id) as TFilterPreset;
    setCurrentPreset(newCurrentPreset);
    setAllPresets(parserFilterPresets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPreset]);

  useEffect(() => {
    apiFactory(FilterPresetsApi)
      .apiFilterPresetsGet({ componentId: componentId})
      .then(loadedFilterPresets => {
        reloadPresets(loadedFilterPresets);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savePreset = async (preset: TFilterPreset) => {
    if (!preset) throw new Error('Preset should not be null');
    if (!preset.name || !preset.filters) throw new Error('Preset should have name and filters');

    preset.value = JSON.stringify(preset.filters);
    preset.componentId = componentId;
    const savedPreset = await apiFactory(FilterPresetsApi)
      .apiFilterPresetsPost({ filterPreset: preset });
    const tpreset = {
      ...savedPreset,
      filters: jsonToFilters(savedPreset.value)
    } as TFilterPreset;

    setAllPresets([...allPresets, tpreset]);
    setCurrentPreset(tpreset);
  }

  const deletePreset = async (deletingPreset: TFilterPreset) => {
    if (!deletingPreset) throw new Error('Preset should not be null');
    if (!deletingPreset.id) throw new Error('Preset should have id');

    await apiFactory(FilterPresetsApi)
      .apiFilterPresetsIdDelete({ id: deletingPreset.id });

    if (deletingPreset.id === currentPreset?.id) {
      setCurrentPreset(undefined);
    }

    setAllPresets(allPresets => {
      return allPresets.filter(preset => preset?.id !== deletingPreset.id);
    });
  }

  return [allPresets, currentPreset, setCurrentPreset, savePreset, deletePreset];
}

function jsonToFilters(json: string | null | undefined) : TFilters {
  if (!json) return undefined;

  try {
    const filters = JSON.parse(json) as TFilters;
    return filters || undefined;
  }
  catch (error) {
    console.error('Filter preset is corrupted: ', json);
    return undefined;
  }
}