import { useState } from 'react';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import LogisticsWeekCalendar from '../../form/datetime/LogisticsWeekCalendar';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import useBrowserPageStorage from '../../../hooks/BrowserStorageHook';

export interface ServerWeekCalendarProps extends ColumnFilterElementTemplateOptions {
  weekStorageValueName?: string | undefined
}

function ServerWeekCalendar(props: ServerWeekCalendarProps) {
  const [weekFromStorage, setWeekFromStorage] = useBrowserPageStorage<Dayjs | null>(props.weekStorageValueName || 'week', dayjs());
  const [selectedWeek, setSelectedWeek] = useState<Dayjs | null | undefined>(props.weekStorageValueName && weekFromStorage ? dayjs(weekFromStorage) : dayjs());

  const handleClick = (e: MouseEvent) => {
    // @ts-ignore
    e.target!.nextSibling!.click();
  };

  return (
    <LogisticsWeekCalendar
      value={selectedWeek}
      onChange={(value: Dayjs | null): void => {
        setSelectedWeek(value);
        setWeekFromStorage(value);
        props.filterApplyCallback(value ? `${value.format('YY-w')}-` : '');

        // removing event listeners for week clicking events
        document.querySelectorAll<HTMLElement>('.ant-picker-cell-week').forEach((e) => {
          e.removeEventListener("click", handleClick);
        });
      }}
      setSelectedWeek={setSelectedWeek}
      filterApplyCallback={props.filterApplyCallback}
      handleClick={handleClick}
      onListForm
    />
  );
}

export default ServerWeekCalendar;