import React, { useMemo, useRef, useState } from 'react';
import { Autocomplete, Libraries, LoadScript } from '@react-google-maps/api';
import useConfiguration from '../../../hooks/ConfigurationHook';
import CenteredSpinner from '../../../components/CenteredSpinner';
import styled from '@emotion/styled';
import { focusedInputStyle, inputStyle } from '../../../components/form/Styles';

export type GooglePlaceProps = {
  onValueSelected: (value: GoogleCityResult) => any,
  onInputChange: (value: string) => any,
  textValue: string | null | undefined,
}

export type GoogleCityResult = {
  zip: string | null | undefined,
  state: string | null | undefined,
  city: string | null | undefined,
}

const libraries: Libraries = ["places"];
const countryRestrictions = ['us', 'ca'];
const fieldsRestrictions = ['address_components'];
const addressTypes = ['locality', 'postal_code'];

const StyledInput = styled.input`
  & {
    ${inputStyle}
  }

  &:enabled:focus {
    ${focusedInputStyle}
  }
`

function GoogleCityAutocomplete(props: GooglePlaceProps) {
  const {config} = useConfiguration();
  const [autocompleteWidget, setAutocompleteWidget]
    = useState<google.maps.places.Autocomplete>(null as any);
  const inputRef = useRef<HTMLInputElement>(null);

  const autocomplete = useMemo(() => {
    if (!config.javaScriptMapsApiKey) return <CenteredSpinner visible />;

    return <>
      <LoadScript language='en' id="script-loader" googleMapsApiKey={config.javaScriptMapsApiKey} libraries={libraries}>
        <Autocomplete
          onLoad={setAutocompleteWidget}
          onPlaceChanged={() => {
            if (autocompleteWidget) {
              const places = autocompleteWidget.getPlace();
              const state = places.address_components?.find(
                x => x.types.find(
                  y => y === 'administrative_area_level_1'))?.long_name;
              const city = places.address_components?.find(
                x => x.types.find(
                  y => y === 'locality'))?.long_name;
              const zip = places.address_components?.find(
                x => x.types.find(
                  y => y === 'postal_code'))?.long_name;

              props.onValueSelected({ zip, city, state});
              inputRef.current!.value = zip || '';
            }
          }}
          options={{
            componentRestrictions: { country: countryRestrictions},
            fields: fieldsRestrictions,
            types: addressTypes,
          }}
        >
          <StyledInput
            type='text'
            className='p-inputtext'
            placeholder='ZIP'
            ref={inputRef}
            onChange={e => {
              props.onInputChange(e.target.value);
            }}
          />
        </Autocomplete>
      </LoadScript>
    </>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.javaScriptMapsApiKey, autocompleteWidget, props.onValueSelected]);

  return autocomplete;
}

export default GoogleCityAutocomplete;