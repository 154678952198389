import useBrowserPageStorage from '../../hooks/BrowserStorageHook';
import LogisticsTabs from '../../components/form/LogisticsTabs';
import { TabPanel } from 'primereact/tabview';
import TrackingList from './TrackingList';

function TrackingPage() {
  const [tabIndex, setTabIndex] = useBrowserPageStorage('tracking-tab', 1);

  return <LogisticsTabs
    activeIndex={tabIndex}
    onTabChange={e => setTabIndex(e.index)}
  >
    <TabPanel header="Tracking">
      <TrackingList delivered={false} />
    </TabPanel>
    <TabPanel header="Delivered">
      <TrackingList delivered={true} />
    </TabPanel>
  </LogisticsTabs>
}

export default TrackingPage;