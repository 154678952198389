import { createAction, createSlice } from '@reduxjs/toolkit';
import { LoadableState } from '../../shared';
import { UserDto } from '../../generated-api';

interface Authentication extends LoadableState {
  loaded: boolean,
  loading: boolean,
  isAuthenticated: boolean,
  account: UserDto,
  isSecondSession: boolean,
  token: string,
}

export interface AuthenticationState extends Readonly<Authentication> {

}

export const initialState: AuthenticationState = {
  loaded: false,
  loading: false,
  isAuthenticated: false,
  account: {} as any,
  isSecondSession: false,
  token: '',
};

export const setSecondSession = createAction(
  "setSecondSession",
  (secondSession: boolean) => {
    return { payload: secondSession }
});

export const setToken = createAction(
  "setToken",
  (token: string) => {
    return { payload: token }
  }
);

export const login = createAction(
  "login",
  (auth: AuthenticationState) => {
    return { payload: auth };
  }
);

export const logout = createAction(
  "logout",
)

export const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setSecondSession, (state, action) => ({
        ...state,
        isSecondSession: action.payload,
      }))
      .addCase(setToken, (state, action) => ({
        ...state,
        token: action.payload,
      }))
      .addCase(login, (state, action) => {
        const newState = action.payload;
        if (state.token === newState.token
            && state.isAuthenticated === newState.isAuthenticated
            && state.isSecondSession === newState.isSecondSession) {
          return;
        }

        return { ...state, ...newState };
      })
      .addCase(logout, state => initialState);
  },
});

// Reducer
export default AuthenticationSlice.reducer;
