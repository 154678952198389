import { useEffect, useMemo } from 'react';
import WidgetToolbar, { WidgetFilters } from '../components/WidgetToolbar';
import DashboardPanel from '../components/DashboardPanel';
import { DashboardApi, TopVehiclesResponse } from '../../../generated-api';
import { apiFactory } from '../../../shared';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { nameof } from 'ts-simple-nameof';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import useWidget from '../hooks/WidgetHook';
import { today } from '../../../shared/util/dateUtils';
import { getDashboardDispatchersTopVehicles } from '../DashboardSlice';

function TopVehicles() {
  const appDispatch = useAppDispatch();
  const dispatchers = useAppSelector(state => state.dashboard.dispatcherState?.topVehicles);
  const initialFilters : WidgetFilters = {
    periodType: 'Day',
    from: today().toDate(),
    dispatcherIds: dispatchers?.map(x => x.id || '') || [],
  };

  const {filters, setFilters, data, loadData } = useWidget({
    initialFilters: initialFilters,
    initialWidgetData: [],
    loadWidgetData: filters => apiFactory(DashboardApi)
      .apiDashboardTopVehiclesGet(filters),
    usesDispatchers: true,
    dispatcherState: state => state.dashboard.dispatcherState?.topVehicles,
    getDispatcherSliceAction: getDashboardDispatchersTopVehicles,
  });

  useEffect(() => {
    appDispatch(getDashboardDispatchersTopVehicles(initialFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const joinedTableData = useMemo(() => {
    return data.map(x => {
      const dispatcher = dispatchers?.find(d => d.id === x.dispatcher);
      const dispatcherName = dispatcher ? `${dispatcher.firstName} ${dispatcher.lastName}` : x.dispatcher;

      return { ...x, dispatcher: dispatcherName } as TopVehiclesResponse;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return <StyledDashboardPanel>
    <header className='flex'>
      <span className='text-3xl font-medium white-space-nowrap flex justify-content-start align-items-center flex-grow-1 gap-2'>
        Top vehicles
      </span>
      <span className='flex justify-content-end align-items-center flex-grow-1 gap-2'>
        <WidgetToolbar
          value={filters}
          dispatcherState={(state) => state.dashboard.dispatcherState?.topVehicles}
          onChange={setFilters}
          onFilter={loadData}
          visibility={{
            period: false,
            from: true,
            to: false,
            dispatchers: true,
            filterButton: true,
          }}
        />
      </span>
    </header>
    <div className='flex flex-column pt-3'>
      <StyledTable
        dataSource={joinedTableData}
        columns={columns}
        pagination={false}
        size='small'
        bordered
        summary={(pageData : readonly TopVehiclesResponse[]) => {
          let totalVehicleTotal = 0;
          let totalVehicleInService = 0;
          let totalVehicleUsed = 0;

          pageData.forEach(({ vehiclesTotal }) => {
            totalVehicleTotal += vehiclesTotal || 0;
          });

          pageData.forEach(({ vehiclesInService }) => {
            totalVehicleInService += vehiclesInService || 0;
          });

          pageData.forEach(({ vehiclesUsed }) => {
            totalVehicleUsed += vehiclesUsed || 0;
          });

          return <>
            <Table.Summary.Row>
              <Table.Summary.Cell align='center' index={0}>
                <b>All</b>
                </Table.Summary.Cell>
              <Table.Summary.Cell align='center' index={1}>
                <b>{totalVehicleTotal}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell align='center' index={2}>
                <b>{totalVehicleInService}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell align='center' index={3}>
                <b>{totalVehicleUsed}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        }}
      />
    </div>
  </StyledDashboardPanel>
}

const StyledTable = styled(Table)`
  & {
    height: 400px;
    overflow: auto;
  }
`

const StyledDashboardPanel = styled(DashboardPanel)`
& {
  align-self: end;
}
`

const columns: ColumnType<TopVehiclesResponse>[] = [
  {
    title: 'Dispatcher',
    dataIndex: nameof<TopVehiclesResponse>(x => x.dispatcher),
    key: nameof<TopVehiclesResponse>(x => x.dispatcher),
    width: 400,
    sorter: (a, b) => a.dispatcher!.localeCompare(b.dispatcher!)
  },
  {
    title: 'Vehicles total',
    dataIndex: nameof<TopVehiclesResponse>(x => x.vehiclesTotal),
    key: nameof<TopVehiclesResponse>(x => x.vehiclesTotal),
    align: 'center',
    width: 100,
    sorter: (a, b) => (a.vehiclesTotal  || 0) - (b.vehiclesTotal || 0)
  },
  {
    title: 'Vehicles in service',
    dataIndex: nameof<TopVehiclesResponse>(x => x.vehiclesInService),
    key: nameof<TopVehiclesResponse>(x => x.vehiclesInService),
    align: 'center',
    width: 100,
    sorter: (a, b) => (a.vehiclesInService || 0) - (b.vehiclesInService || 0)
  },
  {
    title: 'Vehicles used',
    dataIndex: nameof<TopVehiclesResponse>(x => x.vehiclesUsed),
    key: nameof<TopVehiclesResponse>(x => x.vehiclesUsed),
    align: 'center',
    width: 100,
    sorter: (a, b) => (a.vehiclesUsed || 0) - (b.vehiclesUsed || 0)
  }
];

export default TopVehicles;
